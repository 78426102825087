// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivityIcon-module__activityIcon___QsuLX{display:inline-flex !important;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./components/ActivityIcon/ActivityIcon.module.scss"],"names":[],"mappings":"AAEA,2CACE,8BAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":["@import '~styles/variables';\n\n.activityIcon {\n  display: inline-flex !important;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityIcon": "ActivityIcon-module__activityIcon___QsuLX"
};
export default ___CSS_LOADER_EXPORT___;
