import React, { useContext } from 'react'

import deleteFlow from 'mutations/deleteFlow'

import { ModalContext } from 'components/Modal/ModalProvider'
import { ToastContext } from 'components/NotificationCenter'
import { ConfirmationModal } from 'components/modals/ConfirmationModal'
import modalStyles from 'components/modals/ConfirmationModal.module.scss'

const useDeleteFlowErrors = ({ flowId }: { flowId: string }) => {
  const { displayToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)

  const handleDeleteFlowErrors = (e: Error) => {
    const error = JSON.parse(e.message)
    const { type } = error
    if (
      type === 'DependancyError' ||
      type === 'ScoringInputError' ||
      type === 'InheritorError'
    ) {
      // Extract the activity titles from the error message
      const colonPosition = error.message.search(':')
      const activityTitles = error.message.substr(colonPosition + 1)
      let message
      if (type === 'DependancyError') {
        message = (
          <p className={modalStyles.centeredConfirmationMessage}>
            An activity in this flow is being used as a source. To delete it you must
            first delete the activities <strong>{activityTitles}</strong>.
          </p>
        )
      } else if (type === 'ScoringInputError') {
        message = (
          <p className={modalStyles.centeredConfirmationMessage}>
            An activity in this flow is being used as an input to one or more Scoring
            activities. To delete it you must first delete the activities{' '}
            <strong>{activityTitles}</strong>.
          </p>
        )
      } else {
        message = (
          <p className={modalStyles.centeredConfirmationMessage}>
            A group formation activity in the flow you are trying to delete is being
            used by other activities in the course. To delete it you must first update
            the group settings for the following activities:{' '}
            <strong>{activityTitles}</strong>
          </p>
        )
      }
      showModal(ConfirmationModal, {
        confirmBtnStyle: 'cancel' as any,
        confirmText: 'Understood',
        danger: true,
        hasCancelButton: false,
        isOpen: false,
        message,
        title: 'Flow cannot be deleted',
      })
    } else if (type === 'UsedForGradePassbackError') {
      showModal(ConfirmationModal, {
        confirmBtnStyle: 'cancel' as any,
        confirmText: 'Understood',
        danger: true,
        hasCancelButton: false,
        isOpen: false,
        message: (
          <p className={modalStyles.centeredConfirmationMessage}>
            An activity in this flow is being used as an input for a Grade Passback
            activity. To delete this flow you first need to delete the Grade Passback
            activity.
          </p>
        ),
        title: 'Flow cannot be deleted',
      })
    } else if (type === 'InvalidRulesError') {
      showModal(ConfirmationModal, {
        danger: true,
        isOpen: false,
        message: (
          <p className={modalStyles.centeredConfirmationMessage}>
            One or more activities in this flow are referenced by one or more rules.
            Deleting this flow will also delete any rules that reference any activities
            in it.
          </p>
        ),
        title: 'Invalid rules will be removed',
        onConfirm: () => deleteFlow({ flowId, force: true }),
      })
    } else {
      displayToast('Error deleting flow', 'error')
    }
  }

  return handleDeleteFlowErrors
}

export default useDeleteFlowErrors
