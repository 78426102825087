import React, { createContext, useContext, useState } from 'react'

import { GQLActivityPosition } from 'types/graphqlTypes'

export const INSERT_POS_TOP: GQLActivityPosition = 'TOP'
export const INSERT_POS_BOTTOM: GQLActivityPosition = 'BOTTOM'

export interface InsertAttributes {
  flowId: string
  flowTitle: string
  position: GQLActivityPosition
}

interface IInsertActivityContext {
  insertAttributes: InsertAttributes | null
  setInsertAttributes: (props: InsertAttributes) => void
  resetActivityInsertionState: () => void
}

const InsertActivityContext = createContext<IInsertActivityContext>({
  insertAttributes: null,
  setInsertAttributes: () => undefined,
  resetActivityInsertionState: () => undefined,
})

export const useInsertActivity = () => useContext(InsertActivityContext)

const InsertActivityProvider = ({ children }: { children: React.ReactNode }) => {
  const [insertAttributes, setInsertAttributes] = useState<InsertAttributes | null>(
    null,
  )

  const resetInsertionState = () => {
    setInsertAttributes(null)
  }

  return (
    <InsertActivityContext.Provider
      value={{
        insertAttributes,
        setInsertAttributes,
        resetActivityInsertionState: resetInsertionState,
      }}
    >
      {children}
    </InsertActivityContext.Provider>
  )
}

export default InsertActivityProvider
