import classNames from 'classnames/bind'
import React from 'react'

import ErrorSVG from 'assets/illustrations/Error.inline.svg'

import { Button } from 'components/Button'

import styles from './ErrorMessage.module.scss'

const cx = classNames.bind(styles)

interface IErrorMessageProps {
  Animated?: boolean
  test?: string
  dataTest?: string
  className?: string
  children?: React.ReactNode
}

export const ErrorMessage = (props: IErrorMessageProps) => {
  const errorMessageClasses = cx('error-message', {
    animated: props.Animated,
  })

  const extraProps = {
    'data-test': props.test || props.dataTest,
  }

  return (
    <div className={`${errorMessageClasses} ${props.className || ''}`} {...extraProps}>
      <span>{props.children}</span>
    </div>
  )
}

export const ReloadErrorMessage = () => {
  return (
    <div className="tc mt12">
      <ErrorSVG className="w240 h100" />
      <p className="mt6 fs-s fw2">
        Oops, something went wrong! You will be redirected to the overview in 5 seconds.
      </p>
    </div>
  )
}

export const ErrorMessageWithHomeButton = () => {
  return (
    <div className="tc mt12">
      <ErrorSVG className="w240 h100" />
      <p className="mt6 mb6 fs-m fw2">Oops, something went wrong!</p>
      <Button Large href="/">
        Take me home
      </Button>
    </div>
  )
}

export const ErrorMessageWithReloadButton: React.FC = ({ children }) => {
  return (
    <div className="tc mt12">
      <ErrorSVG className="w240 h100" />
      {children}
      <Button Large onClick={() => window.location.reload()}>
        Reload the page
      </Button>
    </div>
  )
}
