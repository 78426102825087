import moment from 'moment-timezone'

export const WARNING_DAYS = 7

export const daysSincePlanLimitsExceeded = (limitsExceededAt: Date) => {
  return moment.utc().startOf('day').diff(moment.utc(limitsExceededAt), 'days')
}

export const warningDaysLeft = (limitsExceededAt: Date) =>
  WARNING_DAYS - daysSincePlanLimitsExceeded(limitsExceededAt)

export const hasLimitsExceededWarning = (limitsExceededAt: Date) =>
  daysSincePlanLimitsExceeded(limitsExceededAt) < WARNING_DAYS

export const isInstitutionBlocked = (limitsExceededAt: Date) =>
  daysSincePlanLimitsExceeded(limitsExceededAt) >= WARNING_DAYS
