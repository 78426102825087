/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditCourseLibraryQueryVariables = {};
export type EditCourseLibraryQueryResponse = {
    readonly institution: {
        readonly id: string;
        readonly courseLibrary: {
            readonly id: string;
            readonly sections: ReadonlyArray<{
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"EditSection_section">;
            }>;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"EditSection_institution">;
    } | null;
};
export type EditCourseLibraryQuery = {
    readonly response: EditCourseLibraryQueryResponse;
    readonly variables: EditCourseLibraryQueryVariables;
};



/*
query EditCourseLibraryQuery {
  institution {
    id
    courseLibrary {
      id
      sections {
        id
        ...EditSection_section
      }
    }
    ...EditSection_institution
  }
}

fragment EditSection_institution on Institution {
  ...SelectableCourses_institution
}

fragment EditSection_section on CourseLibrarySection {
  id
  title
  courses {
    id
  }
}

fragment SelectableCourse_course on Course {
  id
  title
  thumbnail
  isViewerTeacher
  url
  ...StackedTeacherBadges_course
}

fragment SelectableCourses_institution on Institution {
  courses {
    edges {
      node {
        id
        ...SelectableCourse_course
      }
    }
  }
}

fragment StackedTeacherBadges_course on Course {
  teacherShortList {
    id
    name
    badge {
      ...UserBadge_badge
    }
  }
  teachersCount
}

fragment UserBadge_badge on UserBadge {
  text
  profileImage
  backgroundColor {
    hex
    isLight
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCourseLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Institution",
        "kind": "LinkedField",
        "name": "institution",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseLibrary",
            "kind": "LinkedField",
            "name": "courseLibrary",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseLibrarySection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditSection_section"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditSection_institution"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EditCourseLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Institution",
        "kind": "LinkedField",
        "name": "institution",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseLibrary",
            "kind": "LinkedField",
            "name": "courseLibrary",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseLibrarySection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "courses",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseConnection",
            "kind": "LinkedField",
            "name": "courses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CourseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Course",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isViewerTeacher",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScopedUser",
                        "kind": "LinkedField",
                        "name": "teacherShortList",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserBadge",
                            "kind": "LinkedField",
                            "name": "badge",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profileImage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Color",
                                "kind": "LinkedField",
                                "name": "backgroundColor",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hex",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isLight",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "teachersCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4a5f70380756b7e40e29ba765f4376c",
    "id": null,
    "metadata": {},
    "name": "EditCourseLibraryQuery",
    "operationKind": "query",
    "text": "query EditCourseLibraryQuery {\n  institution {\n    id\n    courseLibrary {\n      id\n      sections {\n        id\n        ...EditSection_section\n      }\n    }\n    ...EditSection_institution\n  }\n}\n\nfragment EditSection_institution on Institution {\n  ...SelectableCourses_institution\n}\n\nfragment EditSection_section on CourseLibrarySection {\n  id\n  title\n  courses {\n    id\n  }\n}\n\nfragment SelectableCourse_course on Course {\n  id\n  title\n  thumbnail\n  isViewerTeacher\n  url\n  ...StackedTeacherBadges_course\n}\n\nfragment SelectableCourses_institution on Institution {\n  courses {\n    edges {\n      node {\n        id\n        ...SelectableCourse_course\n      }\n    }\n  }\n}\n\nfragment StackedTeacherBadges_course on Course {\n  teacherShortList {\n    id\n    name\n    badge {\n      ...UserBadge_badge\n    }\n  }\n  teachersCount\n}\n\nfragment UserBadge_badge on UserBadge {\n  text\n  profileImage\n  backgroundColor {\n    hex\n    isLight\n  }\n}\n"
  }
};
})();
(node as any).hash = '7928bb74a7c02650bade238bd502e55e';
export default node;
