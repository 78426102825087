/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TestUsersOptionsQueryVariables = {
    courseId: string;
};
export type TestUsersOptionsQueryResponse = {
    readonly course: {
        readonly hasTestRealUserMix: boolean;
        readonly testAssistantCount: number | null;
        readonly testLearnerCount: number | null;
        readonly " $fragmentRefs": FragmentRefs<"TestUsersContextMenu_course">;
    } | null;
};
export type TestUsersOptionsQuery = {
    readonly response: TestUsersOptionsQueryResponse;
    readonly variables: TestUsersOptionsQueryVariables;
};



/*
query TestUsersOptionsQuery(
  $courseId: ID!
) {
  course(id: $courseId) {
    hasTestRealUserMix
    testAssistantCount
    testLearnerCount
    ...TestUsersContextMenu_course
    id
  }
}

fragment TestUsersContextMenu_course on Course {
  id
  testLearnerCount
  testAssistantCount
  hasTestRealUserMix
  testLearners {
    id
    name
    badge {
      ...UserBadge_badge
    }
  }
  testAssistants {
    id
    name
    badge {
      ...UserBadge_badge
    }
  }
  institution {
    hasPreviewCourseAssistants: isFeatureEnabled(feature: ASSISTANT_ROLE)
    id
  }
}

fragment UserBadge_badge on UserBadge {
  text
  profileImage
  backgroundColor {
    hex
    isLight
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "courseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTestRealUserMix",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testAssistantCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "testLearnerCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserBadge",
    "kind": "LinkedField",
    "name": "badge",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profileImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Color",
        "kind": "LinkedField",
        "name": "backgroundColor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLight",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestUsersOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TestUsersContextMenu_course"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestUsersOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "testLearners",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "testAssistants",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Institution",
            "kind": "LinkedField",
            "name": "institution",
            "plural": false,
            "selections": [
              {
                "alias": "hasPreviewCourseAssistants",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "feature",
                    "value": "ASSISTANT_ROLE"
                  }
                ],
                "kind": "ScalarField",
                "name": "isFeatureEnabled",
                "storageKey": "isFeatureEnabled(feature:\"ASSISTANT_ROLE\")"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4abc97dfb3b32155298bf35fa3629dda",
    "id": null,
    "metadata": {},
    "name": "TestUsersOptionsQuery",
    "operationKind": "query",
    "text": "query TestUsersOptionsQuery(\n  $courseId: ID!\n) {\n  course(id: $courseId) {\n    hasTestRealUserMix\n    testAssistantCount\n    testLearnerCount\n    ...TestUsersContextMenu_course\n    id\n  }\n}\n\nfragment TestUsersContextMenu_course on Course {\n  id\n  testLearnerCount\n  testAssistantCount\n  hasTestRealUserMix\n  testLearners {\n    id\n    name\n    badge {\n      ...UserBadge_badge\n    }\n  }\n  testAssistants {\n    id\n    name\n    badge {\n      ...UserBadge_badge\n    }\n  }\n  institution {\n    hasPreviewCourseAssistants: isFeatureEnabled(feature: ASSISTANT_ROLE)\n    id\n  }\n}\n\nfragment UserBadge_badge on UserBadge {\n  text\n  profileImage\n  backgroundColor {\n    hex\n    isLight\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cab60db390488730d72441b920b3b92f';
export default node;
