import classNames from 'classnames'
import React from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import { TestUsersOptionsQuery } from '__generated__/TestUsersOptionsQuery.graphql'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { Icons } from 'components/Icons'
import { Spinner } from 'components/Spinner'
import { useTestUsersContextMenu } from 'components/TestUsersContextMenu/TestUsersContextMenu'

import styles from './Sidebar.module.scss'

interface IProps {
  courseId: string
}

const TestUsersOptions = ({ courseId }: IProps) => {
  const data = useLazyLoadQuery<TestUsersOptionsQuery>(
    graphql`
      query TestUsersOptionsQuery($courseId: ID!) {
        course(id: $courseId) {
          hasTestRealUserMix
          testAssistantCount
          testLearnerCount
          ...TestUsersContextMenu_course
        }
      }
    `,
    { courseId },
  )

  if (!data?.course) {
    return null
  }

  const { renderElement, isRemovingTestUsers } = useTestUsersContextMenu({
    course: data.course,
  })
  const renderLabel = (
    ref: React.RefObject<HTMLElement>,
    { onClick }: { onClick: () => void },
  ) => {
    return (
      <Button
        Icon
        Small
        ref={ref}
        onClick={onClick}
        isLoading={isRemovingTestUsers}
        Disabled={isRemovingTestUsers}
      >
        {isRemovingTestUsers ? <Spinner accentColor /> : <Icons.ThreeDots nearBlack />}
      </Button>
    )
  }

  const { testAssistantCount, testLearnerCount } = data.course

  const totalTestUsers = (testAssistantCount ?? 0) + (testLearnerCount ?? 0)

  if (totalTestUsers < 1) {
    return null
  }

  const containerStyles = classNames(styles.previewCourseOptions, {
    [styles.hasTestRealUserMix]: data.course.hasTestRealUserMix,
  })

  return (
    <div className={containerStyles}>
      <span>
        {data.course.hasTestRealUserMix ? (
          <Icons.WarningTriangle nearBlack className={styles.icon} />
        ) : (
          <span className={styles.badge}>{totalTestUsers}</span>
        )}
        Test users
      </span>
      <ContextMenu
        renderLabel={renderLabel}
        renderElement={renderElement}
        targetAttachment="bottom left"
        attachment="top left"
      />
    </div>
  )
}

export default TestUsersOptions
