import moment from 'moment'
import React, { useEffect } from 'react'
import { useCurrentRoute } from 'react-navi'
import { useQuery, graphql } from 'relay-hooks'

import {
  UserContextQuery,
  UserContextQueryResponse,
} from '__generated__/UserContextQuery.graphql'
import { DEFAULT_LANGUAGE } from 'utils/constants'

const DEFAULT_USER_CONTEXT = {
  me: null,
  globalUser: null,
  institution: null,
}

export const UserContext =
  React.createContext<UserContextQueryResponse>(DEFAULT_USER_CONTEXT)

/* eslint-disable relay/unused-fields */
/* eslint-disable relay/must-colocate-fragment-spreads */
const query = graphql`
  query UserContextQuery {
    me {
      id
      language
      canChangeLanguage
      timeFormat24
      ...ImpersonationBar_user
      ...UserTracking_user
      ...AppHeader_user
      ...CKEditor_user
      ...UpgradePlanInfo_me
      ...InstitutionParticipantsContextMenu_me
      # Student submission form
      ...SubmissionFormButtons_me
      ...PreviewButtons_me
    }
    globalUser {
      ...AppHeader_globalUser
    }
    institution {
      ...InstitutionBlockedBanner_institution
    }
  }
`

const LOGGED_OUT = 'logged_out'

const onStorageChange = (event: StorageEvent) => {
  if (event.key === 'user_id' && event.newValue !== event.oldValue) {
    setTimeout(() => {
      window.location.assign('/')
    }, 250)
  }
}

export const UserProvider: React.FC = ({ children }) => {
  const results = useQuery<UserContextQuery>(query)
  const {
    data: { isLocalizationDisabledForRoute },
  } = useCurrentRoute()
  const isLoading = !results.data
  const props = results['data'] ?? DEFAULT_USER_CONTEXT
  const language = props?.me?.language || DEFAULT_LANGUAGE
  const canChangeLanguage = props?.me?.canChangeLanguage ?? false

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem('user_id', props.me?.id || LOGGED_OUT)
    }
  }, [props])

  useEffect(() => {
    window.addEventListener('storage', onStorageChange)

    return () => window.removeEventListener('storage', onStorageChange)
  }, [])

  useEffect(() => {
    if (!canChangeLanguage) {
      return
    }
    // Load moment locales dynamically one by one
    // See https://danlaush.biz/posts/exploring-dynamic-imports
    ;(async () => {
      switch (language) {
        case 'da':
          // @ts-ignore
          return await import(`moment/locale/da`)
        case 'fr':
          // @ts-ignore
          return await import(`moment/locale/fr`)
        case 'es':
          // @ts-ignore
          return await import(`moment/locale/es`)
        default:
          return null // language === en goes here, moment default
      }
    })()
    moment.locale(language)

    // Load CKEditor locales dynamically
    ;(async () => {
      switch (language) {
        case 'da':
          return await import(
            // @ts-ignore
            '@peergrade/ckeditor5-build-eduflow/build/translations/da'
          )
        case 'fr':
          return await import(
            // @ts-ignore
            '@peergrade/ckeditor5-build-eduflow/build/translations/fr'
          )
        case 'es':
          return await import(
            // @ts-ignore
            '@peergrade/ckeditor5-build-eduflow/build/translations/es'
          )
        default:
          return null // language === en goes here, moment default
      }
    })()
  }, [language, canChangeLanguage, isLocalizationDisabledForRoute])

  return <UserContext.Provider value={props}>{children}</UserContext.Provider>
}
