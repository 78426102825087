import classNames from 'classnames/bind'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd'
import { useCurrentRoute } from 'react-navi'

import { toGlobalId } from 'utils/relay'

import styles from './Tree.module.scss'

const cx = classNames.bind(styles)

type ItemId = string
type TreeItemData = any

export interface ITreeData {
  head?: ITreeItem
  root: ITreeItem
}

export interface IRenderItemParams {
  item: ITreeItem
  isDragging?: boolean
  itemClass?: string
  emptyItemClass?: string
  borderBottom?: boolean
  borderTop?: boolean
  handleToggleFlowCollapsed?: (flowId: string) => void
  isCollapsed?: boolean
  wasCollapsed?: boolean
}

export interface ITreeItem {
  id: ItemId
  children: readonly ITreeItem[]
  isNode?: boolean
  isPlaceholder?: boolean
  isGhostActivity?: boolean
  data?: TreeItemData
}

export interface ITreeSourcePosition {
  parentId: ItemId
  index: number
}

export interface ITreeDestinationPosition {
  parentId: ItemId
  index?: number
}

export interface ITreeProps {
  tree: ITreeData
  renderItem: (itemProps: IRenderItemParams) => ReactElement
  onDragEnd: DragDropContextProps['onDragEnd']
  isDragEnabled: boolean
}

const Tree = ({ tree, onDragEnd, renderItem, isDragEnabled }: ITreeProps) => {
  const { root, head } = tree
  const [draggableId, setDraggableId] = useState<string | null>(null)
  const [borderBottomIndex, setBorderBottomIndex] = useState<number | null>()
  const [borderTopIndex, setBorderTopIndex] = useState<number | null>()
  const onDragStart: DragDropContextProps['onDragStart'] = (start) => {
    if (start.source.droppableId === tree.root.id) {
      setBorderTopIndex(start.source.index + 1)
      setBorderBottomIndex(start.source.index - 1)
    }
    setDraggableId(start.draggableId)
  }
  const handleDragEnd: DragDropContextProps['onDragEnd'] = (result, provided) => {
    setDraggableId(null)
    setBorderBottomIndex(null)
    setBorderTopIndex(null)
    return onDragEnd(result, provided)
  }
  const onDragUpdate: DragDropContextProps['onDragUpdate'] = (start) => {
    if (
      start.destination &&
      start.source &&
      start.destination.droppableId === tree.root.id
    ) {
      const destinationIndex = start.destination.index
      let sourceIndex = start.source.index
      if (start.source.droppableId !== tree.root.id) {
        sourceIndex = root.children
          .map(({ id }) => id)
          .indexOf(start.source.droppableId)
        if (destinationIndex === sourceIndex) {
          setBorderTopIndex(sourceIndex)
          setBorderBottomIndex(sourceIndex - 1)
        } else {
          setBorderTopIndex(destinationIndex)
          setBorderBottomIndex(destinationIndex - 1)
        }
      } else {
        if (destinationIndex === sourceIndex) {
          setBorderTopIndex(sourceIndex + 1)
          setBorderBottomIndex(sourceIndex - 1)
        } else if (destinationIndex > sourceIndex) {
          setBorderBottomIndex(destinationIndex)
          setBorderTopIndex(destinationIndex + 1)
        } else {
          setBorderBottomIndex(destinationIndex - 1)
          setBorderTopIndex(destinationIndex)
        }
      }
    } else {
      setBorderTopIndex(null)
      setBorderBottomIndex(null)
    }
  }

  const isDropEnabled = useCallback(
    (droppableId: string) => {
      if (!draggableId) {
        return true
      }
      return (
        (draggableId.includes('outer') && droppableId === tree.root.id) ||
        (!draggableId.includes('outer') && droppableId !== tree.root.id)
      )
    },
    [draggableId],
  )

  const {
    data: { flowId },
  } = useCurrentRoute()

  let initFlowCollapsedState: { [flowId: string]: boolean } = {}
  const flowCollapseState = localStorage.getItem('flowCollapseState')
  if (flowCollapseState && flowCollapseState !== 'undefined') {
    initFlowCollapsedState = JSON.parse(flowCollapseState)
  } else {
    root.children.map((item) => {
      if (!item.isNode) {
        initFlowCollapsedState[item.id] = item.id !== toGlobalId('Flow', flowId)
      }
    })
  }

  const [oldFlowCollapsedState, setOldFlowCollapsedState] =
    useState(initFlowCollapsedState)
  const [flowCollapsedState, setFlowCollapsedState] = useState(initFlowCollapsedState)
  const handleToggleFlowCollapsed = (id: string, isTeacherTitleClick?: boolean) => {
    // Store the old flow collapsed state to manage the yellow background of activities with subsets
    setOldFlowCollapsedState(flowCollapsedState)

    const newState = {
      ...flowCollapsedState,
      [id]:
        isTeacherTitleClick && !flowCollapsedState[id]
          ? flowCollapsedState[id]
          : !flowCollapsedState[id],
    }
    setFlowCollapsedState(newState)
    setTimeout(() => setOldFlowCollapsedState(newState), 1000)
    localStorage.setItem('flowCollapseState', JSON.stringify(newState))
  }

  useEffect(() => {
    const newState = {
      ...flowCollapsedState,
      [toGlobalId('Flow', flowId)]: false,
    }
    setOldFlowCollapsedState(newState)
    setFlowCollapsedState(newState)
    localStorage.setItem('flowCollapseState', JSON.stringify(newState))
  }, [flowId])

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      {head && (
        <Droppable droppableId={head.id} isDropDisabled={!isDropEnabled(head.id)}>
          {(innerDropProvided, innerDropSnapshot) => {
            return (
              <div
                {...innerDropProvided.droppableProps}
                ref={innerDropProvided.innerRef}
                className={cx('headDroppable', {
                  isDraggingOver: innerDropSnapshot.isDraggingOver,
                })}
              >
                {head.children.map((child, innerIndex) => {
                  return (
                    <Draggable
                      key={child.id}
                      draggableId={child.id}
                      index={innerIndex}
                      isDragDisabled={!isDragEnabled}
                    >
                      {(innerDragProvided, innerDragSnapshot) => {
                        const itemNode = (
                          <div
                            ref={innerDragProvided.innerRef}
                            {...innerDragProvided.draggableProps}
                            {...innerDragProvided.dragHandleProps}
                          >
                            {renderItem({
                              emptyItemClass: styles.emptyListItem,
                              isDragging: innerDragSnapshot.isDragging,
                              item: child,
                              itemClass: cx('listItem', {
                                isDragging: innerDragSnapshot.isDragging,
                              }),
                            })}
                          </div>
                        )
                        return itemNode
                      }}
                    </Draggable>
                  )
                })}
                {innerDropProvided.placeholder}
              </div>
            )
          }}
        </Droppable>
      )}
      {root.children.length > 0 && (
        <Droppable
          droppableId={tree.root.id}
          isDropDisabled={!isDragEnabled || !isDropEnabled(tree.root.id)}
        >
          {(outerDropProvided, outerDropSnapshot) => {
            return (
              <div
                {...outerDropProvided.droppableProps}
                ref={outerDropProvided.innerRef}
                className={cx('tree', {
                  isDragging: !!draggableId,
                  isDraggingOver: outerDropSnapshot.isDraggingOver,
                })}
              >
                {root.children.map((item, index) => {
                  const borderBottom = index === borderBottomIndex
                  const borderTop = index === borderTopIndex

                  return (
                    <Draggable
                      key={item.id}
                      draggableId={`outer-${item.id}`}
                      index={index}
                      isDragDisabled={!isDragEnabled}
                    >
                      {(
                        outerDragProvided: DraggableProvided,
                        outerDragSnapshot: DraggableStateSnapshot,
                      ) => {
                        return (
                          <div
                            ref={outerDragProvided.innerRef}
                            {...outerDragProvided.draggableProps}
                            {...outerDragProvided.dragHandleProps}
                            className={cx('listItem', 'innerWrapper', {
                              borderBottom,
                              borderTop,
                              dragging: outerDragSnapshot.isDragging,
                              isCollapsed: flowCollapsedState[item.id],
                            })}
                          >
                            <div
                              className={cx('innerContainer', {
                                isDragging: outerDragSnapshot.isDragging,
                              })}
                            >
                              <Droppable
                                droppableId={item.id}
                                isDropDisabled={
                                  !isDropEnabled(item.id) || !isDragEnabled
                                }
                              >
                                {(innerDropProvided, innerDropSnapshot) => {
                                  return (
                                    <div
                                      {...innerDropProvided.droppableProps}
                                      ref={innerDropProvided.innerRef}
                                      className={cx('innerDroppable', {
                                        isDraggingOver:
                                          innerDropSnapshot.isDraggingOver,
                                      })}
                                    >
                                      {!item.isNode &&
                                        renderItem({
                                          handleToggleFlowCollapsed,
                                          isCollapsed: flowCollapsedState[item.id],
                                          isDragging: outerDragSnapshot.isDragging,
                                          item,
                                          itemClass: styles.listItem,
                                        })}
                                      {!flowCollapsedState[item.id] &&
                                        item.children.map((child, innerIndex) => {
                                          if (child.isPlaceholder) {
                                            return (
                                              <div key={child.id}>
                                                {renderItem({
                                                  emptyItemClass: styles.emptyListItem,
                                                  item: child,
                                                  itemClass: cx('listItem'),
                                                })}
                                              </div>
                                            )
                                          }
                                          if (child.isGhostActivity) {
                                            return (
                                              <div key={child.id}>
                                                {renderItem({
                                                  emptyItemClass: styles.emptyListItem,
                                                  item: child,
                                                  itemClass: cx('listItem'),
                                                })}
                                              </div>
                                            )
                                          }
                                          return (
                                            <Draggable
                                              key={child.id}
                                              draggableId={child.id}
                                              index={innerIndex}
                                              isDragDisabled={!isDragEnabled}
                                            >
                                              {(
                                                innerDragProvided,
                                                innerDragSnapshot,
                                              ) => {
                                                const itemNode = (
                                                  <div
                                                    ref={innerDragProvided.innerRef}
                                                    {...innerDragProvided.draggableProps}
                                                    {...innerDragProvided.dragHandleProps}
                                                  >
                                                    {renderItem({
                                                      emptyItemClass:
                                                        styles.emptyListItem,
                                                      isDragging:
                                                        innerDragSnapshot.isDragging,
                                                      item: child,
                                                      itemClass: cx('listItem', {
                                                        isDragging:
                                                          innerDragSnapshot.isDragging,
                                                      }),
                                                      wasCollapsed:
                                                        oldFlowCollapsedState[item.id],
                                                    })}
                                                  </div>
                                                )
                                                return itemNode
                                              }}
                                            </Draggable>
                                          )
                                        })}
                                      {innerDropProvided.placeholder}
                                    </div>
                                  )
                                }}
                              </Droppable>
                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {outerDropProvided.placeholder}
              </div>
            )
          }}
        </Droppable>
      )}
    </DragDropContext>
  )
}

export default Tree
