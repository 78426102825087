/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateCourseThumbnailInput = {
    courseId: string;
    thumbnail: string;
    unsplashPingUrl?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type updateCourseThumbnailMutationVariables = {
    input: UpdateCourseThumbnailInput;
};
export type updateCourseThumbnailMutationResponse = {
    readonly updateCourseThumbnail: {
        readonly course: {
            readonly id: string;
            readonly thumbnail: string | null;
        } | null;
    } | null;
};
export type updateCourseThumbnailMutation = {
    readonly response: updateCourseThumbnailMutationResponse;
    readonly variables: updateCourseThumbnailMutationVariables;
};



/*
mutation updateCourseThumbnailMutation(
  $input: UpdateCourseThumbnailInput!
) {
  updateCourseThumbnail(input: $input) {
    course {
      id
      thumbnail
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCourseThumbnailPayload",
    "kind": "LinkedField",
    "name": "updateCourseThumbnail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCourseThumbnailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCourseThumbnailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bd18370a2a441e860925c272d5ef919d",
    "id": null,
    "metadata": {},
    "name": "updateCourseThumbnailMutation",
    "operationKind": "mutation",
    "text": "mutation updateCourseThumbnailMutation(\n  $input: UpdateCourseThumbnailInput!\n) {\n  updateCourseThumbnail(input: $input) {\n    course {\n      id\n      thumbnail\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a3acad8049b770790106940f9f082c5b';
export default node;
