/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseContextMenu_course = {
    readonly id: string;
    readonly isArchived: boolean | null;
    readonly isViewerOwner: boolean | null;
    readonly " $fragmentRefs": FragmentRefs<"CourseThumbnailPicker_course">;
    readonly " $refType": "CourseContextMenu_course";
};
export type CourseContextMenu_course$data = CourseContextMenu_course;
export type CourseContextMenu_course$key = {
    readonly " $data"?: CourseContextMenu_course$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CourseContextMenu_course">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseContextMenu_course",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerOwner",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseThumbnailPicker_course"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = 'd36985c789bbdd8d7cc3a7beb32dfa3c';
export default node;
