import { graphql } from 'react-relay'

import {
  DeleteActivityInput,
  deleteActivityMutation,
} from '__generated__/deleteActivityMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: DeleteActivityInput) =>
  mutationPromise<deleteActivityMutation>({
    mutation: graphql`
      mutation deleteActivityMutation($input: DeleteActivityInput!) {
        deleteActivity(input: $input) {
          clientMutationId
          course {
            flows {
              activities {
                id
                ruleGroupsCompletion {
                  __typename
                }
                ruleGroupsVisibility {
                  __typename
                }
              }
            }
          }
        }
      }
    `,
    variables: { input },
  })
