import { graphql } from 'react-relay'

import {
  DuplicateFlowInput,
  duplicateFlowMutation,
} from '__generated__/duplicateFlowMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: DuplicateFlowInput) =>
  mutationPromise<duplicateFlowMutation>({
    mutation: graphql`
      mutation duplicateFlowMutation($input: DuplicateFlowInput!) {
        duplicateFlow(input: $input) {
          course {
            ...SidebarTree_course
          }
          flow {
            url
            id
            hasSubsetVisibilityRule
          }
        }
      }
    `,
    variables: { input },
  })
