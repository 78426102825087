// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContextMenuNavigator-module__animatedHeader___DYO9W{will-change:transform,opacity}.ContextMenuNavigator-module__animatedContent___BGJMR{width:100%;will-change:transform}.ContextMenuNavigator-module__section___g1pHj{display:grid;grid-template-rows:auto 1fr;overflow-x:clip}.ContextMenuNavigator-module__header___AxSeL{position:relative;border-bottom:1px solid #e6e9ef}.ContextMenuNavigator-module__main___KksYC{position:relative;overflow-x:clip}", "",{"version":3,"sources":["webpack://./components/ContextMenuNavigator/ContextMenuNavigator.module.scss"],"names":[],"mappings":"AAAA,qDACE,6BAAA,CAGF,sDACE,UAAA,CACA,qBAAA,CAGF,8CACE,YAAA,CACA,2BAAA,CACA,eAAA,CAGF,6CACE,iBAAA,CACA,+BAAA,CAGF,2CACE,iBAAA,CACA,eAAA","sourcesContent":[".animatedHeader {\n  will-change: transform, opacity;\n}\n\n.animatedContent {\n  width: 100%;\n  will-change: transform;\n}\n\n.section {\n  display: grid;\n  grid-template-rows: auto 1fr;\n  overflow-x: clip;\n}\n\n.header {\n  position: relative;\n  border-bottom: 1px solid #e6e9ef;\n}\n\n.main {\n  position: relative;\n  overflow-x: clip;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animatedHeader": "ContextMenuNavigator-module__animatedHeader___DYO9W",
	"animatedContent": "ContextMenuNavigator-module__animatedContent___BGJMR",
	"section": "ContextMenuNavigator-module__section___g1pHj",
	"header": "ContextMenuNavigator-module__header___AxSeL",
	"main": "ContextMenuNavigator-module__main___KksYC"
};
export default ___CSS_LOADER_EXPORT___;
