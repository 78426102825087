import * as Sentry from '@sentry/react'
import React from 'react'
import axe from 'react-axe-es'
import ReactDOM from 'react-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import 'regenerator-runtime/runtime'
// Needed for async/await
import 'what-input'

import rootReducer from 'reducers/root.reducer'
import App from 'routes/index'
import tracking from 'utils/tracking'

import './styles/styles.scss'

tracking.setupSentry()

declare let __PRODUCTION__: boolean
declare let __AXE__: boolean

if (!__PRODUCTION__) {
  if (__AXE__) {
    axe(React, ReactDOM, 1000, {
      rules: [{ id: 'tabindex', enabled: false }],
    })
  }
}

const configureStore = () => {
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  // Middlewares
  const middleware = [thunkMiddleware]

  // Enhancers
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

  const store = createStore(
    rootReducer,
    undefined,
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer),
  )

  return store
}

const root = document.getElementById('react-root')

ReactDOM.render(<App store={configureStore()} />, root)
