import React, { createContext, useCallback, useState } from 'react'

interface IProps {
  isCollapsed: boolean
  collapseSidebar: () => void
  uncollapseSidebar: () => void
  isResizingSidebar: boolean
  setIsResizingSidebar: (state: boolean) => void
}

export const SidebarCollapseContext = createContext<IProps>({
  isCollapsed: false,
  collapseSidebar: () => {},
  uncollapseSidebar: () => {},
  isResizingSidebar: false,
  setIsResizingSidebar: () => {},
})

const LOCAL_STORAGE_KEY = 'sidebar-collapsed-state'

const SidebarCollapseProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) === 'true',
  )
  const [isResizingSidebar, setIsResizingSidebar] = useState(false)

  const collapseSidebar = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
    setIsCollapsed(true)
  }, [setIsCollapsed])
  const uncollapseSidebar = useCallback(() => {
    setIsCollapsed(false)
    localStorage.setItem(LOCAL_STORAGE_KEY, 'false')
  }, [setIsCollapsed])

  return (
    <SidebarCollapseContext.Provider
      value={{
        isCollapsed,
        collapseSidebar,
        uncollapseSidebar,
        setIsResizingSidebar,
        isResizingSidebar,
      }}
    >
      {children}
    </SidebarCollapseContext.Provider>
  )
}

export default SidebarCollapseProvider
