import React, { createContext, useEffect, useState } from 'react'
import { Notification } from 'react-notification-system'

import { displayToast as makeToastAction } from './helpers'
import type { MessageType } from './types'

import './NotificationCenter.scss'

export interface INotificationContextValue {
  notify: (message: string | JSX.Element, type: MessageType) => void
  notifications: readonly Notification[]
}

export const NotificationContext = createContext<INotificationContextValue>({
  notifications: [],
  notify: () => undefined,
})

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [isMounted, setIsMounted] = useState(false)

  const notify: INotificationContextValue['notify'] = (message, type) => {
    setNotifications((currentNotifications) => [
      ...currentNotifications,
      makeToastAction(message, type),
    ])
  }

  // This is an ugly hack, but because we use notify() inside async
  // actions, it can happen that this component has already unmounted
  // by the time notify() runs, and React will complain if we try
  // setting state then.
  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  })

  return (
    <NotificationContext.Provider
      value={{ notify: isMounted ? notify : () => undefined, notifications }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
