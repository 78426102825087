import type { CopyActivitiesSection, IState } from './types'

export enum ActionType {
  SetIsOpen,
  SetCopyMutationUnderway,
  SetCurrentSection,
  SetCourses,
  SetCourseId,
  SetCourse,
  ToggleFlowId,
  ToggleActivityId,
  ToggleSelectAll,
  Reset,
}

export const COURSE_PICKER_SECTION: CopyActivitiesSection = 'COURSE_PICKER_SECTION'
export const ACTIVITY_PICKER_SECTION: CopyActivitiesSection = 'ACTIVITY_PICKER_SECTION'

export const defaultState: IState = {
  isOpen: undefined,
  isCopyMutationUnderway: false,
  currentSection: COURSE_PICKER_SECTION,
  course: null,
  courses: [],
  uncopyableActivityIds: [],
  selectedActivityIds: [],
  selectedFlowIds: [],
  selectedAll: false,
  activityToFlowMap: {},
  sourceActivityToActivityMap: {},
  missingSourceActivityMap: {},
}
