import classNames from 'classnames/bind'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useCurrentRoute } from 'react-navi'
import { useFragment, graphql } from 'react-relay'

import { ActivityTitle_activity$key } from '__generated__/ActivityTitle_activity.graphql'
import {
  ActivityStudentStatus,
  SidebarTree_course,
} from '__generated__/SidebarTree_course.graphql'
import updateActivityTitle from 'mutations/updateActivityTitle'
import activityUtils from 'utils/activity'

import { ActivityIcon } from 'components/ActivityIcon'
import { EditingContext } from 'components/EditingContext'
import { Icons } from 'components/Icons'
import { MobileContext } from 'components/MobileContext'
import { ToastContext } from 'components/NotificationCenter'
import { RulesContext } from 'components/RulesSlideIn'
import { getStatusIcon } from 'components/StateTag/CompletionStateTag'
import { TitleForm } from 'components/TitleForm'

import ActivityContextMenu from '../teacher/ActivityContextMenu'

import styles from './ActivityTitle.module.scss'

interface IProps {
  activity: SidebarTree_course['flows'][number]['activities'][number]
  isClassActivity: boolean
  isDraggable: boolean
  status: ActivityStudentStatus
  isTeacher: boolean
  isAssistant: boolean
  isDragging?: boolean
  sidebarMounted: boolean
  wasCollapsed?: boolean
}

const cx = classNames.bind(styles)
const fragment = graphql`
  fragment ActivityTitle_activity on Activity {
    id
    activityType
    title
    url
    source {
      id
    }
    hasSubsetVisibilityRule
    ruleGroupsVisibility {
      ... on RuleGroup {
        openFor
      }
    }
    flow {
      hasSubsetVisibilityRule
    }
    ... on PeerReviewActivity {
      feedbackRubric: rubric {
        questionCount
      }
    }
    ... on TeacherReviewActivity {
      teacherRubric: rubric {
        questionCount
      }
    }
    ... on SelfReviewActivity {
      selfRubric: rubric {
        questionCount
      }
    }
    ... on SelectTagActivity {
      settings {
        selectableTags {
          id
        }
      }
    }
    ... on ScoringActivity {
      inputs {
        isValid
      }
    }
    ... on PollActivity {
      poll {
        isValid
      }
    }
    ... on QuizActivity {
      quiz {
        isValid
      }
    }
    ... on FormActivity {
      form {
        isValid
      }
    }
    ... on FeedbackReflectionActivity {
      settings {
        isRubricEnabled
      }
      reflectionRubric: rubric {
        questionCount
      }
    }
    ... on GradePassbackActivity {
      settings {
        isEnabled
      }
    }
    ... on HapYakActivity {
      settings {
        srcUrl
      }
    }
    ... on TurnItInActivity {
      isTurnItInValid: isValid
    }
    ... on EmbedActivity {
      settings {
        embedCode
      }
    }
    ... on ScormActivity {
      settings {
        entrypoint
      }
    }
    ... on GroupFormationActivity {
      isBeingUsedInCourse
    }
    ... on ZoomActivity {
      settings {
        inviteUrl
      }
    }
    ... on GoogleMeetActivity {
      settings {
        inviteUrl
      }
    }
    ... on MicrosoftTeamsActivity {
      settings {
        inviteUrl
      }
    }
    ... on VideoActivity {
      videoSettings {
        videoUrl
      }
    }
    ...ActivityContextMenu_activity
    ...ActivityContainerProvider_activity
    ...ActivityIcon_activity
    ...StudentActivityHeader_activity
  }
`

const ActivityTitle = ({
  activity: activityFragment,
  isClassActivity,
  isDraggable,
  isTeacher,
  isAssistant,
  status,
  isDragging,
  sidebarMounted,
  wasCollapsed,
}: IProps) => {
  const activity = useFragment<ActivityTitle_activity$key>(fragment, activityFragment)
  const { displayToast } = useContext(ToastContext)
  const { editingActivityId, setEditingActivityId } = useContext(EditingContext)

  const { setIsShowingContent } = useContext(MobileContext)
  const {
    activityType,
    source,
    title,
    url,
    feedbackRubric,
    reflectionRubric,
    selfRubric,
    settings,
    teacherRubric,
    inputs,
    isBeingUsedInCourse,
  } = activity

  const [isEditing, setIsEditingTitle] = useState(false)
  const editing = isEditing || editingActivityId === activity.id
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [yellowBackground, setYellowBackground] = useState(false)

  const { setShouldAnimate, isShowingRules } = React.useContext(RulesContext)

  const handleEditingTitleChange = (value: boolean) => {
    setIsEditingTitle(value)
    setEditingActivityId('')
  }
  const route = useCurrentRoute()
  const isEditPageOpenAndNew =
    route.url.pathname === url && route?.url?.query?.new === '1'

  const feedbackQuestionsMissing = feedbackRubric && !feedbackRubric.questionCount
  const teacherQuestionsMissing = teacherRubric && !teacherRubric.questionCount
  const selfQuestionsMissing = selfRubric && !selfRubric.questionCount
  const reflectionQuestionsMissing =
    reflectionRubric &&
    settings &&
    settings.isRubricEnabled &&
    !reflectionRubric.questionCount
  const rubricQuestionsMissing =
    feedbackQuestionsMissing ||
    teacherQuestionsMissing ||
    reflectionQuestionsMissing ||
    selfQuestionsMissing

  const isMissingSource = activityUtils.requiresSource({ activityType, source })

  const isMissingTags = activityUtils.requiresTags({ activityType, settings })

  const isMissingInputs = activityUtils.requiresInputs({ activityType, inputs })

  const invalidQuiz = activityType === 'Quiz' && !activity.quiz?.isValid
  const invalidPoll = activityType === 'Poll' && !activity.poll?.isValid
  const invalidForm = activityType === 'Form' && !activity.form?.isValid

  const isMissingInheritorsOrSource = activityUtils.requiresInheritorsOrSource({
    activityType,
    isBeingUsedInCourse,
  })

  const isGradePassbackNotConfigured =
    activity.activityType === 'GradePassback' &&
    activity.settings &&
    !activity.settings.isEnabled

  const isHapYakNotConfigured =
    activity.activityType === 'HapYak' && !activity.settings?.srcUrl

  const isTurnItInNotConfigured =
    activity.activityType === 'TurnItIn' && !activity.isTurnItInValid

  const isEmbedNotConfigured =
    activity.activityType === 'Embed' && !activity.settings?.embedCode

  const isScormNotConfigured =
    activity.activityType === 'Scorm' && !activity.settings?.entrypoint

  const isZoomNotConfigured =
    activity.activityType === 'Zoom' && !activity.settings?.inviteUrl

  const isGoogleMeetNotConfigured =
    activity.activityType === 'GoogleMeet' && !activity.settings?.inviteUrl

  const isMicrosoftTeamsNotConfigured =
    activity.activityType === 'MicrosoftTeams' && !activity.settings?.inviteUrl

  const isVideoNotConfigured =
    activity.activityType === 'Video' && !activity.videoSettings?.videoUrl

  const icon =
    !isEditPageOpenAndNew &&
    (isMissingSource ||
      isMissingTags ||
      isMissingInputs ||
      invalidQuiz ||
      invalidPoll ||
      invalidForm ||
      isMissingInheritorsOrSource ||
      rubricQuestionsMissing ||
      isHapYakNotConfigured ||
      isEmbedNotConfigured ||
      isScormNotConfigured ||
      isZoomNotConfigured ||
      isGoogleMeetNotConfigured ||
      isMicrosoftTeamsNotConfigured ||
      isVideoNotConfigured ||
      isTurnItInNotConfigured ||
      isGradePassbackNotConfigured) ? (
      <Icons.WarningTriangle className={`${styles.itemIcon} ${styles.warning}`} />
    ) : (
      <ActivityIcon className={styles.itemIcon} activity={activity} size={16} />
    )

  const handleSubmit = async (title: string) => {
    if (title) {
      try {
        await updateActivityTitle({
          activityId: activity.id,
          title: title,
        })
        handleEditingTitleChange(false)
      } catch (e) {
        displayToast(e.message, 'error')
      }
    }
  }
  const handleTitleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (editing) {
      // Disable the link when while we're editing
      e.preventDefault()
      return
    }

    isShowingRules && setShouldAnimate(false) // don't animate the rules slide in if its already open
    setIsShowingContent(true)
  }

  useEffect(() => {
    // Show a temporary yellow background for activities
    // with a subset that appeared after a student's tag selection
    const hasSubset =
      activity.hasSubsetVisibilityRule || activity.flow?.hasSubsetVisibilityRule
    if (!isTeacher && !wasCollapsed && sidebarMounted && hasSubset) {
      setTimeout(() => {
        setYellowBackground(true)
      }, 100)

      setTimeout(() => {
        setYellowBackground(false)
      }, 1100)
    }
  }, [])

  const hasVisibilityRules = activity.ruleGroupsVisibility?.length
  const activityOpenForRule = activity.ruleGroupsVisibility?.find((r) => r.openFor)
  const isOpenForStudents =
    !activityOpenForRule || activityOpenForRule?.openFor?.includes('Student')
  let ExtraIcon = null
  if (!isMenuOpen && isTeacher) {
    if (!isOpenForStudents) {
      ExtraIcon = Icons.EyeSlash
    } else if (hasVisibilityRules) {
      ExtraIcon = Icons.UserWithMinus
    }
  }

  return (
    <Link
      href={url}
      className={cx('activityItemContent', {
        classActivity: isClassActivity,
        editing,
        isDragging,
        showDragHandle: isDraggable,
        yellowBackground,
      })}
      onClick={handleTitleClick}
      activeClassName={styles.active}
      data-test={`sidebar-activity-title-${activity.title}`}
    >
      {editing ? (
        <TitleForm initialValues={{ title: activity.title }} onSubmit={handleSubmit} />
      ) : (
        <>
          <span
            className={cx('itemLeft', {
              withVisibilityIndicator: hasVisibilityRules || !isOpenForStudents,
              isMenuOpen,
            })}
          >
            {icon}
            <Icons.Draggable className={`${styles.itemDragIcon} dragIcon`} nearBlack />
            <span className={`${styles.itemText}`} translate="no">
              {title}
            </span>
          </span>
          {status && getStatusIcon(status)}
          {ExtraIcon && (
            <span className={styles.itemWrapVisibilityIndicator}>
              <ExtraIcon nearBlack className={styles.itemVisibilityIndicator} />
            </span>
          )}
          {isTeacher && !isAssistant && (
            <ActivityContextMenu
              activity={activity}
              setIsEditingTitle={handleEditingTitleChange}
              onMenuOpen={setIsMenuOpen}
            />
          )}
        </>
      )}
    </Link>
  )
}

export default ActivityTitle
