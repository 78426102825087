/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type courseRootRedirectQueryVariables = {
    courseId: string;
    activityId: string;
    flowId: string;
    includeActivity: boolean;
    includeFlow: boolean;
};
export type courseRootRedirectQueryResponse = {
    readonly activity?: {
        readonly url: string;
        readonly flow: {
            readonly isDeleted: boolean;
        } | null;
    } | null | undefined;
    readonly flow?: {
        readonly url: string;
        readonly isDeleted: boolean;
    } | null | undefined;
    readonly course: {
        readonly firstActivity: {
            readonly url: string;
        } | null;
    } | null;
};
export type courseRootRedirectQuery = {
    readonly response: courseRootRedirectQueryResponse;
    readonly variables: courseRootRedirectQueryVariables;
};



/*
query courseRootRedirectQuery(
  $courseId: ID!
  $activityId: ID!
  $flowId: ID!
  $includeActivity: Boolean!
  $includeFlow: Boolean!
) {
  activity(id: $activityId) @include(if: $includeActivity) {
    __typename
    url
    flow(includeClassActivity: true) {
      isDeleted
      id
    }
    id
  }
  flow(id: $flowId) @include(if: $includeFlow) {
    url
    isDeleted
    id
  }
  course(id: $courseId) {
    firstActivity {
      __typename
      url
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "activityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "courseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "flowId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeActivity"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeFlow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "courseId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "activityId"
  }
],
v8 = [
  {
    "kind": "Literal",
    "name": "includeClassActivity",
    "value": true
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDeleted",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "flowId"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "courseRootRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "firstActivity",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "includeActivity",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "PublicFlow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": "flow(includeClassActivity:true)"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "includeFlow",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "courseRootRedirectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "firstActivity",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v6/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "includeActivity",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "PublicFlow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": "flow(includeClassActivity:true)"
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "includeFlow",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v9/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "837b973701053c01ef88526f1065a78b",
    "id": null,
    "metadata": {},
    "name": "courseRootRedirectQuery",
    "operationKind": "query",
    "text": "query courseRootRedirectQuery(\n  $courseId: ID!\n  $activityId: ID!\n  $flowId: ID!\n  $includeActivity: Boolean!\n  $includeFlow: Boolean!\n) {\n  activity(id: $activityId) @include(if: $includeActivity) {\n    __typename\n    url\n    flow(includeClassActivity: true) {\n      isDeleted\n      id\n    }\n    id\n  }\n  flow(id: $flowId) @include(if: $includeFlow) {\n    url\n    isDeleted\n    id\n  }\n  course(id: $courseId) {\n    firstActivity {\n      __typename\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c292936f70538ce74169c36d85c57fa1';
export default node;
