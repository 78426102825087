/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteFlowInput = {
    flowId: string;
    force?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type deleteFlowMutationVariables = {
    input: DeleteFlowInput;
};
export type deleteFlowMutationResponse = {
    readonly deleteFlow: {
        readonly course: {
            readonly flows: ReadonlyArray<{
                readonly id: string;
                readonly activities: ReadonlyArray<{
                    readonly ruleGroupsCompletion: ReadonlyArray<{
                        readonly __typename: string;
                    }>;
                    readonly ruleGroupsVisibility: ReadonlyArray<{
                        readonly __typename: string;
                    }>;
                }>;
            }>;
        } | null;
    } | null;
};
export type deleteFlowMutation = {
    readonly response: deleteFlowMutationResponse;
    readonly variables: deleteFlowMutationVariables;
};



/*
mutation deleteFlowMutation(
  $input: DeleteFlowInput!
) {
  deleteFlow(input: $input) {
    course {
      flows {
        id
        activities {
          __typename
          ruleGroupsCompletion {
            __typename
            ... on Node {
              __isNode: __typename
              id
            }
          }
          ruleGroupsVisibility {
            __typename
            ... on Node {
              __isNode: __typename
              id
            }
          }
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteFlowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFlowPayload",
        "kind": "LinkedField",
        "name": "deleteFlow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsCompletion",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsVisibility",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteFlowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteFlowPayload",
        "kind": "LinkedField",
        "name": "deleteFlow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsCompletion",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsVisibility",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "346b812fbacb145fd311f0e6dae979d1",
    "id": null,
    "metadata": {},
    "name": "deleteFlowMutation",
    "operationKind": "mutation",
    "text": "mutation deleteFlowMutation(\n  $input: DeleteFlowInput!\n) {\n  deleteFlow(input: $input) {\n    course {\n      flows {\n        id\n        activities {\n          __typename\n          ruleGroupsCompletion {\n            __typename\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          ruleGroupsVisibility {\n            __typename\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5f0c7f591084c261c306f5c1f712a97e';
export default node;
