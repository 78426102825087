import React from 'react'
import { graphql, useFragment } from 'react-relay'

import { CourseThumbnailPicker_course$key } from '__generated__/CourseThumbnailPicker_course.graphql'
import updateCourseThumbnail from 'mutations/updateCourseThumbnail'

import { ImagePicker, IOnChangeImageArgs } from 'components/ImagePicker'

interface IProps {
  course: CourseThumbnailPicker_course$key
  hideEditThumbnail: () => void
}

const CourseThumbnailPicker = ({
  course: courseFragment,
  hideEditThumbnail,
}: IProps) => {
  const course = useFragment<CourseThumbnailPicker_course$key>(
    graphql`
      fragment CourseThumbnailPicker_course on Course {
        id
        thumbnail
      }
    `,
    courseFragment,
  )

  const handleChangeThumbnail = async ({
    image,
    unsplashPingUrl,
  }: IOnChangeImageArgs) => {
    await updateCourseThumbnail({
      courseId: course.id,
      thumbnail: image,
      unsplashPingUrl,
    })
  }

  return (
    <ImagePicker
      handleClose={hideEditThumbnail}
      initialImage={course.thumbnail}
      onChangeImage={handleChangeThumbnail}
      successMsg={'Course thumbnail updated'}
      s3Destination={`course-thumbnails`}
    />
  )
}

export default CourseThumbnailPicker
