import { useEffect, useRef } from 'react'

// This hook is the same as `useEffect`, except it doesn't fire on the initial
// render
const useEffectSkipFirstRender: typeof useEffect = (handler, deps) => {
  const isInitial = useRef(true)

  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false
    } else {
      handler()
    }
  }, deps)
}

export default useEffectSkipFirstRender
