import { graphql } from 'react-relay'

import {
  DuplicateActivityInput,
  duplicateActivityMutation,
} from '__generated__/duplicateActivityMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: DuplicateActivityInput) =>
  mutationPromise<duplicateActivityMutation>({
    mutation: graphql`
      mutation duplicateActivityMutation($input: DuplicateActivityInput!) {
        duplicateActivity(input: $input) {
          course {
            flows {
              id
              title
              isClassActivity
              activities {
                id
                activityType
                title
                url
                hasSubsetVisibilityRule
                isCopyable
              }
            }
          }
          activity {
            id
            hasSubsetVisibilityRule
          }
          newActivityUrl
        }
      }
    `,
    variables: { input },
  })
