import React, { createContext, useState } from 'react'

export interface IScrollerContextValue {
  scrollToElement: string
  setScrollToElement: (value: string) => void
}

export const ScrollerContext = createContext<IScrollerContextValue>({
  scrollToElement: '',
  setScrollToElement: () => undefined,
})

export const ScrollerProvider = ({ children }: { children: React.ReactNode }) => {
  const [scrollToElement, setScrollToElement] = useState('')
  return (
    <ScrollerContext.Provider value={{ scrollToElement, setScrollToElement }}>
      {children}
    </ScrollerContext.Provider>
  )
}
