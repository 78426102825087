/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteCourseInput = {
    courseId: string;
    clientMutationId?: string | null | undefined;
};
export type deleteCourseMutationVariables = {
    input: DeleteCourseInput;
};
export type deleteCourseMutationResponse = {
    readonly deleteCourse: {
        readonly institution: {
            readonly id: string;
        } | null;
        readonly me: {
            readonly hasCourses: boolean;
            readonly institution: {
                readonly courseLibrary: {
                    readonly sections: ReadonlyArray<{
                        readonly " $fragmentRefs": FragmentRefs<"CourseLibrarySection_section">;
                    }>;
                } | null;
            } | null;
        };
    } | null;
};
export type deleteCourseMutation = {
    readonly response: deleteCourseMutationResponse;
    readonly variables: deleteCourseMutationVariables;
};



/*
mutation deleteCourseMutation(
  $input: DeleteCourseInput!
) {
  deleteCourse(input: $input) {
    institution {
      id
    }
    me {
      hasCourses
      institution {
        courseLibrary {
          sections {
            ...CourseLibrarySection_section
            id
          }
          id
        }
        id
      }
      id
    }
  }
}

fragment CourseCardDisplay_course on Course {
  id
  title
  thumbnail
  isViewerTeacher
  isViewerStudent
  isArchived
  isCopying
}

fragment CourseCard_course on Course {
  ...CourseCardDisplay_course
}

fragment CourseLibrarySection_section on CourseLibrarySection {
  id
  title
  courses(searchTerm: "", orderBy: {field: CREATED_AT, direction: DESC}, filterBy: {status: [ACTIVE]}) {
    id
    title
    ...CourseCard_course
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Institution",
  "kind": "LinkedField",
  "name": "institution",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCourses",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteCourseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCoursePayload",
        "kind": "LinkedField",
        "name": "deleteCourse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScopedInstitution",
                "kind": "LinkedField",
                "name": "institution",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseLibrary",
                    "kind": "LinkedField",
                    "name": "courseLibrary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseLibrarySection",
                        "kind": "LinkedField",
                        "name": "sections",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CourseLibrarySection_section"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteCourseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteCoursePayload",
        "kind": "LinkedField",
        "name": "deleteCourse",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScopedInstitution",
                "kind": "LinkedField",
                "name": "institution",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseLibrary",
                    "kind": "LinkedField",
                    "name": "courseLibrary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseLibrarySection",
                        "kind": "LinkedField",
                        "name": "sections",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "filterBy",
                                "value": {
                                  "status": [
                                    "ACTIVE"
                                  ]
                                }
                              },
                              {
                                "kind": "Literal",
                                "name": "orderBy",
                                "value": {
                                  "direction": "DESC",
                                  "field": "CREATED_AT"
                                }
                              },
                              {
                                "kind": "Literal",
                                "name": "searchTerm",
                                "value": ""
                              }
                            ],
                            "concreteType": "Course",
                            "kind": "LinkedField",
                            "name": "courses",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnail",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isViewerTeacher",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isViewerStudent",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isArchived",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isCopying",
                                "storageKey": null
                              }
                            ],
                            "storageKey": "courses(filterBy:{\"status\":[\"ACTIVE\"]},orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"},searchTerm:\"\")"
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6570a923dadc18f71d33bb64351e9b4",
    "id": null,
    "metadata": {},
    "name": "deleteCourseMutation",
    "operationKind": "mutation",
    "text": "mutation deleteCourseMutation(\n  $input: DeleteCourseInput!\n) {\n  deleteCourse(input: $input) {\n    institution {\n      id\n    }\n    me {\n      hasCourses\n      institution {\n        courseLibrary {\n          sections {\n            ...CourseLibrarySection_section\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment CourseCardDisplay_course on Course {\n  id\n  title\n  thumbnail\n  isViewerTeacher\n  isViewerStudent\n  isArchived\n  isCopying\n}\n\nfragment CourseCard_course on Course {\n  ...CourseCardDisplay_course\n}\n\nfragment CourseLibrarySection_section on CourseLibrarySection {\n  id\n  title\n  courses(searchTerm: \"\", orderBy: {field: CREATED_AT, direction: DESC}, filterBy: {status: [ACTIVE]}) {\n    id\n    title\n    ...CourseCard_course\n  }\n}\n"
  }
};
})();
(node as any).hash = '8d4bdc6c2db4fff99d5f9b294056f500';
export default node;
