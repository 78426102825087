export const CUSTOM_EMOJIS = [
  {
    id: 'party_parrot',
    name: 'Party Parrot',
    short_names: ['party_parrot'],
    keywords: ['party', 'parrot'],
    imageUrl: require('assets/custom-emojis/party_parrot.gif'),
    isCustom: true,
  },
]
