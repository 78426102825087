/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ImpersonationUserSelectorQueryVariables = {};
export type ImpersonationUserSelectorQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ImpersonationUserSelector_query">;
};
export type ImpersonationUserSelectorQuery = {
    readonly response: ImpersonationUserSelectorQueryResponse;
    readonly variables: ImpersonationUserSelectorQueryVariables;
};



/*
query ImpersonationUserSelectorQuery {
  ...ImpersonationUserSelector_query
}

fragment ImpersonationUserSelector_query on Query {
  courseImpersonatableParticipants(first: 10, searchTerm: "") {
    edges {
      node {
        participantType
        user {
          id
          name
          badge {
            ...UserBadge_badge
          }
          isTestUser
        }
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment UserBadge_badge on UserBadge {
  text
  profileImage
  backgroundColor {
    hex
    isLight
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "searchTerm",
    "value": ""
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ImpersonationUserSelectorQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ImpersonationUserSelector_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ImpersonationUserSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "ParticipantConnection",
        "kind": "LinkedField",
        "name": "courseImpersonatableParticipants",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ParticipantEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Participant",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "participantType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserBadge",
                        "kind": "LinkedField",
                        "name": "badge",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profileImage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Color",
                            "kind": "LinkedField",
                            "name": "backgroundColor",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hex",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isLight",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isTestUser",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "courseImpersonatableParticipants(first:10,searchTerm:\"\")"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "ImpersonationUserSelector_courseImpersonatableParticipants",
        "kind": "LinkedHandle",
        "name": "courseImpersonatableParticipants"
      }
    ]
  },
  "params": {
    "cacheID": "3b0b7274ef9f0d4bf1a60feb610e51ef",
    "id": null,
    "metadata": {},
    "name": "ImpersonationUserSelectorQuery",
    "operationKind": "query",
    "text": "query ImpersonationUserSelectorQuery {\n  ...ImpersonationUserSelector_query\n}\n\nfragment ImpersonationUserSelector_query on Query {\n  courseImpersonatableParticipants(first: 10, searchTerm: \"\") {\n    edges {\n      node {\n        participantType\n        user {\n          id\n          name\n          badge {\n            ...UserBadge_badge\n          }\n          isTestUser\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserBadge_badge on UserBadge {\n  text\n  profileImage\n  backgroundColor {\n    hex\n    isLight\n  }\n}\n"
  }
};
})();
(node as any).hash = '75f140c5c4b91904d74ee219696d3469';
export default node;
