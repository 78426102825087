import { graphql } from 'react-relay'

import {
  SetCourseIsArchivedInput,
  setCourseIsArchivedMutation,
} from '__generated__/setCourseIsArchivedMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: SetCourseIsArchivedInput) =>
  mutationPromise<setCourseIsArchivedMutation>({
    mutation: graphql`
      mutation setCourseIsArchivedMutation($input: SetCourseIsArchivedInput!) {
        setCourseIsArchived(input: $input) {
          course {
            isArchived
          }
        }
      }
    `,
    variables: { input },
  })
