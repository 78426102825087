import classNames from 'classnames/bind'
import React from 'react'
import { useCurrentRoute } from 'react-navi'
import { Mutate } from 'relay-hooks'

import type { CopyActivitiesMenuMutation } from '__generated__/CopyActivitiesMenuMutation.graphql'
import { isObjEmpty, notEmpty } from 'utils/functions'
import { toGlobalId } from 'utils/relay'

import { ActivityIcon } from 'components/ActivityIcon'
import { Button } from 'components/Button'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { Icons } from 'components/Icons'
import { ToastContext } from 'components/NotificationCenter'

import { Header } from './Shared'
import { ActionType, ACTIVITY_PICKER_SECTION, COURSE_PICKER_SECTION } from './constants'
import { stateReducer } from './reducer'
import type { Activity, CopyActivitiesSection, Flow } from './types'

import styles from './CopyActivitiesMenu.module.scss'

const cx = classNames.bind(styles)

const ActivityPicker: React.FC<{
  state: React.ReducerState<typeof stateReducer>
  dispatch: React.Dispatch<React.ReducerAction<typeof stateReducer>>
  copyActivitiesMutation: Mutate<CopyActivitiesMenuMutation>
}> = ({ dispatch, state, copyActivitiesMutation }) => {
  const course = state?.course
  const isLoading = !course?.id
  const hasMissingSources = !isObjEmpty(state.missingSourceActivityMap)
  const currentRoute = useCurrentRoute()
  const { displayToast } = React.useContext(ToastContext)
  const onNavigate = (section: CopyActivitiesSection) =>
    dispatch({ type: ActionType.SetCurrentSection, currentSection: section })
  const onBack = () => onNavigate(COURSE_PICKER_SECTION)
  return (
    <>
      <Header
        id={ACTIVITY_PICKER_SECTION}
        navigateLeft={onNavigate}
        headerText={course?.title ? `Copy from ${course.title}` : undefined}
        {...(!isLoading
          ? {
              actionDisabled:
                !state.selectedActivityIds.length || state.isCopyMutationUnderway,
              onAction: async () => {
                const courseId = currentRoute.data.courseId
                if (!state?.courseId || state.isCopyMutationUnderway) {
                  return
                }

                try {
                  dispatch({
                    type: ActionType.SetCopyMutationUnderway,
                    isCopyMutationUnderway: true,
                  })
                  await copyActivitiesMutation({
                    variables: {
                      input: {
                        activityIds: state.selectedActivityIds,
                        courseId: toGlobalId('Course', courseId),
                        oldCourseId: state.courseId,
                      },
                    },
                  })

                  displayToast('Successfully copied activities', 'success')
                  dispatch({
                    type: ActionType.Reset,
                  })
                } catch (e) {
                  displayToast('Error copying activities', 'error')
                  dispatch({
                    type: ActionType.SetCopyMutationUnderway,
                    isCopyMutationUnderway: false,
                  })
                } finally {
                  dispatch({
                    type: ActionType.SetIsOpen,
                    isOpen: false,
                  })
                }
              },
            }
          : {})}
      />
      {hasMissingSources && (
        <div className={styles.warningMessage}>
          <Icons.WarningTriangle nearBlack xSmall className={styles.itemIcon} />{' '}
          <p>Copying the selected activities will break their source connection.</p>
        </div>
      )}

      <div
        className={cx(styles.menuItem, { selectAll: true })}
        onClick={() => dispatch({ type: ActionType.ToggleSelectAll })}
      >
        <span className={styles.itemLeft}>
          <CheckBox
            className={styles.itemText}
            input={{
              onChange: () => dispatch({ type: ActionType.ToggleSelectAll }),
              value: state.selectedAll ? 'true' : '',
              onKeyPress: (event: React.KeyboardEvent) => {
                if (event.key === ' ' || event.key === 'Enter') {
                  event.stopPropagation()
                  dispatch({ type: ActionType.ToggleSelectAll })
                }
              },
            }}
            label="Select All"
          />
        </span>
      </div>

      {!isLoading && !course?.flows?.length && (
        <div className={`${styles.emptyBox} `}>
          {isLoading ? (
            <>Loading courses</>
          ) : (
            <>
              <p className={styles.emptyStateText}>No activities in this course</p>
              <Button Small onClick={onBack}>
                Back
              </Button>
            </>
          )}
        </div>
      )}

      {!isLoading &&
        course &&
        course.flows &&
        course.flows.filter(notEmpty).map?.((flow: Flow) => (
          <React.Fragment key={flow.id}>
            {!flow.isClassActivity && (
              <div
                className={cx(styles.menuItem, { flow: true })}
                data-test={`flow-lookup-title-${flow?.title}`}
                onClick={() =>
                  flow?.id &&
                  dispatch({ type: ActionType.ToggleFlowId, flowId: flow.id })
                }
              >
                <span className={styles.itemLeft}>
                  <CheckBox
                    className={styles.itemText}
                    input={{
                      onChange: () =>
                        flow?.id &&
                        dispatch({ type: ActionType.ToggleFlowId, flowId: flow.id }),
                      value: state.selectedFlowIds.includes(flow.id) ? 'true' : '',
                      onKeyPress: (event: React.KeyboardEvent) => {
                        if (event.key === ' ' || event.key === 'Enter') {
                          event.stopPropagation()
                          dispatch({ type: ActionType.ToggleFlowId, flowId: flow.id })
                        }
                      },
                    }}
                    label={<span className={styles.itemText}>{flow?.title}</span>}
                  />
                </span>
              </div>
            )}
            {flow &&
              flow.activities &&
              flow.activities.filter(notEmpty).map?.((activity: Activity) => (
                <div
                  className={cx(styles.menuItem, {
                    warning: state.missingSourceActivityMap[activity.id],
                    activity: true,
                    isClassActivity: flow.isClassActivity,
                  })}
                  data-test={`activity-lookup-title-${activity?.title}`}
                  key={activity.id}
                  onClick={
                    activity.isCopyable
                      ? () =>
                          activity?.id &&
                          dispatch({
                            type: ActionType.ToggleActivityId,
                            activityId: activity.id,
                          })
                      : undefined
                  }
                >
                  <span className={styles.itemLeft}>
                    <CheckBox
                      className={styles.itemText}
                      input={
                        activity.isCopyable
                          ? {
                              onChange: () =>
                                dispatch({
                                  type: ActionType.ToggleActivityId,
                                  activityId: activity.id,
                                }),
                              value: state.selectedActivityIds.includes(activity.id)
                                ? 'true'
                                : '',
                              onKeyPress: (event: React.KeyboardEvent) => {
                                if (event.key === ' ' || event.key === 'Enter') {
                                  event.stopPropagation()
                                  dispatch({
                                    type: ActionType.ToggleActivityId,
                                    activityId: activity.id,
                                  })
                                }
                              },
                            }
                          : undefined
                      }
                      disabled={!activity.isCopyable}
                      label={
                        <div className={styles.activityText}>
                          <ActivityIcon
                            nearBlack
                            className={styles.activityIcon}
                            activity={activity}
                            size={16}
                          />
                          <span className={styles.itemText}>{activity?.title}</span>
                        </div>
                      }
                    />
                  </span>
                </div>
              ))}
          </React.Fragment>
        ))}
    </>
  )
}

export default ActivityPicker
