import classNames from 'classnames'
import React, { ChangeEventHandler, InputHTMLAttributes } from 'react'

import styles from './CheckBox.module.scss'

interface ICheckBoxProps {
  input?: {
    value: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
  } & InputHTMLAttributes<HTMLInputElement>
  label?: React.ReactNode
  helpText?: React.ReactNode
  dataTest?: string
  disabled?: boolean
  clickable?: boolean
  className?: string
  isTextFirst?: boolean
  noLabelTag?: boolean
  noMargin?: boolean
  ariaLabel?: string
  verticalCenter?: boolean
  marginLeft?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  tabIndex?: number
  field?: {
    value?: string
    onChange: ChangeEventHandler<HTMLInputElement>
  }
}

export const CheckBox = (props: ICheckBoxProps) => {
  const {
    label,
    helpText,
    dataTest,
    disabled,
    clickable,
    className = '',
    isTextFirst,
    noLabelTag,
    noMargin = false,
    ariaLabel,
    verticalCenter,
    marginLeft,
    onClick,
    tabIndex,
  } = props
  const input = props.input || props.field
  const checkBoxStyleNames = classNames(className, styles.label, {
    [styles.disabled]: disabled,
    [styles.clickable]: clickable,
    [styles.verticalCenter]: verticalCenter,
    [styles.marginLeft]: marginLeft,
    [styles.noMarginBottom]: noMargin,
  })
  const noLabel = label === undefined
  const checked = input && !!input.value

  const inputElement = (
    <>
      {!noLabel && isTextFirst && label}
      <div className={styles.checkboxContainer}>
        <input
          data-test={dataTest}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
          {...input}
        />
        <span className={styles.customCheckbox} />
      </div>
      <div>
        {!noLabel && !isTextFirst && label}
        {!isTextFirst && helpText && <div className={styles.helpText}>{helpText}</div>}
      </div>
    </>
  )

  if (noLabel || noLabelTag) {
    return (
      <div className={checkBoxStyleNames} onClick={onClick}>
        {inputElement}
      </div>
    )
  } else {
    return (
      <label className={checkBoxStyleNames} onClick={onClick}>
        {inputElement}
      </label>
    )
  }
}

export const HighlightedCheckBox = ({
  input,
  label,
  disabled,
  className = '',
}: ICheckBoxProps) => (
  <div
    data-test="highlighted-checkbox"
    className={`${styles.highlightedCheckbox} ${className}`}
  >
    <CheckBox input={input} label={label} disabled={disabled} />
  </div>
)
