import RcTooltip, { RCTooltip } from 'rc-tooltip'
import React from 'react'

import './rc-tooltip.css'

export const ConditionalTooltip: React.FC<
  Partial<Omit<React.ComponentProps<typeof Tooltip>, 'text'>> & {
    text: JSX.Element | string | null | undefined
    children: React.ReactNode
  }
> = ({ text, children, ...rest }) => {
  return text ? (
    <Tooltip
      text={text}
      placement="top"
      mouseEnterDelay={0.25}
      mouseLeaveDelay={0}
      {...rest}
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )
}

export const Tooltip = ({
  children,
  mouseEnterDelay,
  mouseLeaveDelay,
  visible,
  placement = 'top',
  trigger = ['hover'],
  text,
  zIndex,
  getTooltipContainer,
  align,
  overlayClassName,
  translate = 'yes',
}: {
  children: React.ReactNode
  mouseEnterDelay?: number
  mouseLeaveDelay?: number
  visible?: boolean
  placement?: string
  trigger?: RCTooltip.Trigger[]
  text?: string | JSX.Element
  zIndex?: number
  getTooltipContainer?: () => Element
  align?: Object
  overlayClassName?: string
  translate?: 'yes' | 'no'
}) => {
  const extraProps = typeof visible === 'boolean' ? { visible } : {}
  return text ? (
    <RcTooltip
      overlay={<span translate={translate}>{text}</span>}
      placement={placement}
      trigger={trigger}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      getTooltipContainer={getTooltipContainer}
      overlayStyle={{ zIndex }}
      align={align}
      overlayClassName={overlayClassName}
      {...extraProps}
    >
      {children}
    </RcTooltip>
  ) : (
    <>{children}</>
  )
}
