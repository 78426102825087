/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityStudentStatus = "Finished" | "Locked" | "NotAssigned" | "Overdue" | "Unstarted";
export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type SidebarTree_course = {
    readonly id: string;
    readonly isViewerTeacher: boolean | null;
    readonly isViewerAssistant: boolean | null;
    readonly title: string;
    readonly ownProgress: ReadonlyArray<{
        readonly activity: {
            readonly __typename: string;
            readonly id: string;
        };
        readonly status: ActivityStudentStatus;
    }> | null;
    readonly flows: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
        readonly isClassActivity: boolean;
        readonly activities: ReadonlyArray<{
            readonly __typename: string;
            readonly id: string;
            readonly activityType: ActivityType;
            readonly title: string;
            readonly url: string;
            readonly " $fragmentRefs": FragmentRefs<"ActivityTitle_activity">;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"FlowTitle_flow">;
    }>;
    readonly " $refType": "SidebarTree_course";
};
export type SidebarTree_course$data = SidebarTree_course;
export type SidebarTree_course$key = {
    readonly " $data"?: SidebarTree_course$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SidebarTree_course">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarTree_course",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerTeacher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerAssistant",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityProgress",
      "kind": "LinkedField",
      "name": "ownProgress",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "activity",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flows",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isClassActivity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "activities",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activityType",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ActivityTitle_activity"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FlowTitle_flow"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();
(node as any).hash = 'ff64cb0f0b929fc9caf44dc364a78f9f';
export default node;
