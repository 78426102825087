import React, { PropsWithChildren } from 'react'
import { useCurrentRoute, View } from 'react-navi'
import { graphql } from 'react-relay'

import { TemplateLayoutQuery } from '__generated__/TemplateLayoutQuery.graphql'
import withQueryRenderer, { OuterProps } from 'relay/withQueryRenderer'

import TemplateCategoriesSidebar from './TemplateCategoriesSidebar'

import styles from './TemplateLibrary.module.scss'

type IOwnProps = PropsWithChildren<{}>

const TemplateLayout = (props: OuterProps<TemplateLayoutQuery, IOwnProps>) => {
  const {
    data: { templateSlug },
  } = useCurrentRoute()

  if (props.relayLoading) {
    return null
  }

  return (
    <main className={styles.TemplateLibrary}>
      <div className={styles.container}>
        <TemplateCategoriesSidebar
          withBackButton={!!templateSlug}
          query={props}
          me={props.me}
        />
        <View />
      </div>
    </main>
  )
}

export default withQueryRenderer<TemplateLayoutQuery, IOwnProps>({
  query: graphql`
    query TemplateLayoutQuery {
      me {
        ...TemplateCategoriesSidebar_me
      }
      ...TemplateCategoriesSidebar_query
    }
  `,
})(TemplateLayout)
