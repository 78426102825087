import classNames from 'classnames'
import classNamesBind from 'classnames/bind'
import React from 'react'

import styles from './Icons.module.scss'

const cx = classNamesBind.bind(styles)

interface IIconColorProps {
  white?: boolean
  nearBlack?: boolean
  green?: boolean
  darkGreen?: boolean
  red?: boolean
  darkRed?: boolean
  yellow?: boolean
  darkYellow?: boolean
  lightGrey?: boolean
  grey?: boolean
  darkGrey?: boolean
  blue?: boolean
  accentColor?: boolean
  darkAccentColor?: boolean
  gradient?: boolean
  lightGreen?: boolean
  lightRed?: boolean
  color?: string
}

export interface IIconSizeProps {
  xxxSmall?: boolean
  xxSmall?: boolean
  xSmall?: boolean
  small?: boolean
  large?: boolean
  xLarge?: boolean
  xxLarge?: boolean
}

export interface IIconProps extends IIconColorProps, IIconSizeProps {
  className?: string
  style?: React.CSSProperties
}

export const fillColors = (props: IIconColorProps) => {
  return classNames('fill', {
    'accent-color': props.accentColor,
    'dark-accent-color': props.darkAccentColor,
    blue: props.blue,
    'dark-green': props.darkGreen,
    'dark-grey': props.darkGrey,
    'dark-red': props.darkRed,
    'dark-yellow': props.darkYellow,
    'gradient-blue': props.gradient && props.accentColor,
    green: props.green,
    grey: props.grey,
    'light-grey': props.lightGrey,
    'near-black': props.nearBlack,
    red: props.red,
    white: props.white,
    yellow: props.yellow,
  })
}

export const iconSize = (props: IIconSizeProps) => {
  return cx('icon-size', {
    'icon-size--lg': props.large,
    'icon-size--sm': props.small,
    'icon-size--xl': props.xLarge,
    'icon-size--xs': props.xSmall,
    'icon-size--xxl': props.xxLarge,
    'icon-size--xxs': props.xxSmall,
    'icon-size--xxxs': props.xxxSmall,
  })
}

export const Wrapper = (props: JSX.IntrinsicElements['span']) => {
  const { children, onMouseOver, onMouseOut, onMouseEnter, onMouseLeave } = props
  const eventListeners = { onMouseOver, onMouseOut, onMouseEnter, onMouseLeave }
  return <span {...eventListeners}>{children}</span>
}
