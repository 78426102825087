import React from 'react'
import type { Notification } from 'react-notification-system'

import { Icons } from 'components/Icons'

import type { MessageType } from './types'

export const displayToast = (
  message: string | JSX.Element,
  type: MessageType,
): Notification => {
  const args = {
    autoDismiss: 8,
    message,
    messageType: type,
  }

  switch (type) {
    case 'success':
      return flash({ ...args, level: 'success' })
    case 'warning':
      return flash({ ...args, level: 'warning' })
    case 'error':
      return flash({ ...args, level: 'error' })
    case 'saving':
      return flash({ ...args, state: 'saving', level: 'info' })
    case 'saved':
      return flash({ ...args, state: 'saved', level: 'success' })
    case 'saveFailed':
      return flash({ ...args, state: 'saveFailed', level: 'error' })
    default:
      throw new Error(`Invalid toast type: ${type}`)
  }
}

export const showMessage = (message: string | JSX.Element, type: MessageType) => {
  let icon
  if (type === 'success') {
    icon = <Icons.Checkmark white xSmall className="icon" />
  } else if (type === 'warning') {
    icon = <Icons.WarningTriangle white xSmall className="icon" />
  } else if (type === 'error') {
    icon = <Icons.Bell white xSmall className="icon" />
  }

  return (
    <div className="df ai-center" role="status">
      <div className="notification-icon-area">{icon}</div>
      <span className="notification-message">{message}</span>
      <div className="notification-bar">&nbsp;</div>
    </div>
  )
}

export function flash({
  message,
  messageType,
  autoDismiss,
  level,
  state,
}: {
  message: string | JSX.Element
  messageType: MessageType
  autoDismiss: number
  level: 'success' | 'info' | 'warning' | 'error'
  state?: 'saving' | 'saved' | 'saveFailed'
}): Notification {
  return {
    autoDismiss,
    children: showMessage(message, messageType),
    level,
    position: 'tc',
    state,
  }
}
