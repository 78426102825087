import React from 'react'

const DEFAULT_PAGE_TITLE = window.eduflow?.constants.PAGE_TITLE || 'Eduflow'

export const PageTitleContext = React.createContext<{
  pageTitle: string
  setPageTitle: React.Dispatch<React.SetStateAction<string>>
}>({
  pageTitle: DEFAULT_PAGE_TITLE,
  setPageTitle: () => null,
})

export const PageTitleProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = React.useState<string>(DEFAULT_PAGE_TITLE)
  React.useEffect(() => {
    setPageTitle(window.eduflow.constants.PAGE_TITLE)
  }, [window.eduflow?.constants.PAGE_TITLE])
  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  )
}
