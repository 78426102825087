import {
  compose,
  lazy,
  map,
  MatcherGenerator,
  mount,
  redirect,
  withData,
  withContext,
  withTitle,
  withView,
} from 'navi'
import React from 'react'
import { View } from 'react-navi'
import { fetchQuery, graphql } from 'react-relay'

import { courseRootRedirectQuery } from '__generated__/courseRootRedirectQuery.graphql'
import { courseRoutesQuery } from '__generated__/courseRoutesQuery.graphql'
import LayoutView from 'pages/CourseOverview/LayoutView'
import EmptyCourse from 'pages/CourseOverview/teacher/EmptyCourse'
import environment from 'relay/environment'
import { ChildContext } from 'relay/withQuery'
import { toGlobalId } from 'utils/relay'

import { InsertActivityProvider } from 'components/InsertActivity'

export const makeLastVisitedKey = (courseId: string) =>
  `lastVisitedActivity:${courseId}`

const requestCache: {
  [courseId: string]: courseRoutesQuery['response'] | undefined
} = {}

const withLocalizationDisabled = () =>
  withData({ isLocalizationDisabledForRoute: true })

const courseRoute = compose<MatcherGenerator<any>>(
  // `withData` -> `courseId` is need for the `<Header>`-component
  withData((request) => ({ courseId: request.params.courseId })),
  map(async (request) => {
    const courseId = request.params.courseId

    // HACK: To not refetch student/teacher status when navigating between activities
    let relayResponse = requestCache[courseId]
    if (!relayResponse) {
      relayResponse = await fetchQuery<courseRoutesQuery>(
        environment,
        graphql`
          query courseRoutesQuery($courseId: ID!) {
            me {
              isImpersonating
            }
            course(id: $courseId) {
              id
              isViewerTeacher
              isViewerAssistant
              title
            }
          }
        `,
        { courseId: toGlobalId('Course', courseId) },
        { fetchPolicy: 'store-or-network' },
      ).toPromise()
      requestCache[courseId] = relayResponse
    }

    if (!relayResponse || !relayResponse.course) {
      return withView(<div>Unauthorized</div>)
    }

    const { isViewerAssistant, isViewerTeacher, title } = relayResponse.course

    let routesToShow

    if (isViewerAssistant || isViewerTeacher) {
      routesToShow = compose(
        withLocalizationDisabled(),
        lazy(() => import('./teacherCourse')),
      )
    } else {
      routesToShow = lazy(() => import('./studentCourse'))
    }

    return compose(
      withTitle(title),
      withContext(() => ({
        isImpersonating: relayResponse?.me?.isImpersonating,
        isViewerTeacher,
        isViewerAssistant,
      })),
      withView(
        <div data-test="course-overview">
          <InsertActivityProvider>
            <View />
          </InsertActivityProvider>
        </div>,
      ),
      mount<ChildContext<courseRoutesQuery>>({
        '*': routesToShow,
        '/': map(async () => {
          let activityId = ''
          let flowId = ''
          const activityJSON = localStorage.getItem(makeLastVisitedKey(courseId))
          if (activityJSON && activityJSON !== 'undefined') {
            const obj = JSON.parse(activityJSON)
            activityId = obj.activityId || ''
            flowId = obj.flowId || ''
          }

          const query = graphql`
            query courseRootRedirectQuery(
              $courseId: ID!
              $activityId: ID!
              $flowId: ID!
              $includeActivity: Boolean!
              $includeFlow: Boolean!
            ) {
              activity(id: $activityId) @include(if: $includeActivity) {
                url
                flow(includeClassActivity: true) {
                  isDeleted
                }
              }
              flow(id: $flowId) @include(if: $includeFlow) {
                url
                isDeleted
              }
              course(id: $courseId) {
                firstActivity {
                  url
                }
              }
            }
          `

          const isImpersonating = relayResponse?.me?.isImpersonating
          const redirectResponse = await fetchQuery<courseRootRedirectQuery>(
            environment,
            query,
            {
              activityId: toGlobalId('Activity', activityId),
              courseId: toGlobalId('Course', courseId),
              flowId: toGlobalId('Flow', flowId),
              includeActivity: !!activityId && !isImpersonating,
              includeFlow: !!flowId && !isImpersonating,
            },
            { fetchPolicy: 'store-or-network' },
          ).toPromise()

          const course = redirectResponse?.course
          const activity = redirectResponse?.activity
          const flow = redirectResponse?.flow

          if (!isImpersonating) {
            if (activity && activity?.flow?.isDeleted === false) {
              return redirect(activity.url)
            } else if (flow && flow?.isDeleted === false) {
              return redirect(flow.url)
            }
          }

          if (course?.firstActivity) {
            return redirect(course.firstActivity.url)
          }

          return compose(
            withView(LayoutView),
            withView(<EmptyCourse isViewerTeacher={!!isViewerTeacher} />),
          )
        }),
      }),
    )
  }),
)

export default courseRoute
