import React from 'react'

import { Icons } from 'components/Icons'

import styles from './EmptyCourse.module.scss'

const EmptyCourse = ({ isViewerTeacher }: { isViewerTeacher: boolean }) => {
  if (!isViewerTeacher) {
    return (
      <div className={styles.emptyClassContainer}>
        <div className={styles.inner}>
          <Icons.InfoAlternativeCircle accentColor xLarge className={styles.topIcon} />
          <h2>This course has no activities</h2>
          <p>Please contact your instructor for more information.</p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.emptyClassContainer}>
      <div className={styles.inner}>
        <Icons.PlusCircle xLarge accentColor className={styles.topIcon} />
        <h2>Add flows & activities to your course</h2>
        <p>
          Your course looks a little empty. Let's get started by adding some activities
          learners can interact with by clicking the <strong>+ Add new</strong> button
          in the sidebar.
        </p>
        <Icons.HandDrawnArrow accentColor className={styles.arrowIcon} />
      </div>
    </div>
  )
}

export default EmptyCourse
