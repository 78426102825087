import classNames from 'classnames'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import useDebounce from 'hooks/useDebounce'
import { imageUrl } from 'utils/functions'
import defaultImagesList from 'utils/getRandomImage/images.json'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Spinner } from 'components/Spinner'

import styles from '../ImagePicker.module.scss'

interface UnsplashImage {
  id: string
  urls: {
    thumbnail: string
    regular: string
  }
  photographer?: {
    name: string
    url: string
  }
  links: { downloadLocation: string }
}

const parseImage = (result: any): UnsplashImage => ({
  id: result.id,
  urls: {
    thumbnail: result.urls.thumb,
    regular: result.urls.regular,
  },
  photographer: {
    name: result.user.name,
    url: result.user.links.html,
  },
  links: { downloadLocation: result.links.download_location },
})

const defaultImages: UnsplashImage[] = defaultImagesList.map(parseImage)

const UnsplashImageViewer = ({
  img,
  onSelect,
  selected,
}: {
  img: UnsplashImage
  onSelect: () => void
  selected: boolean
}) => (
  <div className={styles.unsplashImageWrapper}>
    <button onClick={onSelect}>
      <img
        loading="lazy"
        src={imageUrl(img.urls.thumbnail)}
        className={classNames(styles.unsplashImage, {
          [styles.selected]: selected,
        })}
      />
    </button>
    {img.photographer && (
      <div className={styles.photographer}>
        by{' '}
        <a
          className={styles.link}
          href={imageUrl(img.photographer.url)}
          target="_blank"
          rel="noreferrer"
        >
          {img.photographer.name}
        </a>
      </div>
    )}
  </div>
)

const UnsplashPicker = ({
  image,
  onImageChange,
  onError,
}: {
  image: string | null
  onImageChange: (
    url: string,
    downloadLocation: string,
    opts?: { closeOnSave: boolean; deferSuccessToast: boolean },
  ) => void
  onError: (e: Error) => void
}) => {
  const [images, setImages] = useState<UnsplashImage[]>(defaultImages)
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)

  async function loadPhotos(newPage: number) {
    try {
      setLoading(true)
      if (newPage === 1) setImages([])

      const params: Record<string, string> = {
        query: debouncedSearchTerm,
        page: newPage.toString(),
        per_page: '20',
      }
      const urlQuery = Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')

      const response = await fetch(`/unsplash/search?${urlQuery}`)
      const data = await response.json()
      const { results, total_pages } = data
      const newImages = results.map(parseImage)

      setHasNextPage(newPage < total_pages)
      setImages((currentImages) => [...currentImages, ...newImages])
      setPage(newPage + 1)
    } catch (e) {
      onError(e)
    } finally {
      setLoading(false)
    }
  }

  const loadMorePhotos = () => {
    if (hasNextPage && !loading) loadPhotos(page)
  }

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage,
    onLoadMore: loadMorePhotos,
    scrollContainer: 'parent',
    threshold: 30,
  })

  useEffect(() => {
    if (debouncedSearchTerm.trim() === '') {
      setHasNextPage(false)
      setImages(defaultImages)
    } else {
      loadPhotos(1)
    }
  }, [debouncedSearchTerm])

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearchTerm(event.target.value)

  const resetSearch = () => setSearchTerm('')

  const selectImage = (selectedImage: UnsplashImage) => () => {
    onImageChange(selectedImage.urls.regular, selectedImage.links.downloadLocation, {
      closeOnSave: false,
      deferSuccessToast: true,
    })
  }

  return (
    <div className={styles.unsplashPicker}>
      <div className={styles.unsplashSearch}>
        <Input
          withSearchIcon
          placeholder="Search images"
          input={{
            value: searchTerm,
            onChange: handleSearchChange,
          }}
        />
      </div>
      {images.length > 0 && (
        <div className={styles.unsplashImages} ref={infiniteRef}>
          {images.map((img) => (
            <UnsplashImageViewer
              key={img.id}
              img={img}
              onSelect={selectImage(img)}
              selected={img.urls.regular === image}
            />
          ))}
        </div>
      )}
      {images.length === 0 && !loading && (
        <div className={styles.unsplashEmptyState}>
          <div className={styles.unsplashEmptyStateText}>No search results</div>
          <Button onClick={resetSearch}>Clear search</Button>
        </div>
      )}
      {loading && (
        <div
          className={classNames(styles.loading, {
            [styles.paddingTop]: images.length === 0,
          })}
        >
          <Spinner accentColor large />
        </div>
      )}
    </div>
  )
}

export default UnsplashPicker
