import classNames from 'classnames/bind'
import React, { useContext, useState } from 'react'
import { Link, useNavigation } from 'react-navi'
import { useFragment, graphql } from 'react-relay'

import { FlowTitle_flow$key } from '__generated__/FlowTitle_flow.graphql'
import { SidebarTree_course } from '__generated__/SidebarTree_course.graphql'
import updateFlowTitle from 'mutations/updateFlowTitle'

import { Button } from 'components/Button'
import { EditingContext } from 'components/EditingContext'
import { Icons } from 'components/Icons'
import { MobileContext } from 'components/MobileContext'
import { ToastContext } from 'components/NotificationCenter'
import { RulesContext } from 'components/RulesSlideIn'
import { TitleForm } from 'components/TitleForm'

import FlowAddActivityContextMenu from '../teacher/FlowAddActivityContextMenu'
import FlowContextMenu from '../teacher/FlowContextMenu'

import styles from './FlowTitle.module.scss'

interface IProps {
  flow: SidebarTree_course['flows'][number]
  isAssistant: boolean
  isTeacher: boolean
  isDragging?: boolean
  handleToggleFlowCollapsed?: (flowId: string, isTeacherTitleClick?: boolean) => void
  isCollapsed: boolean
}

const cx = classNames.bind(styles)
const fragment = graphql`
  fragment FlowTitle_flow on Flow {
    id
    title
    url
    ruleGroupsVisibility {
      openFor
    }
    ...FlowAddActivityContextMenu_flow
    # eslint-disable-next-line relay/must-colocate-fragment-spreads - required for FlowOverviewActivityDescription.tsx
    ...FlowOverviewBody_flow
    # eslint-disable-next-line relay/must-colocate-fragment-spreads - required for FlowOverviewActivityDescription.tsx
  }
`

const FlowTitle = ({
  flow: flowFragment,
  isAssistant,
  isTeacher,
  isCollapsed,
  isDragging,
  handleToggleFlowCollapsed,
}: IProps) => {
  const flow = useFragment<FlowTitle_flow$key>(fragment, flowFragment)
  const { setShouldAnimate, isShowingRules } = React.useContext(RulesContext)

  const handleTitleClickStudent = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    handleToggleFlowCollapsed && handleToggleFlowCollapsed(flow.id)
  }

  const handleCollapseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleToggleFlowCollapsed && handleToggleFlowCollapsed(flow.id)
  }

  if (!isTeacher || isAssistant) {
    return (
      <span
        className={`${styles.flowItemContent} ${styles.student}`}
        onClick={handleTitleClickStudent}
      >
        <span className={styles.left}>
          <Button
            Small
            Icon
            Transparent
            className={styles.collapseButton}
            onClick={handleCollapseClick}
          >
            {isCollapsed ? (
              <Icons.ChevronRight nearBlack />
            ) : (
              <Icons.ChevronDown nearBlack />
            )}
          </Button>
          <span translate="no">{flow.title}</span>
        </span>
      </span>
    )
  }

  const { displayToast } = useContext(ToastContext)
  const { editingActivityId, setEditingActivityId } = useContext(EditingContext)
  const navigation = useNavigation()
  const { setIsShowingContent } = useContext(MobileContext)
  const [isEditing, setIsEditingTitle] = useState(false)
  const editing = isEditing || editingActivityId === flow.id

  const handleEditingTitleChange = (value: boolean) => {
    setIsEditingTitle(value)
    setEditingActivityId('')
  }

  const handleSubmit = async (title: string) => {
    try {
      await updateFlowTitle({
        flowId: flow.id,
        title: title,
      })
      handleEditingTitleChange(false)
    } catch (e) {
      displayToast(e.message, 'error')
    }
  }
  const handleTitleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (editing) {
      // Disable the link when while we're editing
      e.preventDefault()
      return
    }
    isShowingRules && setShouldAnimate(false) // don't animate the rules slide in if its already open
    setIsShowingContent(true)
    await navigation.navigate(flow.url, { replace: true })
    setIsShowingContent(true)
    handleToggleFlowCollapsed && handleToggleFlowCollapsed(flow.id, true)
  }

  const hasVisibilityRules = flow.ruleGroupsVisibility?.length
  const openForRule = flow.ruleGroupsVisibility?.find((r) => r.openFor)
  const isOpenForStudents = !openForRule || openForRule?.openFor?.includes('Student')

  return (
    <Link
      href={flow.url}
      className={cx('flowItemContent', {
        isDragging,
        showDragHandle: true,
      })}
      activeClassName={styles.active}
      onClick={handleTitleClick}
      exact
    >
      {editing ? (
        <TitleForm initialValues={{ title: flow.title }} onSubmit={handleSubmit} />
      ) : (
        <>
          <span
            className={cx('left', {
              withVisibilityIndicator: hasVisibilityRules || !isOpenForStudents,
            })}
          >
            <Button
              Small
              Icon
              Transparent
              className={styles.collapseButton}
              onClick={handleCollapseClick}
            >
              {isCollapsed ? (
                <Icons.ChevronRight nearBlack />
              ) : (
                <Icons.ChevronDown nearBlack />
              )}
            </Button>
            <span className={styles.title} translate="no">
              {flow.title}
            </span>
          </span>

          {!isOpenForStudents ? (
            <span className={styles.itemWrapVisibilityIndicator}>
              <Icons.EyeSlash nearBlack className={styles.itemVisibilityIndicator} />
            </span>
          ) : hasVisibilityRules ? (
            <span className={styles.itemWrapVisibilityIndicator}>
              <Icons.UserWithMinus
                nearBlack
                className={styles.itemVisibilityIndicator}
              />
            </span>
          ) : null}

          <div className={styles.contextMenuContainer}>
            <FlowAddActivityContextMenu flow={flow} />
            <FlowContextMenu
              relayFlowId={flow.id}
              setIsEditingTitle={handleEditingTitleChange}
            />
          </div>
        </>
      )}
    </Link>
  )
}

export default FlowTitle
