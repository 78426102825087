/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateCourseTitleInput = {
    courseId: string;
    title: string;
    clientMutationId?: string | null | undefined;
};
export type updateCourseTitleMutationVariables = {
    input: UpdateCourseTitleInput;
};
export type updateCourseTitleMutationResponse = {
    readonly updateCourseTitle: {
        readonly course: {
            readonly title: string;
        } | null;
    } | null;
};
export type updateCourseTitleMutation = {
    readonly response: updateCourseTitleMutationResponse;
    readonly variables: updateCourseTitleMutationVariables;
};



/*
mutation updateCourseTitleMutation(
  $input: UpdateCourseTitleInput!
) {
  updateCourseTitle(input: $input) {
    course {
      title
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCourseTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCourseTitlePayload",
        "kind": "LinkedField",
        "name": "updateCourseTitle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateCourseTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateCourseTitlePayload",
        "kind": "LinkedField",
        "name": "updateCourseTitle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a92ab691cb0175176b3d0f3dba9cd85",
    "id": null,
    "metadata": {},
    "name": "updateCourseTitleMutation",
    "operationKind": "mutation",
    "text": "mutation updateCourseTitleMutation(\n  $input: UpdateCourseTitleInput!\n) {\n  updateCourseTitle(input: $input) {\n    course {\n      title\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ac9ba4e39b48df650f19269ea3c23c81';
export default node;
