import classNames from 'classnames/bind'
import React from 'react'

import styles from './Divider.module.scss'

const cx = classNames.bind(styles)

export const Divider = ({
  className = '',
  text,
  grey,
}: {
  className?: string
  text?: string
  white?: boolean
  grey?: boolean
}) => {
  const styleName = cx('text', {
    grey,
  })
  return (
    <div className={styles.dividerContainer}>
      <hr className={`${styles.divider} ${className}`} />
      <div className={styles.textContainer}>
        <span className={styleName}>{text}</span>
      </div>
    </div>
  )
}
