import { graphql } from 'react-relay'

import {
  UpdateActivityTitleInput,
  updateActivityTitleMutation,
} from '__generated__/updateActivityTitleMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: UpdateActivityTitleInput) =>
  mutationPromise<updateActivityTitleMutation>({
    mutation: graphql`
      mutation updateActivityTitleMutation($input: UpdateActivityTitleInput!) {
        updateActivityTitle(input: $input) {
          activity {
            title
          }
        }
      }
    `,
    variables: { input },
  })
