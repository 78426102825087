/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PlanType = "FREE" | "LEGACY" | "LITE" | "PLUS" | "PREMIUM" | "PRO" | "SUPER";
export type InstitutionBlockedBanner_institution = {
    readonly id: string;
    readonly paidPlan: {
        readonly planType: PlanType;
        readonly limitsExceededAt: unknown | null;
    };
    readonly " $refType": "InstitutionBlockedBanner_institution";
};
export type InstitutionBlockedBanner_institution$data = InstitutionBlockedBanner_institution;
export type InstitutionBlockedBanner_institution$key = {
    readonly " $data"?: InstitutionBlockedBanner_institution$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"InstitutionBlockedBanner_institution">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InstitutionBlockedBanner_institution",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InstitutionPaidPlan",
      "kind": "LinkedField",
      "name": "paidPlan",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limitsExceededAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Institution",
  "abstractKey": null
};
(node as any).hash = '4fe16e1642d15ffffb26a98e2ad159c8';
export default node;
