import images from './images.json'

export const getRandomImage = (function () {
  let previous = NaN
  return function () {
    const max = images.length
    let randomIndex = Math.floor(Math.random() * max)
    if (randomIndex === previous) {
      if (randomIndex + 1 > max - 1) {
        randomIndex = 0
      } else {
        randomIndex += 1
      }
    }
    previous = randomIndex
    return images[randomIndex]
  }
})()
