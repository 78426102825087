import classNames from 'classnames/bind'
import React, { useCallback, useEffect } from 'react'
import FocusLock from 'react-focus-lock'

import { Icons } from 'components/Icons'
import { Dots } from 'components/Spinner'
import { Tooltip } from 'components/Tooltip'

import styles from './Modal.module.scss'

const cx = classNames.bind(styles)

const Heading = ({
  children,
  alert,
  className: propsClassName,
}: {
  children: React.ReactNode
  alert?: boolean
  className?: string
}) => {
  const className = cx(propsClassName, 'modal-header', {
    alert,
  })

  return <div className={className}>{children}</div>
}

const Content = ({
  children,
  id,
  className = '',
  boxed,
}: {
  children: JSX.Element | (JSX.Element | null)[] | null
  id?: string | undefined
  className?: string
  boxed?: boolean
}) => {
  const styleName = cx('modal-content', {
    boxed,
  })
  return (
    <div id={id} className={`${styleName} ${className}`}>
      {children}
    </div>
  )
}

const ButtonContainer = ({
  children,
  className = '',
}: {
  children: JSX.Element | JSX.Element[]
  className?: string
}) => <div className={`${cx('modal-button-container')} ${className}`}>{children}</div>

const Button = (props: {
  children: string
  buttonStyle?: 'confirm' | 'anger' | 'happy' | 'cancel' | 'subtle'
  className?: string
  disabled?: boolean
  isLoading?: boolean
  onClick?: () => void
  tabIndex?: number
  type?: 'button' | 'submit'
  'data-test'?: string
  dataTest?: string
  tooltipText?: string
}) => {
  const styleName = cx(props.buttonStyle || 'confirm', {
    disabled: props.disabled,
  })

  const button = (
    <div className={styles.buttonWrapper}>
      <button
        tabIndex={props.tabIndex || 0}
        disabled={props.disabled}
        type={props.type || 'button'}
        className={`${styleName} ${props.className || ''}`}
        onClick={!props.isLoading ? props.onClick : undefined}
        data-test={props.dataTest || props['data-test']}
      >
        {!props.isLoading && props.children}
        {props.isLoading && <Dots />}
      </button>
    </div>
  )

  if (props.tooltipText) {
    return <Tooltip text={props.tooltipText}>{button}</Tooltip>
  } else {
    return button
  }
}

const Container = React.forwardRef(
  (
    props: {
      children: JSX.Element | (JSX.Element | null)[] | null
      isSlideIn?: boolean
      className?: string
      closeButtonClassName?: string
      style?: React.CSSProperties
      backdropStyle?: React.CSSProperties
      onClose?: () => void
      allowEscKeyClose?: boolean
      fullScreen?: boolean
      bgColor?: boolean
      darkBackdrop?: boolean
      positionTop?: boolean
      'data-test'?: string
      dataTest?: string
      id?: string
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const styleName = cx({
      'bg-color': props.bgColor,
      'full-screen': props.fullScreen,
      modal: !props.isSlideIn,
      'slide-in': props.isSlideIn,
    })

    const backdropStyleName = cx('backdrop', {
      dark: props.darkBackdrop,
    })
    const focusLockStyleName = cx('backdrop', {
      positionTop: props.positionTop,
      transparent: true,
    })
    const closeButtonClassName = cx('close-button', props.closeButtonClassName)

    const dataTest = props['data-test'] || props.dataTest

    const { allowEscKeyClose = true } = props
    const escFunction = useCallback((event) => {
      if (event.keyCode === 27 && allowEscKeyClose && props.onClose) {
        props.onClose()
      }
    }, [])

    useEffect(() => {
      document.addEventListener('keydown', escFunction, false)

      return () => {
        document.removeEventListener('keydown', escFunction, false)
      }
    }, [])

    const ignoreFocusLock = useCallback((node) => {
      // This ensures that <FocusLock> allows focus outside of the React root
      // element. This is required for the CKEditor link and embed balloons.
      return !!document.getElementById('react-root')?.contains(node)
    }, [])

    return (
      <div
        id={props.id || 'modal'}
        className={backdropStyleName}
        data-test={dataTest}
        role="dialog"
        style={props.backdropStyle}
      >
        <FocusLock
          returnFocus
          className={focusLockStyleName}
          whiteList={ignoreFocusLock}
        >
          <div
            ref={ref}
            className={`${styleName} ${props.className}`}
            style={props.style}
          >
            {props.onClose && (
              <button
                className={`cursor-pointer ${closeButtonClassName}`}
                tabIndex={0}
                onClick={props.onClose}
                aria-label="Close"
              >
                <Icons.Close small />
              </button>
            )}
            {props.children}
          </div>
        </FocusLock>
      </div>
    )
  },
)
export default {
  Button,
  ButtonContainer,
  Container,
  Content,
  Heading,
}
