import { withProfiler } from '@sentry/react'
import {
  compose,
  lazy,
  Matcher,
  mount,
  redirect,
  route,
  withData,
  withTitle,
  withView,
} from 'navi'
import queryString from 'query-string'
import React, { useContext, useEffect } from 'react'
import BusyIndicator from 'react-busy-indicator'
import {
  NotFoundBoundary,
  Router,
  useCurrentRoute,
  useLoadingRoute,
  useNavigation,
  View,
} from 'react-navi'
import HelmetProvider from 'react-navi-helmet-async'
import { Provider } from 'react-redux'
import { RelayEnvironmentProvider, loadQuery } from 'react-relay'
import { Store } from 'redux'

import { IReduxState } from 'models/redux'
import TemplateLayout from 'pages/CourseTemplate/TemplateLayout'
import renderNotFound from 'pages/NotFound/NotFound'
import environment, { hasNewAppBundle } from 'relay/environment'

import { EditingProvider } from 'components/EditingContext'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { ErrorMessageWithHomeButton } from 'components/ErrorMessage/ErrorMessage'
import { ImpersonationBar } from 'components/ImpersonationBar/ImpersonationBar'
import InstitutionBlockedBanner from 'components/InstitutionBlockedBanner/InstitutionBlockedBanner'
import { MobileProvider } from 'components/MobileContext'
import { ModalProvider } from 'components/Modal/ModalProvider'
import { ToastContext } from 'components/NotificationCenter'
import { NotificationProvider } from 'components/NotificationCenter/NotificationContext'
import { ToastProvider } from 'components/NotificationCenter/ToastContext'
import { PageTitleProvider } from 'components/PageTitleContext'
import { RulesProvider } from 'components/RulesSlideIn'
import WindowMessaging from 'components/ScormWindowMessaging/ScormWindowMessaging'
import { ScrollerProvider } from 'components/ScrollerContext'
import { SidebarCollapseProvider } from 'components/SidebarCollapseContext'
import { Theme } from 'components/Theme'
import { UserProvider } from 'components/UserContext'
import UserTracking from 'components/UserTracking/UserTracking'

import CreateInstitutionQuery, {
  CreateInstitutionQuery as CreateInstitutionQueryType,
} from '../__generated__/CreateInstitutionQuery.graphql'
import EditCourseLibraryQuery, {
  EditCourseLibraryQuery as EditCourseLibraryQueryType,
} from '../__generated__/EditCourseLibraryQuery.graphql'
import courseRoute from './course'
import withBlockImpersonation from './withBlockImpersonation'

declare let __PRODUCTION__: boolean

const Header = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: 'Header' */ 'components/Header/Header'
    ),
)

const routesWithHeader = withView(
  <>
    <Header />
    <ErrorBoundary errorMessage={<ErrorMessageWithHomeButton />}>
      <NotFoundBoundary render={renderNotFound}>
        <View />
      </NotFoundBoundary>
    </ErrorBoundary>
  </>,
  mount({
    '*': compose(
      mount({
        '/template': compose(
          withView(<TemplateLayout />),
          mount({
            '/:templateSlug': compose(
              withData(({ params: { templateSlug } }) => ({ templateSlug })),
              lazy(
                () =>
                  import(
                    /* webpackPrefetch: true, webpackChunkName: 'TemplateBody' */ 'pages/CourseTemplate/TemplateBody'
                  ),
              ),
            ),
          }),
        ),
        '/templates': compose(
          withView(<TemplateLayout />),
          withTitle(
            'Template Library · Bring your classes online in seconds with Eduflow',
          ),
          mount({
            '/': lazy(
              () =>
                import(
                  /* webpackPrefetch: true, webpackChunkName: 'TemplateList' */ 'pages/CourseTemplate/TemplateList'
                ),
            ),
            '/:templateCategorySlug': lazy(
              () =>
                import(
                  /* webpackPrefetch: true, webpackChunkName: 'TemplateList' */ 'pages/CourseTemplate/TemplateList'
                ),
            ),
          }),
        ),
      }),
    ),
    '/plan-blocked': route({
      getView: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: 'PlanBlocked' */ 'pages/PlanBlocked/PlanBlocked'
        ),
    }),
    '/api/graphql': route({
      getView: () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: 'InstitutionAPIExplorerPaywall' */ 'pages/Institution/InstitutionAPIExplorerPaywall'
        ),
    }),
    '/': withBlockImpersonation(
      compose(
        lazy(
          () =>
            import(
              /* webpackPrefetch: true, webpackChunkName: 'UserOverview' */ 'pages/UserOverview/UserOverview'
            ),
        ),
      ),
    ),
    '/course-library': withBlockImpersonation(
      lazy(
        () =>
          import(
            /* webpackPrefetch: true, webpackChunkName: 'UserOverview' */ 'pages/UserOverview/UserOverview'
          ),
      ),
    ),
    '/edit-course-library': withBlockImpersonation(
      compose(
        withData(() => ({
          editCourseLibraryQueryRef: loadQuery<EditCourseLibraryQueryType>(
            environment,
            EditCourseLibraryQuery,
            {},
            { fetchPolicy: 'store-and-network' },
          ),
        })),
        lazy(
          () =>
            import(
              /* webpackPrefetch: true, webpackChunkName: 'EditCourseLibrary' */ 'pages/EditCourseLibrary/EditCourseLibrary'
            ),
        ),
      ),
    ),
    '/copy-course': withBlockImpersonation(
      lazy(
        () =>
          import(
            /* webpackPrefetch: true, webpackChunkName: 'CopyCourse' */ 'pages/CreateCourse/CopyCourse.route'
          ),
      ),
    ),
    '/course/:courseSlugOrId': lazy(
      () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: 'CoursePage' */ 'pages/CoursePage/CoursePage.route'
        ),
    ),
    '/course-library/:courseSlugOrId': lazy(
      () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: 'CourseLibraryCoursePage' */ 'pages/CoursePage/CoursePage.route'
        ),
    ),
    '/courses/:courseId': courseRoute,
    '/create-course': withBlockImpersonation(
      lazy(
        () =>
          import(
            /* webpackPrefetch: true, webpackChunkName: 'CreateCourse' */ 'pages/CreateCourse/CreateCourse.route'
          ),
      ),
    ),
    '/create-institution': withBlockImpersonation(
      lazy(
        () =>
          import(
            /* webpackPrefetch: true, webpackChunkName: 'CreateInstitution' */ 'pages/CreateInstitution/CreateInstitution'
          ),
      ),
    ),
    '/institution/:institutionId': lazy(
      () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: 'Institution' */ 'pages/Institution/Institution.route'
        ),
    ),
    '/link-account': withBlockImpersonation(
      route({
        getView: () =>
          import(
            /* webpackPrefetch: true, webpackChunkName: 'LinkAccount' */ 'pages/LinkAccount/LinkAccount'
          ),
      }),
    ),
    '/settings': withBlockImpersonation(
      mount({
        '/': redirect('./account'),
        '/account': lazy(
          () =>
            import(
              /* webpackPrefetch: true, webpackChunkName: 'UserSettings' */ 'pages/UserSettings/UserSettings'
            ),
        ),
      }),
    ),
    '/use-template/:useTemplateSlug': lazy(
      () =>
        import(
          /* webpackPrefetch: true, webpackChunkName: 'UseTemplate' */ 'pages/CourseTemplate/UseTemplate'
        ),
    ),
  }),
)

const routes: { [route: string]: Matcher<object, object> } = {
  '*': routesWithHeader,
  '/create-institution': withBlockImpersonation(
    compose(
      withData(() => ({
        createInstitutionQueryRef: loadQuery<CreateInstitutionQueryType>(
          environment,
          CreateInstitutionQuery,
          {},
          { fetchPolicy: 'store-and-network' },
        ),
      })),
      lazy(
        () =>
          import(
            /* webpackPrefetch: true, webpackChunkName: 'CreateInstitution' */ 'pages/CreateInstitution/CreateInstitution'
          ),
      ),
    ),
  ),
  '/forgot-password/:uid/:token': lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'ForgotPassword' */ 'pages/ForgotPassword/ForgotPassword'
      ),
  ),

  '/complete-signup': route({
    getView: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'CompleteSignup' */ 'pages/CompleteSignup/CompleteSignup'
      ),
  }),
  '/join': lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'Join' */ 'pages/Join/Join.route'
      ),
  ),
  '/login': route({
    getView: () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'Login' */ 'pages/Login/LoginPage'
      ),
  }),
  '/lti': lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'LTI' */ 'pages/LTI/LTI.route'
      ),
  ),
  '/rich-text/:attachmentId': lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'RichTextAttachmentViewer' */ 'components/AttachmentView/viewers/RichText/RichTextAttachmentViewer.route'
      ),
  ),
  '/sign-up': lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'Signup' */ 'pages/Signup/Signup.route'
      ),
  ),
  '/certificate/:certificateId': lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'CertificatePage' */ 'pages/Certificate/CertificatePage.route'
      ),
  ),
}

if (!__PRODUCTION__) {
  routes['/dev/icons'] = lazy(
    () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: 'IconsGallery' */ 'dev/IconsGallery/IconsGallery'
      ),
  )
}

const mountedRoutes = mount(routes)

const Layout: React.FunctionComponent = ({ children }) => {
  const navigation = useNavigation()
  if (!window.eduflow.hasRouteAppBundleSubscription) {
    // prevent binding multiple subscriptions
    window.eduflow.hasRouteAppBundleSubscription = true

    navigation.subscribe((route) => {
      if (hasNewAppBundle) {
        window.location.href = route.url.href
      }
    })
  }

  const loadingRoute = useLoadingRoute()
  return (
    <>
      <BusyIndicator
        isBusy={!!loadingRoute}
        style={{
          background: 'linear-gradient(90deg, #3CBBFF 0%, #006CFA 100%',
          height: '2px',
        }}
      />
      <NotFoundBoundary render={renderNotFound}> {children} </NotFoundBoundary>
    </>
  )
}

const ServerRedirectToastMsg = () => {
  const { displayToast } = useContext(ToastContext)
  const {
    url: { search, pathname },
  } = useCurrentRoute()

  useEffect(() => {
    const {
      successMsg = undefined,
      errorMsg = undefined,
      ...qs
    } = queryString.parse(search)
    const url = queryString.stringifyUrl({ url: pathname, query: qs })
    if (successMsg) {
      displayToast(successMsg as string, 'success')
      window.history.replaceState({}, document.title, url)
    } else if (errorMsg) {
      displayToast(errorMsg as string, 'error')
      window.history.replaceState({}, document.title, url)
    }
  }, [search])

  return null
}

const EduFlowApp = ({ store }: { store: Store<IReduxState> }) => {
  return (
    <Provider store={store}>
      <RelayEnvironmentProvider environment={environment}>
        <PageTitleProvider>
          <HelmetProvider>
            <ScrollerProvider>
              <Router routes={mountedRoutes}>
                <ErrorBoundary errorMessage={<ErrorMessageWithHomeButton />}>
                  <ToastProvider>
                    <MobileProvider>
                      <EditingProvider>
                        <WindowMessaging>
                          <NotificationProvider>
                            <RulesProvider>
                              <ModalProvider>
                                <UserProvider>
                                  <SidebarCollapseProvider>
                                    <Theme />
                                    <ImpersonationBar />
                                    <UserTracking />
                                    <InstitutionBlockedBanner />
                                    <ServerRedirectToastMsg />
                                    <Layout>
                                      <React.Suspense fallback={null}>
                                        <View />
                                      </React.Suspense>
                                    </Layout>
                                  </SidebarCollapseProvider>
                                </UserProvider>
                              </ModalProvider>
                            </RulesProvider>
                          </NotificationProvider>
                        </WindowMessaging>
                      </EditingProvider>
                    </MobileProvider>
                  </ToastProvider>
                </ErrorBoundary>
              </Router>
            </ScrollerProvider>
          </HelmetProvider>
        </PageTitleProvider>
      </RelayEnvironmentProvider>
    </Provider>
  )
}

export default window.eduflow.constants.SENTRY_REACT_PROFILER_ENABLED
  ? withProfiler(EduFlowApp)
  : EduFlowApp
