import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import trackEvent from 'mutations/trackEvent'

declare let __SENTRY_ENVIRONMENT__: any

const normalizePath = (path: string) => {
  // Replace UUIDs
  const normalized = path.replace(/[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/g, '<ID>')

  // Replace course codes and other tokens
  const prefixes = [
    '/join/no-session/',
    '/join/',
    '/forgot-password/',
    '/templates/',
    '/template/',
    '/use-template/',
  ]
  for (const prefix of prefixes) {
    if (normalized.startsWith(prefix)) {
      return prefix + '<ID>'
    }
  }
  return normalized
}

const trackPageView = (pathname: string, data: any) => {
  return trackEvent({
    eventType: 'USER_VIEWED_PAGE',
    // We send camelCase because of relay, we always store and send metrics in under_scores
    ...(data.courseId ? { courseId: data.courseId } : {}),
    ...(data.activityId ? { activityId: data.activityId } : {}),
    ...(data.institutionId ? { institutionId: data.institutionId } : {}),
    metadata: JSON.stringify({
      normalized_url: normalizePath(pathname),
      page_name: document.title,
      url: pathname,
      ...(data.submissionId ? { submissionId: data.submissionId } : {}),
    }),
  })
}

const setupSentry = () => {
  if (window.eduflow.constants.SENTRY_ENABLED) {
    const extraSettings: { integrations?: any; tracesSampleRate?: number } = {}
    if (window.eduflow.constants.SENTRY_TRACES_SAMPLE_RATE_JS > 0) {
      extraSettings.integrations = [
        new Integrations.BrowserTracing({
          beforeNavigate: (context) => ({
            ...context,
            name: normalizePath(location.pathname),
            ...(typeof window.eduflow.constants?.SENTRY_MAX_TRANSACTION_DURATION ===
              'number' && {
              maxTransactionDuration:
                window.eduflow.constants.SENTRY_MAX_TRANSACTION_DURATION,
            }),
            ...(typeof window.eduflow.constants.SENTRY_IDLE_TIMEOUT === 'number' && {
              idleTimeout: window.eduflow.constants.SENTRY_IDLE_TIMEOUT,
            }),
          }),
        }),
      ]
      extraSettings.tracesSampleRate =
        window.eduflow.constants.SENTRY_TRACES_SAMPLE_RATE_JS
    }
    Sentry.init({
      dsn: 'https://e2c313d9a57b401a8f7435e755fc9edd@sentry.io/1474614',
      environment:
        window.eduflow.constants.SENTRY_ENVIRONMENT || __SENTRY_ENVIRONMENT__,
      release: window.eduflow.constants.SENTRY_RELEASE,
      normalizeDepth: 5,
      ignoreErrors: ['ChunkLoadError'],
      ...extraSettings,
    })
  }
}

export default {
  setupSentry,
  trackPageView,
}
