import React from 'react'
import { useCurrentRoute } from 'react-navi'

export enum RuleTabs {
  ACTIVITY = 'ACTIVITY',
  COMPLETION = 'COMPLETION',
  VISIBILITY = 'VISIBILITY',
}

type HandleOpenRulesArgs = { activeTab?: RuleTabs }

export const RulesContext = React.createContext<{
  activeTab: RuleTabs
  closeRules: () => void
  isShowingRules: boolean
  openRules: (options?: HandleOpenRulesArgs) => void
  selectActivityTab: () => void
  selectCompletionTab: () => void
  selectVisibilityTab: () => void
  shouldAnimate: boolean
  setShouldAnimate: React.Dispatch<React.SetStateAction<boolean>>
}>({
  activeTab: RuleTabs.ACTIVITY,
  closeRules: () => null,
  isShowingRules: false,
  openRules: () => null,
  selectActivityTab: () => null,
  selectCompletionTab: () => null,
  selectVisibilityTab: () => null,
  shouldAnimate: true,
  setShouldAnimate: () => null,
})

export const RulesProvider: React.FC<{}> = (props) => {
  const [activeTab, setActiveTab] = React.useState<RuleTabs>(RuleTabs.ACTIVITY)
  const selectActivityTab = () => setActiveTab(RuleTabs.ACTIVITY)
  const selectCompletionTab = () => setActiveTab(RuleTabs.COMPLETION)
  const selectVisibilityTab = () => setActiveTab(RuleTabs.VISIBILITY)

  const [shouldAnimate, setShouldAnimate] = React.useState<boolean>(true)
  const [isShowingRules, setIsShowingRules] = React.useState<boolean>(false)
  const handleOpenRules = (options?: HandleOpenRulesArgs) => {
    if (options?.activeTab) {
      setActiveTab(options.activeTab)
    }
    setIsShowingRules(true)
  }
  const handleCloseRules = () => {
    setIsShowingRules(false)
    setShouldAnimate(true)
  }

  const { data } = useCurrentRoute()
  const { activityId, flowId } = data
  // We check if we are on the activity or flow page to determine if we should show the rules slide in
  const isOnFlowOrActivityPage = Boolean(activityId || flowId)

  React.useEffect(() => {
    if (!isShowingRules) {
      return
    }
    if (!activityId && flowId && activeTab === RuleTabs.ACTIVITY) {
      // We have the activity tab open on the rules slide in open and transitioned to a flow
      // Since the flow rules don't have an activity tab we switch to the completion tab
      selectCompletionTab()
    }
  }, [activityId, flowId])

  return (
    <RulesContext.Provider
      value={{
        activeTab,
        isShowingRules: isOnFlowOrActivityPage && isShowingRules,
        closeRules: handleCloseRules,
        openRules: handleOpenRules,
        selectActivityTab,
        selectCompletionTab,
        selectVisibilityTab,
        shouldAnimate,
        setShouldAnimate,
      }}
    >
      {props.children}
    </RulesContext.Provider>
  )
}
