import classNames from 'classnames'
import React, { useContext, useState, Suspense } from 'react'
import { useFragment, graphql } from 'react-relay'

import { SidebarCourseQueryResponse } from '__generated__/SidebarCourseQuery.graphql'
import { SidebarHero_course$key } from '__generated__/SidebarHero_course.graphql'
import updateCourseTitle from 'mutations/updateCourseTitle'
import { imageUrl } from 'utils/functions'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { CourseThumbnailPicker } from 'components/CourseThumbnailPicker'
import { Divider } from 'components/Divider'
import { Icons } from 'components/Icons'
import { ToastContext } from 'components/NotificationCenter'
import { TitleForm } from 'components/TitleForm'
import { Tooltip } from 'components/Tooltip'

import CourseContextMenu from '../teacher/CourseContextMenu'
import TestUsersOptions from './TestUsersOptions'

import styles from './Sidebar.module.scss'

interface IOwnProps {
  course: SidebarCourseQueryResponse['course']
}

const SidebarHero: React.FC<IOwnProps> = ({ course: courseFragment }) => {
  const course = useFragment<SidebarHero_course$key>(
    graphql`
      fragment SidebarHero_course on Course {
        id
        isViewerTeacher
        isViewerAssistant
        isArchived
        thumbnail
        title
        ...CourseContextMenu_course
        ...CourseThumbnailPicker_course
      }
    `,
    courseFragment,
  )

  const { displayToast } = useContext(ToastContext)
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const [isEditingThumbnail, setIsEditingThumbnail] = useState(false)
  const showThumbnailMenu = () => setIsEditingThumbnail(true)
  const hideThumbnailMenu = () => setIsEditingThumbnail(false)

  if (!course) {
    return null
  }

  const isTeacher = !!course.isViewerTeacher
  const isAssistant = !!course.isViewerAssistant
  const classes = classNames(styles.classTitle, { [styles.isTeacher]: isTeacher })
  const canViewTestUsers =
    course.isViewerTeacher &&
    !course.isViewerAssistant &&
    window.waffle.flag_is_active('preview_course')

  const showEditTitle = () => setIsEditingTitle(true)
  const hideEditTitle = () => setIsEditingTitle(false)

  const handleTitleFormSubmit = async (title: string) => {
    try {
      await updateCourseTitle({ courseId: course.id, title: title })
      return hideEditTitle()
    } catch (e) {
      displayToast(e.message, 'error')
    }
  }

  const renderEditThumbnailButton = (ref: React.RefObject<HTMLElement>) => (
    <Button
      ref={ref as React.RefObject<HTMLButtonElement>}
      className={styles.thumbnailButton}
      Small
      onClick={showThumbnailMenu}
      style={isEditingThumbnail ? { display: 'flex' } : undefined}
    >
      <Icons.Camera accentColor className={styles.thumbnailButtonIcon} />
      Edit thumbnail
    </Button>
  )

  const renderEditThumbnailMenu = (ref: React.RefObject<HTMLElement>) => {
    return (
      <div ref={ref as React.RefObject<HTMLDivElement>}>
        <CourseThumbnailPicker course={course} hideEditThumbnail={hideThumbnailMenu} />
      </div>
    )
  }

  return (
    <div className={styles.top}>
      <div className={styles.thumbnailWrapper}>
        <img
          className={styles.thumbnail}
          src={imageUrl(course.thumbnail)}
          alt={`Thumbnail image for ${course.title}`}
        />
        {isTeacher && !isAssistant && (
          <Suspense fallback={null}>
            <ContextMenu
              renderLabel={renderEditThumbnailButton}
              menuClassName={styles.thumbnailContextMenu}
              alignLeftMobile
              target="#course-actions-button"
              attachment="middle left"
              targetAttachment="middle right"
              renderElement={renderEditThumbnailMenu}
              isOpen={isEditingThumbnail}
              onHide={hideThumbnailMenu}
            />
          </Suspense>
        )}
        <div className={styles.thumbnailLoader} />
      </div>
      {course.isArchived && (
        <Tooltip placement="right" text="The course is archived and read-only">
          <span className={styles.archivedLabel}>Archived</span>
        </Tooltip>
      )}
      <div className={styles.content}>
        {isEditingTitle ? (
          <TitleForm
            initialValues={{ title: course.title }}
            onSubmit={handleTitleFormSubmit}
          />
        ) : (
          <>
            <div onClick={isTeacher ? showEditTitle : undefined}>
              <h1 className={classes} translate="no">
                {course.title}
              </h1>
            </div>
            {isTeacher && !isAssistant && (
              <Suspense fallback={null}>
                <CourseContextMenu onEditTitle={showEditTitle} course={course} />
              </Suspense>
            )}
          </>
        )}
      </div>
      <Suspense fallback={null}>
        {canViewTestUsers ? <TestUsersOptions courseId={course.id} /> : null}
      </Suspense>
      <Divider className={styles.divider} />
    </div>
  )
}
export default SidebarHero
