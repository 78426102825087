import classNames from 'classnames/bind'
import { Picker as EmojiPicker, BaseEmoji } from 'emoji-mart'
import React, { useContext, useEffect, useRef, useState } from 'react'

import useEventListener from 'hooks/useEventListener'
import useMediaQuery from 'hooks/useMediaQuery'
import { isDataURI, sleep } from 'utils/functions'

import { Button } from 'components/Button'
import { CUSTOM_EMOJIS } from 'components/CustomIcon/constants'
import { getRandomEmoji } from 'components/CustomIcon/utils'
import { Icons } from 'components/Icons'
import LinkPicker from 'components/ImagePicker/components/LinkPicker'
import UploadPicker from 'components/ImagePicker/components/UploadPicker'
import { ToastContext } from 'components/NotificationCenter'
import { Spinner } from 'components/Spinner'
import NavBar from 'components/modals/components/NavBar'

import { handleKeyboardNavigation } from './keyboard'

import styles from './IconPicker.module.scss'

interface IProps {
  initialIcon: string | null
  onChangeIcon: (icon: string | null) => Promise<void> | void
  handleClose: () => void
  additionalContainerStyles?: string
}

const cx = classNames.bind(styles)

enum Tab {
  Emoji,
  Upload,
  Link,
}

const tabs = [
  {
    label: 'Emoji',
    value: Tab.Emoji,
  },
  {
    label: 'Upload',
    value: Tab.Upload,
  },
  {
    label: 'Link',
    value: Tab.Link,
  },
]

const IconPicker = ({
  handleClose,
  onChangeIcon,
  initialIcon,
  additionalContainerStyles = '',
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [activeTab, setActiveTab] = useState(Tab.Emoji)
  const [mounted, setMounted] = useState(false)
  const [pendingSuccessMsg, setPendingSuccessMsg] = useState<null | string>(null)
  const [submitting, setSubmitting] = useState(false)
  const { displayToast } = useContext(ToastContext)
  const isMobile = useMediaQuery('(max-width: 768px)')

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 300)

    document.addEventListener('keydown', handleKeyboardNavigation)
    return () => document.removeEventListener('keydown', handleKeyboardNavigation)
  }, [])

  useEffect(() => {
    if (mounted) {
      document.querySelector('.emoji-mart-search')?.setAttribute('tabindex', '-1')
      document.querySelector('.emoji-mart-scroll')?.setAttribute('tabindex', '-1')
      document.querySelector('.emoji-mart-search-icon')?.setAttribute('tabindex', '-1')
      document
        .querySelectorAll('.emoji-mart-category .emoji-mart-emoji')
        .forEach(async (element, index) => {
          element.setAttribute('tabindex', '0')
          if (index && index % 20 === 0) await sleep(300)
        })
    }
  }, [mounted])

  const onClose = () => {
    handleClose()
    if (pendingSuccessMsg) {
      displayToast(pendingSuccessMsg, 'success')
    }
  }

  useEventListener(
    'mousedown',
    async (event: MouseEvent) => {
      const isClickOutside =
        event.target instanceof Element && !ref?.current?.contains(event.target)
      if (isClickOutside) {
        onClose()
      }
    },
    window,
    { capture: true },
  )

  const setIcon = async (icon: string | null) => {
    try {
      onClose()
      await onChangeIcon(icon)
    } catch (e) {
      displayToast(e.message, 'error')
    }
  }

  const selectEmoji = (emoji: BaseEmoji) => setIcon('emoji:' + emoji.id)
  const removeIcon = () => setIcon(null)
  const setRandomIcon = () => onChangeIcon('emoji:' + getRandomEmoji())

  const submitImage = async (
    url?: string,
    opts: { closeOnSave: boolean; deferSuccessToast: boolean } = {
      closeOnSave: true,
      deferSuccessToast: false,
    },
  ) => {
    if (!url) return
    if (isDataURI(url)) return

    try {
      setSubmitting(true)
      await onChangeIcon(url)

      if (!opts.deferSuccessToast) {
        displayToast('Activity icon updated', 'success')
        setPendingSuccessMsg(null)
      } else {
        setPendingSuccessMsg('Activity icon updated')
      }

      if (opts.closeOnSave) {
        handleClose()
      }
    } catch (e) {
      displayToast(e.message, 'error')
    }
    setSubmitting(false)
  }

  const handleError = (e: Error) => {
    console.error(e)
    displayToast('Failed to update the image', 'error')
  }

  return (
    <div ref={ref} className={cx(styles.container, additionalContainerStyles)}>
      <div className={styles.header}>
        <NavBar
          items={tabs}
          onClick={setActiveTab}
          activeTab={activeTab}
          tabClassName={styles.tab}
        />
        <div>
          <Button
            Icon
            className={styles.headerButton}
            tooltipText={'Remove'}
            tooltipZIndex={2000}
            onClick={removeIcon}
          >
            <Icons.Trash red />
          </Button>
          {!isMobile && (
            <Button
              Icon
              className={styles.headerButton}
              tooltipText={'Pick random'}
              tooltipZIndex={2000}
              onClick={setRandomIcon}
            >
              <Icons.Sync accentColor />
            </Button>
          )}
          <Button Icon onClick={onClose} ariaLabel="Close">
            <Icons.Close />
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {activeTab === Tab.Emoji &&
          (mounted ? (
            <EmojiPicker
              autoFocus
              native
              style={{ width: isMobile ? 320 : 400 }}
              onClick={selectEmoji}
              emojisToShowFilter={(emoji: any) => emoji.added_in < 12 || emoji.isCustom}
              custom={CUSTOM_EMOJIS}
            />
          ) : (
            <div className={styles.loading}>
              <Spinner />
            </div>
          ))}
        {activeTab === Tab.Upload && (
          <UploadPicker
            submitImage={submitImage}
            onError={handleError}
            s3Destination={'activity-icons'}
            className={styles.uploadPicker}
          />
        )}
        {activeTab === Tab.Link && (
          <LinkPicker
            image={initialIcon?.startsWith('emoji:') ? null : initialIcon}
            submitting={submitting}
            submitImage={submitImage}
            className={styles.linkPicker}
          />
        )}
      </div>
    </div>
  )
}

export default IconPicker
