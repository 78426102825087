import { useEffect, useRef } from 'react'

/**
 * Hook that call a function when user click outside the specified elements
 */
export default function useClickOutside(
  elementsIds: string[],
  handler: (event: MouseEvent) => void,
) {
  const savedHandler = useRef<(event: MouseEvent) => void>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  function handleClickOutside(event: MouseEvent) {
    if (!savedHandler.current) return

    let isOutside = true

    for (const elementId of elementsIds) {
      const element = document.getElementById(elementId)
      if (element && element.contains(event.target as Node)) {
        isOutside = false
        break
      }
    }

    if (isOutside) savedHandler.current(event)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])
}
