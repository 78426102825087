import debounce from 'lodash/debounce'
import React from 'react'

import { SidebarCollapseContext } from 'components/SidebarCollapseContext'

import styles from './Sidebar.module.scss'

const useSidebarResizer = () => {
  const { setIsResizingSidebar } = React.useContext(SidebarCollapseContext)

  const startSidebarResize = () => {
    document.addEventListener('mouseup', stopSidebarResize, true)
    document.addEventListener('mousemove', handleSidebarResize, true)
    document.body.style.cursor = 'col-resize'
    setIsResizingSidebar(true)
  }

  const stopSidebarResize = () => {
    document.removeEventListener('mouseup', stopSidebarResize, true)
    document.removeEventListener('mousemove', handleSidebarResize, true)
    document.body.style.removeProperty('cursor')
    setIsResizingSidebar(false)
  }

  const resetSidebarWidth = () => {
    const root = document.documentElement
    root.style.setProperty('--sidebar-width', '20rem')

    localStorage.removeItem('sidebar-width')
  }

  const saveSidebarWidth = debounce((newWidthPx) => {
    localStorage.setItem('sidebar-width', newWidthPx)
  }, 500)

  const setSidebarWidth = (newWidthPx: string) => {
    const root = document.documentElement
    root.style.setProperty('--sidebar-width', newWidthPx)

    saveSidebarWidth(newWidthPx)
  }

  const handleSidebarResize = React.useCallback((e) => {
    const minDrawerWidth = 240
    const maxDrawerWidth = window.innerWidth / 2

    const newWidth = e.clientX - document.body.offsetLeft
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      const newWidthPx = newWidth + 'px'
      setSidebarWidth(newWidthPx)
    }
  }, [])

  return { startSidebarResize, resetSidebarWidth }
}

const SidebarResizer = () => {
  const { startSidebarResize, resetSidebarWidth } = useSidebarResizer()

  return (
    <div
      className={styles.resizer}
      onMouseDown={startSidebarResize}
      onDoubleClick={resetSidebarWidth}
      id="sidebar-resizer"
    />
  )
}

export default SidebarResizer
