import debounce from 'lodash/debounce'

import { isValidFileMimeType, fileMimeTypeMap } from 'utils/constants'
import logException from 'utils/logException'

import { FileReferenceType } from 'components/RichText/editors/CKEditor/constants'

declare global {
  interface Window {
    XDomainRequest: typeof XMLHttpRequest
  }
}

type onErrorFn = (message: string) => void
type onFinishS3PutFn = (
  submissionUrl: string,
  mimeType: string,
  origMimeType: string,
  fileName: string,
  urlAuthenticated: string,
  results: any,
) => void

export type onProgressFn = (percent: number, status: string) => void

export interface S3UploadOptions {
  readonly destination: string
  readonly fileReferenceType?: FileReferenceType
  readonly fileReferenceId?: string
  readonly allowedFileTypes?: string[]

  readonly file: File | Blob

  readonly customXhr?: XMLHttpRequest

  readonly onProgress?: onProgressFn
  readonly onFinishS3Put?: onFinishS3PutFn
  readonly onError?: onErrorFn
}

interface Payload {
  filename: string
  contentType: string
  acl: string
  destination: string
  fileReferenceId?: string
  fileReferenceType?: FileReferenceType
}

export default class S3Upload implements S3UploadOptions {
  readonly fileDOMSelector = 'file_upload'
  readonly s3SignPutUrl = '/file_upload/sign_s3/'

  readonly fileReferenceType?: FileReferenceType
  readonly fileReferenceId?: string
  readonly destination!: string
  readonly allowedFileTypes: string[] = ['*']

  readonly file!: File | Blob

  readonly customXhr!: XMLHttpRequest | undefined

  readonly onProgress: onProgressFn = () => void 0
  readonly onFinishS3Put: onFinishS3PutFn = () => void 0
  readonly onError: onErrorFn = (status: string) => {
    const err = new Error('s3 Upload Err: ' + status)
    logException(err)
  }

  uploadXHR?: XMLHttpRequest // via .uploadToS3()

  constructor(options: S3UploadOptions) {
    this.destination = options.destination
    this.fileReferenceType = options.fileReferenceType
    this.fileReferenceId = options.fileReferenceId
    this.file = options.file
    this.customXhr = options.customXhr

    this.onProgress = options.onProgress ?? this.onProgress
    this.onFinishS3Put = options.onFinishS3Put ?? this.onFinishS3Put
    this.onError = options.onError ?? this.onError

    if (typeof this.file === 'undefined') {
      this.handleFileSelect(
        document.getElementById(this.fileDOMSelector) as HTMLInputElement,
      )
    }
  }

  abort() {
    // Aborts file upload
    if (this.uploadXHR) {
      this.uploadXHR.abort()
    }
  }

  handleFileSelect(elFileUpload: HTMLInputElement | null) {
    let f, _i, _len
    this.onProgress(0, 'Upload started.')
    const files = elFileUpload?.files
    if (!files) return []
    const _results = []
    for (_i = 0, _len = files.length; _i < _len; _i++) {
      f = files[_i]
      _results.push(this.uploadFile(f))
    }
    return _results
  }

  createCORSRequest(method: string, url: string) {
    let xhr = this.customXhr || new XMLHttpRequest()
    if (xhr.withCredentials != null) {
      xhr.open(method, url, true)
    } else if (typeof window.XDomainRequest !== 'undefined') {
      xhr = new window.XDomainRequest()
      xhr.open(method, url)
    } else {
      return null
    }
    if (xhr) {
      // See `file_upload/s3.py` on `Content-Disposition`
      xhr.setRequestHeader('Content-Disposition', 'attachment')
    }
    return xhr
  }

  executeOnSignedUrl(
    file: File,
    fileType: string,
    fileACL: string,
    callback: (
      signedRequest: any,
      url: string,
      urlAuthenticated: string,
      result: any,
    ) => void,
  ) {
    const xhr = new XMLHttpRequest()
    const payload: Payload = {
      filename: file.name,
      contentType: fileType,
      acl: fileACL,
      destination: this.destination,
      fileReferenceId: this.fileReferenceId,
      fileReferenceType: this.fileReferenceType,
    }
    const jsonPayload = JSON.stringify(payload)

    xhr.open('POST', this.s3SignPutUrl, true)
    // Send the proper header information along with the request
    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8')
    const self = this
    xhr.onreadystatechange = function () {
      let result
      if (this.readyState === 4 && this.status === 200) {
        try {
          result = JSON.parse(this.responseText)
        } catch (error) {
          self.onError(
            'Signing server returned some ugly/empty JSON: "' + this.responseText + '"',
          )
          return false
        }
        return callback(
          result.signedRequest,
          result.url,
          result.urlAuthenticated,
          result,
        )
      } else if (this.readyState === 4 && this.status !== 200) {
        return self.onError(
          'Could not contact request signing server. Status = ' + this.status,
        )
      }
    }
    return xhr.send(jsonPayload)
  }

  uploadToS3(
    file: File,
    fileType: string,
    origFileType: string,
    fileACL: string,
    url: string,
    urlPublic: string,
    urlAuthenticated: string,
    result: any,
  ) {
    const xhr = this.createCORSRequest('PUT', url)
    this.uploadXHR = xhr ?? undefined

    const handleUploadProgress = debounce(
      (e) => {
        let percentLoaded
        if (e.lengthComputable) {
          percentLoaded = Math.round((e.loaded / e.total) * 100)
          return this.onProgress(
            percentLoaded,
            percentLoaded === 100 ? 'Finalizing.' : 'Uploading.',
          )
        }
      },
      50,
      { maxWait: 200 },
    )

    if (!xhr) {
      this.onError('CORS not supported')
      return
    } else {
      xhr.onload = () => {
        if (xhr.status === 200) {
          this.onProgress(100, 'Upload completed.')
          return this.onFinishS3Put(
            urlPublic,
            fileType,
            origFileType,
            file.name,
            urlAuthenticated,
            result,
          )
        } else {
          return this.onError('Upload error: ' + xhr.status)
        }
      }
      xhr.onerror = () => {
        return this.onError('Please check your internet connection')
      }
      xhr.upload.onprogress = handleUploadProgress
    }
    if (fileType) {
      xhr.setRequestHeader('Content-Type', fileType)
    }
    xhr.setRequestHeader('x-amz-acl', fileACL)

    return xhr.send(file)
  }

  uploadBlob(blob: Blob, context = 'handin', fileName: string): void {
    const b: any = blob
    b.lastModifiedDate = new Date()
    b.name = fileName
    return this.uploadFile(blob as File, context)
  }

  uploadFile(file: File, context = 'handin'): void {
    let errorMessage

    // File must have an extension of size greater than 0.
    const strAllowedFileTypes =
      this.allowedFileTypes &&
      this.allowedFileTypes.length !== 0 &&
      this.allowedFileTypes[0] !== '*'
        ? this.allowedFileTypes.filter((type) => type !== 'LINK').join(', ')
        : 'e.g. pdf, .docx'

    const fileName = file.name
    const filenameSplit = fileName.split('.')
    if (
      filenameSplit.length === 1 ||
      filenameSplit[filenameSplit.length - 1].length === 0
    ) {
      errorMessage = `Please, upload a file with a file-extension (${strAllowedFileTypes})`
      this.onError(errorMessage)
      throw errorMessage
    }

    if (this.allowedFileTypes && this.allowedFileTypes[0] !== '*') {
      const reAllowedFileTypes = this.allowedFileTypes.join('|')

      if (RegExp('\\.(' + reAllowedFileTypes + ')$', 'i').exec(fileName) == null) {
        if (this.allowedFileTypes.filter((type) => type !== 'LINK').length > 1) {
          errorMessage = 'Incorrect file type, must be one of ' + strAllowedFileTypes
        } else {
          errorMessage = 'Incorrect file type, must be ' + strAllowedFileTypes
        }

        this.onError(errorMessage)
        throw errorMessage
      }
    }

    // In some cases .pdf-files will be uploaded with `Content-Type: application/file`
    // instead of `Content-Type: application/pdf`, we override this, so that the file
    // will be correctly handled (e.g. in Chrome on iPad)
    const filenameExt = filenameSplit[filenameSplit.length - 1]
    let fileType = file.type
    if (filenameExt in fileMimeTypeMap && isValidFileMimeType(filenameExt)) {
      fileType = fileMimeTypeMap[filenameExt]
    }

    // - Hand-ins are not public by default (you have to use an authenticed URL)
    // - Assignment attachments and images in the rich-text description are public
    // - .ipynb-files are public, in order for the webviewer to work (http://nbviewer.ipython.org/urls/<IPYNB_URL>)
    let fileACL = 'authenticated-read'
    if (filenameExt === 'ipynb' || context === 'attachment' || context === 'public') {
      fileACL = 'public-read'
    }

    return this.executeOnSignedUrl(
      file,
      fileType,
      fileACL,
      (signedURL, publicURL, authenticatedURL, result) => {
        return this.uploadToS3(
          file,
          fileType,
          file.type,
          fileACL,
          signedURL,
          publicURL,
          authenticatedURL,
          result,
        )
      },
    )
  }
}
