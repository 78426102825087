import { commitMutation } from 'react-relay'
import { MutationConfig, MutationParameters } from 'relay-runtime'

import environment from 'relay/environment'

export type ErrorWithValidationErrors = {
  message?: string
  validationErrors?: { [k: string]: string }
}

export default function mutationPromise<T extends MutationParameters>(
  mutationParams: MutationConfig<T>,
) {
  return new Promise<T['response']>((resolve, reject) => {
    commitMutation(environment, {
      ...mutationParams,
      onCompleted: (response, errors) => {
        if (mutationParams.onCompleted) {
          mutationParams.onCompleted(response, errors)
        }

        if (errors) {
          const defaultError = {
            message: 'Oops, something went wrong. Please try again later.',
          }

          // Find the first error in the graphql payload that has either a message or validation errors
          const error: ErrorWithValidationErrors =
            errors.find((e) => 'message' in e || 'validationErrors' in e) ||
            defaultError

          reject(error)
        } else {
          resolve(response)
        }
      },
      onError: (error) => {
        if (mutationParams.onError) {
          mutationParams.onError(error)
        }
        reject({
          message: 'We are experiencing network issues. Please try again later.',
        })
      },
    })
  })
}
