/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type FlowAddActivityContextMenu_flow = {
    readonly id: string;
    readonly title: string;
    readonly " $refType": "FlowAddActivityContextMenu_flow";
};
export type FlowAddActivityContextMenu_flow$data = FlowAddActivityContextMenu_flow;
export type FlowAddActivityContextMenu_flow$key = {
    readonly " $data"?: FlowAddActivityContextMenu_flow$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlowAddActivityContextMenu_flow">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlowAddActivityContextMenu_flow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Flow",
  "abstractKey": null
};
(node as any).hash = 'd0ef971d8c8fbd500778709d65788308';
export default node;
