/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserBadge_badge = {
    readonly text: string | null;
    readonly profileImage: string | null;
    readonly backgroundColor: {
        readonly hex: string | null;
        readonly isLight: boolean | null;
    };
    readonly " $refType": "UserBadge_badge";
};
export type UserBadge_badge$data = UserBadge_badge;
export type UserBadge_badge$key = {
    readonly " $data"?: UserBadge_badge$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserBadge_badge">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserBadge_badge",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Color",
      "kind": "LinkedField",
      "name": "backgroundColor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLight",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserBadge",
  "abstractKey": null
};
(node as any).hash = '0f41c1a53bff742dbcb81c1e0883071c';
export default node;
