/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type DuplicateActivityInput = {
    activityId: string;
    clientMutationId?: string | null | undefined;
};
export type duplicateActivityMutationVariables = {
    input: DuplicateActivityInput;
};
export type duplicateActivityMutationResponse = {
    readonly duplicateActivity: {
        readonly course: {
            readonly flows: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly isClassActivity: boolean;
                readonly activities: ReadonlyArray<{
                    readonly id: string;
                    readonly activityType: ActivityType;
                    readonly title: string;
                    readonly url: string;
                    readonly hasSubsetVisibilityRule: boolean | null;
                    readonly isCopyable: boolean | null;
                }>;
            }>;
        };
        readonly activity: {
            readonly id: string;
            readonly hasSubsetVisibilityRule: boolean | null;
        };
        readonly newActivityUrl: string;
    } | null;
};
export type duplicateActivityMutation = {
    readonly response: duplicateActivityMutationResponse;
    readonly variables: duplicateActivityMutationVariables;
};



/*
mutation duplicateActivityMutation(
  $input: DuplicateActivityInput!
) {
  duplicateActivity(input: $input) {
    course {
      flows {
        id
        title
        isClassActivity
        activities {
          __typename
          id
          activityType
          title
          url
          hasSubsetVisibilityRule
          isCopyable
        }
      }
      id
    }
    activity {
      __typename
      id
      hasSubsetVisibilityRule
    }
    newActivityUrl
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClassActivity",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSubsetVisibilityRule",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCopyable",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newActivityUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "duplicateActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateActivityPayload",
        "kind": "LinkedField",
        "name": "duplicateActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "duplicateActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DuplicateActivityPayload",
        "kind": "LinkedField",
        "name": "duplicateActivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68d6c19372f536814f88d71412d00786",
    "id": null,
    "metadata": {},
    "name": "duplicateActivityMutation",
    "operationKind": "mutation",
    "text": "mutation duplicateActivityMutation(\n  $input: DuplicateActivityInput!\n) {\n  duplicateActivity(input: $input) {\n    course {\n      flows {\n        id\n        title\n        isClassActivity\n        activities {\n          __typename\n          id\n          activityType\n          title\n          url\n          hasSubsetVisibilityRule\n          isCopyable\n        }\n      }\n      id\n    }\n    activity {\n      __typename\n      id\n      hasSubsetVisibilityRule\n    }\n    newActivityUrl\n  }\n}\n"
  }
};
})();
(node as any).hash = 'eba256d259baa99a8898a2676eb1053e';
export default node;
