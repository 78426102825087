export const toGlobalId = (type: string, id: string) => btoa(`${type}:${id}`)

export const fromGlobalId = (globalId: string) => {
  const tokens = atob(globalId).split(':')
  return {
    id: tokens[1],
    type: tokens[0],
  }
}

export const castGlobalId = (type: string, globalId: string) => {
  const { id } = fromGlobalId(globalId)
  return toGlobalId(type, id)
}
