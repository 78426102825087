import { graphql } from 'react-relay'

import {
  RemoveParticipantsInput,
  removeParticipantsMutation,
} from '__generated__/removeParticipantsMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default ({ courseId, participantIds }: RemoveParticipantsInput) =>
  mutationPromise<removeParticipantsMutation>({
    configs: [
      {
        connectionKeys: [
          {
            key: 'PaginatedParticipantsList_participants',
          },
        ],
        deletedIDFieldName: 'removedParticipantIds',
        parentID: courseId,
        pathToConnection: ['course', 'participants'],
        type: 'RANGE_DELETE',
      },
    ],
    mutation: graphql`
      mutation removeParticipantsMutation(
        $input: RemoveParticipantsInput!
        $courseId: ID!
      ) {
        removeParticipants(input: $input) {
          removedParticipantIds
          course {
            participantsCount
            studentsCount
            institution {
              activeUserCount
              paidPlan {
                numUserSeatsLeft
              }
            }
            hasTestRealUserMix
            testLearners {
              id
            }
            testAssistants {
              id
            }
            ...InviteModalFormFields_course
            ...TestUsersContextMenu_course
            ...FromInstitution_course @arguments(courseId: $courseId)
          }
          assistants {
            ...Row_participant
          }
        }
      }
    `,
    variables: { input: { courseId, participantIds }, courseId },
  })
