/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import ImpersonationUserSelectorPaginationQuery from "./ImpersonationUserSelectorPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type UserType = "Admin" | "Assistant" | "Owner" | "Student" | "Teacher";
export type ImpersonationUserSelector_query = {
    readonly courseImpersonatableParticipants: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly participantType: UserType;
                readonly user: {
                    readonly id: string;
                    readonly name: string;
                    readonly badge: {
                        readonly " $fragmentRefs": FragmentRefs<"UserBadge_badge">;
                    };
                    readonly isTestUser: boolean | null;
                };
            } | null;
        } | null>;
    };
    readonly " $refType": "ImpersonationUserSelector_query";
};
export type ImpersonationUserSelector_query$data = ImpersonationUserSelector_query;
export type ImpersonationUserSelector_query$key = {
    readonly " $data"?: ImpersonationUserSelector_query$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ImpersonationUserSelector_query">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "courseImpersonatableParticipants"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": ImpersonationUserSelectorPaginationQuery
    }
  },
  "name": "ImpersonationUserSelector_query",
  "selections": [
    {
      "alias": "courseImpersonatableParticipants",
      "args": [
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "ParticipantConnection",
      "kind": "LinkedField",
      "name": "__ImpersonationUserSelector_courseImpersonatableParticipants_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ParticipantEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "participantType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserBadge",
                      "kind": "LinkedField",
                      "name": "badge",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "UserBadge_badge"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isTestUser",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '934ba37192e62c4cc5e73e8743b8aa38';
export default node;
