import queryString from 'query-string'
import React from 'react'
import { Portal } from 'react-overlays'
import { useFragment, graphql } from 'react-relay'

import type { ImpersonationBar_user$key } from '__generated__/ImpersonationBar_user.graphql'
import { fromGlobalId } from 'utils/relay'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { UserContext } from 'components/UserContext'

import ImpersonationUserSelector from './ImpersonationUserSelector'
import useActivityIdFromRoute from './useActivityIdFromRoute'

import styles from './ImpersonationBar.module.scss'

const query = graphql`
  fragment ImpersonationBar_user on User {
    isImpersonating
    id
    impersonationCourseId
    ...ImpersonationUserSelector_me
  }
`

export const ImpersonationBar = () => {
  const user = React.useContext(UserContext)
  const me = useFragment<ImpersonationBar_user$key>(query, user.me)
  const { currentActivityId } = useActivityIdFromRoute()
  if (!me || !me.isImpersonating) {
    return null
  }

  const stopImpersonationQs = queryString.stringify({
    activity_id: currentActivityId,
  })

  const stopImpersonationLink = !!me.impersonationCourseId
    ? `/courses/${fromGlobalId(me.impersonationCourseId).id}/participants/${
        fromGlobalId(me.id).id
      }/stop-impersonation?${stopImpersonationQs}`
    : '/user/stop-impersonation'

  return (
    <Portal>
      <div className={styles.impersonationBar} role="dialog">
        <div className={styles.top} />
        <div className={styles.bottom} />
        <div className={styles.right} />
        <div className={styles.left} />
        {window.waffle.flag_is_active('preview_course') ? (
          <div className={styles.bottomLeft}>
            <span className={styles.text}>
              <Icons.ImpersonationEye className={styles.icon} nearBlack />
              Logged in as
            </span>
            {me.impersonationCourseId ? (
              <div className={styles.comboBox}>
                <ImpersonationUserSelector me={me} />
              </div>
            ) : null}
            <Button className={styles.stopBtn} href={stopImpersonationLink}>
              <Icons.ExitDoor accentColor className={styles.icon} />
              <span>Stop impersonation</span>
            </Button>
          </div>
        ) : (
          <Button AccentColor className={styles.logout} href={stopImpersonationLink}>
            Stop impersonation
          </Button>
        )}
      </div>
    </Portal>
  )
}
