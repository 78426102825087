import React, { createContext, useContext, useState } from 'react'
import ReactDOM from 'react-dom'

export interface IModalProps {
  isOpen?: boolean
  hide: () => void
  backdrop?: boolean
  explicitClose?: boolean
  notStackable?: boolean
}

interface IState {
  Components: React.ComponentType<any>[]
  modalsProps: any[]
}

export interface IModalContextValue extends IState {
  showModal: <P extends IModalProps>(
    Components: React.ComponentType<P>,
    modalProps: Omit<P, 'hide'>,
  ) => void
  hideModal: () => void
}

export const ModalContext = createContext<IModalContextValue>({
  Components: [],
  hideModal: () => undefined,
  modalsProps: [],
  showModal: () => undefined,
})

export const useModal = () => useContext(ModalContext)

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const initialModalState: IState = {
    Components: [],
    modalsProps: [],
  }

  const [modalState, setModalState] = useState<IState>(initialModalState)

  const hideModal = () => {
    setModalState((previousModalState) => {
      const Components = [...previousModalState.Components.slice(0, -1)]
      const modalsProps = [...previousModalState.modalsProps.slice(0, -1)]
      return { Components, modalsProps }
    })
  }

  const showModal: IModalContextValue['showModal'] = (Component, modalProps) => {
    setModalState((previousModalState) => {
      if (modalProps.notStackable) {
        const Components = [Component]
        const modalsProps = [modalProps]
        return { Components, modalsProps }
      } else {
        const Components = [...previousModalState.Components, Component]
        const modalsProps = [...previousModalState.modalsProps, modalProps]
        return { Components, modalsProps }
      }
    })
  }

  const value = {
    ...modalState,
    hideModal,
    showModal,
  }

  return (
    <ModalContext.Provider value={value}>
      <ModalRoot />
      {children}
    </ModalContext.Provider>
  )
}

const ModalWrapper = React.memo(
  ({ ModalComponent, modalProps }: any) => <ModalComponent {...modalProps} />,
  (prevProps, nextProps) =>
    prevProps.ModalComponent === nextProps.ModalComponent &&
    prevProps.modalProps === nextProps.modalProps,
)

export const ModalRoot = () =>
  ReactDOM.createPortal(
    <ModalContext.Consumer>
      {({ Components, modalsProps, hideModal }: IModalContextValue) =>
        Components.map((Component, index) => {
          modalsProps[index]['hide'] = hideModal
          return (
            <ModalWrapper
              key={index}
              ModalComponent={Component}
              modalProps={modalsProps[index]}
            />
          )
        })
      }
    </ModalContext.Consumer>,
    document.getElementById('react-root') as HTMLElement,
  )
