/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserType = "Admin" | "Assistant" | "Owner" | "Student" | "Teacher";
export type FlowTitle_flow = {
    readonly id: string;
    readonly title: string;
    readonly url: string;
    readonly ruleGroupsVisibility: ReadonlyArray<{
        readonly openFor: ReadonlyArray<UserType> | null;
    }> | null;
    readonly " $fragmentRefs": FragmentRefs<"FlowAddActivityContextMenu_flow" | "FlowOverviewBody_flow">;
    readonly " $refType": "FlowTitle_flow";
};
export type FlowTitle_flow$data = FlowTitle_flow;
export type FlowTitle_flow$key = {
    readonly " $data"?: FlowTitle_flow$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FlowTitle_flow">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FlowTitle_flow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroup",
      "kind": "LinkedField",
      "name": "ruleGroupsVisibility",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "openFor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FlowAddActivityContextMenu_flow"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FlowOverviewBody_flow"
    }
  ],
  "type": "Flow",
  "abstractKey": null
};
(node as any).hash = '39b90642c51a4d0f31e06021c5b630a7';
export default node;
