import React from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'
import { graphql, useFragment } from 'react-relay'

import { FlowAddActivityContextMenu_flow$key } from '__generated__/FlowAddActivityContextMenu_flow.graphql'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { Icons, IIconProps } from 'components/Icons'
import {
  INSERT_POS_BOTTOM,
  INSERT_POS_TOP,
  useInsertActivity,
} from 'components/InsertActivity/InsertActivityContext'

import flowTitleStyles from '../Sidebar/FlowTitle.module.scss'

function renderItemLabel(label: string, Icon: (props: IIconProps) => JSX.Element) {
  return (
    <>
      <Icon style={{ marginRight: '1rem' }} />
      {label}
    </>
  )
}

interface IProps {
  flow: FlowAddActivityContextMenu_flow$key
}

const FlowAddActivityContextMenu = (props: IProps) => {
  const flow = useFragment<FlowAddActivityContextMenu_flow$key>(
    graphql`
      fragment FlowAddActivityContextMenu_flow on Flow {
        id
        title
      }
    `,
    props.flow,
  )

  const {
    data: { courseId },
  } = useCurrentRoute()
  const navigation = useNavigation()
  const { setInsertAttributes } = useInsertActivity()

  const renderLabel = (
    ref: React.RefObject<HTMLElement>,
    {
      onClick,
    }: {
      onClick: React.MouseEventHandler<HTMLButtonElement>
    },
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      onClick(e)
    }
    return (
      <Button
        ref={ref as React.RefObject<HTMLButtonElement>}
        Icon
        Small
        ariaLabel="Add activities to flow"
        onClick={handleClick}
      >
        <Icons.Plus nearBlack />
      </Button>
    )
  }

  const items: {
    action: (event: React.MouseEvent<HTMLButtonElement>) => void
    label: JSX.Element
  }[] = [
    {
      action: async (event) => {
        event.stopPropagation()
        event.preventDefault()
        setInsertAttributes({
          flowId: flow.id,
          flowTitle: flow.title,
          position: INSERT_POS_TOP,
        })
        await navigation.navigate(`/courses/${courseId}/create`)
      },
      label: renderItemLabel('Top of flow', Icons.ArrowUp),
    },
    {
      action: async (event) => {
        event.stopPropagation()
        event.preventDefault()
        setInsertAttributes({
          flowId: flow.id,
          flowTitle: flow.title,
          position: INSERT_POS_BOTTOM,
        })
        await navigation.navigate(`/courses/${courseId}/create`)
      },
      label: renderItemLabel('Bottom of flow', Icons.ArrowDown),
    },
  ]

  const Header = () => (
    <div className={flowTitleStyles.flowAddActivityContextMenuHeader}>
      Add activity to...
    </div>
  )

  const handleContainerClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <ContextMenu
      attachment="top left"
      targetAttachment="bottom left"
      Header={Header}
      items={items}
      renderLabel={renderLabel}
      alignLeftMobile
      className={flowTitleStyles.flowAddActivityContextMenuButton}
      onContainerClick={handleContainerClick}
    />
  )
}

export default FlowAddActivityContextMenu
