// From https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/docs/useMediaQuery.md
import { useState, useEffect } from 'react'

const errorMessage =
  'matchMedia is not supported, this could happen both because window.matchMedia is not supported by' +
  " your current browser or you're using the useMediaQuery hook whilst server side rendering."

const isClient = typeof window === 'object'

const isAPISupported = (api: any) => api in window

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 * Usage example:
 *
 * const MediaQueryReporter = () => {
 *   const isSmall = useMediaQuery('(max-width: 48rem)');
 *   const isLarge = useMediaQuery('(min-width: 48rem)');
 *
 *   return (
 *    <DisplayDemo>
 *      <p>Small view? {isSmall ? 'yes' : 'no'}</p>
 *      <p>Large view? {isLarge ? 'yes' : 'no'}</p>
 *    </DisplayDemo>
 *   );
 *
 *   };
 *
 */
const useMediaQuery = (mediaQuery: string) => {
  if (!isClient || !isAPISupported('matchMedia')) {
    // eslint-disable-next-line no-console
    console.warn(errorMessage)
    return null
  }

  const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    mediaQueryList.addListener(documentChangeHandler)

    documentChangeHandler()
    return () => {
      mediaQueryList.removeListener(documentChangeHandler)
    }
  }, [mediaQuery])

  return isVerified
}

export default useMediaQuery
