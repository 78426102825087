import { graphql } from 'react-relay'

import {
  DeleteFlowInput,
  deleteFlowMutation,
} from '__generated__/deleteFlowMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: DeleteFlowInput) =>
  mutationPromise<deleteFlowMutation>({
    mutation: graphql`
      mutation deleteFlowMutation($input: DeleteFlowInput!) {
        deleteFlow(input: $input) {
          course {
            flows {
              id
              activities {
                ruleGroupsCompletion {
                  __typename
                }
                ruleGroupsVisibility {
                  __typename
                }
              }
            }
          }
        }
      }
    `,
    variables: { input },
  })
