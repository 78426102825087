import React from 'react'

type WrapperProps = JSX.IntrinsicElements['span']

interface IProps extends WrapperProps {
  Icon: JSX.Element
}

const IconWithEvents = (props: IProps) => {
  const { Icon, onMouseOver, onMouseOut, onMouseEnter, onMouseLeave } = props
  const eventListeners = { onMouseOver, onMouseOut, onMouseEnter, onMouseLeave }
  return <span {...eventListeners}>{Icon}</span>
}

export default IconWithEvents
