import classNames from 'classnames/bind'
import React from 'react'

import { GQLUserType } from 'types/graphqlTypes'
import { ALL_USER_TYPE_LABELS } from 'utils/constants'
import { assertUnreachable } from 'utils/functions'

import styles from './ParticipantTypeBadge.module.scss'

const cx = classNames.bind(styles)

interface IProps {
  participantType: GQLUserType
  labelsOverrides?: Partial<Record<GQLUserType, string>>
  isCoursePage?: boolean
  isTestUser?: boolean
  badgeClassName?: string
  textClassName?: string
}

type BadgeMap = {
  color: string
  initial: string
  text: string
}

const getBadgeProps = ({
  participantType,
  isCoursePage,
  isTestUser = false,
  labelsOverrides = {},
}: IProps): BadgeMap => {
  if (isCoursePage && participantType === 'Admin') {
    participantType = 'Teacher'
  }

  const labels = { ...ALL_USER_TYPE_LABELS, ...labelsOverrides }

  if (participantType === 'Admin') {
    return {
      color: 'lightblue',
      initial: 'A',
      text: labels[participantType],
    }
  } else if (participantType === 'Owner') {
    return {
      color: 'green',
      initial: 'O',
      text: labels[participantType],
    }
  } else if (participantType === 'Student') {
    if (isTestUser) {
      return {
        color: 'lightblue',
        initial: 'T',
        text: 'Test learner',
      }
    } else {
      return {
        color: 'blue',
        initial: 'L',
        text: labels[participantType],
      }
    }
  } else if (participantType === 'Teacher') {
    return {
      color: 'black',
      initial: 'I',
      text: labels[participantType],
    }
  } else if (participantType === 'Assistant') {
    if (isTestUser) {
      return {
        color: 'lightred',
        initial: 'T',
        text: 'Test assistant',
      }
    } else {
      return {
        color: 'red',
        initial: 'A',
        text: labels[participantType],
      }
    }
  } else {
    assertUnreachable(participantType)
  }
}

export const ParticipantTypeBadge = ({
  participantType,
  isCoursePage = false,
  labelsOverrides,
  isTestUser,
  badgeClassName,
  textClassName,
}: IProps) => {
  const badgeProps: BadgeMap = getBadgeProps({
    participantType,
    isCoursePage,
    labelsOverrides,
    isTestUser,
  })
  const badgeStyleName = cx('badge', badgeProps.color, badgeClassName, {
    test: isTestUser,
  })
  const textStyleName = cx('userType', textClassName)
  return (
    <div className={styles.container}>
      <span className={badgeStyleName} translate="no">
        {badgeProps.initial}
      </span>
      <span className={textStyleName}>{badgeProps.text}</span>
    </div>
  )
}
