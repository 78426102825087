import React, { useContext, useEffect, useState } from 'react'
import { useNavigation } from 'react-navi'
import { graphql, useFragment } from 'react-relay'

import { ActivityContextMenu_activity$key } from '__generated__/ActivityContextMenu_activity.graphql'
import duplicateActivity from 'mutations/duplicateActivity'
import updateActivityIcon from 'mutations/updateActivityIcon'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { EditingContext } from 'components/EditingContext'
import { IconPicker } from 'components/IconPicker'
import { Icons } from 'components/Icons'
import { ModalContext } from 'components/Modal/ModalProvider'
import { ToastContext } from 'components/NotificationCenter'
import DeleteActivityModal, {
  IDeleteActivityModalProps,
} from 'components/modals/DeleteActivityModal'

import styles from '../Sidebar/ActivityTitle.module.scss'

interface IProps {
  activity: ActivityContextMenu_activity$key
  setIsEditingTitle: (isEditing: boolean) => void
  onMenuOpen: (isOpen: boolean) => void
}

const renderItemLabel = (
  label: React.ReactNode,
  Icon: React.FunctionComponent<{ style: React.CSSProperties }>,
) => (
  <>
    <Icon style={{ marginRight: '1rem' }} />
    {label}
  </>
)

export const ActivityContextMenu = ({
  setIsEditingTitle,
  activity: activityFragment,
  onMenuOpen,
}: IProps) => {
  const activity = useFragment<ActivityContextMenu_activity$key>(
    graphql`
      fragment ActivityContextMenu_activity on Activity {
        url
        id
        isCopyable
        icon
        activityType
      }
    `,
    activityFragment,
  )

  const navigation = useNavigation()
  const { showModal } = useContext(ModalContext)
  const { displayToast } = useContext(ToastContext)
  const { setEditingActivityId } = useContext(EditingContext)
  const [isOpen, setIsOpen] = useState(false)
  const toggleShowMenu = () => setIsOpen(!isOpen)
  const hideMenu = () => setIsOpen(false)
  const [noAnimation, setNoAnimation] = useState(true)
  const [isEditingIcon, setIsEditingIcon] = useState(false)
  const [isDuplicationUnderway, setIsDuplicationUnderway] = useState(false)

  useEffect(() => {
    onMenuOpen(isOpen)
  }, [isOpen])

  const renderLabel = (ref: React.RefObject<HTMLElement>) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      toggleShowMenu()
    }
    return (
      <Button
        ref={ref as React.RefObject<HTMLButtonElement>}
        Icon
        Small
        ariaLabel="Activity actions"
        onClick={handleClick}
      >
        <Icons.ThreeDots nearBlack />
      </Button>
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => setNoAnimation(isOpen), 500)
    return () => clearTimeout(timer)
  }, [isOpen])

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    showModal<IDeleteActivityModalProps>(DeleteActivityModal, {
      activityId: activity.id,
    })
  }

  const handleDuplicateActivityClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    event.preventDefault()

    if (isDuplicationUnderway) {
      return
    }

    try {
      setIsDuplicationUnderway(true)
      const response = await duplicateActivity({ activityId: activity.id })
      if (response.duplicateActivity) {
        await navigation.navigate(response.duplicateActivity.newActivityUrl)
        setEditingActivityId(response.duplicateActivity.activity.id)
      }
    } catch (e) {
      displayToast(e.message, 'error')
    } finally {
      setIsDuplicationUnderway(false)
    }
  }

  const items: {
    action: (event: React.MouseEvent<HTMLButtonElement>) => void
    label: JSX.Element
    disabled?: boolean
    showMenuOnItemClick?: boolean
  }[] = [
    {
      action: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setIsEditingTitle(true)
      },
      label: renderItemLabel('Rename', Icons.TypeCursor),
    },
    {
      action: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setIsEditingIcon(true)
      },
      label: renderItemLabel(`${activity.icon ? 'Edit' : 'Add'} icon`, Icons.Smiley),
      showMenuOnItemClick: true,
    },
    {
      action: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        return navigation.navigate(`${activity.url}?edit=1`, { replace: true })
      },
      label: renderItemLabel('Settings', Icons.Cog),
    },
    {
      action: handleConfirm,
      label: renderItemLabel('Delete', Icons.Trash),
    },
  ]

  activity.isCopyable &&
    items.splice(-1, 0, {
      action: handleDuplicateActivityClick,
      label: renderItemLabel('Duplicate', Icons.Union),
      disabled: isDuplicationUnderway,
    })

  const onChangeIcon = async (icon: string | null) => {
    hideMenu()
    await updateActivityIcon(
      {
        activityId: activity.id,
        icon,
      },
      activity.activityType,
    )
  }

  const renderElement = isEditingIcon
    ? (ref: React.RefObject<HTMLElement>) => {
        return (
          <div ref={ref as React.RefObject<HTMLDivElement>}>
            <IconPicker
              initialIcon={activity.icon}
              handleClose={() => setIsEditingIcon(false)}
              onChangeIcon={onChangeIcon}
            />
          </div>
        )
      }
    : undefined

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.activityContextMenu}
      style={isOpen ? { display: 'flex' } : undefined}
    >
      <ContextMenu
        attachment={isEditingIcon ? 'middle left' : 'top left'}
        targetAttachment={isEditingIcon ? 'middle right' : 'bottom left'}
        items={items}
        renderLabel={renderLabel}
        renderElement={renderElement}
        alignLeftMobile
        isOpen={isOpen}
        onHide={hideMenu}
        noAnimation={noAnimation}
      />
    </div>
  )
}

export default ActivityContextMenu
