/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserType = "Admin" | "Assistant" | "Owner" | "Student" | "Teacher";
export type AddTestUsersToCourseInput = {
    courseId: string;
    count: number;
    userType: UserType;
    clientMutationId?: string | null | undefined;
};
export type addTestUsersToCourseMutationVariables = {
    input: AddTestUsersToCourseInput;
};
export type addTestUsersToCourseMutationResponse = {
    readonly addTestUsersToCourse: {
        readonly course: {
            readonly id: string;
            readonly hasTestRealUserMix: boolean;
            readonly testLearners: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly testAssistants: ReadonlyArray<{
                readonly id: string;
            }>;
            readonly " $fragmentRefs": FragmentRefs<"TestUsersContextMenu_course">;
        };
        readonly addedTestUsers: ReadonlyArray<{
            readonly id: string;
            readonly user: {
                readonly name: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"Row_participant">;
        } | null>;
    } | null;
};
export type addTestUsersToCourseMutation = {
    readonly response: addTestUsersToCourseMutationResponse;
    readonly variables: addTestUsersToCourseMutationVariables;
};



/*
mutation addTestUsersToCourseMutation(
  $input: AddTestUsersToCourseInput!
) {
  addTestUsersToCourse(input: $input) {
    course {
      id
      hasTestRealUserMix
      testLearners {
        id
      }
      testAssistants {
        id
      }
      ...TestUsersContextMenu_course
    }
    addedTestUsers {
      id
      user {
        name
        id
      }
      ...Row_participant
    }
  }
}

fragment InviteMenu_participant on Participant {
  id
  user {
    isViewer
    name
    isTestUser
    id
  }
  participantStatus
  participantType
}

fragment Row_participant on Participant {
  id
  participantStatus
  participantType
  assignedStudentCount
  user {
    name
    identifier
    isViewer
    isTestUser
    badge {
      ...UserBadge_badge
    }
    ...TagsLabel_user
    id
  }
  ...InviteMenu_participant
}

fragment TagsLabel_user on User {
  id
  name
}

fragment TestUsersContextMenu_course on Course {
  id
  testLearnerCount
  testAssistantCount
  hasTestRealUserMix
  testLearners {
    id
    name
    badge {
      ...UserBadge_badge
    }
  }
  testAssistants {
    id
    name
    badge {
      ...UserBadge_badge
    }
  }
  institution {
    hasPreviewCourseAssistants: isFeatureEnabled(feature: ASSISTANT_ROLE)
    id
  }
}

fragment UserBadge_badge on UserBadge {
  text
  profileImage
  backgroundColor {
    hex
    isLight
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTestRealUserMix",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserBadge",
  "kind": "LinkedField",
  "name": "badge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Color",
      "kind": "LinkedField",
      "name": "backgroundColor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLight",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTestUsersToCourseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTestUsersToCoursePayload",
        "kind": "LinkedField",
        "name": "addTestUsersToCourse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "testLearners",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "testAssistants",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TestUsersContextMenu_course"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Participant",
            "kind": "LinkedField",
            "name": "addedTestUsers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Row_participant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTestUsersToCourseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTestUsersToCoursePayload",
        "kind": "LinkedField",
        "name": "addTestUsersToCourse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "testLearners",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "testAssistants",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "testLearnerCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "testAssistantCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Institution",
                "kind": "LinkedField",
                "name": "institution",
                "plural": false,
                "selections": [
                  {
                    "alias": "hasPreviewCourseAssistants",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "feature",
                        "value": "ASSISTANT_ROLE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "isFeatureEnabled",
                    "storageKey": "isFeatureEnabled(feature:\"ASSISTANT_ROLE\")"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Participant",
            "kind": "LinkedField",
            "name": "addedTestUsers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "identifier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isViewer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTestUser",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "participantStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "participantType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assignedStudentCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b0caad3fde8f0505e96badbb2dd9bc8",
    "id": null,
    "metadata": {},
    "name": "addTestUsersToCourseMutation",
    "operationKind": "mutation",
    "text": "mutation addTestUsersToCourseMutation(\n  $input: AddTestUsersToCourseInput!\n) {\n  addTestUsersToCourse(input: $input) {\n    course {\n      id\n      hasTestRealUserMix\n      testLearners {\n        id\n      }\n      testAssistants {\n        id\n      }\n      ...TestUsersContextMenu_course\n    }\n    addedTestUsers {\n      id\n      user {\n        name\n        id\n      }\n      ...Row_participant\n    }\n  }\n}\n\nfragment InviteMenu_participant on Participant {\n  id\n  user {\n    isViewer\n    name\n    isTestUser\n    id\n  }\n  participantStatus\n  participantType\n}\n\nfragment Row_participant on Participant {\n  id\n  participantStatus\n  participantType\n  assignedStudentCount\n  user {\n    name\n    identifier\n    isViewer\n    isTestUser\n    badge {\n      ...UserBadge_badge\n    }\n    ...TagsLabel_user\n    id\n  }\n  ...InviteMenu_participant\n}\n\nfragment TagsLabel_user on User {\n  id\n  name\n}\n\nfragment TestUsersContextMenu_course on Course {\n  id\n  testLearnerCount\n  testAssistantCount\n  hasTestRealUserMix\n  testLearners {\n    id\n    name\n    badge {\n      ...UserBadge_badge\n    }\n  }\n  testAssistants {\n    id\n    name\n    badge {\n      ...UserBadge_badge\n    }\n  }\n  institution {\n    hasPreviewCourseAssistants: isFeatureEnabled(feature: ASSISTANT_ROLE)\n    id\n  }\n}\n\nfragment UserBadge_badge on UserBadge {\n  text\n  profileImage\n  backgroundColor {\n    hex\n    isLight\n  }\n}\n"
  }
};
})();
(node as any).hash = '038f0b9fe330f74e0d5c2a5bcd6d53f7';
export default node;
