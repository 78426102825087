import classNames from 'classnames/bind'
import * as React from 'react'

import styles from './Dots.module.scss'

const cx = classNames.bind(styles)

interface IProps {
  rightAligned?: boolean
  alignCenter?: boolean
  Dark?: boolean
  largeDots?: boolean
  className?: string
}

export const Dots = (props: IProps) => {
  const dotsClasses = cx('loading-balls', {
    'align-center': props.alignCenter,
    dark: props.Dark,
    'large-dots': props.largeDots,
    'right-aligned': props.rightAligned,
  })

  return (
    <span className={`${dotsClasses} ${props.className || ''}`}>
      <span />
      <span />
      <span />
    </span>
  )
}

export default Dots
