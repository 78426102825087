/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CourseThumbnailPicker_course = {
    readonly id: string;
    readonly thumbnail: string | null;
    readonly " $refType": "CourseThumbnailPicker_course";
};
export type CourseThumbnailPicker_course$data = CourseThumbnailPicker_course;
export type CourseThumbnailPicker_course$key = {
    readonly " $data"?: CourseThumbnailPicker_course$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CourseThumbnailPicker_course">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseThumbnailPicker_course",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '4fa41a817e1c7dffc8aacdcc0b318766';
export default node;
