import emojiData from 'emoji-mart/data/all.json'

import { getRandomProperty } from 'utils/functions'

import { CUSTOM_EMOJIS } from './constants'

export const getRandomEmoji = () => {
  let emoji = getRandomProperty(emojiData.emojis)
  while (emoji.added_in >= 12) {
    emoji = getRandomProperty(emojiData.emojis)
  }
  return emoji['short_names'][0]
}

export const isCustomEmoji = (emojiId: string) => {
  if (emojiId.startsWith('emoji:')) emojiId = emojiId.replace('emoji:', '')
  return CUSTOM_EMOJIS.findIndex((emoji) => emoji.id === emojiId) !== -1
}

export const getCustomEmojiUrl = (emojiId: string) => {
  if (emojiId.startsWith('emoji:')) emojiId = emojiId.replace('emoji:', '')
  return CUSTOM_EMOJIS.find((emoji) => emoji.id === emojiId)?.imageUrl
}
