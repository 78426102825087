import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Input } from 'components/Input'
import { Tooltip } from 'components/Tooltip'

import styles from './TitleForm.module.scss'

export interface ITitleFormValues {
  title: string
}

interface IProps {
  onSubmit: (value: string) => void
  initialValues: ITitleFormValues
  submitting?: boolean
  invalid?: boolean
}

const TitleForm = ({ onSubmit, submitting, invalid, initialValues }: IProps) => {
  const [title, setTitle] = useState(initialValues.title)

  const handleKeyPress = (evt: React.KeyboardEvent<EventTarget>) => {
    if (evt.key === 'Enter') {
      onSubmit(title)
    }
  }

  const useOutsideAlerter = (ref: React.RefObject<HTMLFormElement>) => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as HTMLFormElement)) {
        onSubmit(title)
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    })
  }

  const editTitleInput = useRef(null)
  useOutsideAlerter(editTitleInput)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    onSubmit(title)
  }

  const onChange = useCallback(
    (evt) => {
      setTitle(evt.target.value)
    },
    [setTitle],
  )

  // Hack for redux-form compatibility in <Input>
  const field = {
    value: title,
    onChange,
  }

  return (
    <form ref={editTitleInput} className={styles.inputContainer}>
      <Input
        autoFocus
        aria-label="title"
        containerClassName={styles.classInput}
        onKeyPress={handleKeyPress}
        field={field}
        maxLength={255}
        name="title"
      />
      <Tooltip text="Save title">
        <Button
          onClick={handleClick}
          Icon
          AccentColor
          type="submit"
          ariaLabel="Save title"
          Disabled={invalid || submitting}
        >
          <Icons.Checkmark white small />
        </Button>
      </Tooltip>
    </form>
  )
}

export default TitleForm
