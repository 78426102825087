/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TestUsersContextMenu_course = {
    readonly id: string;
    readonly testLearnerCount: number | null;
    readonly testAssistantCount: number | null;
    readonly hasTestRealUserMix: boolean;
    readonly testLearners: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly badge: {
            readonly " $fragmentRefs": FragmentRefs<"UserBadge_badge">;
        };
    }>;
    readonly testAssistants: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly badge: {
            readonly " $fragmentRefs": FragmentRefs<"UserBadge_badge">;
        };
    }>;
    readonly institution: {
        readonly hasPreviewCourseAssistants: boolean;
    } | null;
    readonly " $refType": "TestUsersContextMenu_course";
};
export type TestUsersContextMenu_course$data = TestUsersContextMenu_course;
export type TestUsersContextMenu_course$key = {
    readonly " $data"?: TestUsersContextMenu_course$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TestUsersContextMenu_course">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserBadge",
    "kind": "LinkedField",
    "name": "badge",
    "plural": false,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserBadge_badge"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestUsersContextMenu_course",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testLearnerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testAssistantCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasTestRealUserMix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "testLearners",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "testAssistants",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Institution",
      "kind": "LinkedField",
      "name": "institution",
      "plural": false,
      "selections": [
        {
          "alias": "hasPreviewCourseAssistants",
          "args": [
            {
              "kind": "Literal",
              "name": "feature",
              "value": "ASSISTANT_ROLE"
            }
          ],
          "kind": "ScalarField",
          "name": "isFeatureEnabled",
          "storageKey": "isFeatureEnabled(feature:\"ASSISTANT_ROLE\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();
(node as any).hash = '99c9180ac2ff21a462b140dc8b49bcc6';
export default node;
