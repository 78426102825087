import flatten from 'lodash/flatten'

import {
  GQLActivityType,
  GQLAttachmentType,
  GQLFileType,
  GQLUserType,
} from 'types/graphqlTypes'

export const DEFAULT_LANGUAGE = 'en'

// Works with URLs, including signed S3/CloudFront URIs
export const fileExtensionRegex = /([^.]*?)([0-9a-z]+)(?=[?#])|([^.]*?)(?:[\w]+)$/gim

export const fileMimeTypeMap = {
  ipynb: 'application/x-ipynb+json',
  pdf: 'application/pdf',
  py: 'text/x-python',
}
export type fileMimeExtension = keyof typeof fileMimeTypeMap
export type fileExtensionMimeType = typeof fileMimeTypeMap[fileMimeExtension]
export const isValidFileMimeType = (value: string): value is fileMimeExtension => {
  return value in fileMimeTypeMap
}

export const attachmentTypeMap: { [type in GQLAttachmentType]: { label: string } } = {
  FileUpload: {
    label: 'File',
  },
  GoogleDocPdf: {
    label: 'Google Drive',
  },
  Link: {
    label: 'Link',
  },
  RichText: {
    label: 'Text editor',
  },
  ScreencastVideo: {
    label: 'Video',
  },
  WebcamVideo: {
    label: 'Video',
  },
}

export const fileTypeMap: {
  [fileType in GQLFileType]: { extensions: string[]; label: string }
} = {
  ALL: {
    extensions: ['*'],
    label: 'All file types',
  },
  AUDIO: {
    extensions: ['m4a', 'mp3', 'mpa', 'wav', 'wma'],
    label: 'Audio',
  },
  EXCEL: {
    extensions: ['xls', 'xlsx'],
    label: 'Spreadsheets',
  },
  IMAGE: {
    extensions: ['tif', 'tiff', 'gif', 'jpeg', 'jpg', 'png', 'bmp', 'heic'],
    label: 'Images',
  },
  PDF: {
    extensions: ['pdf'],
    label: 'PDF',
  },
  PRESENTATION: {
    extensions: ['ppt', 'pptx', 'key', 'ppsx', 'pps'],
    label: 'Slideshows',
  },
  PYTHON: {
    extensions: ['ipynb', 'py'],
    label: 'Python code',
  },
  TINSPIRE: {
    extensions: ['tns', 'tnsp'],
    label: 'TI-Nspire',
  },
  VIDEO: {
    extensions: ['avi', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'wmv'],
    label: 'Videos',
  },
  WORD: {
    extensions: ['doc', 'docx', 'odt', 'wpd', 'ps', 'hwp', 'rtf', 'txt'],
    label: 'Word and text files',
  },
  ZIP: {
    extensions: ['zip'],
    label: 'ZIP',
  },
}

export const getFileTypeExtensions = (types: ReadonlyArray<GQLFileType>) =>
  flatten(
    Array.from(types)
      .filter((fileType) => fileType in fileTypeMap)
      .map((fileType) => fileTypeMap[fileType].extensions),
  )

export const disallowedFileExtensions = ['gdoc', 'gslides', 'gsheet']

export const getActivityTypeName = (activityType: GQLActivityType) => {
  if (activityType === 'PeerReview') {
    return 'Peer review'
  }
  if (activityType === 'FeedbackReflection') {
    return 'Feedback reflection'
  }
  if (activityType === 'TeacherReview') {
    return 'Instructor review'
  }
  if (activityType === 'GradePassback') {
    return 'Grade passback'
  }
  if (activityType === 'SelfReview') {
    return 'Self review'
  }
  if (activityType === 'SelectTag') {
    return 'Tag selection'
  }
  if (activityType === 'GroupFormation') {
    return 'Group formation'
  }
  if (activityType === 'GroupMemberReview') {
    return 'Group member review'
  }
  if (activityType === 'Zoom') {
    return 'Zoom'
  }
  if (activityType === 'GoogleMeet') {
    return 'Google Meet'
  }
  if (activityType === 'MicrosoftTeams') {
    return 'Microsoft Teams'
  }
  if (activityType === 'HapYak') {
    return 'HapYak'
  }
  if (activityType === 'InviteTA') {
    return 'Invite evaluator'
  }
  if (activityType === 'TurnItIn') {
    return 'Turnitin'
  }
  return activityType
}

export const getActivitySourceTypeName = (activityType: GQLActivityType) => {
  if (
    activityType === 'PeerReview' ||
    activityType === 'TeacherReview' ||
    activityType === 'SelfReview'
  ) {
    return 'Submission activity'
  }
  if (activityType === 'FeedbackReflection') {
    return 'Review activity'
  }
  if (activityType === 'GroupMemberReview') {
    return 'Group formation activity'
  }
  return activityType
}

export const s3ActivityResourcesDir = 'activity-resources'
export const s3SubmissionAttachmentsDir = 'submission-attachments'
export const s3CoursePagesDir = 'public-courses'
export const s3RubricResourcesDir = 'rubric-resources'
export const s3ReviewAnswersResourcesDir = 'review-answers-resources'
export const s3QuizAnswersResourcesDir = 'quiz-answers-resources'
export const s3QuizQuestionsResourcesDir = 'quiz-questions-resources'
export const s3PollQuestionsResourcesDir = 'poll-questions-resources'
export const s3FormQuestionsResourcesDir = 'form-questions-resources'
export const s3FormAnswersResourcesDir = 'form-answers-resources'
export const s3ScormUploadsDir = 'scorm'

// Keep this in sync with LIMIT_INFINITY from eduflow.paid_plan.constants
export const LIMIT_INFINITY = 1000000

export const BASIC_ACTIVITY_TYPES: GQLActivityType[] = [
  'Content',
  'Submission',
  'Video',
  'Quiz',
  'Poll',
  'SelfReview',
  'TeacherReview',
  'Discussion',
  'Embed',
  'GroupFormation',
  'SelectTag',
  'Scoring',
  'InviteTA',
  'GradePassback',
  'Certificate',
  'Scorm',
  'Checklist',
]

if (window?.waffle?.flag_is_active('form_activity')) {
  // Insert Form activity type after Poll activity type
  BASIC_ACTIVITY_TYPES.splice(5, 0, 'Form')
}

export const EMBED_ACTIVITY_TYPES: GQLActivityType[] = [
  'Zoom',
  'GoogleMeet',
  'MicrosoftTeams',
  'HapYak',
  'TurnItIn',
]

export const ADVANCED_ACTIVITY_TYPES: GQLActivityType[] = [
  'PeerReview',
  'GroupMemberReview',
  'FeedbackReflection',
]

export const ALL_USER_TYPE_LABELS: Record<GQLUserType, string> = {
  Admin: 'Admin',
  Owner: 'Owner',
  Teacher: 'Instructor',
  Assistant: 'Assistant',
  Student: 'Learner',
}

export const ALL_USER_TYPES: GQLUserType[] = Object.keys(
  ALL_USER_TYPE_LABELS,
) as GQLUserType[]
