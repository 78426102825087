/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type withBlockImpersonationQueryVariables = {};
export type withBlockImpersonationQueryResponse = {
    readonly me: {
        readonly impersonationCourseId: string | null;
    } | null;
};
export type withBlockImpersonationQuery = {
    readonly response: withBlockImpersonationQueryResponse;
    readonly variables: withBlockImpersonationQueryVariables;
};



/*
query withBlockImpersonationQuery {
  me {
    impersonationCourseId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "impersonationCourseId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "withBlockImpersonationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "withBlockImpersonationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9de901ed6a5b668f9f32914a01b9e302",
    "id": null,
    "metadata": {},
    "name": "withBlockImpersonationQuery",
    "operationKind": "query",
    "text": "query withBlockImpersonationQuery {\n  me {\n    impersonationCourseId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2a4b7367f63cd6c07732c2f4c3fd42d4';
export default node;
