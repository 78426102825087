import React, { useContext, useState } from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'

import useDeleteFlowErrors from 'hooks/useDeleteFlowErrors'
import deleteFlow from 'mutations/deleteFlow'
import duplicateFlow from 'mutations/duplicateFlow'
import { fromGlobalId, toGlobalId } from 'utils/relay'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { EditingContext } from 'components/EditingContext'
import { Icons } from 'components/Icons'
import { ModalContext } from 'components/Modal/ModalProvider'
import { ToastContext } from 'components/NotificationCenter'
import { DangerModal, IDangerModalProps } from 'components/modals/DangerModal'

import flowTitleStyles from '../Sidebar/FlowTitle.module.scss'

interface IProps {
  relayFlowId: string
  setIsEditingTitle: (isEditing: boolean) => void
}

const renderItemLabel = (
  label: React.ReactNode,
  Icon: React.FunctionComponent<{ style: React.CSSProperties }>,
) => (
  <>
    <Icon style={{ marginRight: '1rem' }} />
    {label}
  </>
)

export const FlowContextMenu = ({ setIsEditingTitle, relayFlowId }: IProps) => {
  const { displayToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)
  const { setEditingActivityId } = useContext(EditingContext)
  const [isDuplicationUnderway, setIsDuplicationUnderway] = useState(false)

  const navigation = useNavigation()
  const {
    data: { courseId, flowId },
  } = useCurrentRoute()

  const handleDeleteFlowErrors = useDeleteFlowErrors({
    flowId: toGlobalId('Flow', flowId),
  })

  const renderLabel = (
    ref: React.RefObject<HTMLElement>,
    {
      onClick,
    }: {
      onClick: React.MouseEventHandler<HTMLButtonElement>
    },
  ) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      onClick(e)
    }
    return (
      <Button
        ref={ref as React.RefObject<HTMLButtonElement>}
        Icon
        Small
        ariaLabel="Flow actions"
        onClick={handleClick}
      >
        <Icons.ThreeDots nearBlack />
      </Button>
    )
  }

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    showModal<IDangerModalProps>(DangerModal, {
      message: (
        <div>
          Deleting a flow will remove all its activities from Eduflow, including learner
          access. <strong className="color red">This action cannot be undone</strong>.
        </div>
      ),
      onConfirm: async () => {
        try {
          await deleteFlow({
            flowId: relayFlowId,
          })

          if (flowId === fromGlobalId(relayFlowId).id) {
            // If we are deleting a flow which is currently selected
            // we should redirect to the first flow/activity in the course
            await navigation.navigate(`/courses/${courseId}`)
          }

          displayToast('Successfully deleted flow', 'success')
        } catch (e) {
          handleDeleteFlowErrors(e)
        }
      },
      title: 'Are you sure you want to delete this flow?',
    })
  }

  const handleDuplicateFlowClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    event.preventDefault()

    if (isDuplicationUnderway) {
      return
    }

    try {
      setIsDuplicationUnderway(true)
      const response = await duplicateFlow({ flowId: relayFlowId })
      if (response.duplicateFlow) {
        setEditingActivityId(response.duplicateFlow.flow.id)
        await navigation.navigate(response.duplicateFlow.flow.url)
      }
    } catch (e) {
      displayToast(e.message, 'error')
    } finally {
      setIsDuplicationUnderway(false)
    }
  }

  const items: {
    action: (event: React.MouseEvent<HTMLButtonElement>) => void
    label: JSX.Element
    disabled?: boolean
    showMenuOnItemClick?: boolean
  }[] = [
    {
      action: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setIsEditingTitle(true)
      },
      label: renderItemLabel('Rename', Icons.TypeCursor),
    },
    {
      action: (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        return navigation.navigate(
          `/courses/${courseId}/flows/${fromGlobalId(relayFlowId).id}?edit=1`,
        )
      },
      label: renderItemLabel('Settings', Icons.Cog),
    },
    {
      action: handleDuplicateFlowClick,
      label: renderItemLabel('Duplicate', Icons.Union),
      disabled: isDuplicationUnderway,
    },
    {
      action: handleConfirm,
      label: renderItemLabel('Delete', Icons.Trash),
    },
  ]

  return (
    <ContextMenu
      attachment="top left"
      targetAttachment="bottom left"
      items={items}
      renderLabel={renderLabel}
      alignLeftMobile
      className={flowTitleStyles.flowContextMenuButton}
      targetModifier="scroll-handle"
    />
  )
}

export default FlowContextMenu
