import React, { createContext, useContext, useRef } from 'react'
import NotificationSystem from 'react-notification-system'

import { displayToast as makeToastAction } from './helpers'
import type { MessageType } from './types'

import './NotificationCenter.scss'

export interface IToastContextValue {
  displayToast: (message: string | JSX.Element, type: MessageType) => void
}

export const ToastContext = createContext<IToastContextValue>({
  displayToast: () => undefined,
})

export const useToast = () => useContext(ToastContext)

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const notificationSystem = useRef<NotificationSystem.System | null>(null)
  const displayToast = (message: string | JSX.Element, type: MessageType) => {
    const ref = notificationSystem.current
    if (ref) {
      ref.addNotification(makeToastAction(message, type))
    }
  }
  const value = {
    displayToast,
  }

  return (
    <ToastContext.Provider value={value}>
      <NotificationSystem ref={notificationSystem} style={false} />
      {children}
    </ToastContext.Provider>
  )
}
