import React from 'react'
import { Link, useCurrentRoute, useNavigation } from 'react-navi'
import { graphql } from 'react-relay'

import { TemplateCategoriesSidebar_me } from '__generated__/TemplateCategoriesSidebar_me.graphql'
import { TemplateCategoriesSidebar_query } from '__generated__/TemplateCategoriesSidebar_query.graphql'
import withFragmentContainer from 'relay/withFragmentContainer'

import { Button } from 'components/Button'
import Dropdown from 'components/Dropdown/Dropdown'
import { Icons } from 'components/Icons'

import CreateCourseButtons from './CreateCourseButtons'

import styles from './TemplateLibrary.module.scss'

interface IProps {
  withBackButton?: boolean
  me: TemplateCategoriesSidebar_me | null
  query: TemplateCategoriesSidebar_query
}

const TemplateCategoriesSidebar = ({ query, me, withBackButton }: IProps) => {
  const {
    url: { href },
  } = useCurrentRoute()
  const navigation = useNavigation()

  const renderBackToGallery = () => (
    <Button Block className={styles.backToGalleryButton} to={`/templates`}>
      <Icons.ArrowLeft accentColor style={{ marginRight: '.5rem' }} />
      Back to gallery
    </Button>
  )

  return (
    <>
      <div className={styles.sidebarDropdown}>
        {withBackButton ? (
          renderBackToGallery()
        ) : (
          <>
            <CreateCourseButtons me={me} />
            <span className={styles.heading}>Get started with a template</span>
            <Dropdown
              fullWidth
              input={{
                onChange: async (value: string) => {
                  return navigation.navigate(value)
                },
                value: href,
              }}
              options={[{ label: 'All templates', value: '/templates' }].concat(
                query.templateCategories.map((category) => ({
                  label: category.title,
                  value: category.url,
                })),
              )}
            />
          </>
        )}
      </div>
      <nav className={styles.sidebar} id="get-started">
        <div className={styles.headingAndList}>
          {withBackButton ? (
            renderBackToGallery()
          ) : (
            <h1 className={styles.heading}>Get started with a template</h1>
          )}
          <ul className={styles.list}>
            <li key="all_templates">
              <Link href="/templates" activeClassName={styles.active} exact>
                All templates
              </Link>
            </li>
            {query.templateCategories.map((category) => (
              <li key={category.id}>
                <Link href={category.url} activeClassName={styles.active}>
                  {category.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <CreateCourseButtons me={me} />
      </nav>
    </>
  )
}

export default withFragmentContainer<IProps>({
  me: graphql`
    fragment TemplateCategoriesSidebar_me on User {
      ...CreateCourseButtons_me
    }
  `,
  query: graphql`
    fragment TemplateCategoriesSidebar_query on Query {
      templateCategories {
        id
        title
        url
      }
    }
  `,
})(TemplateCategoriesSidebar)
