import { graphql } from 'react-relay'

import {
  TrackEventInput,
  trackEventMutation,
} from '__generated__/trackEventMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default function trackEvent(input: TrackEventInput) {
  return mutationPromise<trackEventMutation>({
    mutation: graphql`
      mutation trackEventMutation($input: TrackEventInput!) {
        trackEvent(input: $input) {
          ok
        }
      }
    `,
    variables: {
      input,
    },
  })
}
