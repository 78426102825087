import { useCurrentRoute } from 'react-navi'
import { useQuery, graphql } from 'relay-hooks'

import { ThemeContextQuery } from '__generated__/ThemeContextQuery.graphql'
import { toGlobalId } from 'utils/relay'

import { applyTheme, ITheme } from 'components/Theme/utils'

const query = graphql`
  query ThemeContextQuery($courseId: ID, $hasCourseId: Boolean!) {
    institutionTheme {
      borderRadius
      accentColor
      fontFamily
    }
    me {
      institution {
        theme {
          borderRadius
          accentColor
          fontFamily
        }
      }
    }
    course(id: $courseId) @include(if: $hasCourseId) {
      theme {
        borderRadius
        accentColor
        fontFamily
      }
    }
  }
`

const Theme = () => {
  const { data: routeVariables } = useCurrentRoute()
  let courseId = null
  if (routeVariables.courseId) {
    courseId = toGlobalId('Course', routeVariables.courseId)
  }

  const results = useQuery<ThemeContextQuery>(query, {
    courseId,
    hasCourseId: !!courseId,
  })
  const props = results['data']
  const courseTheme = props?.course?.theme
  const institutionTheme = props?.me?.institution?.theme ?? props?.institutionTheme

  let theme: ITheme | null = null
  if (institutionTheme || courseTheme) {
    theme = {
      fontFamily: courseTheme?.fontFamily || institutionTheme?.fontFamily || null,
      accentColor: courseTheme?.accentColor || institutionTheme?.accentColor || null,
      borderRadius: courseTheme?.borderRadius || institutionTheme?.borderRadius || null,
    }
  }
  applyTheme(theme)

  return null
}

export default Theme
