import React from 'react'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { ConditionalTooltip } from 'components/Tooltip'

import { COURSE_PICKER_SECTION } from './constants'
import type { CopyActivitiesSection } from './types'

import styles from './CopyActivitiesMenu.module.scss'

interface IBackButtonProps {
  onClick: (event: React.SyntheticEvent) => void
}

const BackButton: React.FC<IBackButtonProps> = React.memo(function BackButtonMemo({
  onClick,
}: IBackButtonProps) {
  return (
    <Button onClick={onClick} Small Icon ariaLabel="Go back" className="mr2">
      <Icons.ArrowLeft accentColor xSmall />
    </Button>
  )
})

interface ICopyButtonProps extends IBackButtonProps {
  disabled?: boolean
}

const CopyButton: React.FC<ICopyButtonProps> = React.memo(function CopyButtonMemo({
  onClick,
  disabled,
}: ICopyButtonProps) {
  return (
    <ConditionalTooltip
      text={disabled ? 'Select 1 or more flows & activities to copy' : undefined}
    >
      <Button onClick={onClick} Disabled={disabled} AccentColor Small>
        Copy
      </Button>
    </ConditionalTooltip>
  )
})

export const Header: React.FC<{
  id: CopyActivitiesSection
  navigateLeft?: (id: CopyActivitiesSection) => void
  onAction?: () => void
  actionDisabled?: boolean
  headerText?: string
}> = ({ id, navigateLeft = () => void 0, onAction, actionDisabled, headerText }) => {
  const onBack = React.useCallback(() => {
    navigateLeft(COURSE_PICKER_SECTION)
  }, [id, navigateLeft])
  return (
    <div className={styles.menuHeader}>
      {id === COURSE_PICKER_SECTION ? (
        <span>Copy from ...</span>
      ) : (
        <>
          <div className={styles.headerLeftSection}>
            <BackButton onClick={onBack} />
            {headerText && <span className={styles.headerText}>{headerText}</span>}
          </div>
          {onAction && <CopyButton onClick={onAction} disabled={actionDisabled} />}
        </>
      )}
    </div>
  )
}
