import { useEffect, useRef } from 'react'

type EventOptions = Pick<AddEventListenerOptions, 'capture' | 'passive'>

const useEventListener = <T extends Event>(
  eventType: string,
  handler: (event: T) => void,
  element: EventTarget | null = window,
  options?: EventOptions | boolean,
) => {
  const savedHandler = useRef<(event: T) => void>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (!element) {
      return
    }

    const eventListener = (event: Event) => {
      if (savedHandler.current) {
        return savedHandler.current(event as T)
      }
    }

    element.addEventListener(eventType, eventListener, options)

    return () => element.removeEventListener(eventType, eventListener, options)
  }, [eventType, element])
}

export default useEventListener
