import classNames from 'classnames'
import classNamesBind from 'classnames/bind'
import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  KeyboardEventHandler,
  ReactNode,
} from 'react'
import { DebounceInput } from 'react-debounce-input'

import { Icons } from 'components/Icons'
import { Tooltip } from 'components/Tooltip/Tooltip'

import styles from './Input.module.scss'

const cx = classNamesBind.bind(styles)

export const DefaultMessageBox = ({
  children,
  ...props
}: {
  children?: ReactNode
  Medium?: boolean
  Large?: boolean
  className?: string
}) => {
  const className = classNames(props.className || '', {
    large: props.Large,
    medium: props.Medium,
    [styles.message]: !props.Medium || !props.Large,
  })
  return (
    <span className={className} data-input-error>
      {children}
    </span>
  )
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.RefObject<HTMLInputElement>
  className?: string
  containerClassName?: string
  Disabled?: boolean
  Invalid?: boolean
  Large?: boolean
  withSearchIcon?: boolean
  Medium?: boolean
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>
  meta?: {
    valid?: boolean
    touched?: boolean
    error?: any
    submitError?: any
    warning?: any
  }
  ShowFieldWarning?: boolean
  WarningMessageBox?: React.ComponentType
  ShowFieldError?: boolean
  ErrorMessageBox?: React.ComponentType<{ children?: React.ReactChild }>
  withValidation?: boolean
  input?: InputHTMLAttributes<HTMLInputElement>
  debounceTimeout?: number
  field?: {
    value?: string
    onChange: ChangeEventHandler<HTMLInputElement>
  }
  maxLength?: number
  autoFocus?: boolean
  children?: React.ReactNode
}

interface IPropsWithDebounce extends IProps {
  debounceTimeout: number
}

interface IDebounceInputType {
  onChange: ChangeEventHandler<HTMLInputElement>
  value: 'number' | 'string' | undefined
}

export const Input = (props: IProps | IPropsWithDebounce) => {
  const {
    autoFocus,
    inputRef,
    className = '',
    Disabled,
    Invalid,
    Large,
    withSearchIcon,
    children,
    Medium,
    maxLength,
    onKeyUp,

    // react-debounce-input
    debounceTimeout,

    // redux-form
    meta,

    // redux-form support
    ShowFieldWarning,
    WarningMessageBox = DefaultMessageBox,
    ShowFieldError,
    ErrorMessageBox = DefaultMessageBox,

    withValidation,
    containerClassName,
    ...inputProps
  } = props

  let _inputRef: React.RefObject<HTMLInputElement>
  if (inputRef) {
    _inputRef = inputRef
  } else {
    _inputRef = React.useRef<HTMLInputElement>(null)
  }

  React.useEffect(() => {
    if (!autoFocus) {
      return
    }
    _inputRef?.current?.focus()
  }, [autoFocus])

  const input = props.input || props.field

  const touched = withValidation && meta && meta.touched
  const valid = withValidation && meta && meta.valid

  const inputStyles = cx('input', {
    inputWithIcon: withSearchIcon,
    invalid: (touched && !valid) || Invalid,
    large: Large,
    medium: Medium,
    valid: touched && valid,
  })

  let ele
  if (debounceTimeout && input && input.value !== undefined && input.onChange) {
    const debounceInput = input as IDebounceInputType
    ele = (
      <DebounceInput
        {...inputProps}
        className={`${inputStyles} ${className}`}
        debounceTimeout={debounceTimeout}
        maxLength={maxLength}
        disabled={Disabled}
        type={inputProps.type}
        inputRef={_inputRef}
        {...debounceInput}
      />
    )
  } else {
    ele = (
      <input
        className={`${inputStyles} ${className}`}
        disabled={Disabled}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
        ref={_inputRef}
        {...inputProps}
        {...input}
      />
    )
  }

  return (
    <span className={cx('inputContainer', containerClassName)}>
      {meta &&
        meta.touched &&
        ((ShowFieldError && (meta.error || meta.submitError) && (
          <ErrorMessageBox {...props}>
            <span
              dangerouslySetInnerHTML={{ __html: meta.error ?? meta.submitError }}
            />
          </ErrorMessageBox>
        )) ||
          (ShowFieldWarning && meta.warning && (
            <WarningMessageBox {...props}>
              <span dangerouslySetInnerHTML={{ __html: meta.warning }} />
            </WarningMessageBox>
          )))}
      {ele}
      {withSearchIcon && <Icons.Search className={cx('searchIcon')} />}
      {children}
    </span>
  )
}

export const InputWithDelete = (
  props: (IProps | IPropsWithDebounce) & {
    deleteHandler?: () => void
    'data-test'?: string
  },
) => {
  const {
    deleteHandler,
    containerClassName,
    className = '',
    input,
    placeholder,

    // redux-form
    meta,

    // redux-form support
    ShowFieldWarning,
    WarningMessageBox = DefaultMessageBox,
    ShowFieldError,
    ErrorMessageBox = DefaultMessageBox,

    // HTML Props
    ...otherProps
  } = props

  const { touched = false, error = false, warning = false } = meta || {}

  const inputWithWarnings = cx('input', {
    error: error || false,
  })

  const htmlProps = input && input.value && input.onChange ? input : {}

  return (
    <div>
      <div className={`df w100 ${containerClassName || ''} ${inputWithWarnings}`}>
        <input
          className={`w100 bn ${className} ${cx('delete-input')}`}
          data-test={props['data-test']}
          placeholder={placeholder}
          {...htmlProps}
        />
        <Tooltip placement="top" text="Remove">
          <button
            type="button"
            className={cx('remove-button')}
            onClick={otherProps.Disabled ? undefined : deleteHandler}
          >
            <Icons.Close xSmall nearBlack />
          </button>
        </Tooltip>
      </div>
      {touched &&
        ((ShowFieldError && error && (
          <ErrorMessageBox {...props}>{error}</ErrorMessageBox>
        )) ||
          (ShowFieldWarning && warning && (
            <WarningMessageBox {...props}>{warning}</WarningMessageBox>
          )))}
    </div>
  )
}
