import { useContext } from 'react'

import { ToastContext } from 'components/NotificationCenter'

function useTryAndNotify() {
  const { displayToast } = useContext(ToastContext)

  return async (
    fn: () => Promise<any>,
    setLoading?: (l: boolean) => void,
    successMessage?: string,
  ) => {
    try {
      if (setLoading) setLoading(true)
      await fn()
      if (successMessage) displayToast(successMessage, 'success')
    } catch (e) {
      displayToast(e.message, 'error')
    } finally {
      if (setLoading) setLoading(false)
    }
  }
}

export default useTryAndNotify
