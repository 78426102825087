import { graphql } from 'react-relay'

import {
  UpdateFlowTitleInput,
  updateFlowTitleMutation,
} from '__generated__/updateFlowTitleMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: UpdateFlowTitleInput) =>
  mutationPromise<updateFlowTitleMutation>({
    mutation: graphql`
      mutation updateFlowTitleMutation($input: UpdateFlowTitleInput!) {
        updateFlowTitle(input: $input) {
          flow {
            title
            activities {
              flow {
                title
              }
            }
          }
        }
      }
    `,
    variables: { input },
  })
