import { graphql } from 'react-relay'

import { SidebarMutationsMoveActivityMutation } from '__generated__/SidebarMutationsMoveActivityMutation.graphql'
import { SidebarMutationsMoveFlowMutation } from '__generated__/SidebarMutationsMoveFlowMutation.graphql'
import { SidebarTree_course } from '__generated__/SidebarTree_course.graphql'
import mutationPromise from 'relay/mutationPromise'
import { notEmpty, reorder } from 'utils/functions'

export const moveActivity = (
  course: SidebarTree_course,
  activityStartIndex: number,
  activityEndIndex: number,
  flowStartIndex: number,
  flowEndIndex: number,
  options: { force: boolean } = { force: false },
) => {
  const optimisticFlows = course.flows.map((flow) => ({
    ...flow,
    activities: flow.activities.map((a) => ({ ...a })),
  }))

  let newFlowId
  if (flowEndIndex === flowStartIndex) {
    // Moving inside a flow.
    const flow = optimisticFlows[flowStartIndex]
    flow.activities = reorder(flow.activities, activityStartIndex, activityEndIndex)
    newFlowId = flow.id
  } else {
    // Moving from a flow (which could be a course activity) to another flow.
    const newFlow = optimisticFlows[flowEndIndex]
    const oldFlow = optimisticFlows[flowStartIndex]
    const activity = oldFlow.activities.splice(activityStartIndex, 1)[0]
    newFlow.activities.splice(activityEndIndex, 0, activity)
    newFlowId = newFlow.id
  }

  const activity = course.flows[flowStartIndex].activities[activityStartIndex]
  const sortedActivities = optimisticFlows.flatMap((f) => f.activities)
  const index = sortedActivities.findIndex((a) => a.id == activity.id)
  const activityIdBefore = index > 0 ? sortedActivities[index - 1].id : null

  return mutationPromise<SidebarMutationsMoveActivityMutation>({
    mutation: graphql`
      mutation SidebarMutationsMoveActivityMutation($input: MoveActivityInput!) {
        moveActivity(input: $input) {
          activity {
            id
            url
          }
          course {
            id
            flows {
              id
              title
              isClassActivity
              url
              ruleGroupsVisibility {
                id
                prerequisites {
                  id
                }
                courseData {
                  id
                }
              }
              activities {
                id
                title
                activityType
                url
                ruleGroupsVisibility {
                  __typename
                  ... on RuleGroup {
                    id
                    prerequisites {
                      id
                    }
                    courseData {
                      id
                    }
                  }
                }
                ruleGroupsCompletion {
                  __typename
                  ... on RuleGroup {
                    id
                    prerequisites {
                      id
                    }
                    courseData {
                      id
                    }
                  }
                }
                selectableOutputActivities {
                  id
                }
                selectableSourceActivities {
                  id
                }
                selectableDataRuleOutputActivities {
                  id
                }
              }
            }
          }
        }
      }
    `,
    optimisticUpdater: (store) => {
      optimisticFlows.forEach((flow) => {
        const activities = flow.activities
          .map((a) => store.get(a.id) ?? null)
          .filter(notEmpty)

        const storeFlow = store.get(flow.id)
        storeFlow?.setLinkedRecords(activities, 'activities')
      })
    },
    variables: {
      input: {
        activityId: activity.id,
        newFlowId,
        activityIdBefore,
        force: options.force,
      },
    },
  })
}

export const moveFlow = (
  course: SidebarTree_course,
  startIndex: number,
  endIndex: number,
  options: { force: boolean } = { force: false },
) => {
  const offset = 1 // offset by 1 to account for the default immovable 'course level' flow
  const newFlowIndex = endIndex + offset
  const flowIdBefore =
    startIndex > endIndex
      ? course.flows[newFlowIndex - 1].id
      : course.flows[newFlowIndex].id
  return mutationPromise<SidebarMutationsMoveFlowMutation>({
    mutation: graphql`
      mutation SidebarMutationsMoveFlowMutation($input: MoveFlowInput!) {
        moveFlow(input: $input) {
          course {
            id
            flows {
              id
              title
              ruleGroupsVisibility {
                prerequisites {
                  id
                }
                courseData {
                  id
                }
              }
              selectableDataRuleOutputActivities {
                id
              }
              activities {
                id
                activityType
                title
                url
                ruleGroupsVisibility {
                  ... on RuleGroup {
                    prerequisites {
                      id
                    }
                    courseData {
                      id
                    }
                  }
                }
                ruleGroupsCompletion {
                  ... on RuleGroup {
                    prerequisites {
                      id
                    }
                    courseData {
                      id
                    }
                  }
                }
                selectableOutputActivities {
                  id
                }
                selectableSourceActivities {
                  id
                }
                selectableDataRuleOutputActivities {
                  id
                }
              }
            }
          }
        }
      }
    `,
    optimisticUpdater: (store) => {
      const optimisticFlows = reorder(
        course.flows,
        startIndex + offset,
        newFlowIndex,
      ).map((flow) => flow)
      const flows = optimisticFlows.map((flow) => store.get(flow.id)).filter(notEmpty)
      const courseRecord = store.get(course.id)
      courseRecord?.setLinkedRecords(flows, 'flows')
    },
    variables: {
      input: {
        flowId: course.flows[startIndex + offset].id,
        flowIdBefore,
        force: options.force,
      },
    },
  })
}
