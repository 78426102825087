import { map, Matcher, redirect } from 'navi'
import { fetchQuery, graphql } from 'react-relay'

import { withBlockImpersonationQuery } from '__generated__/withBlockImpersonationQuery.graphql'
import environment from 'relay/environment'
import { fromGlobalId } from 'utils/relay'

function withBlockImpersonation<ParentContext extends object = {}>(
  matcher: Matcher<ParentContext>,
) {
  return map<ParentContext>(async (request) => {
    const query = graphql`
      query withBlockImpersonationQuery {
        me {
          impersonationCourseId
        }
      }
    `
    const response = (await fetchQuery(
      environment,
      query,
      {},
      { fetchPolicy: 'store-or-network' },
    ).toPromise()) as withBlockImpersonationQuery['response']
    const { me } = response
    if (!me) {
      return redirect(`/login?next=${request.mountpath}`)
    }
    if (me && me.impersonationCourseId) {
      return redirect(`/courses/${fromGlobalId(me.impersonationCourseId).id}`)
    }
    return matcher
  })
}

export default withBlockImpersonation
