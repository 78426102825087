import React from 'react'
import { graphql, useQuery, useMutation } from 'relay-hooks'

import type { CopyActivitiesMenuCourseQuery } from '__generated__/CopyActivitiesMenuCourseQuery.graphql'
import type { CopyActivitiesMenuMutation } from '__generated__/CopyActivitiesMenuMutation.graphql'

import { Button } from 'components/Button'
import ContextMenu from 'components/ContextMenu/ContextMenu'
import { Icons } from 'components/Icons'
import { Tooltip } from 'components/Tooltip'

import ActivityPicker from './ActivityPicker'
import CoursePicker from './CoursePicker'
import {
  ACTIVITY_PICKER_SECTION,
  COURSE_PICKER_SECTION,
  ActionType,
  defaultState,
} from './constants'
import { stateReducer } from './reducer'
import type { CopyActivitiesSection, ISection } from './types'

import styles from './CopyActivitiesMenu.module.scss'

export const courseQuery = graphql`
  query CopyActivitiesMenuCourseQuery($courseId: ID) {
    course(id: $courseId) {
      id
      title
      isArchived
      flows {
        id
        title
        isClassActivity
        activities {
          id
          title
          source {
            id
          }
          isCopyable
          ...ActivityIcon_activity
          ...ActivityTitle_activity
        }
      }
    }
  }
`

export const renderCopyActivitesContextMenuLabel = (
  ref: React.RefObject<HTMLElement>,
  {
    onClick,
    isOpen,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    isOpen: boolean
  },
) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    onClick(e)
  }
  return (
    <Tooltip
      placement="top"
      text="Copy flows and activities from another course"
      mouseEnterDelay={0.75}
      visible={isOpen ? false : undefined}
    >
      <Button
        Large
        Icon
        className={styles.copyButton}
        ref={ref}
        onClick={handleClick}
        ariaLabel="Copy activities & flows"
        id="copy-activities-button"
      >
        <Icons.Copy nearBlack />
      </Button>
    </Tooltip>
  )
}

const CopyActivitiesMenu: React.FC<
  {
    courseId?: string
  } & Partial<React.ComponentProps<typeof ContextMenu>>
> = ({ ...rest }) => {
  const [state, dispatch] = React.useReducer(stateReducer, defaultState)

  // Course selected
  const courseQueryResults = useQuery<CopyActivitiesMenuCourseQuery>(
    courseQuery,
    {
      courseId: state.courseId,
    },
    { skip: !state.courseId },
  )

  const [copyActivitiesMutation] = useMutation<CopyActivitiesMenuMutation>(
    graphql`
      mutation CopyActivitiesMenuMutation($input: CopyActivitiesInput!) {
        copyActivities(input: $input) {
          course {
            flows {
              id
              title
              isClassActivity
              hasSubsetVisibilityRule
              ...FlowTitle_flow
              activities {
                id
                activityType
                title
                url
                hasSubsetVisibilityRule
                isCopyable
                source {
                  id
                }
                ...ActivityIcon_activity
                ...ActivityTitle_activity
              }
            }
          }
        }
      }
    `,
  )

  React.useEffect(() => {
    const { course } = courseQueryResults.data || { course: null }
    if (!course || state?.course?.id == course?.id) {
      return
    }

    dispatch({ type: ActionType.SetCourse, course })
  }, [courseQueryResults, state.courseId])

  React.useEffect(() => {
    // After menu closed is passed via isOpen prop - closing the menu - hand back control to ContextMenu's events
    if (state.isOpen === false) {
      dispatch({ type: ActionType.SetIsOpen, isOpen: undefined })
    }
  }, [state.isOpen])

  const sections: Record<CopyActivitiesSection, ISection<any, any>> = {
    COURSE_PICKER_SECTION: {
      id: COURSE_PICKER_SECTION,
      Component: CoursePicker,
      props: { dispatch, state },
    } as ISection<typeof CoursePicker, React.ComponentProps<typeof CoursePicker>>,
    ACTIVITY_PICKER_SECTION: {
      id: ACTIVITY_PICKER_SECTION,
      Component: ActivityPicker,
      props: { dispatch, state, copyActivitiesMutation },
    } as ISection<typeof ActivityPicker, React.ComponentProps<typeof ActivityPicker>>,
  }

  const renderElement = (ref: React.RefObject<HTMLElement>) => {
    const SectionComponent = sections[state.currentSection].Component
    return (
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className={styles.menuContainer}
      >
        <SectionComponent
          {...sections[state.currentSection].props}
          state={state}
          dispatch={dispatch}
        />
      </div>
    )
  }

  return (
    <ContextMenu
      renderLabel={renderCopyActivitesContextMenuLabel}
      renderElement={renderElement}
      tetherClassName={styles.tetherClass}
      attachment="top left"
      targetAttachment="bottom left"
      onHide={() => dispatch({ type: ActionType.Reset })}
      isOpen={state.isOpen}
      focusLockProps={{
        autoFocus: false, // Disable autoFocus of search field in CoursePicker
        className: styles.menuClass,
      }}
      {...rest}
    />
  )
}

export default CopyActivitiesMenu
