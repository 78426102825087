import { graphql } from 'react-relay'

import {
  UpdateActivityIconInput,
  updateActivityIconMutation,
} from '__generated__/updateActivityIconMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: UpdateActivityIconInput, activityType: any) =>
  mutationPromise<updateActivityIconMutation>({
    mutation: graphql`
      mutation updateActivityIconMutation($input: UpdateActivityIconInput!) {
        updateActivityIcon(input: $input) {
          activity {
            id
            icon
            __typename
          }
        }
      }
    `,
    variables: { input },
    optimisticResponse: {
      updateActivityIcon: {
        activity: {
          id: input.activityId,
          icon: input?.icon ?? null,
          __typename: activityType,
        },
      },
    },
  })
