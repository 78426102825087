import classNames from 'classnames'
import queryString from 'query-string'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import FocusLock from 'react-focus-lock'
import InfiniteScroll from 'react-infinite-scroller'
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from 'react-relay'

import { ImpersonationUserSelectorPaginationQuery } from '__generated__/ImpersonationUserSelectorPaginationQuery.graphql'
import { ImpersonationUserSelectorQuery } from '__generated__/ImpersonationUserSelectorQuery.graphql'
import {
  ImpersonationUserSelector_me$key,
  ImpersonationUserSelector_me,
} from '__generated__/ImpersonationUserSelector_me.graphql'
import {
  ImpersonationUserSelector_query$key,
  ImpersonationUserSelector_query,
} from '__generated__/ImpersonationUserSelector_query.graphql'
import useClickOutside from 'hooks/useClickOutside'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { notEmpty } from 'utils/functions'
import { fromGlobalId } from 'utils/relay'

import dropdownStyles from 'components/Dropdown/Dropdown.module.scss'
import { Input } from 'components/Input'
import { ParticipantTypeBadge } from 'components/ParticipantTypeBadge'
import { Spinner } from 'components/Spinner'
import { UserBadge } from 'components/UserBadge'

import useActivityIdFromRoute from './useActivityIdFromRoute'

import styles from './ImpersonationUserSelector.module.scss'

interface ICurrentUser
  extends Pick<
    ImpersonationUserSelector_me,
    'id' | 'name' | 'badge' | 'impersonationCourseId'
  > {}

interface IProps {
  me: ICurrentUser
}

interface IDropdownProps {
  me: ImpersonationUserSelector_me$key
}

interface IParticipantRowProps {
  participant: Exclude<
    Exclude<
      ImpersonationUserSelector_query['courseImpersonatableParticipants']['edges'][number],
      null
    >['node'],
    null
  >['user']
  courseId: string | null
  currentUserId: string
  currentActivityId?: string
}

const ParticipantRow = ({
  participant,
  courseId,
  currentUserId,
  currentActivityId,
}: IParticipantRowProps) => {
  if (!courseId) {
    return null
  }

  const replaceImpersonationQs = queryString.stringify({
    activity_id: currentActivityId,
    new_user: fromGlobalId(participant.id).id,
  })

  return (
    <a
      className={styles.participantRow}
      href={`/courses/${fromGlobalId(courseId).id}/participants/${
        fromGlobalId(currentUserId).id
      }/replace-impersonation?${replaceImpersonationQs}`}
    >
      <UserBadge badge={participant.badge} className={styles.badge} small />
      <p>{participant.name}</p>
    </a>
  )
}

interface IPaginatedUserListProps {
  queryProp: ImpersonationUserSelectorQuery['response']
  searchTerm: string
  me: ICurrentUser
}

const PaginatedUserList = ({ queryProp, searchTerm, me }: IPaginatedUserListProps) => {
  const { data, loadNext, refetch, hasNext } = usePaginationFragment<
    ImpersonationUserSelectorPaginationQuery,
    ImpersonationUserSelector_query$key
  >(
    graphql`
      fragment ImpersonationUserSelector_query on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        searchTerm: { type: "String", defaultValue: "" }
      )
      @refetchable(queryName: "ImpersonationUserSelectorPaginationQuery") {
        courseImpersonatableParticipants(
          first: $count
          after: $cursor
          searchTerm: $searchTerm
        )
          @connection(
            key: "ImpersonationUserSelector_courseImpersonatableParticipants"
          ) {
          edges {
            node {
              participantType
              user {
                id
                name
                badge {
                  ...UserBadge_badge
                }
                isTestUser
              }
            }
          }
        }
      }
    `,
    queryProp,
  )

  const { currentActivityId } = useActivityIdFromRoute()

  const participants = data?.courseImpersonatableParticipants.edges
    .map((e) => e?.node)
    .filter(notEmpty)

  const { testAssistants, assistants, testLearners, learners } = useMemo(() => {
    return {
      testAssistants: participants?.filter(
        (p) => p.participantType === 'Assistant' && p.user.isTestUser,
      ),
      assistants: participants?.filter(
        (p) => p.participantType === 'Assistant' && !p.user.isTestUser,
      ),
      testLearners: participants?.filter(
        (p) => p.participantType === 'Student' && p.user.isTestUser,
      ),
      learners: participants?.filter(
        (p) => p.participantType === 'Student' && !p.user.isTestUser,
      ),
    }
  }, [participants])

  const handleLoadMore = useCallback(() => loadNext(20), [loadNext])

  useEffectSkipFirstRender(() => {
    refetch({
      searchTerm,
    })
  }, [searchTerm])

  if (participants && participants.length > 0) {
    return (
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasNext}
        threshold={20}
        useWindow={false}
      >
        {testAssistants && testAssistants.length > 0 ? (
          <div className={styles.section}>
            <span className={styles.sectionHeader}>
              <ParticipantTypeBadge
                participantType="Assistant"
                isTestUser
                textClassName={styles.sectionHeaderText}
              />
            </span>
            {testAssistants.map((p) => (
              <ParticipantRow
                currentUserId={me.id}
                participant={p.user}
                key={p.user.id}
                courseId={me.impersonationCourseId}
                currentActivityId={currentActivityId}
              />
            ))}
          </div>
        ) : null}
        {assistants && assistants.length > 0 ? (
          <div className={styles.section}>
            <span className={styles.sectionHeader}>
              <ParticipantTypeBadge
                participantType="Assistant"
                textClassName={styles.sectionHeaderText}
              />
            </span>
            {assistants.map((p) => (
              <ParticipantRow
                currentUserId={me.id}
                participant={p.user}
                key={p.user.id}
                courseId={me.impersonationCourseId}
                currentActivityId={currentActivityId}
              />
            ))}
          </div>
        ) : null}
        {testLearners && testLearners.length > 0 ? (
          <div className={styles.section}>
            <span className={styles.sectionHeader}>
              <ParticipantTypeBadge
                participantType="Student"
                isTestUser
                textClassName={styles.sectionHeaderText}
              />
            </span>
            {testLearners.map((p) => (
              <ParticipantRow
                currentUserId={me.id}
                participant={p.user}
                key={p.user.id}
                courseId={me.impersonationCourseId}
                currentActivityId={currentActivityId}
              />
            ))}
          </div>
        ) : null}
        {learners && learners.length > 0 ? (
          <div className={styles.section}>
            <span className={styles.sectionHeader}>
              <ParticipantTypeBadge
                participantType="Student"
                textClassName={styles.sectionHeaderText}
              />
            </span>
            {learners.map((p) => (
              <ParticipantRow
                currentUserId={me.id}
                participant={p.user}
                key={p.user.id}
                courseId={me.impersonationCourseId}
                currentActivityId={currentActivityId}
              />
            ))}
          </div>
        ) : null}
      </InfiniteScroll>
    )
  } else {
    return <p className={styles.empty}>No participants found</p>
  }
}

const ImpersonationUserSelector = ({ me }: IProps) => {
  const query = useLazyLoadQuery<ImpersonationUserSelectorQuery>(
    graphql`
      query ImpersonationUserSelectorQuery {
        ...ImpersonationUserSelector_query
      }
    `,
    {},
  )
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchTermChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)
  }

  return (
    <FocusLock returnFocus>
      <div
        className={classNames(
          dropdownStyles.selectMenuOuter,
          styles.participantsListOuter,
        )}
      >
        <div
          className={classNames(
            dropdownStyles.selectMenu,
            dropdownStyles.openOnTop,
            styles.participantsList,
          )}
        >
          <div className={styles.header}>
            <p>Search participants</p>
            <Input
              autoFocus
              id="search-term"
              name="search-term"
              input={{
                value: searchTerm,
                onChange: handleSearchTermChange,
              }}
              debounceTimeout={500}
              withSearchIcon
            />
          </div>
          <Suspense fallback={<Spinner className={styles.spinner} />}>
            <PaginatedUserList queryProp={query} searchTerm={searchTerm} me={me} />
          </Suspense>
        </div>
      </div>
    </FocusLock>
  )
}

const Dropdown = ({ me: meProp }: IDropdownProps) => {
  const me = useFragment<ImpersonationUserSelector_me$key>(
    graphql`
      fragment ImpersonationUserSelector_me on User {
        id
        name
        badge {
          ...UserBadge_badge
        }
        impersonationCourseId
      }
    `,
    meProp,
  )

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen((prevState) => !prevState)
  useClickOutside(['impersonation-user-selector'], () => setIsOpen(false))

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        setIsOpen(false)
      }
    }

    document.addEventListener('keydown', handler)

    return () => document.removeEventListener('keydown', handler)
  }, [isOpen, setIsOpen])

  return (
    <div
      className={classNames(styles.container, { [dropdownStyles.isOpen]: isOpen })}
      id="impersonation-user-selector"
    >
      <button className={dropdownStyles.selectButton} onClick={toggleOpen}>
        <div
          className={dropdownStyles.selectButtonWrap}
          aria-label="selected option"
          role="textbox"
          aria-readonly
        >
          <span className={classNames(dropdownStyles.selectText)}>
            <span className={styles.badgeAndText}>
              <UserBadge badge={me.badge} className={styles.badge} small />
              {me.name}
            </span>
          </span>
          <span className={dropdownStyles.selectArrowZone}>
            <span className={dropdownStyles.selectArrow} />
          </span>
        </div>
      </button>
      {isOpen ? <ImpersonationUserSelector me={me} /> : null}
    </div>
  )
}

export default Dropdown
