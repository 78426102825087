import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React, { Suspense, useEffect } from 'react'
import { useActive, useCurrentRoute, useNavigation } from 'react-navi'
import {
  graphql,
  loadQuery,
  PreloadedQuery,
  requestSubscription,
  usePreloadedQuery,
} from 'react-relay'

import SidebarCourseQuery, {
  SidebarCourseQuery as SidebarCourseQueryType,
} from '__generated__/SidebarCourseQuery.graphql'
import environment from 'relay/environment'
import { fromGlobalId, toGlobalId } from 'utils/relay'

import { Button } from 'components/Button'
import CopyActivitiesMenu from 'components/CopyActivities/CopyActivitiesMenu'
import { Icons } from 'components/Icons'
import { useInsertActivity } from 'components/InsertActivity/InsertActivityContext'
import { MobileContext } from 'components/MobileContext'
import { SidebarCollapseContext } from 'components/SidebarCollapseContext'

import SidebarHero from './SidebarHero'
import SidebarResizer from './SidebarResizer'
import SidebarTree from './SidebarTree'

import styles from './Sidebar.module.scss'

export const getSidebarWidth = () => localStorage.getItem('sidebar-width') || undefined

interface IProps {
  queryRef: PreloadedQuery<SidebarCourseQueryType>
}

const Sidebar: React.FC<IProps> = ({ queryRef }) => {
  const {
    data: { courseId },
  } = useCurrentRoute()

  const results = usePreloadedQuery<SidebarCourseQueryType>(
    graphql`
      query SidebarCourseQuery($courseId: ID!) {
        course(id: $courseId) {
          id
          isViewerTeacher
          isViewerAssistant
          ...SidebarHero_course
          ...SidebarTree_course
        }
      }
    `,
    queryRef,
  )
  const { course } = results || { course: null }

  const navigation = useNavigation()
  const [sidebarMounted, setSidebarMounted] = React.useState(false)
  const { setIsShowingContent, isShowingContent } = React.useContext(MobileContext)
  const { isCollapsed, uncollapseSidebar, collapseSidebar } =
    React.useContext(SidebarCollapseContext)

  React.useEffect(() => {
    const listener = debounce((event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === 'e' &&
        // Ensure we don't trigger when focused on an input field
        event.target === document.body
      ) {
        if (isCollapsed) {
          uncollapseSidebar()
        } else {
          collapseSidebar()
        }
      }
    }, 100)

    window.addEventListener('keydown', listener)

    return () => window.removeEventListener('keydown', listener)
  }, [isCollapsed, uncollapseSidebar, collapseSidebar])

  React.useEffect(() => {
    // Refresh sidebar in the background when it's being re-rendered
    loadQuery<SidebarCourseQueryType>(
      environment,
      SidebarCourseQuery,
      { courseId: toGlobalId('Course', courseId) },
      { fetchPolicy: 'store-and-network' },
    )
  }, [])

  React.useEffect(() => {
    // Set a variable to know if the sidebar is mounted
    // This is useful for showing a yellow background for
    // activities with a subset that appeared after a tag selection
    // See also the component ActivityTitle
    setSidebarMounted(true)

    // Set initial sidebar width if saved
    const sidebarWidth = localStorage.getItem('sidebar-width') || undefined
    if (sidebarWidth) {
      const root = document.documentElement
      root.style.setProperty('--sidebar-width', sidebarWidth)
    }
  }, [])

  useEffect(() => {
    if (!course || course.isViewerTeacher || course.isViewerAssistant) {
      return
    }

    const sub = requestSubscription(environment, {
      subscription: graphql`
        subscription SidebarStudentCourseUpdatedSubscription($courseId: ID!) {
          studentCourseUpdated(courseId: $courseId) {
            ok
          }
        }
      `,
      variables: { courseId: course.id },
      onNext: () => window.location.reload(),
    })
    return () => sub.dispose()
  }, [course?.id])

  if (!course) {
    return null
  }

  const { id: dbCourseId } = fromGlobalId(course.id)
  const addActivitiesUrl = `/courses/${dbCourseId}/create`
  const isCreate = useActive(addActivitiesUrl)
  const { resetActivityInsertionState } = useInsertActivity()

  const isTeacher = !!course.isViewerTeacher
  const isAssistant = !!course.isViewerAssistant
  const isAddingActivity = isCreate && isShowingContent

  const styleSidebar = classNames(styles.sidebar, {
    [styles.teacher]: isTeacher && !isAssistant,
    [styles.isShowingContent]: isShowingContent,
  })
  const styleClasses = classNames(styles.addNewContainer, {
    [styles.addingActivity]: isAddingActivity,
  })
  const sidebarContainerStyles = classNames(styles.sidebarContainer, {
    [styles.teacher]: isTeacher && !isAssistant,
  })
  const handleAddActivitiesClick = async () => {
    await navigation.navigate(addActivitiesUrl, { replace: true })
    resetActivityInsertionState()
    setIsShowingContent(true)
  }

  return (
    <aside className={styleSidebar}>
      <a href="#layout-section" className={styles.skipLink}>
        Skip course navigation
      </a>
      <div className={sidebarContainerStyles} id="sidebar-container">
        <SidebarResizer />
        <SidebarHero course={course} />

        <Suspense fallback={null}>
          <SidebarTree course={course} sidebarMounted={sidebarMounted} />
          {isTeacher && !isAssistant && (
            <>
              <div className={styles.addNewContainerSpacer} />
              <div className={styleClasses}>
                <button
                  className={styles.addButtonMobile}
                  onClick={handleAddActivitiesClick}
                >
                  <Icons.Plus white />
                </button>
                <Button
                  Large
                  AccentColor
                  Block
                  className={styles.addButtonDesktop}
                  onClick={handleAddActivitiesClick}
                  data-test="btn-add-activities-and-flows"
                >
                  <Icons.Plus white style={{ marginRight: '.5rem' }} />
                  <span>Add new</span>
                </Button>
                <CopyActivitiesMenu courseId={course.id} />
              </div>
            </>
          )}
        </Suspense>
      </div>
    </aside>
  )
}

export default (props: IProps) => (
  <Suspense
    fallback={
      <div className={styles.skeletonSidebar}>
        <div className={styles.skeletonThumbnail} />
        <div className={styles.skeletonTop}>
          <div className={styles.skeletonTitle} />
          <div className={styles.skeletonBtn} />
        </div>
      </div>
    }
  >
    <Sidebar {...props} />
  </Suspense>
)
