/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateActivityTitleInput = {
    activityId: string;
    title: string;
    clientMutationId?: string | null | undefined;
};
export type updateActivityTitleMutationVariables = {
    input: UpdateActivityTitleInput;
};
export type updateActivityTitleMutationResponse = {
    readonly updateActivityTitle: {
        readonly activity: {
            readonly title: string;
        } | null;
    } | null;
};
export type updateActivityTitleMutation = {
    readonly response: updateActivityTitleMutationResponse;
    readonly variables: updateActivityTitleMutationVariables;
};



/*
mutation updateActivityTitleMutation(
  $input: UpdateActivityTitleInput!
) {
  updateActivityTitle(input: $input) {
    activity {
      __typename
      title
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateActivityTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateActivityTitlePayload",
        "kind": "LinkedField",
        "name": "updateActivityTitle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateActivityTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateActivityTitlePayload",
        "kind": "LinkedField",
        "name": "updateActivityTitle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1bb35fc29b477a867b0e2a750598516",
    "id": null,
    "metadata": {},
    "name": "updateActivityTitleMutation",
    "operationKind": "mutation",
    "text": "mutation updateActivityTitleMutation(\n  $input: UpdateActivityTitleInput!\n) {\n  updateActivityTitle(input: $input) {\n    activity {\n      __typename\n      title\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2ebb9004c8b95a355913179056507958';
export default node;
