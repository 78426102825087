/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type ActivityIcon_teacherActivity = {
    readonly activityType: ActivityType;
    readonly icon: string | null;
    readonly " $refType": "ActivityIcon_teacherActivity";
};
export type ActivityIcon_teacherActivity$data = ActivityIcon_teacherActivity;
export type ActivityIcon_teacherActivity$key = {
    readonly " $data"?: ActivityIcon_teacherActivity$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActivityIcon_teacherActivity">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityIcon_teacherActivity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    }
  ],
  "type": "TeacherActivity",
  "abstractKey": null
};
(node as any).hash = '30ef8b9f46ebedf609dfb72584624c5b';
export default node;
