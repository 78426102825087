import classNames from 'classnames'
import React, { memo, Suspense, useContext, useEffect, useState } from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'

import { CourseContextMenuQuery } from '__generated__/CourseContextMenuQuery.graphql'
import { CourseContextMenu_course$key } from '__generated__/CourseContextMenu_course.graphql'
import deleteCourse from 'mutations/deleteCourse'
import setCourseIsArchived from 'mutations/setCourseIsArchived'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { MenuItem } from 'components/ContextMenu/ContextMenu'
import contextMenuStyles from 'components/ContextMenu/ContextMenu.module.scss'
import ContextMenuNavigator, {
  ISection,
  NavigateFn,
} from 'components/ContextMenuNavigator/ContextMenuNavigator'
import { CourseThumbnailPicker } from 'components/CourseThumbnailPicker'
import { Divider } from 'components/Divider'
import { Icons } from 'components/Icons'
import { MobileContext } from 'components/MobileContext'
import { ModalContext } from 'components/Modal/ModalProvider'
import { ToastContext } from 'components/NotificationCenter'
import { Spinner } from 'components/Spinner'
import { useTestUsersContextMenu } from 'components/TestUsersContextMenu/TestUsersContextMenu'
import {
  ConfirmationModal,
  IConfirmationModalProps,
} from 'components/modals/ConfirmationModal'
import { DangerModal, IDangerModalProps } from 'components/modals/DangerModal'

import styles from './CourseContextMenu.module.scss'

interface IProps {
  course: CourseContextMenu_course$key
  onEditTitle: () => void
}

interface RenderSectionProps {
  navigateLeft: NavigateFn
  navigateRight: NavigateFn
}

const SECTION_COURSE_ACTIONS = 'COURSE_ACTIONS'
const SECTION_PREVIEW_COURSE = 'PREVIEW_COURSE'

const renderItemLabel = (
  label: React.ReactNode,
  Icon: React.FunctionComponent<{ style: React.CSSProperties }>,
) => (
  <>
    <Icon style={{ marginRight: '1rem' }} />
    {label}
  </>
)

const BackButton = memo(function BackButtonMemo({
  onClick,
}: {
  onClick: (event: React.SyntheticEvent) => void
}) {
  return (
    <Button onClick={onClick} Small Icon ariaLabel="Go back">
      <Icons.ArrowLeft accentColor xSmall />
    </Button>
  )
})

const PreviewCourseSection = ({ courseId }: { courseId: string }) => {
  const data = useLazyLoadQuery<CourseContextMenuQuery>(
    graphql`
      query CourseContextMenuQuery($courseId: ID!) {
        course(id: $courseId) {
          id
          ...TestUsersContextMenu_course
        }
      }
    `,
    {
      courseId,
    },
  )

  if (!data.course) {
    return null
  }

  const { renderMenu: renderTestUsersMenu } = useTestUsersContextMenu({
    course: data.course,
  })

  return renderTestUsersMenu()
}

export const CourseContextMenu = ({ course: courseFragment, onEditTitle }: IProps) => {
  const course = useFragment<CourseContextMenu_course$key>(
    graphql`
      fragment CourseContextMenu_course on Course {
        id
        isArchived
        isViewerOwner
        ...CourseThumbnailPicker_course
      }
    `,
    courseFragment,
  )

  const { showModal } = useContext(ModalContext)
  const { displayToast } = useContext(ToastContext)
  const { setIsShowingContent } = useContext(MobileContext)
  const navigation = useNavigation()
  const route = useCurrentRoute()
  const [isOpen, setIsOpen] = useState(false)
  const toggleShowMenu = () => setIsOpen(!isOpen)
  const hideMenu = () => setIsOpen(false)
  const [noAnimation, setNoAnimation] = useState(true)
  const [isEditingThumbnail, setIsEditingThumbnail] = useState(false)
  const hideEditThumbnail = () => setIsEditingThumbnail(false)

  useEffect(() => {
    setTimeout(() => setNoAnimation(isOpen), 500)
  }, [isOpen])

  const renderLabel = (ref: React.RefObject<HTMLElement>) => (
    <Button
      id="course-actions-button"
      ref={ref as React.RefObject<HTMLButtonElement>}
      Icon
      ariaLabel="Course actions"
      onClick={toggleShowMenu}
    >
      <Icons.ThreeDots nearBlack />
    </Button>
  )

  const handleDelete = () => {
    showModal<IDangerModalProps>(DangerModal, {
      message: (
        <div>
          Deleting a course will remove all flows and activities from Eduflow, including
          learner access. If you'd like to keep your data you can archive the course to
          remove it from the home page and enable a 'read only' mode for learners.{' '}
          <strong className="color red">This action cannot be undone</strong>.
        </div>
      ),
      onConfirm: async () => {
        try {
          await deleteCourse({ courseId: course.id })
          displayToast(`Successfully deleted course`, 'success')
          return navigation.navigate('/')
        } catch (e) {
          displayToast(e.message, 'error')
        }
      },
      title: 'Are you sure you want to delete this course?',
    })
  }

  const handleArchive = () => {
    showModal<IConfirmationModalProps>(ConfirmationModal, {
      message:
        "Archiving your course will hide it from the home page. Both you and your learners will still be able to access the course by clicking 'Archived courses' on the home page. Learners will only have access to activities as 'read-only'. You can unarchive a course at any time.",
      onConfirm: async () => {
        try {
          await setCourseIsArchived({ courseId: course.id, isArchived: true })
          displayToast('Successfully archived course', 'success')
        } catch (e) {
          displayToast(e.message, 'error')
        }
      },
      title: 'Are you sure you want to archive this course?',
    })
  }

  const handleUnArchive = async () => {
    try {
      await setCourseIsArchived({ courseId: course.id, isArchived: false })
      displayToast('Successfully unarchived course', 'success')
    } catch (e) {
      displayToast(e.message, 'error')
    }
  }

  const sections: ISection[] = [
    {
      id: SECTION_COURSE_ACTIONS,
      render: (props: RenderSectionProps) => (
        <div
          className={classNames(
            contextMenuStyles.listContainer,
            styles.courseActionsMenu,
          )}
        >
          <ul role="menu">
            <MenuItem
              item={{
                isHeading: false,
                label: renderItemLabel('Rename course', Icons.TypeCursor),
              }}
              onClick={onEditTitle}
            />
            <MenuItem
              item={{
                isHeading: false,
                label: renderItemLabel('Edit thumbnail photo', Icons.Camera),
                showMenuOnItemClick: true,
              }}
              onClick={() => setIsEditingThumbnail(true)}
            />
            <MenuItem
              item={{
                isHeading: false,
                label: renderItemLabel('Course settings', Icons.Cog),
              }}
              onClick={async () => {
                await navigation.navigate(`/courses/${route.data.courseId}/settings`)
                setIsShowingContent(true)
                hideMenu()
              }}
            />
            {window.waffle.flag_is_active('preview_course') ? (
              <MenuItem
                item={{
                  isHeading: false,
                  label: (
                    <div
                      className={classNames(
                        contextMenuStyles.listItem,
                        styles.testUsersMenuItem,
                      )}
                    >
                      <span>
                        <Icons.ImpersonationEye className={styles.icon} />
                        Test users
                      </span>
                      <Icons.ChevronRight />
                    </div>
                  ),
                }}
                onClick={() => props.navigateRight(SECTION_PREVIEW_COURSE)}
              />
            ) : null}
            <Divider />
            <MenuItem
              item={{
                isHeading: false,
                label: renderItemLabel(
                  course.isArchived ? 'Unarchive course' : 'Archive course',
                  Icons.Archive,
                ),
                disabled: !course.isViewerOwner,
                tooltipText: !course.isViewerOwner
                  ? 'Only the course owner can archive the course'
                  : undefined,
                tooltipPlacement: 'right',
              }}
              onClick={course.isArchived ? handleUnArchive : handleArchive}
            />
            <MenuItem
              item={{
                isHeading: false,
                label: renderItemLabel('Delete course', Icons.Trash),
                disabled: !course.isViewerOwner,
                tooltipText: !course.isViewerOwner
                  ? 'Only the course owner can delete the course'
                  : undefined,
                tooltipPlacement: 'right',
              }}
              onClick={handleDelete}
            />
          </ul>
        </div>
      ),
    },
    {
      id: SECTION_PREVIEW_COURSE,
      render: (props: RenderSectionProps) => (
        <div
          className={classNames(contextMenuStyles.listContainer, styles.testUsersMenu)}
        >
          <div className={styles.testUsersMenuHeader}>
            <BackButton onClick={() => props.navigateLeft(SECTION_COURSE_ACTIONS)} />
            Test users
          </div>
          <Suspense fallback={<Spinner />}>
            <PreviewCourseSection courseId={course.id} />
          </Suspense>
        </div>
      ),
    },
  ]

  const hideAndClose = (hidePanel: () => void) => () => {
    hideMenu()
    hidePanel()
  }

  const renderContextMenuNavigator = (ref: React.RefObject<HTMLElement>) => {
    return (
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className={styles.courseContextMenu}
      >
        <ContextMenuNavigator
          initialId={SECTION_COURSE_ACTIONS}
          sections={sections}
          renderHeader={() => null}
          headerContainerStyles={styles.courseContextMenuHeader}
        />
      </div>
    )
  }

  const renderElement = isEditingThumbnail
    ? (ref: React.RefObject<HTMLElement>) => {
        return (
          <div ref={ref as React.RefObject<HTMLDivElement>}>
            <CourseThumbnailPicker
              hideEditThumbnail={hideAndClose(hideEditThumbnail)}
              course={course}
            />
          </div>
        )
      }
    : renderContextMenuNavigator

  return (
    <ContextMenu
      renderLabel={renderLabel}
      menuClassName={styles.courseContextMenu}
      alignLeftMobile
      attachment={isEditingThumbnail ? 'middle left' : 'top left'}
      targetAttachment={isEditingThumbnail ? 'middle right' : 'bottom left'}
      renderElement={renderElement}
      isOpen={isOpen}
      onHide={hideMenu}
      noAnimation={noAnimation}
    />
  )
}

export default CourseContextMenu
