import classNamesBind from 'classnames/bind'
import React from 'react'
import { Link } from 'react-navi'

import { Spinner } from 'components/Spinner'
import { Tooltip } from 'components/Tooltip'

import styles from './Button.module.scss'

const cx = classNamesBind.bind(styles)

type IntrinsicButtonProps = JSX.IntrinsicElements['button']

export interface IButtonProps extends IntrinsicButtonProps {
  AccentColor?: boolean
  Block?: boolean
  Green?: boolean
  Red?: boolean
  Yellow?: boolean
  Dark?: boolean
  Small?: boolean
  Large?: boolean
  Disabled?: boolean
  isLoading?: boolean
  Icon?: boolean
  Transparent?: boolean
  'data-test'?: string
  'data-clipboard-text'?: string
  dataTest?: string
  href?: string
  target?: string
  NewTab?: boolean
  to?: string
  ariaLabel?: string
  tooltipText?: React.ComponentProps<typeof Tooltip>['text']
  tooltipStyles?: React.CSSProperties
  tooltipZIndex?: number
  tooltipPlacement?: string
  clipboardText?: string
  rel?: string
  style?: React.CSSProperties
  tooltipMouseLeaveDelay?: number
}

const buttonClasses = (props: IButtonProps) =>
  cx('btn', {
    block: props.Block,
    accentColor: props.AccentColor,
    dark: props.Dark,
    disabled: props.Disabled,
    green: props.Green,
    icon: props.Icon,
    large: props.Large,
    loading: props.isLoading,
    red: props.Red,
    small: props.Small,
    transparent: props.Transparent,
    yellow: props.Yellow,
  })

/**
 * Button has a props named `to` and `href`
 * Difference between both was one has <a> tag while other has <Link> tag
 * Likely a tech debt that we have to check at other places to
 * change Button.href to Link
 */
export const Button = React.forwardRef(
  (props: IButtonProps, ref: React.Ref<HTMLElement>) => {
    let dataTest = props['data-test'] || props.dataTest || ''
    const clipboardText = props['data-clipboard-text'] || props.clipboardText || ''

    let elLoading: React.ReactNode | undefined
    if (props.isLoading) {
      dataTest += ' loading'
      elLoading = (
        <div className={styles.loadingElement} data-test={dataTest}>
          <Spinner />
        </div>
      )
    }

    const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
      if (props.isLoading || props.Disabled) {
        // Do nothing
        evt.preventDefault()
      } else if (props.onClick) {
        props.onClick(evt)
      } else {
        return undefined
      }
    }

    if (props.Disabled) {
      dataTest += ' disabled'
    }

    const className = `${buttonClasses(props)} ${
      props.className ? props.className : ''
    }`

    if (props.href) {
      const linkEle = (
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          rel={props.rel}
          className={className}
          style={props.style}
          href={props.href}
          title={props.title}
          target={props.target || (props.NewTab ? '_blank' : '_self')}
          tabIndex={props.tabIndex}
          data-test={dataTest}
          aria-label={props.ariaLabel}
          onMouseOver={
            props.onMouseOver as unknown as React.MouseEventHandler<HTMLAnchorElement>
          }
          onMouseOut={
            props.onMouseOut as unknown as React.MouseEventHandler<HTMLAnchorElement>
          }
          onMouseEnter={
            props.onMouseEnter as unknown as React.MouseEventHandler<HTMLAnchorElement>
          }
          onMouseLeave={
            props.onMouseLeave as unknown as React.MouseEventHandler<HTMLAnchorElement>
          }
        >
          {props.children}
        </a>
      )
      return props.tooltipText ? (
        <Tooltip
          text={props.tooltipText}
          zIndex={props.tooltipZIndex}
          mouseLeaveDelay={props.tooltipMouseLeaveDelay}
          placement={props.tooltipPlacement}
        >
          {linkEle}
        </Tooltip>
      ) : (
        linkEle
      )
    }

    if (props.to) {
      const toLinkEle = (
        <Link
          ref={ref as React.Ref<HTMLAnchorElement>}
          href={props.to}
          title={props.title}
          className={className}
          style={props.style}
          data-test={dataTest}
          disabled={props.Disabled}
          aria-label={props.ariaLabel}
        >
          {props.children}
        </Link>
      )
      return props.tooltipText ? (
        <Tooltip
          text={props.tooltipText}
          zIndex={props.tooltipZIndex}
          mouseLeaveDelay={props.tooltipMouseLeaveDelay}
          placement={props.tooltipPlacement}
        >
          {toLinkEle}
        </Tooltip>
      ) : (
        toLinkEle
      )
    }

    let style = props.style
    if (props.tooltipText && props.Disabled) {
      // rc-tooltip disabled workaround, part 1
      // https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
      style = { ...style, pointerEvents: 'none' }
    }

    const buttonEle = (
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        name={props.name}
        className={className}
        type={props.type || 'button'}
        tabIndex={props.tabIndex}
        id={props.id}
        onClick={handleClick}
        data-test={dataTest}
        data-clipboard-text={clipboardText}
        disabled={props.Disabled}
        onMouseOver={props.onMouseOver}
        onMouseOut={props.onMouseOut}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        aria-label={props.ariaLabel}
        style={style}
      >
        {elLoading}
        {props.children}
      </button>
    )

    if (props.tooltipText && props.Disabled) {
      // rc-tooltip workaround, part 2
      return (
        <Tooltip
          text={props.tooltipText}
          zIndex={props.tooltipZIndex}
          mouseLeaveDelay={props.tooltipMouseLeaveDelay}
          placement={props.tooltipPlacement || 'top'}
        >
          <span
            className={cx(styles.disabledTooltip, { [styles.block]: props.Block })}
            style={props.tooltipStyles}
          >
            {buttonEle}
          </span>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip
          text={props.tooltipText}
          zIndex={props.tooltipZIndex}
          mouseLeaveDelay={props.tooltipMouseLeaveDelay}
          placement={props.tooltipPlacement || 'top'}
        >
          {buttonEle}
        </Tooltip>
      )
    }
  },
)
