import classNames from 'classnames'
import React from 'react'
import { graphql, useFragment } from 'react-relay'

import {
  ActivityIcon_activity$key,
  ActivityIcon_activity,
} from '__generated__/ActivityIcon_activity.graphql'
import {
  ActivityIcon_publicActivity$key,
  ActivityIcon_publicActivity,
} from '__generated__/ActivityIcon_publicActivity.graphql'
import {
  ActivityIcon_teacherActivity$key,
  ActivityIcon_teacherActivity,
} from '__generated__/ActivityIcon_teacherActivity.graphql'
import { GQLActivityType } from 'types/graphqlTypes'
import { assertUnreachable } from 'utils/functions'

import { CustomIcon } from 'components/CustomIcon'
import { Icons, IIconProps } from 'components/Icons'

import styles from './ActivityIcon.module.scss'

interface IActivityIconProps extends IIconProps {
  size?: number
  noCustom?: boolean
}

interface IProps extends IActivityIconProps {
  activity:
    | ActivityIcon_activity
    | ActivityIcon_publicActivity
    | ActivityIcon_teacherActivity
}

export const getActivityIcon = (activityType: GQLActivityType) => {
  if (activityType === 'Submission') {
    return Icons.Quill
  }
  if (activityType === 'Poll') {
    return Icons.Poll
  }
  if (activityType === 'Quiz') {
    return Icons.QuizActivity
  }
  if (activityType === 'Content') {
    return Icons.Book
  }
  if (activityType === 'PeerReview') {
    return Icons.PeerReviewActivity
  }
  if (activityType === 'FeedbackReflection') {
    return Icons.FeedbackReflectionActivity
  }
  if (activityType === 'TeacherReview') {
    return Icons.TeacherReviewActivity
  }
  if (activityType === 'GradePassback') {
    return Icons.GradePassback
  }
  if (activityType === 'SelfReview') {
    return Icons.SelfReview
  }
  if (activityType === 'Video') {
    return Icons.Play
  }
  if (activityType === 'SelectTag') {
    return Icons.TagOutlined
  }
  if (activityType === 'Discussion') {
    return Icons.Discussion
  }
  if (activityType === 'GroupFormation') {
    return Icons.Groups
  }
  if (activityType === 'GroupMemberReview') {
    return Icons.GroupMemberReview
  }
  if (activityType === 'Zoom') {
    return Icons.ZoomMeeting
  }
  if (activityType === 'GoogleMeet') {
    return Icons.GoogleMeet
  }
  if (activityType === 'MicrosoftTeams') {
    return Icons.MicrosoftTeams
  }
  if (activityType === 'TurnItIn') {
    return Icons.TurnItIn
  }
  if (activityType === 'HapYak') {
    return Icons.Hapyak
  }
  if (activityType === 'Embed') {
    return Icons.Embed
  }
  if (activityType === 'InviteTA') {
    return Icons.TeachingAssistant
  }
  if (activityType === 'Scoring') {
    return Icons.Scoring
  }
  if (activityType === 'Certificate') {
    return Icons.Certificate
  }
  if (activityType === 'Checklist') {
    return Icons.Checklist
  }
  if (activityType === 'Form') {
    return Icons.FormActivity
  }
  if (activityType === 'Scorm') {
    return Icons.Scorm
  }
  assertUnreachable(activityType)
}

function hasActivitySettings(
  activity:
    | ActivityIcon_activity
    | ActivityIcon_publicActivity
    | ActivityIcon_teacherActivity,
): activity is ActivityIcon_activity {
  return activity.hasOwnProperty('settings')
}

const ActivityIcon = ({
  activity,
  size = 16,
  noCustom,
  style,
  className,
  ...iconProps
}: IProps) => {
  const StandardIcon = getActivityIcon(activity.activityType)

  const customEmbedActivityTypeIcon =
    hasActivitySettings(activity) &&
    activity.settings?.customEmbedActivityType?.activityIcon

  return (
    <span
      className={classNames(styles.activityIcon, className)}
      style={{ ...style, width: size, height: size }}
    >
      {activity.icon && !noCustom ? (
        <CustomIcon
          icon={activity.icon}
          size={size}
          fallback={() => <StandardIcon {...iconProps} />}
        />
      ) : customEmbedActivityTypeIcon ? (
        <CustomIcon
          icon={customEmbedActivityTypeIcon}
          size={size}
          fallback={() => <StandardIcon {...iconProps} />}
        />
      ) : (
        <StandardIcon {...iconProps} />
      )}
    </span>
  )
}

export default (
  props: { activity: ActivityIcon_activity$key } & IActivityIconProps,
) => {
  const activity = useFragment<ActivityIcon_activity$key>(
    graphql`
      fragment ActivityIcon_activity on Activity {
        activityType
        icon
        ... on EmbedActivity {
          settings {
            customEmbedActivityType {
              activityIcon
            }
          }
        }
      }
    `,
    props.activity,
  )
  return <ActivityIcon {...props} activity={activity} />
}

export const PublicActivityIcon = (
  props: {
    activity: ActivityIcon_publicActivity$key
  } & IActivityIconProps,
) => {
  const activity = useFragment<ActivityIcon_publicActivity$key>(
    graphql`
      fragment ActivityIcon_publicActivity on PublicActivity {
        activityType
        icon
      }
    `,
    props.activity,
  )
  return <ActivityIcon {...props} activity={activity} />
}

export const TeacherActivityIcon = (
  props: {
    activity: ActivityIcon_teacherActivity$key
  } & IActivityIconProps,
) => {
  const activity = useFragment(
    graphql`
      fragment ActivityIcon_teacherActivity on TeacherActivity {
        activityType
        icon
      }
    `,
    props.activity,
  )
  return <ActivityIcon {...props} activity={activity} />
}
