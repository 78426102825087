import React, { createContext, useState } from 'react'
import { useCurrentRoute } from 'react-navi'

export interface IMobileContextValue {
  isShowingContent: boolean
  isShowingHomeButton: boolean
  setIsShowingContent: (value: boolean) => void
  setIsShowingHomeButton: (value: boolean) => void
}

export const MobileContext = createContext<IMobileContextValue>({
  isShowingContent: false,
  isShowingHomeButton: false,
  setIsShowingContent: () => undefined,
  setIsShowingHomeButton: () => undefined,
})

export const MobileProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    url: { pathname },
  } = useCurrentRoute()
  const [isShowingContent, setIsShowingContent] = useState(true)
  const [isShowingHomeButton, setIsShowingHomeButton] = useState(false)

  React.useEffect(() => {
    // The root path should always show the sidebar on first navigation
    if (['', '/'].includes(pathname)) {
      setIsShowingContent(false)
    }
  }, [pathname])

  React.useEffect(() => {
    // The following routes show the home button
    if (['/course-library', '/edit-course-library'].includes(pathname)) {
      setIsShowingHomeButton(true)
    }
  }, [pathname])

  return (
    <MobileContext.Provider
      value={{
        isShowingContent,
        isShowingHomeButton,
        setIsShowingContent,
        setIsShowingHomeButton,
      }}
    >
      {children}
    </MobileContext.Provider>
  )
}
