import { graphql } from 'react-relay'

import {
  CommitScormCmiInput,
  commitScormCmiMutation,
} from '__generated__/commitScormCmiMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default ({ activityId, cmi }: CommitScormCmiInput) =>
  mutationPromise<commitScormCmiMutation>({
    mutation: graphql`
      mutation commitScormCmiMutation($input: CommitScormCmiInput!) {
        commitScormCmi(input: $input) {
          activity {
            course {
              ...fragments_CourseStatus
            }
            ... on ScormActivity {
              cmi
            }
          }
        }
      }
    `,
    variables: { input: { activityId, cmi } },
  })
