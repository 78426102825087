import React from 'react'

import { Icons } from 'components/Icons'
import Modal from 'components/Modal/Modal'
import { IModalProps } from 'components/Modal/ModalProvider'

import styles from './ConfirmationModal.module.scss'

export interface IConfirmationModalProps extends IModalProps {
  title: string | JSX.Element
  message: string | JSX.Element
  cancelText?: string
  hasCancelButton?: boolean
  confirmBtnStyle?: 'confirm' | 'anger' | 'happy' | 'cancel' | 'subtle'
  confirmText?: string
  onConfirm?: () => any
  onCancel?: () => any
  shouldShowCloseIcon?: boolean
  danger?: boolean
  Icon?: React.ReactNode
}

export const ConfirmationModal = ({
  hide,
  message,
  title,
  hasCancelButton = true,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmBtnStyle = 'confirm',
  onConfirm,
  onCancel,
  shouldShowCloseIcon,
  danger,
  Icon,
}: IConfirmationModalProps) => {
  const handleCancel = () => {
    hide()
    if (onCancel) {
      onCancel()
    }
  }
  const handleConfirm = () => {
    hide()
    if (onConfirm) {
      onConfirm()
    }
  }

  let buttons = (
    <Modal.Button
      buttonStyle={danger ? 'anger' : confirmBtnStyle}
      onClick={handleConfirm}
      data-test="modal-confirm-btn-confirm"
    >
      {confirmText}
    </Modal.Button>
  )

  if (hasCancelButton) {
    buttons = (
      <>
        <Modal.Button
          buttonStyle="cancel"
          onClick={handleCancel}
          data-test="modal-confirm-btn-cancel"
        >
          {cancelText}
        </Modal.Button>
        {buttons}
      </>
    )
  }

  return (
    <Modal.Container
      style={danger ? { borderTop: '4px red solid' } : { borderTop: 'none' }}
      data-test="modal-confirm"
      id="confirmation-modal"
      onClose={shouldShowCloseIcon ? hide : undefined}
    >
      {danger ? (
        <div className={styles.iconContainer}>
          <span className={styles.iconWrapperDanger}>
            <Icons.Bell large />
          </span>
        </div>
      ) : (
        <span className={styles.confirmationIconContainer}>
          {Icon || <Icons.InfoAlternative xLarge accentColor />}
        </span>
      )}
      {React.isValidElement(title) ? (
        title
      ) : (
        <h1 className={styles.confirmationHeading}>{title}</h1>
      )}
      {React.isValidElement(message) ? (
        message
      ) : (
        <p
          className={
            danger ? styles.centeredConfirmationMessage : styles.confirmationMessage
          }
        >
          {message}
        </p>
      )}
      <Modal.ButtonContainer>{buttons}</Modal.ButtonContainer>
    </Modal.Container>
  )
}

export default ConfirmationModal
