/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type courseRoutesQueryVariables = {
    courseId: string;
};
export type courseRoutesQueryResponse = {
    readonly me: {
        readonly isImpersonating: boolean | null;
    } | null;
    readonly course: {
        readonly id: string;
        readonly isViewerTeacher: boolean | null;
        readonly isViewerAssistant: boolean | null;
        readonly title: string;
    } | null;
};
export type courseRoutesQuery = {
    readonly response: courseRoutesQueryResponse;
    readonly variables: courseRoutesQueryVariables;
};



/*
query courseRoutesQuery(
  $courseId: ID!
) {
  me {
    isImpersonating
    id
  }
  course(id: $courseId) {
    id
    isViewerTeacher
    isViewerAssistant
    title
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isImpersonating",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "courseId"
    }
  ],
  "concreteType": "Course",
  "kind": "LinkedField",
  "name": "course",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerTeacher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerAssistant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "courseRoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "courseRoutesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "845a5a8f50376669856f59d00f7dbc88",
    "id": null,
    "metadata": {},
    "name": "courseRoutesQuery",
    "operationKind": "query",
    "text": "query courseRoutesQuery(\n  $courseId: ID!\n) {\n  me {\n    isImpersonating\n    id\n  }\n  course(id: $courseId) {\n    id\n    isViewerTeacher\n    isViewerAssistant\n    title\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c4244f2a32e2be76e592526ff9b7ded5';
export default node;
