import { Emoji } from 'emoji-mart'
import React from 'react'

import { IIconProps } from 'components/Icons'

import { getCustomEmojiUrl, isCustomEmoji } from './utils'

interface Props extends IIconProps {
  icon: string
  fallback: () => React.Component | JSX.Element
  size: number
}

const CustomIcon = ({ icon, size, fallback }: Props) => {
  if (!icon.startsWith('emoji:')) {
    return <img src={icon} width={size} height={size} alt={'activity icon'} />
  }

  if (isCustomEmoji(icon)) {
    return (
      <img
        src={getCustomEmojiUrl(icon)}
        width={size}
        height={size}
        alt={'activity icon'}
      />
    )
  }

  return (
    <Emoji native emoji={icon.replace('emoji:', '')} size={size} fallback={fallback} />
  )
}

export default CustomIcon
