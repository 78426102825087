import React, { createContext, useState } from 'react'

export interface IEditingContextValue {
  editingActivityId: string
  setEditingActivityId: (value: string) => void
}

export const EditingContext = createContext<IEditingContextValue>({
  editingActivityId: '',
  setEditingActivityId: () => undefined,
})

export const EditingProvider = ({ children }: { children: React.ReactNode }) => {
  const [editingActivityId, setEditingActivityId] = useState('')
  return (
    <EditingContext.Provider
      value={{
        editingActivityId,
        setEditingActivityId,
      }}
    >
      {children}
    </EditingContext.Provider>
  )
}
