import { GQLActivityType } from 'types/graphqlTypes'

export const ReviewTypes = [
  'PeerReview',
  'TeacherReview',
  'SelfReview',
  'GroupMemberReview',
]
export const ReflectionTypes = ['FeedbackReflection']

const requiresSource = (activity: {
  activityType: GQLActivityType
  source: object | null
}) =>
  ['PeerReview', 'FeedbackReflection', 'GroupMemberReview', 'TurnItIn'].includes(
    activity.activityType,
  ) && !activity.source

const requiresTags = (activity: {
  activityType: GQLActivityType
  settings?: {
    selectableTags?: {
      length: number
    }
  }
}) =>
  activity.activityType === 'SelectTag' &&
  activity.settings &&
  activity.settings.selectableTags &&
  activity.settings.selectableTags.length === 0

const requiresInputs = (activity: {
  activityType: GQLActivityType
  inputs?: readonly {
    readonly isValid: boolean
  }[]
}) => {
  return (
    activity.activityType === 'Scoring' &&
    (activity?.inputs?.length === 0 ||
      activity?.inputs?.some((input) => !input.isValid))
  )
}

const requiresInheritorsOrSource = (activity: {
  activityType: GQLActivityType
  isBeingUsedInCourse?: boolean
}) => {
  return activity.activityType === 'GroupFormation' && !activity.isBeingUsedInCourse
}

export default {
  requiresSource,
  requiresTags,
  requiresInputs,
  requiresInheritorsOrSource,
}
