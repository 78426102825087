import * as Sentry from '@sentry/browser'
import React, { useEffect } from 'react'
import { useNavigation } from 'react-navi'
import { useFragment, graphql } from 'react-relay'

import {
  UserTracking_user,
  UserTracking_user$key,
} from '__generated__/UserTracking_user.graphql'
import { fromGlobalId } from 'utils/relay'
import tracking from 'utils/tracking'

import { UserContext } from 'components/UserContext'

type User = NonNullable<UserTracking_user>

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    profitwell: any
    FS: any
  }
}

const userFragment = graphql`
  fragment UserTracking_user on User {
    id
    name
    email
    username
    isTeacher
    isOwner
  }
`

// IMPORTANT: This component works properly during login because we're doing
// a full page refresh, hence a remount of this component.
// Otherwise, we'd have to call trackingIdentify manually after login.
export const UserTracking = () => {
  const user = React.useContext(UserContext)
  const me = useFragment<UserTracking_user$key>(userFragment, user.me)
  const navigation = useNavigation()

  const setSentryUser = (user: User) => {
    if (!user) {
      return
    }
    const { id } = fromGlobalId(user.id)
    if (window.eduflow.constants.WEBPACK_BUNDLE_VERSION) {
      Sentry.setTag('bundle_id', window.eduflow.constants.WEBPACK_BUNDLE_VERSION)
    }
    Sentry.setTag(
      'sentry_react_profiler_enabled',
      window.eduflow.constants.SENTRY_REACT_PROFILER_ENABLED,
    )
    Sentry.configureScope((scope: any) => {
      scope.setUser({
        id,
      })
    })
  }

  const setEduflowTracking = (user: User) => {
    if (!window.eduflow.hasRouteTrackingSubscription && user) {
      // prevent binding multiple subscriptions
      window.eduflow.hasRouteTrackingSubscription = true

      navigation.subscribe((route) => {
        if (route.type === 'ready') {
          tracking.trackPageView(route.url.pathname, route.data) // eslint-disable-line @typescript-eslint/no-floating-promises
        }
      })
    }
  }

  const setProfitWellUser = (user: User) => {
    if (
      window.profitwell &&
      window.eduflow.constants.PROFITWELL_ENABLED &&
      user?.isOwner
    ) {
      window.profitwell('start', {
        user_email: user.email,
      })
    }
  }

  const startTracking = async () => {
    if (me) {
      setSentryUser(me)
      setEduflowTracking(me)
      setProfitWellUser(me)
    }
  }

  useEffect(() => {
    if (!me) {
      return
    }

    startTracking() // eslint-disable-line @typescript-eslint/no-floating-promises
  }, [me])

  return null
}

export default UserTracking
