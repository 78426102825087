/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ImpersonationUserSelector_me = {
    readonly id: string;
    readonly name: string;
    readonly badge: {
        readonly " $fragmentRefs": FragmentRefs<"UserBadge_badge">;
    };
    readonly impersonationCourseId: string | null;
    readonly " $refType": "ImpersonationUserSelector_me";
};
export type ImpersonationUserSelector_me$data = ImpersonationUserSelector_me;
export type ImpersonationUserSelector_me$key = {
    readonly " $data"?: ImpersonationUserSelector_me$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ImpersonationUserSelector_me">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImpersonationUserSelector_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserBadge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserBadge_badge"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "impersonationCourseId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'c1edaa0e3ffa05c62e10215b181ccf50';
export default node;
