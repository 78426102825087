import classNames from 'classnames'
import Downshift, {
  GetItemPropsOptions,
  PropGetters,
  StateChangeOptions,
} from 'downshift'
import React, { useRef, useState } from 'react'

import { escapeRegexCharacters } from 'utils/functions'

import { Tooltip } from 'components/Tooltip'

import styles from './Dropdown.module.scss'

export type OptionValues = string | number | boolean
export type Options<TValue = OptionValues> = IOption<TValue>[]

export interface IOption<TValue = OptionValues> {
  label?: string
  value?: TValue
  clearableValue?: boolean
  disabled?: boolean
  tooltipText?: string
  options?: Options<TValue>
  [property: string]: any
}

type OnChangeHandler<TValue = OptionValues> = (newValue: IOption<TValue> | null) => void
export type CustomRendererHandler<TValue = OptionValues> = (
  option: IOption<TValue>,
  index?: number,
) => JSX.Element | null

interface IProps<TValue> {
  disabled?: boolean
  className?: string
  placeholder?: string
  fixedWidth?: boolean
  fullWidth?: boolean
  secondaryStyle?: boolean
  itemPropsRenderer?: (arg0: GetItemPropsOptions<TValue>, arg1: IOption<TValue>) => void
  optionRenderer?: CustomRendererHandler<TValue>
  valueRenderer?: CustomRendererHandler<TValue>
  placeholderRenderer?: (placeholder: string) => JSX.Element | null
  options: Options<TValue>
  isSearchable?: boolean
  input: {
    value: TValue | null
    onChange: (value: TValue) => void
  }
  name?: string
  menuClassname?: string
  controlled?: boolean
  handleOpenStateChange?: (isOpen: boolean) => void
  itemClassName?: string
  selectedItemClassName?: string
  openOnTop?: boolean
  id?: string
  tabIndex?: number
}

const getSelectedItem = <TValue extends {} = string>(
  hasOptionGroups: boolean,
  options: Options<TValue>,
  value: TValue | null,
) => {
  let item
  if (hasOptionGroups) {
    item = options.find((i) => i.options?.find((o) => o.value === value))
    item = item?.options?.find((o) => o.value === value)
  } else {
    item = options.find((o) => o.value === value)
  }
  return item
}

const Dropdown = <TValue extends {} = string>({
  disabled,
  options,
  controlled,
  className = '',
  placeholder = 'Click to select',
  itemPropsRenderer,
  optionRenderer,
  valueRenderer,
  placeholderRenderer,
  fixedWidth,
  fullWidth,
  secondaryStyle,
  isSearchable,
  input,
  name,
  menuClassname,
  handleOpenStateChange,
  itemClassName,
  selectedItemClassName,
  openOnTop,
  tabIndex,
  id,
}: IProps<TValue>) => {
  const [_options, setOptions] = useState<Options<TValue>>(options)
  const hasOptionGroups = options.every((o) => o.options)
  const inputEl = useRef<HTMLInputElement>(null)

  const initialSelectedItem = getSelectedItem<TValue>(
    hasOptionGroups,
    _options,
    input.value,
  )

  const [selectedItem, setSelectedItem] = useState<IOption<TValue> | undefined>(
    controlled ? initialSelectedItem : undefined,
  )

  // The following 2 lines are intended to circum navigate an issue with downshift in the case of
  // controlled dropdowns (where we manage state ourselves) that have dynamic 'options' and the
  // 'options' prop can change between renders. An example use case: selecting a value from Dropdown A
  // changes the available options for Dropdown B which in turn has no selected value by default.
  // In this case if 'selectedItem' remains 'undefined' downshift will complain and break. Setting
  // 'selectedItem' to 'null' in these cases fixes the issue.
  React.useEffect(() => setOptions(options), [options])
  const _selectedItem = controlled ? selectedItem || null : selectedItem

  React.useEffect(() => {
    if (controlled) {
      setSelectedItem(getSelectedItem<TValue>(hasOptionGroups, _options, input.value))
    }
  }, [input.value])

  const onChange: OnChangeHandler<TValue> = (newItem: IOption<TValue> | null) => {
    if (newItem === null) {
      return
    }

    setOptions(options)

    if (newItem.value !== undefined) {
      input.onChange(newItem.value)

      if (inputEl && inputEl.current) {
        inputEl.current.blur()
      }
    }
  }

  const handleStateChange = (changes: StateChangeOptions<IOption<TValue>> | null) => {
    if (changes === null) {
      return null
    }

    if (handleOpenStateChange && changes?.isOpen !== undefined) {
      handleOpenStateChange(changes.isOpen)
    }

    if (changes.inputValue === null || changes.inputValue === undefined) {
      return null
    }

    if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
      return null
    }

    if (
      controlled &&
      (changes.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem ||
        changes.type === Downshift.stateChangeTypes.blurButton)
    ) {
      return null
    }

    const searchTerm = escapeRegexCharacters(changes.inputValue.trim())
    if (searchTerm === '' || changes.isOpen === false) {
      return setOptions(options)
    }

    const regex = new RegExp(searchTerm, 'i')
    const testLabel = (o: IOption<TValue>) => o.label && regex.test(o.label)

    let filtered
    if (hasOptionGroups) {
      filtered = options
        .map((group) => ({
          label: group.label,
          options: group.options ? group.options.filter(testLabel) : [],
        }))
        .filter((group) => group.options.length > 0)
    } else {
      filtered = options.filter(testLabel)
    }

    if (!filtered.length) {
      return setOptions([{ label: 'No results found', disabled: true }])
    }

    return setOptions(filtered)
  }

  const renderListItem = (
    item: IOption<TValue>,
    index: number,
    getItemProps: PropGetters<any>['getItemProps'],
    isSelected?: boolean,
  ) => {
    const itemProps = itemPropsRenderer
      ? itemPropsRenderer(getItemProps({ disabled: item.disabled, item, index }), item)
      : getItemProps({ disabled: item.disabled, item, index })

    return (
      <Tooltip text={item.tooltipText || undefined} placement="left" key={index}>
        <li
          className={classNames(styles.selectOption, itemClassName, {
            [selectedItemClassName || '']: isSelected,
            [styles.isDisabled]: itemProps.disabled,
          })}
          {...itemProps}
        >
          {optionRenderer ? optionRenderer(item) : item.label}
        </li>
      </Tooltip>
    )
  }

  const renderToggleButtonAndValue = (
    getInputProps: PropGetters<TValue>['getInputProps'],
    getToggleButtonProps: PropGetters<TValue>['getToggleButtonProps'],
    selectedItem: IOption<TValue> | null,
    openMenu: (cb?: () => void) => void,
    isOpen: boolean,
  ) => {
    let value
    if (selectedItem) {
      value = valueRenderer ? valueRenderer(selectedItem) : selectedItem.label
    } else {
      value = placeholderRenderer ? placeholderRenderer(placeholder) : placeholder
    }

    if (isSearchable && isOpen) {
      return (
        <div className={styles.selectButton}>
          <input
            className={styles.searchInput}
            tabIndex={tabIndex}
            disabled={disabled}
            autoFocus
            ref={inputEl}
            {...getInputProps({
              onFocus: openMenu,
              placeholder,
            })}
          />
          <button
            {...getToggleButtonProps()}
            name={name}
            disabled={disabled}
            className={styles.searchButton}
            tabIndex={0}
            data-test="dropdown-btn"
          >
            <span className={styles.selectArrowZone}>
              <span className={styles.selectArrow} />
            </span>
          </button>
        </div>
      )
    } else {
      return (
        <button
          {...getToggleButtonProps()}
          name={name}
          className={classNames(styles.selectButton, {
            [styles.openOnTop]: openOnTop,
          })}
          disabled={disabled}
          tabIndex={tabIndex || 0}
          data-test="dropdown-btn"
        >
          <div
            className={styles.selectButtonWrap}
            aria-label="selected option"
            role="textbox"
            aria-readonly
          >
            <span className={styles.selectText}>{value}</span>
            <span className={styles.selectArrowZone}>
              <span className={styles.selectArrow} />
            </span>
          </div>
        </button>
      )
    }
  }

  const itemToString = (item: IOption<TValue> | null) =>
    item && item.label ? item.label : ''

  return (
    <Downshift
      itemToString={itemToString}
      initialSelectedItem={initialSelectedItem}
      selectedItem={_selectedItem}
      onChange={onChange}
      onStateChange={handleStateChange}
      id={id}
    >
      {({
        getMenuProps,
        selectedItem,
        getToggleButtonProps,
        getItemProps,
        getInputProps,
        isOpen,
        openMenu,
      }) => (
        <div
          aria-label="options"
          className={classNames(className, styles.selectMenuOuter, {
            [styles.fixedWidth]: fixedWidth,
            [styles.fullWidth]: fullWidth,
            [styles.isOpen]: isOpen,
            [styles.isDisabled]: disabled,
            [styles.secondaryStyle]: secondaryStyle,
            [styles.hasOptionGroups]: hasOptionGroups,
          })}
        >
          {renderToggleButtonAndValue(
            getInputProps,
            getToggleButtonProps,
            selectedItem,
            openMenu,
            isOpen,
          )}
          {isOpen && (
            <ul
              {...getMenuProps()}
              className={classNames(styles.selectMenu, menuClassname, {
                [styles.openOnTop]: openOnTop,
              })}
            >
              {hasOptionGroups ? (
                _options.reduce(
                  (result, section, sectionIndex) => {
                    result.sections.push(
                      <div key={sectionIndex}>
                        <div
                          className={classNames(styles.optionGroupLabel, {
                            [styles.isSectionLabelHidden]: section.hideLabel,
                          })}
                        >
                          {section.label}
                        </div>
                        {section.options &&
                          section.options.map((opt) => {
                            const index = result.itemIndex++
                            const isSelected = selectedItem === opt
                            return renderListItem(opt, index, getItemProps, isSelected)
                          })}
                      </div>,
                    )
                    return result
                  },
                  { sections: [], itemIndex: 0 },
                ).sections
              ) : (
                <>
                  {_options.map((option, idx) =>
                    renderListItem(option, idx, getItemProps),
                  )}
                </>
              )}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  )
}

export default Dropdown
