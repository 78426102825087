/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SidebarHero_course = {
    readonly id: string;
    readonly isViewerTeacher: boolean | null;
    readonly isViewerAssistant: boolean | null;
    readonly isArchived: boolean | null;
    readonly thumbnail: string | null;
    readonly title: string;
    readonly " $fragmentRefs": FragmentRefs<"CourseContextMenu_course" | "CourseThumbnailPicker_course">;
    readonly " $refType": "SidebarHero_course";
};
export type SidebarHero_course$data = SidebarHero_course;
export type SidebarHero_course$key = {
    readonly " $data"?: SidebarHero_course$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SidebarHero_course">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarHero_course",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerTeacher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerAssistant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseContextMenu_course"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseThumbnailPicker_course"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
(node as any).hash = '7303a2410e41728ba698f6c8c3eb4638';
export default node;
