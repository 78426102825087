import classNames from 'classnames'
import { NaviRequest, Resolvable } from 'navi'
import React from 'react'
import { View } from 'react-navi'
import { loadQuery } from 'react-relay'

import SidebarCourseQuery, {
  SidebarCourseQuery as SidebarCourseQueryType,
} from '__generated__/SidebarCourseQuery.graphql'
import environment from 'relay/environment'
import { toGlobalId } from 'utils/relay'

import { Button } from 'components/Button'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { ErrorMessageWithHomeButton } from 'components/ErrorMessage/ErrorMessage'
import { Icons } from 'components/Icons'
import { IMobileContextValue, MobileContext } from 'components/MobileContext'
import { RulesContext, RulesProvider } from 'components/RulesSlideIn'
import { SidebarCollapseContext } from 'components/SidebarCollapseContext'
import { Tooltip } from 'components/Tooltip'

import Sidebar from './Sidebar/Sidebar'

import styles from './LayoutView.module.scss'

const LayoutView: React.VFC<NaviRequest> = ({ params: { courseId } }) => {
  const sidebarQueryRef = loadQuery<SidebarCourseQueryType>(
    environment,
    SidebarCourseQuery,
    { courseId: toGlobalId('Course', courseId) },
    // We use the store-or-network fetch policy here so that we don't refetch the sidebar on every
    // route change (e.g. from one activity to another).
    // We counter the staleness of the sidebar data by calling loadQuery again in useEffect in the
    // Sidebar component so that on rerender we make sure we become consistent under the hood.
    { fetchPolicy: 'store-or-network' },
  )
  const { isShowingRules } = React.useContext(RulesContext)

  return (
    <SidebarCollapseContext.Consumer>
      {({ isCollapsed, collapseSidebar, isResizingSidebar }) => {
        const sidebarContainerStyles = classNames(styles.sidebarContainer, {
          [styles.collapsed]: isCollapsed,
        })

        // For displaying the correct meta/ctrl key in the tooltip
        // From MDN docs: This is not the most reliable way of detecting
        // the OS. But if a user were to spoof their user agent, the tooltip likely
        // won't matter to them.
        const isMac = /mac/i.test(navigator.userAgent)
        const shortcutInstruction = isMac ? '(⌘ + Shift + E)' : '(Ctrl + Shift + E)'
        const collapseTooltipText = (
          <>
            <p>Close sidebar</p>
            <p>{shortcutInstruction}</p>
          </>
        )

        return (
          <MobileContext.Consumer>
            {({ isShowingContent }: IMobileContextValue) => {
              const viewStyles = classNames(styles.view, {
                [styles.show]: isShowingContent,
                [styles.sidebarResizing]: isResizingSidebar,
                [styles.sidebarCollapsed]: isCollapsed,
                [styles.rulesVisible]: isShowingRules,
              })
              const collapseButtonStyles = classNames(styles.collapseButton, {
                [styles.collapsed]: isCollapsed,
              })
              return (
                <div className={styles.layout}>
                  <div className={sidebarContainerStyles}>
                    <Sidebar queryRef={sidebarQueryRef} />
                    <div className={styles.CollapseButtonHitArea}>
                      <Tooltip
                        text={collapseTooltipText}
                        placement="bottomLeft"
                        align={{ targetOffset: ['-4px', '-8px'] }}
                        mouseLeaveDelay={0}
                      >
                        <Button
                          Icon
                          Small
                          className={collapseButtonStyles}
                          onClick={collapseSidebar}
                        >
                          <Icons.DoubleChevronsLeft />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <section id="layout-section" className={viewStyles} role="main">
                    <View />
                  </section>
                </div>
              )
            }}
          </MobileContext.Consumer>
        )
      }}
    </SidebarCollapseContext.Consumer>
  )
}

const WrappedLayoutView: Resolvable<JSX.Element> = (props) => (
  <ErrorBoundary errorMessage={<ErrorMessageWithHomeButton />}>
    <RulesProvider>
      <LayoutView {...props} />
    </RulesProvider>
  </ErrorBoundary>
)

export default WrappedLayoutView
