/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type MoveFlowInput = {
    flowId: string;
    flowIdBefore: string;
    force?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type SidebarMutationsMoveFlowMutationVariables = {
    input: MoveFlowInput;
};
export type SidebarMutationsMoveFlowMutationResponse = {
    readonly moveFlow: {
        readonly course: {
            readonly id: string;
            readonly flows: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly ruleGroupsVisibility: ReadonlyArray<{
                    readonly prerequisites: ReadonlyArray<{
                        readonly id: string;
                    }>;
                    readonly courseData: {
                        readonly id: string;
                    } | null;
                }> | null;
                readonly selectableDataRuleOutputActivities: ReadonlyArray<{
                    readonly id: string;
                }> | null;
                readonly activities: ReadonlyArray<{
                    readonly id: string;
                    readonly activityType: ActivityType;
                    readonly title: string;
                    readonly url: string;
                    readonly ruleGroupsVisibility: ReadonlyArray<{
                        readonly prerequisites?: ReadonlyArray<{
                            readonly id: string;
                        }> | undefined;
                        readonly courseData?: {
                            readonly id: string;
                        } | null | undefined;
                    }>;
                    readonly ruleGroupsCompletion: ReadonlyArray<{
                        readonly prerequisites?: ReadonlyArray<{
                            readonly id: string;
                        }> | undefined;
                        readonly courseData?: {
                            readonly id: string;
                        } | null | undefined;
                    }>;
                    readonly selectableOutputActivities: ReadonlyArray<{
                        readonly id: string;
                    }> | null;
                    readonly selectableSourceActivities: ReadonlyArray<{
                        readonly id: string;
                    }> | null;
                    readonly selectableDataRuleOutputActivities: ReadonlyArray<{
                        readonly id: string;
                    }> | null;
                }>;
            }>;
        };
    } | null;
};
export type SidebarMutationsMoveFlowMutation = {
    readonly response: SidebarMutationsMoveFlowMutationResponse;
    readonly variables: SidebarMutationsMoveFlowMutationVariables;
};



/*
mutation SidebarMutationsMoveFlowMutation(
  $input: MoveFlowInput!
) {
  moveFlow(input: $input) {
    course {
      id
      flows {
        id
        title
        ruleGroupsVisibility {
          prerequisites {
            __typename
            id
          }
          courseData {
            __typename
            id
          }
          id
        }
        selectableDataRuleOutputActivities {
          __typename
          id
        }
        activities {
          __typename
          id
          activityType
          title
          url
          ruleGroupsVisibility {
            __typename
            ... on RuleGroup {
              prerequisites {
                __typename
                id
              }
              courseData {
                __typename
                id
              }
            }
            ... on Node {
              __isNode: __typename
              id
            }
          }
          ruleGroupsCompletion {
            __typename
            ... on RuleGroup {
              prerequisites {
                __typename
                id
              }
              courseData {
                __typename
                id
              }
            }
            ... on Node {
              __isNode: __typename
              id
            }
          }
          selectableOutputActivities {
            __typename
            id
          }
          selectableSourceActivities {
            __typename
            id
          }
          selectableDataRuleOutputActivities {
            __typename
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "prerequisites",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "courseData",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "selectableDataRuleOutputActivities",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = [
  {
    "kind": "InlineFragment",
    "selections": (v5/*: any*/),
    "type": "RuleGroup",
    "abstractKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = [
  (v10/*: any*/),
  (v2/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "prerequisites",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "courseData",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "selectableDataRuleOutputActivities",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v15 = [
  (v10/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "type": "RuleGroup",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": (v4/*: any*/),
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SidebarMutationsMoveFlowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MoveFlowPayload",
        "kind": "LinkedField",
        "name": "moveFlow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RuleGroup",
                    "kind": "LinkedField",
                    "name": "ruleGroupsVisibility",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsVisibility",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsCompletion",
                        "plural": true,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "selectableOutputActivities",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "selectableSourceActivities",
                        "plural": true,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SidebarMutationsMoveFlowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MoveFlowPayload",
        "kind": "LinkedField",
        "name": "moveFlow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RuleGroup",
                    "kind": "LinkedField",
                    "name": "ruleGroupsVisibility",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v3/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsVisibility",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsCompletion",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "selectableOutputActivities",
                        "plural": true,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "selectableSourceActivities",
                        "plural": true,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56458c6b5c088541755a607aaa77898e",
    "id": null,
    "metadata": {},
    "name": "SidebarMutationsMoveFlowMutation",
    "operationKind": "mutation",
    "text": "mutation SidebarMutationsMoveFlowMutation(\n  $input: MoveFlowInput!\n) {\n  moveFlow(input: $input) {\n    course {\n      id\n      flows {\n        id\n        title\n        ruleGroupsVisibility {\n          prerequisites {\n            __typename\n            id\n          }\n          courseData {\n            __typename\n            id\n          }\n          id\n        }\n        selectableDataRuleOutputActivities {\n          __typename\n          id\n        }\n        activities {\n          __typename\n          id\n          activityType\n          title\n          url\n          ruleGroupsVisibility {\n            __typename\n            ... on RuleGroup {\n              prerequisites {\n                __typename\n                id\n              }\n              courseData {\n                __typename\n                id\n              }\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          ruleGroupsCompletion {\n            __typename\n            ... on RuleGroup {\n              prerequisites {\n                __typename\n                id\n              }\n              courseData {\n                __typename\n                id\n              }\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          selectableOutputActivities {\n            __typename\n            id\n          }\n          selectableSourceActivities {\n            __typename\n            id\n          }\n          selectableDataRuleOutputActivities {\n            __typename\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '108bc065c2504b43bef51ab1d9943340';
export default node;
