import { graphql } from 'react-relay'

import {
  UpdateCourseTitleInput,
  updateCourseTitleMutation,
} from '__generated__/updateCourseTitleMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: UpdateCourseTitleInput) =>
  mutationPromise<updateCourseTitleMutation>({
    mutation: graphql`
      mutation updateCourseTitleMutation($input: UpdateCourseTitleInput!) {
        updateCourseTitle(input: $input) {
          course {
            title
          }
        }
      }
    `,
    variables: { input },
  })
