import classNames from 'classnames'
import React from 'react'

import { isDataURI } from 'utils/functions'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Input } from 'components/Input'
import { Tooltip } from 'components/Tooltip'

import styles from '../ImagePicker.module.scss'

const LinkPicker = ({
  image,
  submitting,
  submitImage,
  className,
}: {
  image: string | null
  submitting: boolean
  submitImage: (url?: string) => void
  className?: string
}) => {
  const [link, setLink] = React.useState(image || '')
  const [hasError, setHasError] = React.useState(false)

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false)
    setLink(value)
  }

  const handleSubmit = () => {
    if (isDataURI(link)) return setHasError(true)

    const img = new Image()
    img.onload = () => {
      submitImage(link)
    }
    img.onerror = () => setHasError(true)
    img.src = link
  }

  React.useEffect(() => setLink(image || ''), [image])

  return (
    <div className={classNames(styles.linkPicker, className)}>
      <label htmlFor="link-input" className={styles.title}>
        Image URL
      </label>
      <div className={styles.subtitle}>
        Paste a URL from the internet pointing to an image file (png, jpg, gif)
      </div>
      {hasError && (
        <div className={styles.linkError}>The link must be to an image file</div>
      )}
      <div className={styles.form}>
        <div className={styles.input}>
          <Input
            autoFocus
            placeholder="Image URL"
            aria-label="image"
            onChange={handleChange}
            id="link-input"
            name="image"
            value={link}
          />
        </div>
        <Tooltip text="Save">
          <Button Icon AccentColor Disabled={submitting} onClick={handleSubmit}>
            <Icons.Checkmark white />
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default LinkPicker
