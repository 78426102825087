import classNames from 'classnames'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import FocusLock from 'react-focus-lock'
import TetherComponent from 'react-tether'

import { Tooltip } from 'components/Tooltip'

import styles from './ContextMenu.module.scss'

interface ITetherAttachment {
  top: string
  left: string
}
interface IUpdateEventData {
  attachment: ITetherAttachment
  targetAttachment: ITetherAttachment
}

interface ITetherComponentProps {
  renderTarget?: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode
  renderElement?: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode
  renderElementTag?: string
  renderElementTo?: Element | string
  attachment: string
  className?: string
  id?: string
  style?: React.CSSProperties
  onUpdate?: (data: IUpdateEventData) => void
  onRepositioned?: () => void
  target?: HTMLElement | string
}

// TODO: Use generic types for the components. It seems that it won't currently work because
// of the use of forwardRef in Menu [0].
//
// [0] <https://stackoverflow.com/questions/51884498/using-react-forwardref-with-typescript-generic-jsx-arguments>
export interface IItem {
  [key: string]: any
}

interface IMenuProps {
  items: IItem[]
  renderItem: (item: IItem, key: number, hide?: () => void) => JSX.Element | null
  hide: () => void
  Header?: React.ComponentType<{}>
  EmptyListPlaceholder?: React.ComponentType<{}>
  Footer?: JSX.Element
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  style?: React.CSSProperties
  dataTest?: string
  className?: string
  noAnimation?: boolean
  onContainerClick?: (event: React.SyntheticEvent) => void
}

const Menu = React.forwardRef<HTMLDivElement, IMenuProps>((props, ref) => {
  const { Header, EmptyListPlaceholder, Footer, items, onContainerClick } = props
  return (
    <div
      id="context-menu"
      data-test={props.dataTest}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      ref={ref}
      className={classNames(styles.wrapper, {
        [styles.noAnimation]: props.noAnimation,
      })}
    >
      <div
        className={`${styles.listContainer} ${props.className || ''}`}
        onClick={onContainerClick}
      >
        <ul role="menu">
          {Header && <Header />}
          {EmptyListPlaceholder && items.length === 0 && <EmptyListPlaceholder />}
          {items && items.length > 0 && (
            <div className={styles.list}>
              {items.map((item, i) => props.renderItem(item, i, props.hide))}
            </div>
          )}
          {Footer && <div className={styles.menuFooter}>{Footer}</div>}
        </ul>
      </div>
    </div>
  )
})

export interface IMenuItemProps {
  item: IItem
  onClick: React.MouseEventHandler<HTMLButtonElement>
  containerProps?: object
}

export const MenuItem = React.forwardRef<HTMLLIElement, IMenuItemProps>(
  ({ item, onClick, containerProps }, ref) => {
    const listItemClassNames = classNames(styles.listItem, item.className, {
      [styles.isHeading]: item.isHeading,
    })
    const listItemButtonClassNames = classNames({
      [styles.isDisabled]: item.disabled,
      [styles.isClickable]: item.clickable,
      [styles.isActive]: item.active,
    })

    const btnProps: { 'data-test'?: string } = {}
    if (item.dataTest) {
      btnProps['data-test'] = item.dataTest
    }

    const onClickDisabled = (
      e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>,
    ) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const extraContainerProps: {
      onClick?: React.MouseEventHandler<HTMLLIElement>
    } = item.disabled && !item.clickable ? { onClick: onClickDisabled } : {}

    return (
      <Tooltip text={item.tooltipText} placement={item.tooltipPlacement}>
        <li
          role="menuitem"
          ref={ref}
          className={listItemClassNames}
          {...(item.id ? { id: item.id } : {})}
          {...containerProps}
          {...extraContainerProps}
        >
          {item.isHeading ? (
            item.label
          ) : (
            <button
              className={listItemButtonClassNames}
              onClick={item.disabled && !item.clickable ? onClickDisabled : onClick}
              type="button"
              {...btnProps}
            >
              {item.label}
            </button>
          )}
        </li>
      </Tooltip>
    )
  },
)

export interface ILabelProps {
  isOpen: boolean
  onClick: React.MouseEventHandler
}

export type IItemRenderer = (
  item: any,
  key: number,
  hide?: () => void,
) => JSX.Element | null

export type RenderLabelType = (
  ref: React.RefObject<HTMLElement>,
  props: {
    isOpen: boolean
    onClick: () => void
    disabled?: boolean
    loading?: boolean
    onMouseLeave: () => void
    onMouseEnter: () => void
    dataTest?: string
  },
) => React.ReactNode

export type RenderElementType = (
  ref: React.RefObject<HTMLElement>,
  opts: { closeMenu: () => void },
) => React.ReactNode

export interface IContextMenuProps {
  alignLeftMobile?: boolean
  attachment?: string
  targetAttachment?: string
  targetOffset?: string
  items?: IItem[]
  renderLabel: RenderLabelType
  className?: string
  dataTest?: string
  Header?: React.ComponentType<{}>
  EmptyListPlaceholder?: React.ComponentType<{}>
  Footer?: JSX.Element
  openOnHover?: boolean
  itemRenderer?: IItemRenderer
  tetherClassName?: string
  menuClassName?: string
  renderElement?: RenderElementType
  isOpen?: boolean
  onHide?: () => void
  zIndex?: number
  hideOnItemClick?: boolean
  labelDisabled?: boolean
  labelLoading?: boolean
  focusLockProps?: Partial<React.ComponentProps<typeof FocusLock>>
  ref?: React.RefObject<any>
  target?: HTMLElement | string
  noAnimation?: boolean
  targetModifier?: 'scroll-handle' | 'visible'
  onContainerClick?: (event: React.SyntheticEvent) => void
}

interface IState {
  shouldShowMenu: boolean
  mouseOverMenu: boolean
}

export const TETHER_CONTAINER_ID = '#tether-element-container'

class ContextMenu extends React.Component<IContextMenuProps, IState> {
  private containerRef: React.RefObject<HTMLElement>
  private menuRef: React.RefObject<HTMLElement>
  private tetherRef: React.RefObject<HTMLElement>
  private tetherContainer: Element | undefined

  constructor(props: IContextMenuProps) {
    super(props)
    this.state = {
      mouseOverMenu: false,
      shouldShowMenu: false,
    }

    this.menuRef = React.createRef()
    this.containerRef = React.createRef()
    this.tetherRef = this.props.ref ?? React.createRef<HTMLElement>()
    this.tetherContainer = document.querySelector(TETHER_CONTAINER_ID) || undefined
    document.addEventListener('mousedown', this.handleClickOutside, false)
    document.addEventListener('keydown', this.handleClickEsc, false)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
    document.removeEventListener('keydown', this.handleClickEsc, false)
  }

  static getDerivedStateFromProps(props: IContextMenuProps, state: IState) {
    if (typeof props.isOpen === 'boolean' && state.shouldShowMenu != props.isOpen) {
      return {
        ...state,
        shouldShowMenu: props.isOpen,
      }
    }
    return null
  }

  public componentDidUpdate(_: IContextMenuProps, prevState: IState) {
    if (
      !prevState.shouldShowMenu &&
      this.state.shouldShowMenu &&
      this.menuRef.current
    ) {
      this.menuRef.current.focus()
    }
  }

  private toggleShowMenu = () => {
    if (this.state.shouldShowMenu && this.props.onHide) {
      this.props.onHide()
    }
    this.setState((prevState) => ({ shouldShowMenu: !prevState.shouldShowMenu }))
  }

  private hideMenu = () => {
    this.setState({ shouldShowMenu: false })
    if (this.props.onHide) {
      this.props.onHide()
    }
  }

  private handleOnMouseOut = () => {
    setTimeout(() => {
      if (!this.state.mouseOverMenu) {
        this.setState({ shouldShowMenu: false })
      }
    }, 100)
  }

  private handleMenuMouseOver = () => {
    this.setState({ mouseOverMenu: true })
  }

  private handleMenuMouseOut = () => {
    this.setState({
      mouseOverMenu: false,
      shouldShowMenu: false,
    })
  }

  private hasChildMenu = () => {
    const menuNode = this.menuRef.current
    const tetherMenus = Array.from(this.tetherContainer?.children || [])
    const menuIndex = tetherMenus.findIndex((c) => c.contains(menuNode))
    return menuIndex < 0 ? false : tetherMenus.length - 1 > menuIndex
  }

  private handleClickOutside = (e: MouseEvent) => {
    const { target } = e as any
    const menuNode = this.menuRef.current
    const contNode = this.containerRef.current
    const isOutside = !menuNode?.contains(target) && !contNode?.contains(target)
    if (isOutside && !this.hasChildMenu()) {
      this.hideMenu()
    }
  }

  private handleClickEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && !this.hasChildMenu()) {
      this.hideMenu()
    }
  }

  private itemRenderer = (item: IItem, i: number, hide?: () => void) => {
    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      const { hideOnItemClick = true } = this.props
      if (item.action) {
        item.action(event)
      }
      if (hide && hideOnItemClick && !item.showMenuOnItemClick) {
        hide()
      }
    }

    return <MenuItem key={i} item={item} onClick={handleOnClick} />
  }

  public render() {
    const {
      alignLeftMobile,
      attachment = 'top right',
      className = '',
      dataTest,
      EmptyListPlaceholder,
      Header,
      items,
      renderLabel,
      Footer,
      openOnHover,
      itemRenderer = this.itemRenderer,
      menuClassName = '',
      targetAttachment = 'bottom right',
      tetherClassName = '',
      zIndex = 12,
      focusLockProps,
      target,
      targetOffset = '0 0',
      targetModifier = 'visible',
      onContainerClick,
    } = this.props

    const renderTarget = (ref: React.RefObject<HTMLElement>) => {
      this.containerRef = ref
      return renderLabel(ref, {
        disabled: this.props.labelDisabled,
        isOpen: this.props.isOpen || this.state.shouldShowMenu,
        loading: this.props.labelLoading,
        onClick: this.toggleShowMenu,
        onMouseLeave: this.handleOnMouseOut,
        onMouseEnter: this.toggleShowMenu,
        dataTest,
      })
    }

    const renderElement = (ref: React.RefObject<HTMLElement>) => {
      this.menuRef = ref

      if (this.props.renderElement) {
        if (this.props.isOpen || this.state.shouldShowMenu) {
          return (
            <FocusLock returnFocus {...focusLockProps}>
              <div
                className={classNames(styles.wrapper, {
                  [styles.noAnimation]: this.props.noAnimation,
                })}
              >
                {this.props.renderElement(ref, {
                  closeMenu: () => this.setState({ shouldShowMenu: false }),
                })}
              </div>
            </FocusLock>
          )
        }
        return null
      }

      if (!items) {
        if (!this.props.labelLoading) {
          console.error('if renderElement is not passed, items must be passed')
        }

        return null
      }

      return this.props.isOpen || this.state.shouldShowMenu ? (
        <FocusLock returnFocus {...focusLockProps}>
          <Menu
            ref={ref as React.RefObject<HTMLDivElement>}
            dataTest={`${dataTest}-menu`}
            Header={Header}
            EmptyListPlaceholder={EmptyListPlaceholder}
            Footer={Footer}
            items={items}
            renderItem={itemRenderer}
            hide={this.hideMenu}
            onMouseEnter={openOnHover ? this.handleMenuMouseOver : undefined}
            onMouseLeave={openOnHover ? this.handleMenuMouseOut : undefined}
            className={menuClassName}
            noAnimation={this.props.noAnimation}
            onContainerClick={onContainerClick}
          />
        </FocusLock>
      ) : null
    }

    const tetherOptions = {
      attachment,
      targetOffset,
      constraints: [
        {
          attachment: 'together',
          pin: true,
          to: 'window',
        },
      ],
      // Work around a Chrome bug which makes the context menu blurry
      optimizations: { gpu: false },
      targetAttachment,
      targetModifier,
    }

    if (isMobileOnly && alignLeftMobile) {
      tetherOptions.attachment = 'top left'
      tetherOptions.targetAttachment = 'bottom left'
    }

    // react-tether's prop types seem to require passing "element" and "target",
    // even though they're not really required. We can remove this once and just
    // use TetherComponent once the types are fixed.
    const Tether =
      TetherComponent as unknown as React.ComponentClass<ITetherComponentProps>

    const el = (
      <Tether
        ref={(tether) => {
          ;(this.tetherRef as React.MutableRefObject<any>).current = tether
        }}
        className={tetherClassName}
        style={{ zIndex }}
        renderTarget={renderTarget}
        renderElement={renderElement}
        renderElementTo={this.tetherContainer}
        {...(target ? { target } : {})}
        {...tetherOptions}
      />
    )

    return className ? <div className={className}>{el}</div> : el
  }
}

export default ContextMenu
