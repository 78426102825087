import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { IReduxState } from 'models/redux'

const rootReducer = combineReducers<IReduxState>({
  form: formReducer,
})

export default rootReducer
