/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteActivityInput = {
    activityId: string;
    force?: boolean | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type deleteActivityMutationVariables = {
    input: DeleteActivityInput;
};
export type deleteActivityMutationResponse = {
    readonly deleteActivity: {
        readonly clientMutationId: string | null;
        readonly course: {
            readonly flows: ReadonlyArray<{
                readonly activities: ReadonlyArray<{
                    readonly id: string;
                    readonly ruleGroupsCompletion: ReadonlyArray<{
                        readonly __typename: string;
                    }>;
                    readonly ruleGroupsVisibility: ReadonlyArray<{
                        readonly __typename: string;
                    }>;
                }>;
            }>;
        } | null;
    } | null;
};
export type deleteActivityMutation = {
    readonly response: deleteActivityMutationResponse;
    readonly variables: deleteActivityMutationVariables;
};



/*
mutation deleteActivityMutation(
  $input: DeleteActivityInput!
) {
  deleteActivity(input: $input) {
    clientMutationId
    course {
      flows {
        activities {
          __typename
          id
          ruleGroupsCompletion {
            __typename
            ... on Node {
              __isNode: __typename
              id
            }
          }
          ruleGroupsVisibility {
            __typename
            ... on Node {
              __isNode: __typename
              id
            }
          }
        }
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteActivityPayload",
        "kind": "LinkedField",
        "name": "deleteActivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsCompletion",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsVisibility",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteActivityPayload",
        "kind": "LinkedField",
        "name": "deleteActivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flows",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "activities",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsCompletion",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "ruleGroupsVisibility",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c51e36e641f666ef8433521d2fe0e6e",
    "id": null,
    "metadata": {},
    "name": "deleteActivityMutation",
    "operationKind": "mutation",
    "text": "mutation deleteActivityMutation(\n  $input: DeleteActivityInput!\n) {\n  deleteActivity(input: $input) {\n    clientMutationId\n    course {\n      flows {\n        activities {\n          __typename\n          id\n          ruleGroupsCompletion {\n            __typename\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          ruleGroupsVisibility {\n            __typename\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b9893ddbbbd412b5ea55cafb676d9098';
export default node;
