/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type ActivityIcon_activity = {
    readonly activityType: ActivityType;
    readonly icon: string | null;
    readonly settings?: {
        readonly customEmbedActivityType: {
            readonly activityIcon: string;
        } | null;
    } | undefined;
    readonly " $refType": "ActivityIcon_activity";
};
export type ActivityIcon_activity$data = ActivityIcon_activity;
export type ActivityIcon_activity$key = {
    readonly " $data"?: ActivityIcon_activity$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActivityIcon_activity">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityIcon_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmbedSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomEmbedActivityType",
              "kind": "LinkedField",
              "name": "customEmbedActivityType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "activityIcon",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EmbedActivity",
      "abstractKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
(node as any).hash = 'ffd7b8e72acf684a248902963729b11c';
export default node;
