import classNames from 'classnames/bind'
import React from 'react'

import styles from './NavBar.module.scss'

const cx = classNames.bind(styles)

interface IProps<TValue> {
  items: { label: string; value: TValue }[]
  activeTab: TValue
  tabClassName?: string
  onClick: (value: TValue) => void
  center?: boolean
}

function NavBar<TValue extends { toString(): string } = string>({
  items,
  activeTab,
  onClick,
  center,
  tabClassName,
}: IProps<TValue>) {
  const list = items.map(({ label, value }) => {
    const styleName = cx('nav-item', tabClassName, {
      active: value === activeTab,
    })

    const handleClick = () => onClick(value)
    return (
      <button
        key={value.toString()}
        onClick={handleClick}
        className={styleName}
        tabIndex={0}
      >
        {label}
      </button>
    )
  })

  const className = center ? 'tc' : ''

  return <ul className={`${styles.navBar} ${className}`}>{list}</ul>
}

export default NavBar
