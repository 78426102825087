import { graphql } from 'react-relay'

import {
  UpdateCourseThumbnailInput,
  updateCourseThumbnailMutation,
} from '__generated__/updateCourseThumbnailMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: UpdateCourseThumbnailInput) =>
  mutationPromise<updateCourseThumbnailMutation>({
    mutation: graphql`
      mutation updateCourseThumbnailMutation($input: UpdateCourseThumbnailInput!) {
        updateCourseThumbnail(input: $input) {
          course {
            id
            thumbnail
          }
        }
      }
    `,
    variables: { input },
    optimisticResponse: {
      updateCourseThumbnail: {
        course: {
          id: input.courseId,
          thumbnail: input.thumbnail,
        },
      },
    },
  })
