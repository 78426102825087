import classNames from 'classnames/bind'
import queryString from 'query-string'
import React, { useContext, useState } from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'
import { useFragment, graphql } from 'react-relay'

import { SidebarCourseQueryResponse } from '__generated__/SidebarCourseQuery.graphql'
import {
  ActivityStudentStatus,
  SidebarTree_course,
  SidebarTree_course$key,
} from '__generated__/SidebarTree_course.graphql'
import { GQLActivityPosition } from 'types/graphqlTypes'
import { fromGlobalId } from 'utils/relay'

import { Icons } from 'components/Icons'
import {
  InsertAttributes,
  INSERT_POS_BOTTOM,
  INSERT_POS_TOP,
  useInsertActivity,
} from 'components/InsertActivity/InsertActivityContext'
import { ModalContext } from 'components/Modal/ModalProvider'
import {
  IRenderItemParams,
  ITreeData,
  ITreeDestinationPosition,
  ITreeItem,
  ITreeProps,
  ITreeSourcePosition,
  Tree,
} from 'components/Tree'
import {
  ConfirmationModal,
  IConfirmationModalProps,
} from 'components/modals/ConfirmationModal'
import { DangerModal, IDangerModalProps } from 'components/modals/DangerModal'

import ActivityTitle from './ActivityTitle'
import FlowTitle from './FlowTitle'
import { moveActivity, moveFlow } from './SidebarMutations'

import styles from './Sidebar.module.scss'

type Flow = SidebarTree_course['flows'][number]
type Activity = Flow['activities'][number]

interface IProps {
  course: SidebarCourseQueryResponse['course']
  sidebarMounted: boolean
}

const cx = classNames.bind(styles)

export const SIDEBAR_CONTAINER_ID = 'sidebar-container'

const renderItem = ({
  item,
  itemClass,
  emptyItemClass,
  isDragging,
  handleToggleFlowCollapsed,
  isCollapsed,
  wasCollapsed,
}: IRenderItemParams) => {
  const data: {
    flow?: Flow
    activity?: Activity & { status: ActivityStudentStatus }
    isClassActivity?: boolean
    isPlaceholder?: boolean
    isTeacher?: boolean
    isAssistant?: boolean
    sidebarMounted: boolean
    isGhostActivity?: boolean
  } = item.data

  const { insertAttributes } = useInsertActivity()

  if (data.isPlaceholder) {
    return (
      <div
        className={cx('emptyFlowItem', emptyItemClass, { showDragHandle: false })}
        tabIndex={-1}
      >
        <span>The flow is empty</span>
      </div>
    )
  }

  if (data.isGhostActivity) {
    return (
      <div
        className={cx('ghostActivity', 'emptyFlowItem', {
          showDragHandle: false,
          indented: !!insertAttributes?.flowId,
        })}
        tabIndex={-1}
      >
        Adding here
      </div>
    )
  }

  let itemContent = null
  let itemId: string = ''

  if (!data.activity) {
    if (!data.flow) {
      throw new Error('No activity and no placeholder and no flow')
    }
    itemContent = (
      <FlowTitle
        flow={data.flow}
        isTeacher={!!data.isTeacher}
        isAssistant={!!data.isAssistant}
        isDragging={isDragging}
        handleToggleFlowCollapsed={handleToggleFlowCollapsed}
        isCollapsed={!!isCollapsed}
      />
    )
    itemId = data.flow.id
  } else {
    // This is an ugly hack. We need to fetch the status from
    // course.ownProgress instead of activity.status (which could be
    // done inside the fragment in ActivityTitle). This is because
    // when a completion state changes (for example, student submits),
    // we need to refetch *all* states in the course, which is done
    // via ownProgress.
    const { status, ...activity } = data.activity
    itemContent = (
      <ActivityTitle
        activity={activity}
        status={status}
        isClassActivity={item.data.isClassActivity}
        isDraggable={data.isTeacher || false}
        isTeacher={!!data.isTeacher}
        isAssistant={!!data.isAssistant}
        isDragging={isDragging}
        sidebarMounted={data.sidebarMounted}
        wasCollapsed={wasCollapsed}
      />
    )
    itemId = activity.id
  }

  itemId = fromGlobalId(itemId).id
  const { data: routeData } = useCurrentRoute()
  const routeId = routeData.activityId || routeData.flowId
  const scrollRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (scrollRef?.current && itemId === routeId) {
      setTimeout(() => {
        // **hack**
        // The timeout is required **only** when scrolling to activities - not flows.
        // It seems the scroll is triggered before the element is rendered. My guess is
        // this is due to some weird interaction with `react-beautiful-dnd` and our
        // collapsible flows. Adding a slight delay before scrolling makes it more likely
        // that the element is rendered before `scrollTo` is called.
        const container = document.getElementById('sidebar-container')
        const containerHeight = container?.offsetHeight ?? 0
        const elY = scrollRef.current!.getBoundingClientRect().y
        // `addNewBtnHeight` is equal to:
        // `$add-new-section-height` - `.addNewContainer.padding`
        // defined in `Sidebar.module.scss`
        const addNewBtnHeight = 54
        let top = elY - containerHeight
        if (data.isTeacher) {
          top += addNewBtnHeight
        }
        container?.scrollTo({
          behavior: 'smooth',
          top,
        })
      }, 800)
    }
  }, [])

  return (
    <div
      ref={scrollRef}
      className={cx('listItem', itemClass, {
        classActivity: data.isClassActivity,
        flowActivity: !data.isClassActivity && !!data.activity,
        flowItem: !data.activity,
        isDragging,
      })}
    >
      {itemContent}
    </div>
  )
}

function addFlow(
  flow: Flow,
  statusMap: { [id: string]: ActivityStudentStatus },
  isTeacher: boolean,
  isAssistant: boolean,
  sidebarMounted: boolean,
  hasGhostActivity = false,
  insertPosition?: GQLActivityPosition,
): ITreeItem {
  let children
  if (flow.activities.length === 0 && !flow.isClassActivity) {
    children = [
      {
        children: [],
        data: { isAssistant, isPlaceholder: true, isTeacher },
        id: `${flow.id}-placeholder`,
        isPlaceholder: true,
      },
    ]
  } else {
    children = flow.activities.map((activity) => ({
      children: [],
      data: {
        activity: {
          ...activity,
          status: statusMap[activity.id],
        },
        isAssistant,
        isClassActivity: flow.isClassActivity,
        isTeacher,
        sidebarMounted,
      },
      id: activity.id,
    }))
  }

  if (hasGhostActivity) {
    const ghostActivity = {
      children: [],
      data: { isAssistant, isGhostActivity: true, isTeacher },
      id: `${flow.id}-indicator`,
      isGhostActivity: true,
    }
    if (insertPosition === INSERT_POS_TOP) {
      children = [ghostActivity, ...children]
    } else if (insertPosition === INSERT_POS_BOTTOM) {
      children = [...children, ghostActivity]
    }
  }

  return {
    children,
    data: { flow, isAssistant, isTeacher },
    id: flow.id,
    isNode: flow.isClassActivity,
  }
}

const initializeTree = (
  course: SidebarTree_course,
  sidebarMounted: boolean,
  flowIdForInsert: string | null,
  activityInsertPosition: GQLActivityPosition,
  isCreating = false,
) => {
  const statusMap = course.ownProgress
    ? course.ownProgress.reduce((acc, { activity: { id: activityId }, status }) => {
        acc[activityId] = status
        return acc
      }, {} as { [id: string]: ActivityStudentStatus })
    : {}

  const lastFlowIdInCourse = course.flows[course.flows.length - 1].id

  const shouldShowGhostActivity = (flowId: string) =>
    isCreating &&
    (flowIdForInsert ? flowIdForInsert === flowId : flowId === lastFlowIdInCourse)

  const head = addFlow(
    course.flows[0],
    statusMap,
    !!course.isViewerTeacher,
    !!course.isViewerAssistant,
    sidebarMounted,
    shouldShowGhostActivity(course.flows[0].id),
    flowIdForInsert ? activityInsertPosition : INSERT_POS_BOTTOM,
  )

  const children = course.flows
    .filter(({ isClassActivity }) => !isClassActivity)
    .map((flow) =>
      addFlow(
        flow,
        statusMap,
        !!course.isViewerTeacher,
        !!course.isViewerAssistant,
        sidebarMounted,
        shouldShowGhostActivity(flow.id),
        flowIdForInsert ? activityInsertPosition : INSERT_POS_BOTTOM,
      ),
    )
  const tree: ITreeData = {
    head,
    root: {
      children,
      data: { title: 'root' },
      id: course.id,
      isNode: false,
    },
  }

  return tree
}

const move = (
  course: SidebarTree_course,
  source: ITreeSourcePosition,
  destination?: ITreeDestinationPosition,
  options: { force: boolean } = { force: false },
) => {
  if (destination && destination.index !== undefined && isNaN(destination.index)) {
    // destination.index can be NaN sometimes. We don't know what to
    // do in such cases, so just do nothing.
    return
  }

  const { type: sourceParentType } = fromGlobalId(source.parentId)
  const getParentIndex = (position: ITreeSourcePosition) => {
    const index = course.flows.findIndex(({ id }) => id === position.parentId)
    if (index === -1) {
      throw new Error(`Flow with id ${position.parentId} not found`)
    }
    return index
  }

  const flowStartIndex =
    sourceParentType === 'Course' ? source.index : getParentIndex(source)

  if (sourceParentType === 'Course') {
    // Moving a flow

    if (!destination || destination.index === undefined) {
      // Moving to bottom, so it should become the last flow.
      return moveFlow(course, flowStartIndex, course.flows.length - 1, options)
    }

    if (fromGlobalId(destination.parentId).type === 'Flow') {
      if (!course.flows[source.index].isClassActivity) {
        // Cannot move flow into another flow, unless it's a course activity
        return
      }
      // Moving from a course activity to a flow
      return moveActivity(
        course,
        0,
        destination.index,
        flowStartIndex,
        getParentIndex(destination as ITreeSourcePosition),
        options,
      )
    }
    return moveFlow(course, source.index, destination.index, options)
  }

  // Moving an activity from a flow (not a course activity)

  if (!destination || destination.index === undefined) {
    // Moving to the bottom, so should become a course activity at the bottom.
    return moveActivity(
      course,
      source.index,
      0,
      flowStartIndex,
      course.flows.length,
      options,
    )
  }

  // Moving from a flow to a flow (which might be the same flow)
  return moveActivity(
    course,
    source.index,
    destination.index,
    flowStartIndex,
    getParentIndex(destination as ITreeSourcePosition),
    options,
  )
}

const fragment = graphql`
  fragment SidebarTree_course on Course {
    id
    isViewerTeacher
    isViewerAssistant
    title
    ownProgress {
      activity {
        __typename
        id
      }
      status
    }
    flows {
      id
      title
      isClassActivity
      ...FlowTitle_flow
      activities {
        __typename
        id
        activityType
        title
        url
        ...ActivityTitle_activity
      }
    }
  }
`

const SidebarTree = ({ course: courseFragment, sidebarMounted }: IProps) => {
  const course = useFragment<SidebarTree_course$key>(fragment, courseFragment)
  const { showModal } = useContext(ModalContext)
  const [isMoving, setIsMoving] = useState(false)
  const navigation = useNavigation()
  const route = useCurrentRoute()

  // Check if we are creating an activity and therefore should show the indicator
  // of where the activity will be added (the ghost activity)
  const isCreating = route.url.pathname.endsWith('create')

  if (!course || course.flows.length === 0) {
    if (isCreating) {
      return (
        <div className={cx('ghostActivity', 'emptyFlowItem')} tabIndex={-1}>
          Adding here
        </div>
      )
    }

    return (
      <div className={styles.emptyState}>
        <Icons.PlusCircle large blue />
        <h2>Add flows & activities to your course</h2>
        <p>
          Your course looks a little empty. Let’s get started by adding some activities
          that your learners can interact with by clicking the button below.
        </p>
      </div>
    )
  }

  const { insertAttributes } = useInsertActivity()
  const { flowId, position: activityInsertPosition } =
    insertAttributes || ({} as InsertAttributes)

  const tree = initializeTree(
    course,
    sidebarMounted,
    flowId,
    activityInsertPosition,
    isCreating,
  )

  const onDragEnd: ITreeProps['onDragEnd'] = async (end: any) => {
    setIsMoving(true)
    const source: ITreeSourcePosition = {
      index: end.source.index,
      parentId: end.source.droppableId,
    }
    const destination: ITreeDestinationPosition = {
      index: end.destination ? end.destination.index : end.source.index,
      parentId: end.destination ? end.destination.droppableId : end.source.droppableId,
    }
    try {
      const response = await move(course, source, destination)
      if (response && 'moveActivity' in response && response.moveActivity) {
        const { id, url } = response.moveActivity.activity
        const {
          data: { activityId },
          url: { query },
        } = route
        const qs = queryString.stringify(query)
        if (fromGlobalId(id).id === activityId) {
          await navigation.navigate(qs ? `${url}?${qs}` : url)
        }
      }
    } catch (e) {
      const error = JSON.parse(e.message)
      const { type } = error
      if (type === 'InvalidPrerequisites') {
        showModal<IConfirmationModalProps>(ConfirmationModal, {
          message: (
            <div className={styles.modalContent}>
              <p>
                Moving this activity will remove one or more prerequisites. Invalid
                prerequisites will be removed.
              </p>
            </div>
          ),
          onConfirm: () => move(course, source, destination, { force: true }),
          title: 'Prerequisites will be removed',
        })
      } else if (type === 'InvalidCourseDataRules') {
        showModal<IConfirmationModalProps>(ConfirmationModal, {
          message: (
            <div className={styles.modalContent}>
              <p>
                Moving this activity will remove one or more course data rules. Invalid
                course data rules will be removed.
              </p>
            </div>
          ),
          onConfirm: () => move(course, source, destination, { force: true }),
          title: 'Course data rules will be removed',
        })
      } else if (type === 'InvalidRules') {
        showModal<IConfirmationModalProps>(ConfirmationModal, {
          message: (
            <div className={styles.modalContent}>
              <p>
                Moving this activity will remove one or more rules. Invalid rules will
                be removed.
              </p>
            </div>
          ),
          onConfirm: () => move(course, source, destination, { force: true }),
          title: 'Invalid rules will be removed',
        })
      } else if (type === 'InvalidSource') {
        showModal<IDangerModalProps>(DangerModal, {
          confirmText: 'Move',
          message: (
            <div className={styles.modalContent}>
              <p>
                Moving this activity will remove the source field. That means this
                activity will become invalid. Are you sure you want to proceed?
              </p>
            </div>
          ),
          onConfirm: () => move(course, source, destination, { force: true }),
          title: 'Moving disrupts other activities',
        })
      } else if (type === 'InvalidInheritGroupsFrom') {
        showModal<IDangerModalProps>(DangerModal, {
          confirmText: 'Move',
          message: (
            <div className={styles.modalContent}>
              <p>
                Moving this activity to the desired position will remove the group
                settings. Are you sure you want to proceed?
              </p>
            </div>
          ),
          onConfirm: () => move(course, source, destination, { force: true }),
          title: 'Moving disrupts other activities',
        })
      } else if (type === 'InvalidInheritors') {
        showModal<IDangerModalProps>(DangerModal, {
          confirmText: 'Move',
          message: (
            <div className={styles.modalContent}>
              <p>
                Moving this activity to the desired position will remove the group
                settings in <strong>{error.message.split(':')[1]}</strong>. Are you sure
                you want to proceed?
              </p>
            </div>
          ),
          onConfirm: () => move(course, source, destination, { force: true }),
          title: 'Moving disrupts other activities',
        })
      } else if (type === 'ActivityHasFeedback') {
        showModal<IConfirmationModalProps>(ConfirmationModal, {
          confirmText: 'Understood',
          danger: true,
          hasCancelButton: false,
          message: (
            <div className={styles.modalContent}>
              <div>
                <p>Removing sources is not possible after feedback has been given.</p>
                <br />
                <p>
                  You cannot move the {error.activityOrFlow} to this position because it
                  will break a review activity source link{' '}
                  {error.activityOrFlow === 'activity' ? (
                    <>
                      between <strong>{error.title}</strong> and{' '}
                      <strong>{error.sourceTitle}</strong>.
                    </>
                  ) : (
                    <>
                      in <strong>{error.sourceTitle}</strong>.
                    </>
                  )}
                </p>
              </div>
            </div>
          ),
          title: `The ${error.activityOrFlow} cannot be moved to this position`,
        })
      } else if (type === 'ActivityHasSubmission') {
        showModal<IConfirmationModalProps>(ConfirmationModal, {
          confirmText: 'Understood',
          danger: true,
          hasCancelButton: false,
          message: (
            <div className={styles.modalContent}>
              <div>
                <p>
                  Removing group settings is not possible after submission has begun.
                </p>
                <br />
                <p>
                  You cannot move the {error.activityOrFlow} to this position. It will
                  invalidate existing group submissions due to the link{' '}
                  {error.activityOrFlow === 'activity' ? (
                    <>
                      between <strong>{error.groupFormationTitle}</strong> and{' '}
                      <strong>{error.inheritorTitles}</strong>.
                    </>
                  ) : (
                    <>
                      in <strong>{error.inheritorTitles}</strong>.
                    </>
                  )}
                </p>
              </div>
            </div>
          ),
          title: `The ${error.activityOrFlow} cannot be moved to this position`,
        })
      } else if (type === 'InvalidScoringInput') {
        showModal<IConfirmationModalProps>(ConfirmationModal, {
          confirmText: 'Understood',
          danger: true,
          hasCancelButton: false,
          message: (
            <div className={styles.modalContent}>
              <p>
                You cannot move the activity to this position because it will break the
                link to one or more of the selected score inputs.
              </p>
            </div>
          ),
          title: 'The activity cannot be moved to this position',
        })
      }
    }
    setIsMoving(false)
  }
  return (
    <div className={styles.listContainer} id={SIDEBAR_CONTAINER_ID}>
      <Tree
        tree={tree}
        renderItem={renderItem}
        onDragEnd={onDragEnd}
        isDragEnabled={
          !!course.isViewerTeacher && !course.isViewerAssistant && !isMoving
        }
      />
    </div>
  )
}
export default SidebarTree
