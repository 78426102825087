import React from 'react'
import { Link } from 'react-navi'
import { graphql } from 'react-relay'

import { CreateCourseButtons_me } from '__generated__/CreateCourseButtons_me.graphql'
import withFragmentContainer from 'relay/withFragmentContainer'

import { Icons } from 'components/Icons'

import styles from './TemplateLibrary.module.scss'

interface IProps {
  me: CreateCourseButtons_me | null
}

const CreateCourseButtons = ({ me }: IProps) => {
  if (!me) {
    return null
  }
  return (
    <div className={styles.LoggedInLinks}>
      <a
        target="_blank"
        href="https://eduflow.typeform.com/to/Ep4HFLJd"
        rel="noreferrer"
      >
        <Icons.Lightbulb accentColor style={{ marginRight: '0.5rem' }} />
        Suggest a template
      </a>
      <Link href="/create-course">
        <Icons.Plus accentColor style={{ marginRight: '0.5rem' }} />
        Create empty course
      </Link>
      <Link href="/copy-course">
        <Icons.Union accentColor style={{ marginRight: '0.5rem' }} />
        Copy existing course
      </Link>
    </div>
  )
}

export default withFragmentContainer<IProps>({
  me: graphql`
    fragment CreateCourseButtons_me on User {
      id
    }
  `,
})(CreateCourseButtons)
