import { useContext, useEffect } from 'react'

import commitScormCmi from 'mutations/commitScormCmi'
import { toGlobalId } from 'utils/relay'

import { ToastContext } from 'components/NotificationCenter'

export default ({ children }: { children: JSX.Element }) => {
  const { displayToast } = useContext(ToastContext)

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleMessage = async (event: MessageEvent) => {
    if (
      event?.data?.source?.startsWith('react-') ||
      event?.data?.source?.startsWith('relay-') ||
      event?.data?.type?.startsWith('webpack')
    ) {
      return
    }

    if (event.origin !== window.eduflow.constants.SCORM_HOST_URL) {
      console.error(`[ScormWindowMessaging] Unexpected message origin: ${event.origin}`)
      return
    }

    const message = event.data

    if (message.type === 'commit-scorm-cmi') {
      const { activityId, cmi } = message
      try {
        JSON.parse(cmi)
      } catch (e) {
        console.error('[ScormWindowMessaging] cmi is not a valid JSON:', cmi)
        return
      }

      try {
        await commitScormCmi({
          activityId: toGlobalId('ScormActivity', activityId),
          cmi,
        })
      } catch (e) {
        displayToast('Could not save SCORM activity progress or data', 'error')
      }
    }
  }

  return children
}
