/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TemplateLayoutQueryVariables = {};
export type TemplateLayoutQueryResponse = {
    readonly me: {
        readonly " $fragmentRefs": FragmentRefs<"TemplateCategoriesSidebar_me">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"TemplateCategoriesSidebar_query">;
};
export type TemplateLayoutQuery = {
    readonly response: TemplateLayoutQueryResponse;
    readonly variables: TemplateLayoutQueryVariables;
};



/*
query TemplateLayoutQuery {
  me {
    ...TemplateCategoriesSidebar_me
    id
  }
  ...TemplateCategoriesSidebar_query
}

fragment CreateCourseButtons_me on User {
  id
}

fragment TemplateCategoriesSidebar_me on User {
  ...CreateCourseButtons_me
}

fragment TemplateCategoriesSidebar_query on Query {
  templateCategories {
    id
    title
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TemplateCategoriesSidebar_me"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TemplateCategoriesSidebar_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TemplateLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TemplateCategory",
        "kind": "LinkedField",
        "name": "templateCategories",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "511f82c703acacb2dc53438e633d4374",
    "id": null,
    "metadata": {},
    "name": "TemplateLayoutQuery",
    "operationKind": "query",
    "text": "query TemplateLayoutQuery {\n  me {\n    ...TemplateCategoriesSidebar_me\n    id\n  }\n  ...TemplateCategoriesSidebar_query\n}\n\nfragment CreateCourseButtons_me on User {\n  id\n}\n\nfragment TemplateCategoriesSidebar_me on User {\n  ...CreateCourseButtons_me\n}\n\nfragment TemplateCategoriesSidebar_query on Query {\n  templateCategories {\n    id\n    title\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = '7a36d62c315ae2d2e750114919ea5b65';
export default node;
