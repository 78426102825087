import { graphql } from 'react-relay'

import {
  DeleteCourseInput,
  deleteCourseMutation,
} from '__generated__/deleteCourseMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: DeleteCourseInput) =>
  mutationPromise<deleteCourseMutation>({
    mutation: graphql`
      mutation deleteCourseMutation($input: DeleteCourseInput!) {
        deleteCourse(input: $input) {
          institution {
            id
          }
          me {
            hasCourses
            institution {
              courseLibrary {
                sections {
                  ...CourseLibrarySection_section
                }
              }
            }
          }
        }
      }
    `,
    variables: { input },
  })
