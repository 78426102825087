const focusFirstEmoji = () => {
  const firstEmoji: any = document.querySelector(
    '.emoji-mart .emoji-mart-emoji:first-child',
  )
  firstEmoji?.focus()
}

const focusNextEmoji = (startElement: any, after: number) => {
  let element = startElement
  for (let i = 0; i < after; i++) {
    element = element?.parentElement?.nextElementSibling?.firstElementChild
  }
  if (!element) {
    const category = startElement?.parentElement?.parentElement?.parentElement
    element = category?.lastElementChild?.lastElementChild?.firstElementChild
  }
  if (element === startElement) {
    const nextCategory =
      element?.parentElement?.parentElement?.parentElement?.nextElementSibling
    element = nextCategory?.lastElementChild?.firstElementChild?.firstElementChild
  }
  element?.focus()
}

const focusPrevEmoji = (startElement: any, after: number) => {
  let element = startElement
  for (let i = 0; i < after; i++) {
    element = element?.parentElement?.previousElementSibling?.firstElementChild
  }
  if (!element) {
    const category = startElement?.parentElement?.parentElement?.parentElement
    element = category?.lastElementChild?.firstElementChild?.firstElementChild
  }
  if (element === startElement) {
    const prevCategory =
      element?.parentElement?.parentElement?.parentElement?.previousElementSibling
    element = prevCategory?.lastElementChild?.lastElementChild?.firstElementChild
  }
  element?.focus()
}

export const handleKeyboardNavigation = (event: any) => {
  const isEmoji = event.target.className.includes('emoji-mart-emoji')
  if (isEmoji) {
    if (
      ['Enter', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'].includes(event.key)
    ) {
      event.preventDefault()
      event.stopPropagation()
    }
    if (event.key === 'Enter') event.target.click()
    if (event.key === 'ArrowRight') focusNextEmoji(event.target, 1)
    if (event.key === 'ArrowLeft') focusPrevEmoji(event.target, 1)
    if (event.key === 'ArrowDown') focusNextEmoji(event.target, 10)
    if (event.key === 'ArrowUp') focusPrevEmoji(event.target, 10)
  }

  const isSearch = event.target.id.includes('emoji-mart-search')
  if (isSearch) {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      event.stopPropagation()
      focusFirstEmoji()
    }
  }
}
