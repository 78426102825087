import React from 'react'
import { Link, useCurrentRoute } from 'react-navi'
import { graphql, useFragment } from 'react-relay'

import { InstitutionBlockedBanner_institution$key } from '__generated__/InstitutionBlockedBanner_institution.graphql'
import { isInstitutionBlocked } from 'pages/Institution/utils'
import { fromGlobalId } from 'utils/relay'

import { Icons } from 'components/Icons'
import { UserContext } from 'components/UserContext'

import styles from './InstitutionBlockedBanner.module.scss'

const PATH_BLACKLIST = ['/plan-blocked']

const InstitutionBlockedBanner = () => {
  const {
    url: { pathname },
  } = useCurrentRoute()

  const context = React.useContext(UserContext)
  const institution = useFragment<InstitutionBlockedBanner_institution$key>(
    graphql`
      fragment InstitutionBlockedBanner_institution on Institution {
        id
        paidPlan {
          planType
          limitsExceededAt
        }
      }
    `,
    context.institution,
  )

  if (
    institution &&
    institution.paidPlan &&
    institution.paidPlan.planType !== 'PREMIUM' &&
    institution.paidPlan.limitsExceededAt &&
    isInstitutionBlocked(institution.paidPlan.limitsExceededAt as Date) &&
    !PATH_BLACKLIST.includes(pathname)
  ) {
    const { id } = fromGlobalId(institution.id)
    return (
      <div className={styles.blockedWarning}>
        <Link
          href={`/institution/${id}/subscription`}
          className={styles.blockedWarningContent}
        >
          <Icons.WarningTriangle red /> All participants have lost access to the
          institution. Please take action to meet your plan limits.
        </Link>
      </div>
    )
  }

  return null
}

export default InstitutionBlockedBanner
