import React from 'react'

import { Icons } from 'components/Icons'
import Modal from 'components/Modal/Modal'
import { IModalProps } from 'components/Modal/ModalProvider'

import styles from './DangerModal.module.scss'

export interface IDangerModalProps extends IModalProps {
  onConfirm: () => any
  disabled?: boolean
  message: string | JSX.Element | JSX.Element[]
  title?: string
  cancelText?: string
  confirmText?: string
}

export const DangerModal = ({
  hide,
  message,
  disabled,
  onConfirm,
  title = 'Are you sure?',
  cancelText = 'Cancel',
  confirmText = 'Delete',
}: IDangerModalProps) => {
  const handleConfirm = () => {
    hide()
    if (onConfirm) {
      onConfirm()
    }
  }
  return (
    <Modal.Container
      data-test="modal-danger-confirm"
      id="danger-modal"
      className={styles.modalContainer}
      backdropStyle={{ zIndex: 2020 }}
    >
      <div className={styles.iconContainer}>
        <span className={styles.iconWrapper}>
          <Icons.Bell large />
        </span>
      </div>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.message}>{message}</div>
      <Modal.ButtonContainer className={styles.ButtonContainer}>
        <Modal.Button
          buttonStyle="cancel"
          onClick={hide}
          data-test="modal-confirm-btn-cancel"
        >
          {cancelText}
        </Modal.Button>
        <Modal.Button
          buttonStyle="anger"
          onClick={handleConfirm}
          disabled={disabled}
          data-test="modal-confirm-btn-confirm"
        >
          {confirmText}
        </Modal.Button>
      </Modal.ButtonContainer>
    </Modal.Container>
  )
}
