import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import styles from './ContextMenuNavigator.module.scss'

const LEFT = 'left'
const RIGHT = 'right'
const SMOOTH = { mass: 0.8, tension: 300, friction: 28 }
const HEADER_X_DELTA = 50
const CONTENT_X_DELTA = 100

type Id = string | any

export interface ISection {
  id: Id
  render: (props?: any) => React.ReactNode
}

export type NavigateFn = (id: Id, props?: any) => void

export interface RenderHeaderProps {
  id: Id
  navigateLeft: NavigateFn
}

interface IProps {
  initialId: Id
  sections: ISection[]
  renderHeader: ({ id, navigateLeft }: RenderHeaderProps) => React.ReactNode
  headerContainerStyles?: string
}

type Direction = 'left' | 'right'

function useContextMenuNavigator({
  initialId,
  sections,
  renderHeader,
}: {
  initialId: string
  sections: ISection[]
  renderHeader: ({ id, navigateLeft }: RenderHeaderProps) => React.ReactNode
}): {
  props?: any
  navigateLeft: NavigateFn
  navigateRight: NavigateFn
  contentTransitions: any
  headerTransitions: any
} {
  const [props, setProps] = useState(null)
  const [content, setContent] = useState(
    sections.find((section) => section.id === initialId),
  )
  const [direction, setDirection] = useState(RIGHT)

  if (!content || !content.id) {
    throw new Error('The given id does not refer to any given section.')
  }
  const id = content && content.id

  const navigate = useCallback(
    (nextDirection: Direction, nextId: Id, nextProps?: any) => {
      setProps(nextProps)
      setDirection(nextDirection)
      setContent(sections.find((section) => section.id === nextId))
    },
    [sections],
  )
  const navigateLeft = useCallback(
    (nextId: Id, nextProps?: any) => navigate(LEFT, nextId, nextProps),
    [navigate],
  )
  const navigateRight = useCallback(
    (nextId: Id, nextProps?: any) => navigate(RIGHT, nextId, nextProps),
    [navigate],
  )
  const animatedHeader = useMemo(
    () => (styleProps: any) =>
      (
        <animated.div className={styles.animatedHeader} style={styleProps}>
          {renderHeader({ id, navigateLeft })}
        </animated.div>
      ),
    [id, renderHeader],
  )
  const headerTransitions = useTransition(animatedHeader, id, {
    config: SMOOTH,
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    from: {
      opacity: 0,
      transform: `translate3d(${
        direction === RIGHT ? `${HEADER_X_DELTA}px` : `-${HEADER_X_DELTA}px`
      },0,0)`,
    },
    initial: null,
    leave: {
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      transform: `translate3d(${
        direction === RIGHT ? `-${HEADER_X_DELTA}px` : `${HEADER_X_DELTA}px`
      },0,0)`,
    },
  })
  const contentTransitions = useTransition(content, (item) => item.id, {
    config: SMOOTH,
    enter: { transform: 'translate3d(0%,0,0)' },
    from: {
      transform: `translate3d(${
        direction === RIGHT ? `${CONTENT_X_DELTA}%` : `-${CONTENT_X_DELTA}%`
      },0,0)`,
    },
    initial: null,
    leave: {
      pointerEvents: 'none',
      position: 'absolute',
      transform: `translate3d(${
        direction === RIGHT ? `-${CONTENT_X_DELTA}%` : `${CONTENT_X_DELTA}%`
      },0,0)`,
    },
    // onRest is not being recognized as a valid method even though it is part to the api
    // will follow up here: https://github.com/react-spring/react-spring/pull/760
    // @ts-ignore
    onRest: () => {
      setProps((currentProps: any) => ({ ...currentProps, onRest: true }))
    },
  })

  useEffect(() => {
    setContent(sections.find((section) => section.id === (content.id || initialId)))
  }, [sections, content.id])

  return {
    contentTransitions,
    headerTransitions,
    navigateLeft,
    navigateRight,
    props,
  }
}

const ContextMenuNavigator = memo(function ContextMenuNavigatorMemo({
  initialId,
  renderHeader,
  sections,
  headerContainerStyles,
}: IProps) {
  const { contentTransitions, headerTransitions, navigateLeft, navigateRight, props } =
    useContextMenuNavigator({ initialId, sections, renderHeader })

  return (
    <div className={styles.section}>
      <div className={classNames(styles.header, headerContainerStyles)}>
        {headerTransitions.map(
          ({
            item: Header,
            props: headerProps,
            key,
          }: {
            item: React.ElementType
            props?: any
            key: string
          }) => {
            return <Header key={key} {...headerProps} />
          },
        )}
      </div>
      <div className={styles.main}>
        {contentTransitions.map(
          ({
            item,
            props: styleProps,
            key,
          }: {
            item: ISection
            props?: any
            key: string
          }) => (
            <animated.div
              key={key}
              className={styles.animatedContent}
              style={styleProps}
            >
              {item.render({ ...props, navigateLeft, navigateRight })}
            </animated.div>
          ),
        )}
      </div>
    </div>
  )
})

export default ContextMenuNavigator
