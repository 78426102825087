import React from 'react'

import { Button } from 'components/Button'
import { EmptyState } from 'components/EmptyState'
import { Icons } from 'components/Icons'

const renderNotFound = () => {
  return (
    <EmptyState
      Icon={Icons.Owl}
      heading="The page you're looking for can't be found"
      paragraph="The otherwise very smart owl wasn’t smart enough to find what you were looking for. It’s smartest to head home now."
    >
      <Button Large to="/">
        Take me home
      </Button>
    </EmptyState>
  )
}

export default renderNotFound
