/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ThemeBorderRadius = "Sharp" | "Soft" | "Softer";
export type ThemeFontFamily = "Default" | "Inter" | "Merriweather" | "OpenSans" | "Poppins" | "Quicksand";
export type ThemeContextQueryVariables = {
    courseId?: string | null | undefined;
    hasCourseId: boolean;
};
export type ThemeContextQueryResponse = {
    readonly institutionTheme: {
        readonly borderRadius: ThemeBorderRadius | null;
        readonly accentColor: string | null;
        readonly fontFamily: ThemeFontFamily | null;
    } | null;
    readonly me: {
        readonly institution: {
            readonly theme: {
                readonly borderRadius: ThemeBorderRadius | null;
                readonly accentColor: string | null;
                readonly fontFamily: ThemeFontFamily | null;
            } | null;
        } | null;
    } | null;
    readonly course?: {
        readonly theme: {
            readonly borderRadius: ThemeBorderRadius | null;
            readonly accentColor: string | null;
            readonly fontFamily: ThemeFontFamily | null;
        } | null;
    } | null | undefined;
};
export type ThemeContextQuery = {
    readonly response: ThemeContextQueryResponse;
    readonly variables: ThemeContextQueryVariables;
};



/*
query ThemeContextQuery(
  $courseId: ID
  $hasCourseId: Boolean!
) {
  institutionTheme {
    borderRadius
    accentColor
    fontFamily
    id
  }
  me {
    institution {
      theme {
        borderRadius
        accentColor
        fontFamily
        id
      }
      id
    }
    id
  }
  course(id: $courseId) @include(if: $hasCourseId) {
    theme {
      borderRadius
      accentColor
      fontFamily
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hasCourseId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "borderRadius",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accentColor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fontFamily",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "theme",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "courseId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v7/*: any*/)
],
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Theme",
    "kind": "LinkedField",
    "name": "theme",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ThemeContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Theme",
        "kind": "LinkedField",
        "name": "institutionTheme",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScopedInstitution",
            "kind": "LinkedField",
            "name": "institution",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasCourseId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThemeContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Theme",
        "kind": "LinkedField",
        "name": "institutionTheme",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScopedInstitution",
            "kind": "LinkedField",
            "name": "institution",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasCourseId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "course",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "db077fe01fe11f920b338621ffe9024b",
    "id": null,
    "metadata": {},
    "name": "ThemeContextQuery",
    "operationKind": "query",
    "text": "query ThemeContextQuery(\n  $courseId: ID\n  $hasCourseId: Boolean!\n) {\n  institutionTheme {\n    borderRadius\n    accentColor\n    fontFamily\n    id\n  }\n  me {\n    institution {\n      theme {\n        borderRadius\n        accentColor\n        fontFamily\n        id\n      }\n      id\n    }\n    id\n  }\n  course(id: $courseId) @include(if: $hasCourseId) {\n    theme {\n      borderRadius\n      accentColor\n      fontFamily\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b9775d79ec9b459316dedf4a887b3b9d';
export default node;
