import React, { useContext } from 'react'
import { useCurrentRoute, useNavigation } from 'react-navi'

import deleteActivity from 'mutations/deleteActivity'

import { IModalProps, ModalContext } from 'components/Modal/ModalProvider'
import { ToastContext } from 'components/NotificationCenter'
import { ConfirmationModal } from 'components/modals/ConfirmationModal'
import modalStyles from 'components/modals/ConfirmationModal.module.scss'

export interface IDeleteActivityModalProps extends IModalProps {
  activityId: string
  afterDeleteCallback?: () => void
}

const DeleteActivityModal = ({
  activityId,
  afterDeleteCallback,
}: IDeleteActivityModalProps) => {
  const {
    data: { courseId },
  } = useCurrentRoute()
  const navigation = useNavigation()
  const { displayToast } = useContext(ToastContext)
  const { showModal, hideModal } = useContext(ModalContext)

  const _deleteActivity = async () => {
    try {
      await deleteActivity({ activityId })
      if (afterDeleteCallback) afterDeleteCallback()
      navigation.navigate(`/courses/${courseId}`)
      return
    } catch (e) {
      const error = JSON.parse(e.message)
      const { type } = error
      if (
        type === 'DependancyError' ||
        type === 'ScoringInputError' ||
        type === 'InheritorError'
      ) {
        // Extract the activity titles from the error message
        const colonPosition = error.message.search(':')
        const activityTitles = error.message.substr(colonPosition + 1)
        let message
        if (type === 'DependancyError') {
          message = (
            <p className={modalStyles.centeredConfirmationMessage}>
              This activity is being used as a source. To delete this activity you need
              to delete <strong>{activityTitles}</strong> first.
            </p>
          )
        } else if (type === 'InheritorError') {
          message = (
            <p className={modalStyles.centeredConfirmationMessage}>
              Other activities are inheriting groups from this activity. To delete this
              activity you need to delete <strong>{activityTitles}</strong> first.
            </p>
          )
        } else {
          message = (
            <p className={modalStyles.centeredConfirmationMessage}>
              This activity is being used as an input to one or more Scoring activities.
              To delete this activity you need to delete{' '}
              <strong>{activityTitles}</strong> first.
            </p>
          )
        }
        showModal(ConfirmationModal, {
          confirmBtnStyle: 'cancel' as any,
          confirmText: 'Understood',
          danger: true,
          hasCancelButton: false,
          isOpen: false,
          message,
          title: 'Activity cannot be deleted',
        })
      } else if (type === 'UsedForGradePassbackError') {
        showModal(ConfirmationModal, {
          confirmBtnStyle: 'cancel' as any,
          confirmText: 'Understood',
          danger: true,
          hasCancelButton: false,
          isOpen: false,
          message: (
            <p className={modalStyles.centeredConfirmationMessage}>
              This activity is being used as an input for a Grade Passback activity. To
              delete this activity you first need to delete the Grade Passback activity.
            </p>
          ),
          title: 'Activity cannot be deleted',
        })
      } else if (type === 'InvalidRulesError') {
        showModal(ConfirmationModal, {
          danger: true,
          isOpen: false,
          message: (
            <p className={modalStyles.centeredConfirmationMessage}>
              This activity is referenced by one or more rules. Deleting this activity
              will also delete any rules that reference it.
            </p>
          ),
          title: 'Invalid rules will be removed',
          onConfirm: () => deleteActivity({ activityId, force: true }),
        })
      } else {
        displayToast(e.message, 'error')
      }
    }
  }

  return (
    <ConfirmationModal
      onConfirm={_deleteActivity}
      title="Delete activity"
      confirmBtnStyle="anger"
      danger
      hide={hideModal}
      message="Are you sure you want to delete this activity?"
    />
  )
}

export default DeleteActivityModal
