import classNames from 'classnames/bind'
import React from 'react'

import styles from './StateTag.module.scss'

const cx = classNames.bind(styles)

const StateTag = ({
  children,
  Green,
  Gray,
  Blue,
  Red,
  whiteBg,
  hasIcon,
  noPaddingRight,
  notClickable,
  className,
}: {
  children?: React.ReactNode
  Green?: boolean
  Gray?: boolean
  Blue?: boolean
  Red?: boolean
  whiteBg?: boolean
  hasIcon?: boolean
  noPaddingRight?: boolean
  notClickable?: boolean
  className?: string
}) => {
  return (
    <div
      data-statetag
      className={cx('stateTag', className, {
        Green,
        Gray,
        Blue,
        Red,
        hasIcon,
        noPaddingRight,
        notClickable,
        whiteBackground: whiteBg,
      })}
    >
      {children}
    </div>
  )
}

export default StateTag
