import React, { useContext, useRef, useState } from 'react'

import useEventListener from 'hooks/useEventListener'
import { isDataURI } from 'utils/functions'
import { getRandomImage } from 'utils/getRandomImage'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { ToastContext } from 'components/NotificationCenter'
import NavBar from 'components/modals/components/NavBar'

import LinkPicker from './components/LinkPicker'
import UnsplashPicker from './components/UnsplashPicker'
import UploadPicker from './components/UploadPicker'

import styles from './ImagePicker.module.scss'

export interface IOnChangeImageArgs {
  image: string
  unsplashPingUrl?: string
}

interface IProps {
  handleClose: () => void
  initialImage: string | null
  onChangeImage: (input: IOnChangeImageArgs) => Promise<void>
  successMsg: string
  s3Destination: string
}

enum Tab {
  Unsplash,
  Upload,
  Link,
}

const tabs = [
  {
    label: 'Unsplash',
    value: Tab.Unsplash,
  },
  {
    label: 'Upload',
    value: Tab.Upload,
  },
  {
    label: 'Link',
    value: Tab.Link,
  },
]

const ImagePicker = ({
  handleClose,
  initialImage,
  onChangeImage,
  successMsg,
  s3Destination,
}: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [activeTab, setActiveTab] = useState(Tab.Unsplash)
  const [submitting, setSubmitting] = useState(false)
  const [pendingSuccessMsg, setPendingSuccessMsg] = useState<null | string>(null)
  const { displayToast } = useContext(ToastContext)

  const submitImage = async (
    url?: string,
    unsplashPingUrl?: string,
    opts: { closeOnSave: boolean; deferSuccessToast: boolean } = {
      closeOnSave: true,
      deferSuccessToast: false,
    },
  ) => {
    const newImage = url || initialImage
    if (!newImage) return
    if (isDataURI(newImage)) return

    try {
      setSubmitting(true)
      await onChangeImage({ image: newImage, unsplashPingUrl })

      if (!opts.deferSuccessToast) {
        displayToast(successMsg, 'success')
        setPendingSuccessMsg(null)
      } else {
        setPendingSuccessMsg(successMsg)
      }

      if (opts.closeOnSave) {
        handleClose()
      }
    } catch (e) {
      displayToast(e.message, 'error')
    }
    setSubmitting(false)
  }

  const handleError = (e: Error) => {
    console.error(e)
    displayToast('Failed to update the image', 'error')
  }

  const onClose = () => {
    handleClose()
    if (pendingSuccessMsg) {
      displayToast(pendingSuccessMsg, 'success')
    }
  }

  useEventListener(
    'mousedown',
    async (event: MouseEvent) => {
      const isClickOutside =
        event.target instanceof Element && !ref?.current?.contains(event.target)
      if (isClickOutside) {
        onClose()
      }
    },
    window,
    { capture: true },
  )

  const shuffleImage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const randomImage = getRandomImage()
    await submitImage(randomImage.urls.regular, randomImage.links.download_location, {
      closeOnSave: false,
      deferSuccessToast: true,
    })
    e.preventDefault()
  }

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.header}>
        <NavBar
          items={tabs}
          onClick={setActiveTab}
          activeTab={activeTab}
          tabClassName={styles.tab}
        />
        <div>
          <Button
            className={styles.shuffleButton}
            onClick={shuffleImage}
            tooltipText={'Shuffle through images'}
          >
            <Icons.Sync accentColor style={{ marginRight: '.5rem' }} />
            Shuffle
          </Button>
          <Button Icon onClick={onClose} ariaLabel="Close">
            <Icons.Close />
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {activeTab === Tab.Unsplash && (
          <UnsplashPicker
            onImageChange={submitImage}
            image={initialImage}
            onError={handleError}
          />
        )}
        {activeTab === Tab.Upload && (
          <UploadPicker
            submitImage={submitImage}
            onError={handleError}
            s3Destination={s3Destination}
          />
        )}
        {activeTab === Tab.Link && (
          <LinkPicker
            image={initialImage}
            submitting={submitting}
            submitImage={submitImage}
          />
        )}
      </div>
    </div>
  )
}

export default ImagePicker
