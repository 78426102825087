import React, { useEffect } from 'react'
import { useNavigation } from 'react-navi'

declare let __PRODUCTION__: boolean

interface IProps {
  errorMessage: React.ReactNode
  shouldRedirect?: boolean
}

const ErrorBoundaryInner: React.FunctionComponent<{
  hasError: boolean
  shouldRedirect?: boolean
  reset: () => void
}> = ({ children, hasError, reset, shouldRedirect }) => {
  const navigation = useNavigation()

  const navigate = async () => {
    await navigation.navigate('/')
    reset()
  }

  useEffect(() => {
    if (__PRODUCTION__) {
      if (hasError && shouldRedirect) {
        const timer = setTimeout(() => navigate(), 5000)

        return () => clearTimeout(timer)
      }
    }
  }, [hasError])

  return <>{children}</>
}

export default class ErrorBoundary extends React.Component<IProps> {
  public state = {
    hasError: false,
  }

  private reset = () => this.setState({ hasError: false })
  public componentDidCatch() {
    this.setState({ hasError: true })
  }

  public render() {
    const shouldShowError = this.state.hasError
    return (
      <ErrorBoundaryInner
        reset={this.reset}
        hasError={this.state.hasError}
        shouldRedirect={this.props.shouldRedirect || false}
      >
        {shouldShowError ? this.props.errorMessage : this.props.children}
      </ErrorBoundaryInner>
    )
  }
}
