/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type ActivityContextMenu_activity = {
    readonly url: string;
    readonly id: string;
    readonly isCopyable: boolean | null;
    readonly icon: string | null;
    readonly activityType: ActivityType;
    readonly " $refType": "ActivityContextMenu_activity";
};
export type ActivityContextMenu_activity$data = ActivityContextMenu_activity;
export type ActivityContextMenu_activity$key = {
    readonly " $data"?: ActivityContextMenu_activity$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActivityContextMenu_activity">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityContextMenu_activity",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCopyable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
(node as any).hash = 'e3ee2f1e8a92dd761bd93abce6ed9791';
export default node;
