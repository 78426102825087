import React from 'react'

import {
  ACTIVITY_PICKER_SECTION,
  COURSE_PICKER_SECTION,
  ActionType,
  defaultState,
} from './constants'
import { Action, IState } from './types'

export const stateReducer: React.Reducer<IState, Action> = (state, action): IState => {
  switch (action.type) {
    case ActionType.SetIsOpen:
      return {
        ...state,
        isOpen: action.isOpen,
      }
    case ActionType.SetCopyMutationUnderway:
      return {
        ...state,
        isCopyMutationUnderway: action.isCopyMutationUnderway,
      }
    case ActionType.SetCurrentSection:
      if (action.currentSection == COURSE_PICKER_SECTION) {
        return { ...defaultState, courses: state.courses }
      }
      return { ...state, currentSection: action.currentSection }
    case ActionType.SetCourses:
      return { ...state, courses: action.courses }
    case ActionType.SetCourseId:
      return {
        ...state,
        courseId: action.courseId,
        course: null,
        activityId: undefined,
        selectedFlowIds: [],
        currentSection: action.courseId
          ? ACTIVITY_PICKER_SECTION
          : COURSE_PICKER_SECTION,
      }
    case ActionType.SetCourse:
      if (!action.course) {
        return state
      }

      const activityToFlowMap: IState['activityToFlowMap'] = {}
      const sourceActivityToActivityMap: IState['sourceActivityToActivityMap'] = {}
      const uncopyableActivityIds: IState['uncopyableActivityIds'] = []
      const course = action.course

      for (const flow of course.flows) {
        for (const activity of flow.activities) {
          activityToFlowMap[activity.id] = flow.id
          if (activity?.source?.id) {
            if (sourceActivityToActivityMap[activity.source.id]) {
              sourceActivityToActivityMap[activity.source.id].push(activity.id)
            } else {
              sourceActivityToActivityMap[activity.source.id] = [activity.id]
            }
          }

          if (!activity?.isCopyable) {
            uncopyableActivityIds.push(activity.id)
          }
        }
      }

      return {
        ...state,
        course: action.course,
        activityToFlowMap,
        sourceActivityToActivityMap,
        uncopyableActivityIds,
        currentSection: action.course ? ACTIVITY_PICKER_SECTION : COURSE_PICKER_SECTION,
      }
    case ActionType.ToggleFlowId: {
      if (!action?.flowId) {
        return state
      }
      const missingSourceActivityMap: IState['missingSourceActivityMap'] = {}
      const currentlyChecked: boolean = state.selectedFlowIds.includes(action.flowId)
      const flowActivityIds = Object.entries(state.activityToFlowMap).reduce(
        (acc, [activityId, flowId]) => {
          if (flowId == action.flowId) {
            acc.push(activityId)
          }
          return acc
        },
        [] as string[],
      )

      const selectedFlowIds = currentlyChecked
        ? state.selectedFlowIds.filter((flowId) => flowId !== action.flowId)
        : [...new Set([...state.selectedFlowIds, action.flowId])]
      const selectedActivityIds = currentlyChecked
        ? state.selectedActivityIds
            .filter(
              (activityId) => state.activityToFlowMap[activityId] !== action.flowId,
            )
            .filter((activityId) => !state.uncopyableActivityIds.includes(activityId))
        : [...new Set([...state.selectedActivityIds, ...flowActivityIds])].filter(
            (activityId) => !state.uncopyableActivityIds.includes(activityId),
          )

      for (const activityId of Object.keys(state.activityToFlowMap)) {
        const isSelected = selectedActivityIds.includes(activityId)
        const isSource = Object.keys(state.sourceActivityToActivityMap).includes(
          activityId,
        )

        if (!isSelected && isSource) {
          const dependentActivityIds = state.sourceActivityToActivityMap[activityId]

          for (const dependentActivityId of dependentActivityIds) {
            if (selectedActivityIds.includes(dependentActivityId)) {
              if (missingSourceActivityMap[activityId]) {
                missingSourceActivityMap[activityId].push(dependentActivityId)
              } else {
                missingSourceActivityMap[activityId] = [dependentActivityId]
              }
            }
          }
        }
      }

      return {
        ...state,
        missingSourceActivityMap,
        selectedFlowIds,
        selectedActivityIds,
        selectedAll:
          Object.values(state.activityToFlowMap).length == selectedActivityIds.length,
      }
    }
    case ActionType.ToggleActivityId:
      const missingSourceActivityMap: IState['missingSourceActivityMap'] = {}
      const selectedFlowIds = state.selectedFlowIds

      if (!action?.activityId) {
        return state
      }

      const selectedActivityIds = state.selectedActivityIds.includes(action.activityId)
        ? state.selectedActivityIds.filter(
            (activityId) => activityId !== action.activityId,
          )
        : [...new Set([...state.selectedActivityIds, action.activityId])]

      for (const activityId of Object.keys(state.activityToFlowMap)) {
        const isSelected = selectedActivityIds.includes(activityId)
        const isSource = Object.keys(state.sourceActivityToActivityMap).includes(
          activityId,
        )

        if (!isSelected && isSource) {
          const dependentActivityIds = state.sourceActivityToActivityMap[activityId]

          for (const dependentActivityId of dependentActivityIds) {
            if (selectedActivityIds.includes(dependentActivityId)) {
              if (missingSourceActivityMap[activityId]) {
                missingSourceActivityMap[activityId].push(dependentActivityId)
              } else {
                missingSourceActivityMap[activityId] = [dependentActivityId]
              }
            }
          }
        }
      }

      if (
        // Check flow if activities checked
        selectedActivityIds.includes(action.activityId) &&
        !selectedFlowIds.includes(state.activityToFlowMap[action.activityId])
      ) {
        selectedFlowIds.push(state.activityToFlowMap[action.activityId])
      } else if (
        // Uncheck flow if no activities checked
        !selectedActivityIds.includes(action.activityId) &&
        selectedFlowIds.includes(state.activityToFlowMap[action.activityId])
      ) {
        const flowIndex = selectedFlowIds.indexOf(
          state.activityToFlowMap[action.activityId],
        )
        if (flowIndex != -1) {
          selectedFlowIds.splice(flowIndex, 1)
        }
      }

      return {
        ...state,
        selectedActivityIds,
        selectedFlowIds,
        missingSourceActivityMap,
        selectedAll:
          Object.values(state.activityToFlowMap).length == selectedActivityIds.length,
      }
    case ActionType.ToggleSelectAll:
      const hasSelectedAll =
        Object.values(state.activityToFlowMap).length ==
        state.selectedActivityIds.length

      return {
        ...state,
        missingSourceActivityMap: {},
        selectedAll: !hasSelectedAll,
        selectedFlowIds: !hasSelectedAll ? Object.values(state.activityToFlowMap) : [],
        selectedActivityIds: !hasSelectedAll
          ? Object.keys(state.activityToFlowMap)
          : [],
      }

    case ActionType.Reset:
      return defaultState

    default:
      return state
  }
}
