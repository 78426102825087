import classNames from 'classnames/bind'
import React from 'react'

import { Icons } from 'components/Icons'
import { IIconProps } from 'components/Icons/utils'

import styles from './EmptyState.module.scss'

const cx = classNames.bind(styles)
export interface IProps {
  Icon: React.ComponentType<IIconProps>
  blue?: boolean
  red?: boolean
  small?: boolean
  IconColor?: string
  leftAligned?: boolean
  children?: React.ReactNode
  heading?: string
  paragraph?: string
  differentOnSmallScreen?: boolean
  SmallScreenIcon?: React.ComponentType<IIconProps>
  smallScreenParagraph?: string
  smallScreenHeading?: string
  className?: string
}

const EmptyState: React.FC<IProps> = ({
  Icon,
  blue,
  red,
  small,
  children,
  heading,
  leftAligned,
  className = '',
  paragraph = '',
  differentOnSmallScreen,
  smallScreenParagraph,
  smallScreenHeading,
  SmallScreenIcon,
}) => {
  const emptyStateStyleNames = cx(`${className} ${styles.emptyState}`, {
    differentOnSmallScreen,
    leftAligned,
  })
  const iconForegroundStyleNames = cx(`${styles.IconForeground}`, {
    small,
  })
  const iconBackgroundStyleNames = cx(`${styles.IconBackground}`, {
    small,
  })
  return (
    <div className={emptyStateStyleNames}>
      <div className={styles.HideOnSmallScreen}>
        <div className={styles.IconWrapper}>
          <Icons.Background className={iconBackgroundStyleNames} white />
          <Icon className={iconForegroundStyleNames} accentColor={blue} red={red} />
        </div>
        <h1 className={styles.Heading}>{heading}</h1>
        <div className={styles.Paragraph}>{paragraph}</div>
        {children}
      </div>
      {differentOnSmallScreen && SmallScreenIcon && (
        <div className={styles.ShowOnSmallScreen}>
          <div className={styles.IconWrapper}>
            <Icons.Background className={styles.IconBackground} white />
            <SmallScreenIcon className={styles.IconForeground} blue={blue} red={red} />
          </div>
          <h1 className={styles.Heading}>{smallScreenHeading}</h1>
          <div className={styles.Paragraph}>{smallScreenParagraph}</div>
        </div>
      )}
    </div>
  )
}

export default EmptyState
