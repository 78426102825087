import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  SubscribeFunction,
  Variables,
  ROOT_TYPE,
} from 'relay-runtime'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { fromGlobalId, toGlobalId } from 'utils/relay'

export let hasNewAppBundle = false

const fetchQuery: FetchFunction = (
  operation: RequestParameters,
  variables: Variables,
) => {
  let graphqlInternalRoute = `/api/graphql-internal`
  if (operation?.name) {
    graphqlInternalRoute = `/api/graphql-internal?${operation.name}`
  }
  return fetch(graphqlInternalRoute, {
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Bundle-Version': window.eduflow.constants.WEBPACK_BUNDLE_VERSION,
    },
    method: 'POST',
  }).then((response) =>
    response.json().then((jsonResponse) => {
      if (
        response.headers.get('X-Bundle-Version') !==
        window.eduflow.constants.WEBPACK_BUNDLE_VERSION
      ) {
        hasNewAppBundle = true
      }
      return jsonResponse
    }),
  )
}

let subscriptionClient: SubscriptionClient | null = null

const setupSubscription: SubscribeFunction = (
  config: RequestParameters,
  variables: Variables,
) => {
  // This gets called on every subscription request, triggered by Relay's requestSubscription within a hook
  // on component mount, and supplies a dispose function to be called for unsubscribe when the component unmounts.
  const query = config.text
  const operationName = config.name

  if (subscriptionClient === null) {
    // This initializes a single websocket per client, all subscription request go through it using protocol called
    // "graphql-ws" which has operations for subscribe, unsubscribe and push data.
    subscriptionClient = new SubscriptionClient(
      `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}${
        window.location.host
      }/ws/subscriptions`,
      {
        reconnect: true,
        reconnectionAttempts: 2,
        lazy: true,
        inactivityTimeout: 10000,
        // keep-alive interval on server is 40 seconds, 50 seconds here gives us
        // some leeway if the server is clogged up
        timeout: 50000,
      },
    )
  }

  if (query === null) {
    throw Error('query cannot be null')
  }

  const subscriptionRequest = subscriptionClient.request({
    query,
    operationName,
    variables,
  }) as any // subscriptions-transport-ws types don't fully match relay-runtime types, ignoring

  // Important: Convert subscriptions-transport-ws observable type to Relay's
  return Observable.from<GraphQLResponse>(subscriptionRequest)
}

// We have to instruct Relay how to cross reference root field queries against the Relay store
// in order to identify if the object is already in the store and it can partially render.
// We supply this logic via missingFieldHandlers, which in the case of nodes simply returns
// the id provided in the query.
// Read more: https://relay.dev/docs/v11.0.0/guided-tour/reusing-cached-data/filling-in-missing-data/
const ROOT_NODE_FIELDS = ['course', 'me', 'node', 'flow']
const nodeMissingFieldHandlers = ROOT_NODE_FIELDS.map((rootField) => ({
  kind: 'linked',
  // @ts-ignore
  handle: (field, record, args) => {
    if (
      record != null &&
      record.__typename === ROOT_TYPE &&
      field.name === rootField &&
      args.hasOwnProperty('id')
    ) {
      return args.id
    }
    return undefined
  },
}))

// Relay saves objects in store using the concrete type, so for activities we have to
// deconstruct Activity:<uuid> and construct it with each activity type,
// e.g. ContentActivity:<uuid> so that it can find the object in the store
const ACTIVITY_TYPES = [
  'CertificateActivity',
  'ContentActivity',
  'DiscussionActivity',
  'EmbedActivity',
  'FeedbackReflectionActivity',
  'GradePassbackActivity',
  'GroupFormationActivity',
  'GroupMemberReviewActivity',
  'HapYakActivity',
  'InviteTAActivity',
  'PeerReviewActivity',
  'PollActivity',
  'FormActivity',
  'QuizActivity',
  'ScoringActivity',
  'ScormActivity',
  'SelectTagActivity',
  'SelfReviewActivity',
  'SubmissionActivity',
  'TeacherReviewActivity',
  'VideoActivity',
  'ZoomActivity',
  'GoogleMeetActivity',
  'MicrosoftTeamsActivity',
  'TurnItInActivity',
]
const activityMissingFieldHandlers = ACTIVITY_TYPES.map((activityType) => ({
  kind: 'linked',
  // @ts-ignore
  handle: (field, record, args) => {
    if (
      record != null &&
      record.__typename === ROOT_TYPE &&
      field.name === 'activity' &&
      args.hasOwnProperty('id')
    ) {
      const { id } = fromGlobalId(args.id)
      return toGlobalId(activityType, id)
    }
    return undefined
  },
}))

const missingFieldHandlers = [
  ...nodeMissingFieldHandlers,
  ...activityMissingFieldHandlers,
]

const environment = new Environment({
  network: Network.create(fetchQuery, setupSubscription),
  store: new Store(new RecordSource(), {
    // This disables the Relay *query* cache to match the behavior of prior versions.
    // Caching is still possible via the store, if the data needed by a query is already in the store,
    // then that can be used right away when fetch policies like store-and-network are used.
    // https://relay.dev/docs/guided-tour/reusing-cached-data/presence-of-data/#gc-release-buffer-size
    gcReleaseBufferSize: 0,
  }),
  // @ts-ignore
  missingFieldHandlers,
})

export default environment
