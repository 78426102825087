import classNames from 'classnames/bind'
import React from 'react'
import { useCurrentRoute } from 'react-navi'
import { graphql, useQuery, useRefetchable } from 'relay-hooks'

import type { CoursePickerQuery } from '__generated__/CoursePickerQuery.graphql'
import { CoursePicker_query$key } from '__generated__/CoursePicker_query.graphql'
import { notEmpty } from 'utils/functions'
import { fromGlobalId } from 'utils/relay'

import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { Input } from 'components/Input'
import { Spinner } from 'components/Spinner'

import { Header } from './Shared'
import { COURSE_PICKER_SECTION, ActionType } from './constants'
import { stateReducer } from './reducer'
import type { Courses, CourseNode } from './types'

import styles from './CopyActivitiesMenu.module.scss'

export const rootQuery = graphql`
  query CoursePickerQuery($searchTerm: String) {
    ...CoursePicker_query @arguments(searchTerm: $searchTerm)
  }
`

export const rootFragment = graphql`
  fragment CoursePicker_query on Query
  @argumentDefinitions(
    searchTerm: { type: "String" }
    first: { type: "Int", defaultValue: 500 }
    after: { type: "String" }
    isTeacher: { type: "Boolean", defaultValue: true }
  )
  @refetchable(queryName: "CoursePickerRefetchQuery") {
    courses(
      searchTerm: $searchTerm
      first: $first
      after: $after
      isTeacher: $isTeacher
    ) @connection(key: "CoursePicker_courses", filters: ["searchTerm"]) {
      edges {
        node {
          id
          title
          isArchived
        }
      }
    }
  }
`

const cx = classNames.bind(styles)

const CoursePicker: React.FC<{
  dispatch: React.Dispatch<React.ReducerAction<typeof stateReducer>>
  state: React.ReducerState<typeof stateReducer>
}> = ({ dispatch, state }) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const route = useCurrentRoute()
  const {
    data: { courseId },
  } = route
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [previousSearchTerm, setPreviousSearchTerm] = React.useState<string>(searchTerm)

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    setSearchTerm(value)
  }

  const { data: queryProps } = useQuery<CoursePickerQuery>(rootQuery)
  const { data: results, refetch } = useRefetchable<
    CoursePickerQuery,
    CoursePicker_query$key
    // @ts-ignore
  >(rootFragment, queryProps) || { data: null }
  const isLoading = !results

  React.useEffect(() => {
    if (!results || isLoading) {
      return
    }
    const { courses: courseEdges }: { courses: Courses } = results ?? {
      courses: [],
    }
    if (!courseEdges) {
      dispatch({ type: ActionType.SetCourses, courses: [] })
    } else {
      dispatch({
        type: ActionType.SetCourses,
        courses: [
          ...courseEdges?.edges
            .filter(notEmpty)
            .map(({ node }) => node)
            .filter((node) => !node?.isArchived),
          ...courseEdges?.edges
            .filter(notEmpty)
            .map(({ node }) => node)
            .filter((node) => node?.isArchived),
        ],
      })
    }
  }, [results, isLoading])

  function fetchData() {
    refetch({ searchTerm }, { onComplete: () => setPreviousSearchTerm(searchTerm) })
  }

  React.useEffect(() => {
    if ((state.courses.length > 0 && searchTerm == previousSearchTerm) || isLoading) {
      return
    }
    fetchData()
  }, [searchTerm])

  React.useLayoutEffect(() => {
    if (!('ontouchstart' in window) && inputRef?.current?.focus) {
      inputRef.current.focus()
    } else if ('ontouchstart' in window && inputRef?.current?.blur) {
      inputRef.current.blur()
    }
  })

  const clearSearch = React.useCallback(() => {
    setSearchTerm('')
  }, [isLoading])

  return (
    <>
      <Header id={COURSE_PICKER_SECTION} />
      <div className={styles.searchInputContainer}>
        <Input
          name="Search courses"
          placeholder="Start typing to search courses"
          aria-label="Start typing to search courses"
          inputRef={inputRef}
          debounceTimeout={500}
          withSearchIcon
          input={{
            onChange,
            value: searchTerm,
          }}
        />
      </div>
      {state.courses.length == 0 && (
        <div className={styles.emptyBox}>
          {isLoading ? (
            <Spinner accentColor />
          ) : (
            <>
              <p className={styles.emptyStateText}>No courses found</p>
              {searchTerm != '' && (
                <Button Small onClick={clearSearch}>
                  Clear search
                </Button>
              )}
            </>
          )}
        </div>
      )}
      <div className={styles.coursesList}>
        {state.courses.map?.((course: CourseNode, idx: number) => (
          <button
            className={cx(styles.menuItem, { course: true })}
            data-test={`course-lookup-title-${course?.title}`}
            key={idx}
            onClick={() =>
              course?.id &&
              dispatch({ type: ActionType.SetCourseId, courseId: course.id })
            }
          >
            <div className={styles.itemLeft}>
              <Icons.Folder grey className={styles.itemIcon} />
              <div className={styles.itemText}>
                {course?.title}{' '}
                {course?.id && fromGlobalId(course.id).id == courseId && (
                  <span className="color grey dif xs"> · Current course</span>
                )}
                {course?.isArchived && (
                  <span className="color grey dif"> · Archived</span>
                )}
              </div>
            </div>

            <div className={styles.itemRight}>
              <Icons.ChevronRight className={styles.rightIcon} />
            </div>
          </button>
        ))}
      </div>
    </>
  )
}

export default CoursePicker
