import React from 'react'

import { GQLActivityStudentStatus, GQLActivityType } from 'types/graphqlTypes'

import { Icons } from 'components/Icons'
import { Tooltip } from 'components/Tooltip'

import StateTag from './StateTag'

import styles from './StateTag.module.scss'

export const getStatusIcon = (status: GQLActivityStudentStatus) => {
  if (status === 'Finished') {
    return <Icons.checkmarkCircle green />
  }

  if (status === 'Locked') {
    return <Icons.lockCircle grey />
  }

  if (status === 'Unstarted') {
    return <Icons.Circle blue />
  }

  if (status === 'Overdue') {
    return <Icons.Remove red />
  }

  if (status === 'NotAssigned') {
    return <Icons.Remove grey />
  }

  throw new Error(`Unknown status: ${status}`)
}

export const getStatusText = (
  status: GQLActivityStudentStatus,
  activityType: GQLActivityType,
) => {
  if (status === 'Finished') {
    return 'Complete'
  } else if (status === 'Locked') {
    return 'Locked'
  } else if (status === 'Unstarted') {
    return activityType == 'Scoring' ? 'No score yet' : 'Ready to complete'
  } else if (status === 'Overdue') {
    return activityType == 'Scoring' ? 'Failed' : 'Overdue'
  } else if (status === 'NotAssigned') {
    return 'Not part of this activity'
  }

  throw new Error(`Unknown status: ${status}`)
}

const Overridden = () => (
  <span className={styles.overriddenIconWrapper}>
    <Icons.Lightning yellow small />
  </span>
)

const CompletionStateTag = ({
  isHidden,
  isOverridden,
  status,
  activityType,
  whiteBg,
}: {
  isHidden?: boolean
  isOverridden?: boolean
  status: GQLActivityStudentStatus
  activityType: GQLActivityType
  whiteBg?: boolean
}) => {
  const statusIcon = getStatusIcon(status)
  const statusText = getStatusText(status, activityType)

  return isHidden ? (
    <>
      <StateTag Gray>
        <Icons.EyeSlash /> Activity hidden {isOverridden && <Overridden />}
      </StateTag>
      <Tooltip text={statusText}>
        <span className={styles.statusIconWithTooltip}>{statusIcon}</span>
      </Tooltip>
    </>
  ) : (
    <StateTag
      Green={status === 'Finished'}
      Gray={status === 'Locked' || status === 'NotAssigned'}
      Blue={status === 'Unstarted'}
      Red={status === 'Overdue'}
      whiteBg={whiteBg}
      hasIcon
      noPaddingRight={isOverridden}
      notClickable={status === 'NotAssigned'}
    >
      {statusIcon} {statusText} {isOverridden && <Overridden />}
    </StateTag>
  )
}

export default CompletionStateTag
