import { graphql } from 'react-relay'
import { ConnectionHandler, ReadOnlyRecordProxy, RecordProxy } from 'relay-runtime'

import {
  AddTestUsersToCourseInput,
  addTestUsersToCourseMutation,
} from '__generated__/addTestUsersToCourseMutation.graphql'
import mutationPromise from 'relay/mutationPromise'

export default (input: AddTestUsersToCourseInput) =>
  mutationPromise<addTestUsersToCourseMutation>({
    mutation: graphql`
      mutation addTestUsersToCourseMutation($input: AddTestUsersToCourseInput!) {
        addTestUsersToCourse(input: $input) {
          course {
            id
            hasTestRealUserMix
            testLearners {
              id
            }
            testAssistants {
              id
            }
            ...TestUsersContextMenu_course
          }
          addedTestUsers {
            id
            user {
              name
            }
            ...Row_participant
          }
        }
      }
    `,
    updater: (store) => {
      const payload = store.getRootField('addTestUsersToCourse')
      if (!payload) {
        return
      }
      const course = store.get(input.courseId) as ReadOnlyRecordProxy
      const addedTestUsers = payload.getLinkedRecords('addedTestUsers')
      if (!addedTestUsers) {
        return
      }
      const participants = ConnectionHandler.getConnection(
        course,
        'PaginatedParticipantsList_participants',
      )
      if (participants) {
        for (const testUser of addedTestUsers) {
          if (testUser) {
            const edge = ConnectionHandler.createEdge(
              store,
              participants,
              testUser as RecordProxy<{}>,
              'UserEdge',
            )
            ConnectionHandler.insertEdgeAfter(participants, edge)
          }
        }
      }
    },
    variables: { input },
  })
