import classNames from 'classnames'
import React from 'react'
import { graphql } from 'react-relay'

import { UserBadge_badge } from '__generated__/UserBadge_badge.graphql'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import withFragmentContainer from 'relay/withFragmentContainer'

import { Tooltip } from 'components/Tooltip'

import styles from './UserBadge.module.scss'

interface IBadge {
  readonly text: string | null
  readonly profileImage: string | null
  readonly backgroundColor: {
    readonly hex: string | null
    readonly isLight: boolean | null
  }
}

interface IOwnProps {
  style?: React.CSSProperties
  small?: boolean
  xSmall?: boolean
  className?: string
}

interface IProps extends IOwnProps {
  badge?: UserBadge_badge | IBadge | null
  badgeCount?: number | string
  badgeTooltipText?: string
  onBadgeClick?: () => void
}

export const Badge = ({
  style,
  className,
  small,
  text = 'Guest',
}: IOwnProps & { text?: string }) => {
  const classes = classNames(className, {
    [styles.small]: small,
    [styles.userBadge]: true,
  })
  return (
    <div className={classes} style={style} translate="no">
      {text}
    </div>
  )
}

export const UserBadge = ({
  badge,
  style,
  xSmall,
  small,
  className,
  badgeCount,
  badgeTooltipText,
  onBadgeClick,
}: IProps) => {
  const [profileImageLoaded, setProfileImageLoaded] = React.useState(false)

  if (!badge) {
    return null
  }

  const classes = classNames(className, {
    [styles.small]: small,
    [styles.xSmall]: xSmall,
    [styles.userBadge]: badge.backgroundColor.isLight,
    [`${styles.userBadge} ${styles.light}`]: !badge.backgroundColor.isLight,
  })
  const badgeClasses = classNames(styles.badgeCount, {
    [styles.badgeClickable]: !!onBadgeClick,
  })

  const backgroundColor = React.useMemo(() => {
    return profileImageLoaded ? 'transparent' : `#${badge.backgroundColor.hex}`
  }, [profileImageLoaded, badge.backgroundColor.hex])

  useEffectSkipFirstRender(() => {
    setProfileImageLoaded(false)
  }, [badge.profileImage])

  return (
    <div className={classes} style={{ backgroundColor, ...style }} translate="no">
      {badgeCount !== undefined ? (
        <div className={badgeClasses} onClick={onBadgeClick}>
          <Tooltip text={badgeTooltipText}>
            <span>{badgeCount}</span>
          </Tooltip>
        </div>
      ) : null}
      {badge.text}
      {badge.profileImage && (
        <img
          alt="Profile image"
          src={badge.profileImage}
          onLoad={() => setProfileImageLoaded(true)}
        />
      )}
    </div>
  )
}

export default withFragmentContainer<IProps>({
  badge: graphql`
    fragment UserBadge_badge on UserBadge {
      text
      profileImage
      backgroundColor {
        hex
        isLight
      }
    }
  `,
})(UserBadge)
