import { NotFoundError } from 'navi'
import { useCurrentRoute } from 'react-navi'
import { formValueSelector } from 'redux-form'

export function objectAsArray<T extends { id: string }>(obj: { [id: string]: T }) {
  if (!obj) {
    return []
  }
  return Object.keys(obj).map((id) => obj[id])
}

// https://stackoverflow.com/a/46700791
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const isEmpty = (text: any) =>
  !text ||
  (typeof text === 'string' &&
    (text === '' || text.replace(/<br ?\/?>/gi, '').replace(/<\/?p>/gi, '') === ''))

export const isObjEmpty = (obj: object) => {
  for (const key in obj) {
    // This should not be possible but `queryString.parse` returns an object
    // without `hasOwnProperty`
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }
  return true
}

export const isLTI = () => {
  try {
    // Will throw an exception if window.top is in a different domain,
    // in which case we are inside LTI.
    return !window?.top?.document
  } catch (e) {
    return true
  }
}

export const selector = (form: string, state: any, ...other: string[]) =>
  formValueSelector(form)(state, ...other)

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export function reorder<T>(items: readonly T[], startIndex: number, endIndex: number) {
  const result = Array.from(items)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const isDataURI = (thumbnailUrl: string) =>
  thumbnailUrl.trim().startsWith('data:')

export const isObject = (value: any) => {
  const type = typeof value
  return value != null && (type === 'object' || type === 'function')
}

export const notFoundError = () => {
  const route = useCurrentRoute()
  throw new NotFoundError(route.url.pathname)
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
export const escapeRegexCharacters = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export function isEqualSets<T>(a: Set<T>, b: Set<T>) {
  return a.size === b.size && [...a].every((element) => b.has(element))
}

export function handlePlural(singular: string, quantity: number, plural?: string) {
  if (quantity === 1) return singular

  if (plural) return plural

  return singular + 's'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(_x: never): never {
  // FROM: https://stackoverflow.com/a/39419171
  throw new Error("Didn't expect to get here")
}

export function deleteProperty(obj: Record<string, any>, property: string) {
  const newObject = { ...obj }
  delete newObject[property]
  return newObject
}

export const imageUrl = (url?: string | null) => {
  if (!url) return undefined

  if (!url.includes('unsplash.com')) return url

  const queryChar = url.includes('?') ? '&' : '?'

  return `${url}${queryChar}utm_source=eduflow&utm_medium=referral`
}

export const shuffleArray = (array: readonly any[]) => {
  // Taken from https://javascript.info/task/shuffle
  const shuffledArray = [...array]
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // random index from 0 to i

    // swap elements array[i] and array[j]
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }
  return shuffledArray
}

export const getRandomProperty = function (obj: Record<string, any>) {
  const keys = Object.keys(obj)
  return obj[keys[(keys.length * Math.random()) << 0]]
}

export const toggleClass = (
  element: Element,
  className: string,
  condition: boolean,
) => {
  if (condition) {
    element.classList.add(className)
  } else {
    element.classList.remove(className)
  }
}

export const truncateText = (text: string, maxLength: number) => {
  const truncatedText = text.split('').slice(0, maxLength).join('')
  return truncatedText.length !== text.length
    ? `${truncatedText.trimRight()}...`
    : truncatedText
}

export const areSetsEqual = (setA: Set<any>, setB: Set<any>): boolean => {
  if (setA.size !== setB.size) {
    return false
  }
  return Array.from(setA).every((x) => setB.has(x))
}
