/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateFlowTitleInput = {
    flowId: string;
    title: string;
    clientMutationId?: string | null | undefined;
};
export type updateFlowTitleMutationVariables = {
    input: UpdateFlowTitleInput;
};
export type updateFlowTitleMutationResponse = {
    readonly updateFlowTitle: {
        readonly flow: {
            readonly title: string;
            readonly activities: ReadonlyArray<{
                readonly flow: {
                    readonly title: string;
                } | null;
            }>;
        } | null;
    } | null;
};
export type updateFlowTitleMutation = {
    readonly response: updateFlowTitleMutationResponse;
    readonly variables: updateFlowTitleMutationVariables;
};



/*
mutation updateFlowTitleMutation(
  $input: UpdateFlowTitleInput!
) {
  updateFlowTitle(input: $input) {
    flow {
      title
      activities {
        __typename
        flow {
          title
          id
        }
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateFlowTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFlowTitlePayload",
        "kind": "LinkedField",
        "name": "updateFlowTitle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "activities",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PublicFlow",
                    "kind": "LinkedField",
                    "name": "flow",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateFlowTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateFlowTitlePayload",
        "kind": "LinkedField",
        "name": "updateFlowTitle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "activities",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PublicFlow",
                    "kind": "LinkedField",
                    "name": "flow",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "978736049747263675d11964650feb00",
    "id": null,
    "metadata": {},
    "name": "updateFlowTitleMutation",
    "operationKind": "mutation",
    "text": "mutation updateFlowTitleMutation(\n  $input: UpdateFlowTitleInput!\n) {\n  updateFlowTitle(input: $input) {\n    flow {\n      title\n      activities {\n        __typename\n        flow {\n          title\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'df578d6c7b4b2c1672dcab5d055550fa';
export default node;
