import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import S3Upload from 'utils/s3upload'

import { Icons } from 'components/Icons'
import { Spinner } from 'components/Spinner'

import styles from '../ImagePicker.module.scss'

const UploadPicker = ({
  submitImage,
  onError,
  s3Destination,
  className,
}: {
  submitImage: (url?: string) => void
  onError: (e: Error) => void
  s3Destination: string
  className?: string
}) => {
  const [loading, setLoading] = useState(false)

  const uploadImage = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length < 1) return

    setLoading(true)

    try {
      const file = acceptedFiles[0]
      const s3upload = new S3Upload({
        destination: s3Destination,
        file,
        onFinishS3Put: (url: string) => {
          submitImage(url)
          setLoading(false)
        },
      })
      s3upload.uploadFile(file, 'public')
    } catch (e) {
      onError(e)
      setLoading(false)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploadImage,
    disabled: loading,
    multiple: false,
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
  })

  return (
    <div className={classNames(styles.uploadPicker, className)}>
      <div className={styles.uploadZone} {...getRootProps()}>
        <input {...getInputProps()} />
        {loading ? (
          <Spinner large accentColor />
        ) : (
          <>
            <Icons.Download xLarge accentColor className={styles.uploadIcon} />
            <p>
              {isDragActive
                ? 'Drop the image here'
                : 'Click or drag an image to upload from your device'}
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default UploadPicker
