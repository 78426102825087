/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityType = "Certificate" | "Checklist" | "Content" | "Discussion" | "Embed" | "FeedbackReflection" | "Form" | "GoogleMeet" | "GradePassback" | "GroupFormation" | "GroupMemberReview" | "HapYak" | "InviteTA" | "MicrosoftTeams" | "PeerReview" | "Poll" | "Quiz" | "Scoring" | "Scorm" | "SelectTag" | "SelfReview" | "Submission" | "TeacherReview" | "TurnItIn" | "Video" | "Zoom";
export type UserType = "Admin" | "Assistant" | "Owner" | "Student" | "Teacher";
export type ActivityTitle_activity = {
    readonly id: string;
    readonly activityType: ActivityType;
    readonly title: string;
    readonly url: string;
    readonly source: {
        readonly id: string;
    } | null;
    readonly hasSubsetVisibilityRule: boolean | null;
    readonly ruleGroupsVisibility: ReadonlyArray<{
        readonly openFor?: ReadonlyArray<UserType> | null | undefined;
    }>;
    readonly flow: {
        readonly hasSubsetVisibilityRule: boolean | null;
    } | null;
    readonly feedbackRubric?: {
        readonly questionCount: number;
    } | null | undefined;
    readonly teacherRubric?: {
        readonly questionCount: number;
    } | null | undefined;
    readonly selfRubric?: {
        readonly questionCount: number;
    } | null | undefined;
    readonly settings?: {
        readonly selectableTags: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly isRubricEnabled?: boolean | undefined;
        readonly isEnabled?: boolean | undefined;
        readonly srcUrl?: string | undefined;
        readonly embedCode?: string | undefined;
        readonly entrypoint?: string | null | undefined;
        readonly inviteUrl: string;
    } | undefined;
    readonly inputs?: ReadonlyArray<{
        readonly isValid: boolean;
    }> | undefined;
    readonly poll?: {
        readonly isValid: boolean;
    } | null | undefined;
    readonly quiz?: {
        readonly isValid: boolean;
    } | null | undefined;
    readonly form?: {
        readonly isValid: boolean;
    } | null | undefined;
    readonly reflectionRubric?: {
        readonly questionCount: number;
    } | null | undefined;
    readonly isTurnItInValid?: boolean | undefined;
    readonly isBeingUsedInCourse?: boolean | undefined;
    readonly videoSettings?: {
        readonly videoUrl: string | null;
    } | null | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActivityContextMenu_activity" | "ActivityContainerProvider_activity" | "ActivityIcon_activity" | "StudentActivityHeader_activity">;
    readonly " $refType": "ActivityTitle_activity";
};
export type ActivityTitle_activity$data = ActivityTitle_activity;
export type ActivityTitle_activity$key = {
    readonly " $data"?: ActivityTitle_activity$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ActivityTitle_activity">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasSubsetVisibilityRule",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "questionCount",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isValid",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inviteUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityTitle_activity",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "source",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "ruleGroupsVisibility",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "openFor",
              "storageKey": null
            }
          ],
          "type": "RuleGroup",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PublicFlow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "feedbackRubric",
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rubric",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PeerReviewActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "teacherRubric",
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rubric",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TeacherReviewActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "selfRubric",
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rubric",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "SelfReviewActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SelectTagSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "selectableTags",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "SelectTagActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScoringInput",
          "kind": "LinkedField",
          "name": "inputs",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ScoringActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Poll",
          "kind": "LinkedField",
          "name": "poll",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "PollActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Quiz",
          "kind": "LinkedField",
          "name": "quiz",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "QuizActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Form",
          "kind": "LinkedField",
          "name": "form",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "FormActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedbackReflectionSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isRubricEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "reflectionRubric",
          "args": null,
          "concreteType": "Rubric",
          "kind": "LinkedField",
          "name": "rubric",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "FeedbackReflectionActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GradePassbackSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GradePassbackActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HapYakSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "srcUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "HapYakActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "isTurnItInValid",
          "args": null,
          "kind": "ScalarField",
          "name": "isValid",
          "storageKey": null
        }
      ],
      "type": "TurnItInActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmbedSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "embedCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EmbedActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScormSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entrypoint",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ScormActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBeingUsedInCourse",
          "storageKey": null
        }
      ],
      "type": "GroupFormationActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ZoomSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ZoomActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GoogleMeetSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "type": "GoogleMeetActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MicrosoftTeamsSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "type": "MicrosoftTeamsActivity",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoSettings",
          "kind": "LinkedField",
          "name": "videoSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "videoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "VideoActivity",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityContextMenu_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityContainerProvider_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityIcon_activity"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudentActivityHeader_activity"
    }
  ],
  "type": "Activity",
  "abstractKey": "__isActivity"
};
})();
(node as any).hash = '4b793d3be035b1ecd7c547871ae549f2';
export default node;
