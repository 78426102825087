import { GQLThemeBorderRadius, GQLThemeFontFamily } from 'types/graphqlTypes'

export interface ITheme {
  fontFamily: GQLThemeFontFamily | null
  accentColor: string | null
  borderRadius: GQLThemeBorderRadius | null
}

const setCssVariable = (name: string, value: string | null) => {
  document.documentElement.style.setProperty('--' + name, value)
  document.documentElement.style.setProperty('--notifly-' + name, value)
}

const removeCssVariable = (name: string) => {
  document.documentElement.style.removeProperty('--' + name)
  document.documentElement.style.removeProperty('--notifly-' + name)
}

const getBorderRadius = (borderRadius: GQLThemeBorderRadius) => {
  switch (borderRadius) {
    case 'Sharp':
      return '0'
    case 'Soft':
      return '0.25rem'
    case 'Softer':
      return '0.5rem'
    default:
      return '0.25rem'
  }
}

// Taken from https://gist.github.com/jedfoster/7939513
const mixColors = (color1: string, color2: string, weight: number) => {
  const d2h = (d: number) => d.toString(16)
  const h2d = (h: string) => parseInt(h, 16)

  // remove initial dash
  color1 = color1.substr(1)
  color2 = color2.substr(1)

  weight = typeof weight !== 'undefined' ? weight : 50 // set the weight to 50%, if that argument is omitted

  let color = '#'

  for (let i = 0; i <= 5; i += 2) {
    // loop through each of the 3 hex pairs—red, green, and blue
    const v1 = h2d(color1.substr(i, 2)) // extract the current pairs
    const v2 = h2d(color2.substr(i, 2))
    // combine the current pairs from each source color, according to the specified weight
    let val = d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)))

    while (val.length < 2) {
      val = '0' + val
    } // prepend a '0' if val results in a single digit

    color += val // concatenate val to our new color string
  }

  return color // PROFIT!
}

const addStylesheetURL = (url: string) => {
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = url
  document.getElementsByTagName('head')[0].appendChild(link)
}

const getFontFamilyCss = (fontFamily: GQLThemeFontFamily) => {
  if (fontFamily === 'Default') {
    return "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', 'Open Sans'"
  }
  if (fontFamily === 'OpenSans') {
    return "'Open Sans'"
  }
  return `'${fontFamily}'`
}

const getFontFamilyGoogleCode = (fontFamily: GQLThemeFontFamily) => {
  if (fontFamily === 'OpenSans') {
    return 'Open+Sans'
  }
  return fontFamily
}

const setFontFamily = (fontFamily: GQLThemeFontFamily) => {
  // Inspired by https://stackoverflow.com/a/65917124/8847344
  if (fontFamily !== 'Default') {
    addStylesheetURL(
      `https://fonts.googleapis.com/css2?family=${getFontFamilyGoogleCode(
        fontFamily,
      )}:wght@400;500;600;700&display=swap`,
    )
  }

  setCssVariable('font-sans-serif', `${getFontFamilyCss(fontFamily)}, sans-serif`)
}

const setAccentColor = (color: string) => {
  setCssVariable('color-theme-10', mixColors('#FFFFFF', color, 95))
  setCssVariable('color-theme-50', mixColors('#FFFFFF', color, 90))
  setCssVariable('color-theme-100', mixColors('#FFFFFF', color, 80))
  setCssVariable('color-theme-200', mixColors('#FFFFFF', color, 60))
  setCssVariable('color-theme-300', mixColors('#FFFFFF', color, 40))
  setCssVariable('color-theme-400', mixColors('#FFFFFF', color, 20))
  setCssVariable('color-theme-500', color)
  setCssVariable('color-theme-600', mixColors('#000000', color, 20))
  setCssVariable('color-theme-700', mixColors('#000000', color, 40))
  setCssVariable('color-theme-800', mixColors('#000000', color, 60))
  setCssVariable('color-theme-900', mixColors('#000000', color, 80))
}

const setBorderRadius = (themeBorderRadius: GQLThemeBorderRadius) => {
  const borderRadius = getBorderRadius(themeBorderRadius)
  setCssVariable('border-radius', borderRadius)
}

const resetFontFamily = () => {
  removeCssVariable('font-sans-serif')
}

const resetAccentColor = () => {
  removeCssVariable('color-theme-10')
  removeCssVariable('color-theme-50')
  removeCssVariable('color-theme-100')
  removeCssVariable('color-theme-200')
  removeCssVariable('color-theme-300')
  removeCssVariable('color-theme-400')
  removeCssVariable('color-theme-500')
  removeCssVariable('color-theme-600')
  removeCssVariable('color-theme-700')
  removeCssVariable('color-theme-800')
  removeCssVariable('color-theme-900')
}

const resetBorderRadius = () => {
  removeCssVariable('border-radius')
}

export const applyTheme = (theme: ITheme | null) => {
  if (theme) {
    theme.borderRadius ? setBorderRadius(theme.borderRadius) : resetBorderRadius()
    theme.accentColor ? setAccentColor(theme.accentColor) : resetAccentColor()
    theme.fontFamily ? setFontFamily(theme.fontFamily) : resetFontFamily()
  } else {
    resetBorderRadius()
    resetAccentColor()
    resetFontFamily()
  }
}

export const isDefaultTheme = (theme: ITheme | null, defaultTheme?: ITheme | null) => {
  if (!theme) return true

  if (!defaultTheme) {
    defaultTheme = {
      fontFamily: 'Default',
      accentColor: '#006CFA',
      borderRadius: 'Soft',
    }
  }

  const isDefaultFontFamily =
    theme.fontFamily === null || theme.fontFamily === defaultTheme.fontFamily

  const isDefaultAccentColor =
    theme.accentColor === null || theme.accentColor === defaultTheme.accentColor

  const isDefaultBorderRadius =
    theme.borderRadius === null || theme.borderRadius === defaultTheme.borderRadius

  return isDefaultFontFamily && isDefaultAccentColor && isDefaultBorderRadius
}

export const colorsContrast = (color1: string, color2: string) => {
  // From https://medium.com/tamman-inc/create-your-own-color-contrast-checker-11d8b95dff5b

  function luminance(r: number, g: number, b: number) {
    const [lumR, lumG, lumB] = [r, g, b].map((component) => {
      const proportion = component / 255

      return proportion <= 0.03928
        ? proportion / 12.92
        : Math.pow((proportion + 0.055) / 1.055, 2.4)
    })

    return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB
  }

  function contrastRatio(luminance1: number, luminance2: number) {
    const lighterLum = Math.max(luminance1, luminance2)
    const darkerLum = Math.min(luminance1, luminance2)

    return (lighterLum + 0.05) / (darkerLum + 0.05)
  }

  const [luminance1, luminance2] = [color1, color2].map((color) => {
    /* Remove the leading hash sign if it exists */
    color = color.startsWith('#') ? color.slice(1) : color

    const r = parseInt(color.slice(0, 2), 16)
    const g = parseInt(color.slice(2, 4), 16)
    const b = parseInt(color.slice(4, 6), 16)

    return luminance(r, g, b)
  })

  return contrastRatio(luminance1, luminance2)
}
