import classNames from 'classnames'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useCurrentRoute } from 'react-navi'
import { graphql, useFragment } from 'react-relay'

import { TestUsersContextMenu_course$key } from '__generated__/TestUsersContextMenu_course.graphql'
import useTryAndNotify from 'hooks/useTryAndNotify'
import addTestUsersToCourse from 'mutations/addTestUsersToCourse'
import removeParticipants from 'mutations/removeParticipants'
import { fromGlobalId, toGlobalId } from 'utils/relay'

import { Button } from 'components/Button'
import { ContextMenu } from 'components/ContextMenu'
import { Divider } from 'components/Divider'
import { Icons } from 'components/Icons'
import { ModalContext } from 'components/Modal/ModalProvider'
import { useToast } from 'components/NotificationCenter'
import { NumberInput } from 'components/NumberInput'
import { Spinner } from 'components/Spinner'
import { UserBadge } from 'components/UserBadge'
import { DangerModal, IDangerModalProps } from 'components/modals/DangerModal'

import styles from './TestUsersContextMenu.module.scss'

interface IProps {
  course: TestUsersContextMenu_course$key
}

function parseValue(value: string) {
  if (value && value === '') {
    return null
  }

  let parsed = parseInt(value, 10)
  if (isNaN(parsed)) {
    parsed = 0
  }

  if (parsed > 20) {
    parsed = 20
  }

  return parsed
}

export const useTestUsersContextMenu = ({ course: courseProp }: IProps) => {
  const course = useFragment<TestUsersContextMenu_course$key>(
    graphql`
      fragment TestUsersContextMenu_course on Course {
        id
        testLearnerCount
        testAssistantCount
        hasTestRealUserMix
        testLearners {
          id
          name
          badge {
            ...UserBadge_badge
          }
        }
        testAssistants {
          id
          name
          badge {
            ...UserBadge_badge
          }
        }
        institution {
          hasPreviewCourseAssistants: isFeatureEnabled(feature: ASSISTANT_ROLE)
        }
      }
    `,
    courseProp,
  )

  const {
    data: { activityId },
  } = useCurrentRoute()

  const [testLearnerCount, setTestLearnerCount] = useState(5)
  const [testAssistantCount, setTestAssistantCount] = useState(0)
  const [isAddingTestUsers, setIsAddingTestUsers] = useState(false)
  const [isRemovingTestUsers, setIsRemovingTestUsers] = useState(false)
  const { showModal } = useContext(ModalContext)
  const { displayToast } = useToast()
  const tryAndNotify = useTryAndNotify()
  const handleImpersonate = useCallback(
    (userId) => {
      if (isRemovingTestUsers) {
        return null
      }
      const queryString = activityId ? `?activity_id=${activityId}` : ''
      window.location.href = `/courses/${fromGlobalId(course.id).id}/participants/${
        fromGlobalId(userId).id
      }/login-as${queryString}`
    },
    [isRemovingTestUsers, course.id, activityId],
  )

  const testUserCount =
    (course.testAssistantCount ?? 0) + (course.testLearnerCount ?? 0)

  const handleAssistantCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!course.institution?.hasPreviewCourseAssistants) {
      return
    }

    const {
      currentTarget: { value },
    } = event

    const parsed = parseValue(value)

    if (parsed === null) {
      return
    }

    setTestAssistantCount(parsed)
  }

  const handleLearnerCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event

    const parsed = parseValue(value)

    if (parsed === null) {
      return
    }

    setTestLearnerCount(parsed)
  }

  const handleAddTestUsers = async () => {
    setIsAddingTestUsers(true)

    if (
      testLearnerCount < 0 ||
      testLearnerCount > 20 ||
      (!!course.institution?.hasPreviewCourseAssistants &&
        (testAssistantCount < 0 || testAssistantCount > 20))
    ) {
      displayToast('Can only add 1 to 20 test users per user type', 'saveFailed')
    }

    let allUsersAdded = true

    if (testLearnerCount > 0 && testLearnerCount <= 20) {
      try {
        const response = await addTestUsersToCourse({
          courseId: course.id,
          count: testLearnerCount,
          userType: 'Student',
        })

        if (!response.addTestUsersToCourse?.course.id) {
          allUsersAdded = false
        }
      } catch (e) {
        displayToast('Could not add test learners', 'saveFailed')
        allUsersAdded = false
      }
    }

    if (
      !!course.institution?.hasPreviewCourseAssistants &&
      testAssistantCount > 0 &&
      testAssistantCount <= 20
    ) {
      try {
        const response = await addTestUsersToCourse({
          courseId: course.id,
          count: testAssistantCount,
          userType: 'Assistant',
        })

        if (!response.addTestUsersToCourse?.course.id) {
          allUsersAdded = false
        }
      } catch (e) {
        displayToast('Could not add test assistants', 'saveFailed')
        allUsersAdded = false
      }
    }

    if (allUsersAdded) {
      displayToast('Successfully added all test users', 'success')
    } else {
      displayToast('Could not add one or more users', 'saveFailed')
    }

    setIsAddingTestUsers(false)
  }

  const testUserRowStyles = classNames(styles.testUserRow, {
    [styles.disabled]: isRemovingTestUsers,
  })

  const handleRemoveTestData = () => {
    showModal<IDangerModalProps>(DangerModal, {
      title: 'Are you sure?',
      message: 'You are about to remove all test users and their data from the course.',
      confirmText: 'Remove test data',
      cancelText: 'Cancel',
      onConfirm: async () => {
        await tryAndNotify(
          async () => {
            await removeParticipants({
              courseId: course.id,
              participantIds: [
                ...course.testLearners.map((learner) =>
                  toGlobalId('Participant', fromGlobalId(learner.id).id),
                ),
                ...course.testAssistants.map((assistant) =>
                  toGlobalId('Participant', fromGlobalId(assistant.id).id),
                ),
              ],
            })
          },
          setIsRemovingTestUsers,
          'Successfully removed all test users',
        )
      },
    })
  }

  const TestLearnersExistComponent = useMemo(() => {
    return (
      <>
        <div className={styles.testUsersList}>
          {course.hasTestRealUserMix ? (
            <div className={styles.mixedTestRealUserWarning}>
              <Icons.WarningTriangle nearBlack className={styles.icon} />
              <p>You have a mix of test and real users in your course</p>
            </div>
          ) : null}
          <p className={styles.headerLearner}>Log in as test learners</p>
          {course.testLearners.map((learner) => {
            return (
              <button
                key={learner.id}
                className={testUserRowStyles}
                onClick={() => handleImpersonate(learner.id)}
                disabled={isRemovingTestUsers}
              >
                <UserBadge badge={learner.badge} className={styles.testUserBadge} />
                {learner.name}
              </button>
            )
          })}
          {course.testAssistants.length > 0 ? (
            <>
              <p className={styles.headerAssistant}>Log in as test assistants</p>
              {course.testAssistants.map((assistant) => {
                return (
                  <button
                    key={assistant.id}
                    className={testUserRowStyles}
                    onClick={() => handleImpersonate(assistant.id)}
                    disabled={isRemovingTestUsers}
                  >
                    <UserBadge
                      badge={assistant.badge}
                      className={styles.testUserBadge}
                    />
                    {assistant.name}
                  </button>
                )
              })}
            </>
          ) : null}
        </div>
        <Divider />
        <button
          className={`${styles.removeTestDataBtn} ${
            isRemovingTestUsers ? styles.loading : ''
          }`}
          onClick={handleRemoveTestData}
          disabled={isRemovingTestUsers}
        >
          <Icons.Trash className={styles.icon} />
          Remove test data
          {isRemovingTestUsers && <Spinner className={styles.spinner} />}
        </button>
      </>
    )
  }, [
    course.testLearners,
    course.testAssistants,
    isRemovingTestUsers,
    handleRemoveTestData,
  ])

  const renderMenu = () => {
    return testUserCount > 0 ? (
      TestLearnersExistComponent
    ) : (
      <>
        <p className={styles.instructions}>
          Add test users to your course to test your course design by easily
          impersonating their accounts.
        </p>
        <div className={styles.inputList}>
          <div className={styles.inputRow}>
            <span>Test learners</span>
            <NumberInput
              min={0}
              max={20}
              input={{
                onChange: handleLearnerCountChange,
                value: testLearnerCount,
              }}
            />
          </div>
          {!!course.institution?.hasPreviewCourseAssistants && (
            <div className={styles.inputRow}>
              <span>Test assistants</span>
              <NumberInput
                min={0}
                max={20}
                input={{
                  onChange: handleAssistantCountChange,
                  value: testAssistantCount,
                }}
              />
            </div>
          )}
        </div>
        <Button
          className={styles.addButton}
          onClick={handleAddTestUsers}
          isLoading={isAddingTestUsers}
          Disabled={
            !(testLearnerCount > 0 && testLearnerCount <= 20) &&
            !(testAssistantCount > 0 && testAssistantCount <= 20)
          }
        >
          <Icons.Plus accentColor className={styles.icon} />
          Add test users
        </Button>
      </>
    )
  }

  const renderElement = (ref: React.RefObject<HTMLElement>) => {
    return (
      <div ref={ref as React.RefObject<HTMLDivElement>} className={styles.container}>
        {renderMenu()}
      </div>
    )
  }

  const renderLabel = (
    ref: React.RefObject<HTMLElement>,
    { onClick }: { onClick: React.MouseEventHandler },
  ) => (
    <Button ref={ref} onClick={onClick} className={styles.button}>
      {testUserCount > 0 ? (
        <span className={styles.btnCount}>{testUserCount}</span>
      ) : (
        <Icons.Eye className={styles.icon} accentColor />
      )}
      Test users
    </Button>
  )

  return {
    renderElement,
    renderLabel,
    renderMenu,
    isRemovingTestUsers,
  }
}

const TestUsersContextMenu = ({ course }: IProps) => {
  const { renderElement, renderLabel } = useTestUsersContextMenu({ course })

  return (
    <ContextMenu
      renderLabel={renderLabel}
      renderElement={renderElement}
      zIndex={100}
      attachment="top left"
      targetAttachment="bottom left"
    />
  )
}

export default TestUsersContextMenu
