import React from 'react'
import { EventOrValueHandler, WrappedFieldProps } from 'redux-form'

import { ErrorMessage } from 'components/ErrorMessage'

import styles from './NumberInput.module.scss'

export interface INumberInputHandles {
  getValueSafe(): number
  select(): number
}

interface IProps extends Partial<Omit<WrappedFieldProps, 'input'>> {
  disabled?: boolean
  className?: string
  min: number
  max?: number
  input: {
    name?: string
    value: any
    onChange: EventOrValueHandler<React.ChangeEvent<HTMLInputElement>>
    onBlur?: React.ChangeEventHandler<HTMLInputElement>
  }
  onClick?: React.MouseEventHandler<HTMLInputElement>
  preNumberLabel?: string
}

const safeGetNumberFieldValue = (ref: React.RefObject<HTMLInputElement>) => {
  if (!ref || !ref.current) {
    return 0
  }

  const value = parseInt(ref.current.value, 10)
  if (isNaN(value)) {
    return 0
  }

  return value
}

const NumberInput = React.forwardRef(
  (
    { disabled, className, input, onClick, preNumberLabel, meta, min, max }: IProps,
    ref,
  ) => {
    const inputRef = React.createRef<HTMLInputElement>()
    const { touched, error } = meta || {}
    const extraProps: Pick<IProps, 'onClick'> = {
      ...(onClick ? { onClick } : {}),
    }

    React.useImperativeHandle(ref, () => ({
      getValueSafe: () => {
        return safeGetNumberFieldValue(inputRef)
      },
      select: () => {
        return inputRef.current && inputRef.current.select()
      },
    }))
    return (
      <div>
        {touched && error && (
          <ErrorMessage Animated className="mb3">
            {error}
          </ErrorMessage>
        )}
        <div>
          {preNumberLabel && <span className="mr2">{preNumberLabel}</span>}
          <input
            {...input}
            {...extraProps}
            ref={inputRef}
            className={`${styles.numericInput} ${className || ''}`}
            type="number"
            min={min}
            max={max}
            disabled={disabled}
          />
        </div>
      </div>
    )
  },
)

export default NumberInput
